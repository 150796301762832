import { getAvatarInitials } from "../../Utils/helper";
import jobIcon from "../../assets/svg/icon/jobIcon.svg";

function TextAvatar({
  name,
  fontSize,
  size,
  isJob,
}: {
  name: string | any;
  fontSize: number;
  size: number;
  isJob?: boolean;
}) {
  return (
    <div
      style={{
        width: size,
        aspectRatio: 1,
        borderRadius: size / 2,
        backgroundColor: "#d0daff",
        color: "#3c65f5",
        border: "1px solid #3c65f5",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontWeight: 600,
        fontSize: fontSize,
        letterSpacing: 1.1,
      }}
    >
      {!isJob ? (
        getAvatarInitials(name)
      ) : (
        <img
          src={jobIcon}
          style={{ width: size / 1.6, aspectRatio: 1, objectFit: "contain" }}
        />
      )}
    </div>
  );
}

export default TextAvatar;
