import { Col, Row, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import CustomCheckButtons from "../../../components/reusableComponents/CustomCheckButton/CustomCheckButtons";
import {
  remoteResource,
  currencyType,
  paymentDuration,
} from "../../../enums/CreateJobEnums";
import Slider from "rc-slider";
import { useEffect } from "react";
import {
  currencyTypeArray,
  paymentDurationArray,
} from "../../../constants/enumLabel";

function Step5({
  step,
  setStep,
  showModal,
  remoteResourceValue,
  setRemoteResourceValue,
  budgetValue,
  setBudgetValue,
  currencyTypeValue,
  setCurrencyTypeValue,
  paymentDurationValue,
  setPaymentDurationValue,
}: {
  step: number;
  setStep: Function;
  showModal: Function;
  remoteResourceValue: number;
  setRemoteResourceValue: Function;
  budgetValue: number;
  setBudgetValue: Function;
  currencyTypeValue: number;
  setCurrencyTypeValue: Function;
  paymentDurationValue: number;
  setPaymentDurationValue: Function;
}) {
  useEffect(() => {
    setBudgetValue(45);
  }, [paymentDurationValue]);
  return (
    <div className="createJob_container2 px-sm-5 px-2">
      <Row className="createJob_contentContainer mt-2 mb-4">
        <Col className="col-12 col-md-5">
          <Row className="createJob_container_title py-4">
            <Col>{LangEN.AreYouOpenToWorkWithRemoteResources}</Col>
          </Row>
          <Row className="createJob_contentContainer">
            <Form.Group>
              <CustomCheckButtons
                label={LangEN.Yes}
                id="step5-1"
                checked={remoteResourceValue == remoteResource.Yes}
                onChange={() => {
                  setRemoteResourceValue(remoteResource.Yes);
                }}
              />
              <CustomCheckButtons
                label={LangEN.No}
                checked={remoteResourceValue == remoteResource.No}
                id="step5-2"
                onChange={() => {
                  setRemoteResourceValue(remoteResource.No);
                }}
              />
              <CustomCheckButtons
                label={LangEN.NotSure}
                checked={remoteResourceValue == remoteResource.NotSure}
                onChange={() => {
                  setRemoteResourceValue(remoteResource.NotSure);
                }}
                id="step5-3"
              />
            </Form.Group>
          </Row>
        </Col>
        <Col className="col-2 d-none d-md-block createJob_center py-0 py-md-5">
          <div className="vertical-divider " />
        </Col>
        <Col className="col-12 col-md-5 mb-5">
          <Row className="createJob_container_title py-4">
            <Col>{LangEN.WhatYourBudgetForThisRole}</Col>
          </Row>
          <Row className="createJob_contentContainer">
            <Row>
              <Row className="mb-4 gy-3">
                <Col md={12} lg={7}>
                  <Form.Select
                    onChange={(e) => setCurrencyTypeValue(e.target.value)}
                    className="shadow-none createJob_select"
                    value={currencyTypeValue}
                  >
                    <option disabled>{LangEN.SelectCurrencyType}</option>
                    <option selected value={currencyType.Dollar}>
                      {LangEN.Dollar}
                    </option>
                    <option value={currencyType.Rupee}>{LangEN.Rupee}</option>
                    <option value={currencyType.Dinar}>{LangEN.Dinar}</option>
                    <option value={currencyType.Euro}>{LangEN.Euro}</option>
                  </Form.Select>
                </Col>
                <Col md={12} lg={5}>
                  <Form.Select
                    onChange={(e) => setPaymentDurationValue(e.target.value)}
                    value={paymentDurationValue}
                    className="shadow-none createJob_select"
                  >
                    <option disabled>{LangEN.PaymentDuration}</option>
                    <option selected value={paymentDuration.Hourly}>
                      {LangEN.Hourly}
                    </option>
                    <option value={paymentDuration.Daily}>
                      {LangEN.Daily}
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <h3 style={styles.labelBlue}>
                {currencyTypeArray[currencyTypeValue]}
                {budgetValue}/{paymentDurationArray[paymentDurationValue]}
              </h3>
              <Slider
                className="mt-2"
                min={1}
                max={paymentDurationValue == paymentDuration.Daily ? 1000 : 120}
                value={budgetValue}
                onChange={(e: any) => setBudgetValue(e)}
                defaultValue={20}
                railStyle={styles.railStyle}
                trackStyle={styles.trackStyle}
                handleStyle={styles.handleStyle}
                //handleRender={handleRender}
              />
            </Row>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex align-item-center createJob_spaceBetween gy-2">
        <Col md={6} lg={5}>
          <CustomButton
            className="createJob_cancel px-4"
            label={LangEN.Cancel}
            onClick={() => showModal()}
          />
        </Col>
        <Col
          md={6}
          lg={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="col-auto createJob_index"
        >{`${LangEN.Step} ${step - 1} ${LangEN.Of} 5`}</Col>
        <Col md={12} lg={5} className="d-flex justify-content-end">
          <Row className="d-flex justify-content-end gy-2">
            <Col>
              <CustomButton
                className="createJob_previous px-4"
                label={LangEN.Previous}
                onClick={() => {
                  setStep(4);
                }}
                iconType="previous"
              />
            </Col>
            <Col>
              <CustomButton
                className="createJob_next px-4"
                label={LangEN.JobPreview}
                onClick={() => {
                  setStep(7);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const styles = {
  labelBlue: {
    fontSize: 20,
    color: "#3c64f3",
    fontWeight: 600,
  },
  railStyle: {
    backgroundColor: "#ddd",
    height: 8,
  },
  trackStyle: {
    backgroundColor: "#3c64f3",
    height: 8,
  },
  handleStyle: {
    borderColor: "#3c64f3",
    borderWidth: 7,
    height: 24,
    width: 24,
    marginLeft: 5,
    marginTop: -8,
    backgroundColor: "#fff",
    opacity: 1,
  },
};

export default Step5;
