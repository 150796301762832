import React from "react";
import { Button } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import Theme from "../../constants/Theme";
import { SignUpEnabledContext } from "../../App";
import { useContext } from "react";

interface ButtonInterface {
  label: string;
  onClick?: any;
  style?: any;
  variant?: string;
  hrefLogin?: string;
  hrefSignin?: string;
  type?: any;
  className?: string;
  size?: "sm" | "lg" | undefined;
  disabled?: boolean;
}

const CustomAuthRoutingButtons = (props: ButtonInterface) => {
  const isSignUpEnabled = useContext(SignUpEnabledContext);

  const innerButtonStyle = {
    flexGrow: 1,
    backgroundColor: "transparent",
    border: "none",
    borderWidth: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    color: props.variant != "light" ? "white" : Theme.colors.SECONDARY,
    fontSize: "18px",
    margin: 5,
  };
  return (
    <div
      className="welcom_header_button_container"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: 10,
      }}
    >
      <Button
        disabled={props.disabled}
        //variant={props.variant}
        // href={props.href}
        style={props.style}
        onClick={props.onClick}
        type={props.type}
        className={"welcome_header_login_button_fake_button"}
        size={props.size}
      >
        {props.label}
      </Button>
      <div // for hidden buttons - show on hover
        style={{
          flexShrink: 0,
          backgroundColor: "transparent",
          width: "100%",
          height: 10,
        }}
      ></div>
      <div
        style={{
          backgroundColor:
            props.variant == "light"
              ? "white"
              : Theme.backgroundColor.DARKBUTTON,
          flexShrink: 0,
          position: "absolute",
          height: 20,
          width: 20,
          rotate: "45deg",
          translate: "107px 48px",
        }}
      />
      <div
        style={{
          display: "flex",
          position: "relative",
          height: "40px",
          borderRadius: 30,
          width: "250px",
          color: props.variant != "light" ? "white" : Theme.colors.SECONDARY,
          backgroundColor:
            props.variant == "light"
              ? "white"
              : Theme.backgroundColor.DARKBUTTON,
        }}
      >
        <Button href={props.hrefLogin} style={innerButtonStyle}>
          {LangEN.Login}
        </Button>
        {isSignUpEnabled && (
          <>
            <div // login and sign up divider
              style={{
                width: "2px",
                height: "60%",
                flexShrink: 0,
                borderRadius: 2,
                alignSelf: "center",
                backgroundColor:
                  props.variant != "light" ? "white" : Theme.colors.SECONDARY,
              }}
            />
            <Button href={props.hrefSignin} style={innerButtonStyle}>
              {LangEN.SignUp}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomAuthRoutingButtons;
