import Http from "./HttpService";
import { API } from "../constants/ApiDetails";

class JobSApplicationService {
  public static async GetJobApplications(jobId: any) {
    const url = `${API.JOB_APPLICATIONS}/${jobId}`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetAcceptedJobApplications(jobId: any) {
    const url = `${API.ACCEPTED_JOB_APPLICATIONS}/${jobId}`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetRejectedJobApplications(jobId: any) {
    const url = `${API.REJECTED_JOB_APPLICATIONS}/${jobId}`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetShortlistedJobApplications(jobId: any) {
    const url = `${API.SHORTLISTED_JOB_APPLICATIONS}/${jobId}`;
    const response = await Http.get(url);
    return response;
  }

  public static async UpdateJobApplicationStatus(apiReq: any) {
    const url = `${API.UPDATE_JOB_APPLICATIONS}`;
    const response = await Http.put(url, apiReq);
    return response;
  }

  public static async ApplyForJob(apiReq: any) {
    const url = `${API.JOB_APPLICATIONS}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async GetJobApplicationStatus(jobId: any) {
    const url = `${API.GET_JOB_APPLICATION_STATUS}/${jobId}`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetHiredJob(search: string) {
    const url = `${API.GET_HIRED_JOBS}/${search}`;
    const response = await Http.get(url);
    return response;
  }

  public static async RevokeJobApplication(jobId: number) {
    const url = `${API.REVOKE_JOB}/${jobId}`;
    const response = await Http.get(url);
    return response;
  }

  public static async TotalCompletedJobs() {
    const url = `${API.TOTAL_JOBS_COMPLETED}`;
    const response = await Http.get(url);
    return response;
  }
  public static async TotalCompletedJobsById(userId: number) {
    const url = `${API.TOTAL_JOBS_COMPLETED}/${userId}`;
    const response = await Http.get(url);
    return response;
  }
}

export default JobSApplicationService;
