import Theme from "../../constants/Theme";

export const FooterStyles = {
  root: {
    backgroundColor: Theme.colors.SECONDARY,
    minWidth: "100%",
    paddingInline: "5vw",
  },
  label: {
    color: Theme.colors.WHITE,
    fontWeight: 300,
    flexGrow: 1,
    textAlign: "center" as "center",
    cursor: "pointer",
  },
  Image: {
    cursor: "pointer",
  },
  label2: {
    color: Theme.colors.WHITE,
    fontWeight: 300,
  },
  row1: {
    padding: "15px 0 15px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  col1: {
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: "20px",
  },
  col2: {
    display: "flex",
    justifyContent: "center",
  },
  col3: {
    justifyContent: "center",
    display: "flex",
  },
};
