import { useState } from "react";
import "../../screens/StaticPages/styles.css";
import { Navigate, Outlet } from "react-router-dom";
import Header1 from "../../components/sharedComponents/NavigationHeader";
import Footer from "../../components/sharedComponents/Footer";
import Header from "../../components/sharedComponents/Header";

function StaticPageRouter() {
  const [token, setToken] = useState(localStorage.getItem("loginToken"));

  if (!token) {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Header />
        <div
          className="staticPages"
          style={{ backgroundColor: "#F5F5F5", flexGrow: 1 }}
        >
          <Outlet />
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#f1f3f6",
        }}
        className="staticPages"
      >
        <Header1 />
        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    );
  }
}

export default StaticPageRouter;
