import "./styles.css";
import { Col, Row } from "react-bootstrap";
import TextAvatar from "../TextAvatar";
import { LightChip } from "../LightChip";
import { jobStatusMenu } from "../../../enums/JobStatus";
import CustomButton from "../CustomButton";
import { LangEN } from "../../../constants/Lang/LangEN";
import { useContext, useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import moment from "moment";
import JobSApplicationService from "../../../services/JobApplicationService";
import { jobApplicationStatus } from "../../../enums/JobApplicationStatus";
import inviteImg from "../../../assets/svg/icon/invite.svg";
import {
  mapJobRole,
  setCurrencyType,
  setPaymentDuration,
} from "../../../Utils/Mappers";
import { User } from "../../../App";
import { UserRoles } from "../../../enums/UserRoles";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import JobInvitationService from "../../../services/JobInvitationService";
import Avatar from "../Avatar";

function ApplicationCard({
  application,
  jobSorting,
  onClick,
}: {
  application: any;
  jobSorting: number;
  onClick: Function;
}) {
  const [loading, setLoading] = useState(true);
  const user = useContext(User);
  const [profileInfo, setProfileInfo] = useState<any>();
  const [isInvited, setIsInvited] = useState(false);
  useEffect(() => {
    UserService.GetProfileById(application.appliedBy)
      .then((res) => {
        setProfileInfo(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let apiReq = {
      appliedBy: application.appliedBy,
      jobId: application.job.id,
    };
    JobInvitationService.IsInvitedForJob(apiReq)
      .then((res) => {
        setIsInvited(res.data.isInvited);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateJobApplicationStatus = (status: number) => {
    let apiReq = {
      id: application.id,
      applicationStatus: status,
    };
    JobSApplicationService.UpdateJobApplicationStatus(apiReq)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onJobApplicationAcceptButton = (menu: number) => {
    if (jobStatusMenu.Received === menu && user.role === UserRoles.Business) {
      updateJobApplicationStatus(jobApplicationStatus.Accepted);
    } else if (
      jobStatusMenu.Received === menu &&
      user.role !== UserRoles.Business
    ) {
      updateJobApplicationStatus(jobApplicationStatus.Shortlisted);
    } else if (jobStatusMenu.Shortlisted === menu) {
      updateJobApplicationStatus(jobApplicationStatus.Accepted);
    }
  };

  return (
    <Col xl={3} lg={4} md={4} sm={6} xs={12} className="p-2">
      <div
        className="applicationCard_container p-3"
        onClick={() => {
          onClick();
        }}
      >
        {isInvited ? (
          <div className="jobApplication_invite">
            <img src={inviteImg} />
          </div>
        ) : null}

        <Row>
          <Col xs="auto" className="m-0 p-0 ms-3">
            {loading ? (
              <Skeleton circle style={{ width: 50, aspectRatio: 1 }} />
            ) : (
              <Avatar
                url={profileInfo.profilePicture}
                size={50}
                fontSize={22}
                firstName={profileInfo.user.firstName}
                lastName={profileInfo.user.lastName}
              />
            )}
          </Col>
          <Col>
            <div className="applicationCard_title">
              {loading ? (
                <Skeleton height={30} />
              ) : (
                `${profileInfo?.user?.firstName} ${
                  profileInfo?.user?.lastName ? profileInfo?.user?.lastName : ""
                }`
              )}
            </div>
            {loading ? (
              <Skeleton height={20} />
            ) : (
              <div className="applicationCard_subtitle">
                {mapJobRole(application.appliedAs)}
                <span className="applicationCard_time px-2">
                  ({moment(application.createdAt).fromNow(true)})
                </span>
              </div>
            )}
          </Col>
        </Row>
        <div className="applicationCard_description p-2 ellipsis-3">
          {loading ? <Skeleton count={3} /> : profileInfo?.aboutMe}
        </div>
        <div className="applicationCard_tagContainer">
          {profileInfo?.customSkill?.map((item: any, index: number) => (
            <LightChip index={index} label={item.skillName} sm />
          ))}
          {profileInfo?.skill?.slice(0, 3).map((item: any, index: number) => (
            <LightChip index={index} label={item.skill.name} sm />
          ))}
          {profileInfo?.skill?.length > 3 && (
            <div
              className="d-flex align-items-center"
              style={{ color: "#3C65F5" }}
            >
              {LangEN.SeeMore}
            </div>
          )}
        </div>
        {loading ? (
          <Skeleton height={40} />
        ) : (
          <Row className="d-flex  mt-3">
            <Col xs={"auto"} className="applicationCard_cost">
              {`${setCurrencyType(application.job.currencyType)}${
                application.job.budget
              }`}
              <span className="applicationCard_costSpan">
                {" "}
                {`${setPaymentDuration(application.job.paymentDuration)}`}
              </span>
            </Col>

            {jobStatusMenu.Received === jobSorting ||
            jobStatusMenu.Shortlisted === jobSorting ? (
              <Col>
                <Row className="d-flex gap-2 justify-content-end px-3 flex-nowrap">
                  <Col xs="auto" className="m-0 p-0">
                    <CustomButton
                      className="applicationCard_button"
                      label={LangEN.Reject}
                      onClick={(e: any) => {
                        e.stopPropagation();

                        updateJobApplicationStatus(
                          jobApplicationStatus.Rejected
                        );
                      }}
                    />
                  </Col>
                  <Col xs="auto" className="m-0 p-0">
                    <CustomButton
                      className="applicationCard_button2"
                      label={LangEN.Accept}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onJobApplicationAcceptButton(jobSorting);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
        )}
      </div>
    </Col>
  );
}

export default ApplicationCard;
