import Theme from "../../constants/Theme";

export const MyProjectStyles = {
  container: {
    minWidth: "100vw",
    backgroundColor: Theme.backgroundColor.CONTAINERBACKGROUND,
    paddingInline: "5vw",
  },
  heading: { fontWeight: 600, fontSize: 22 },
  card: {
    width: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    minHeight: "70vh",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },

  infoIcon: {
    borderRadius: 15,
    backgroundColor: "#fff",
    position: "absolute" as "absolute",
    border: "solid white 4px",
  },
  title: { fontWeight: 700, fontSize: 22 },
  card1: {
    width: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    borderRadius: 10,
    maxWidth: 350,
  },
  cardTitle: { fontWeight: 700, fontSize: 22 },
  cardSubTitle: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center" as "center",
  },
  divider: {
    width: "100%",
    backgroundColor: "#bbb",
    height: 1,
    alignSelf: "center",
  },
  row: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap" as "nowrap",
  },
  subTitle: { color: "#66789c" },
  nav: {
    fontWeight: 600,
    letterSpacing: 0.8,
  },
  invite: {
    color: "#00A938",
    fontSize: 16,
    textDecoration: "underline",
    cursor: "pointer",
  },
  invite2: {
    color: "#00A938",
    fontSize: 16,
  },
};
