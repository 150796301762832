import { useState, useContext } from "react";
import { Container, Card, Form, Row, Col, Button } from "react-bootstrap";
import { EditProfileStyle } from "./EditProfileStyle";
import PhoneInput from "react-phone-number-input";
import { useEffect } from "react";
import {
  validateAboutCompany,
  validateFirstName,
  validateFoundedIn,
  validateLastName,
} from "../../Utils/Validators";
import {
  validateCompanyName,
  validateEmail,
  validateName,
  validatePhone,
} from "../../Utils/Validators";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import CustomImageInput from "../../components/reusableComponents/CustomImageInput";
import { LangEN } from "../../constants/Lang/LangEN";
import { User } from "../../App";
import CustomButton from "../../components/reusableComponents/CustomButton";
import Step4 from "../CreateJob/StepperFormComponents/Step4";
import Loader from "../../components/reusableComponents/Loader";
import { UserRoles } from "../../enums/UserRoles";
import moment from "moment";
import { dateParser } from "../../Utils/helper";

function EditBusinessProfile() {
  const user = useContext(User);
  const navigate = useNavigate();
  const [id, setId] = useState<number>();
  const [pageLoading, setPageLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [foundedIn, setFoundedIn] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [foundedInError, setFoundedInError] = useState("");
  const [aboutCompanyError, setAboutCompanyError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [image, setImage] = useState<any>();
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    getPreviousData();
    UserService.GetAccountVerification()
      .then((res) => {
        let data = res.data.isProfileComplete;
        setIsEdit(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    validate();
  }, [
    companyName,
    foundedIn,
    aboutCompany,
    firstName,
    lastName,
    email,
    phone,
    companyNameError,
    foundedInError,
    aboutCompanyError,
    firstNameError,
    lastNameError,
    phoneError,
    emailError,
  ]);
  const getPreviousData = () => {
    UserService.ViewProfile()
      .then((res) => {
        const user = res.data;
        setId(user.id);
        let foundDate;
        user.user.firstName && setFirstName(user.user.firstName);
        user.user.lastName && setLastName(user.user.lastName);
        // setName(
        //   `${
        //     user.user.lastName
        //       ? user.user.firstName + " " + user.user.lastName
        //       : user.user.firstName
        //   }`
        // );
        user.user.email && setEmail(user.user.email);
        user.user.contact && setPhone(user.user.contact);
        user.companyName && setCompanyName(user.companyName);
        user.about && setAboutCompany(user.about);
        if (user.foundedin) {
          // foundDate = user.foundedin;
          // console.log(foundDate);
          // let month = (parseInt(foundDate.split("-")[1]) + 1).toString();
          // if (month.length == 1) month = "0" + month;
          // let formattedDate = foundDate.split("-")[0] + "-" + month;
          let newDate = new Date(user.foundedin);
          setFoundedIn(moment.parseZone(user.foundedin).format("yyyy-MM"));
          // console.log(user.foundedin);
          // console.log(newDate);
          console.log(moment.parseZone(user.foundedin).format("yyyy-MM"));
          // console.log(moment(newDate).format("YYYY-MM-DD"));
        }
        setPageLoading(false);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  function validate() {
    // check to enable form submission
    if (
      companyName &&
      foundedIn &&
      firstName &&
      email &&
      !companyNameError &&
      !foundedInError &&
      !aboutCompanyError &&
      !emailError &&
      !firstNameError &&
      !lastNameError &&
      !phoneError
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    validate();

    if (!validated) {
      return;
    }

    let dateFoundedIn = dateParser(foundedIn);

    if (isEdit) {
      const isImageChanged = imageFile.length > 0;

      let apiReq = {
        companyName: companyName,
        foundedin: dateFoundedIn,
        about: aboutCompany,
        //profilePicture: null,
        firstName: firstName,
        lastName: lastName,
        email: email,
        contact: phone ? phone : "",
        id: id,
      };

      setIsLoading(true);
      if (isImageChanged) updateProfilePicture(id);

      UserService.EditBusinessProfile(apiReq)
        .then((res) => {
          console.log("success", res);
          if (isImageChanged) return;
          setIsLoading(false);
          navigate("/profile");
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      let apiReq = {
        companyName: companyName,
        foundedin: dateFoundedIn,
        about: aboutCompany,
        // profilePicture: null,
        firstName: firstName,
        lastName: lastName,
        email: email,
        contact: phone,
      };
      setIsLoading(true);
      UserService.CreateBusinessProfile(apiReq)
        .then((res) => {
          console.log("success", res);
          setIsLoading(false);
          if (imageFile.length) updateProfilePictureUsingId();
          else navigate("/profile");
          user.setAccStatus((pre: any) => {
            pre.isProfileComplete = true;
            localStorage.setItem("accStatus", JSON.stringify(pre));
            return pre;
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const updateProfilePicture = (id: any) => {
    var data = new FormData();
    // console.log(imageFile[0]);
    data.append("profilePicture", imageFile[0]);
    data.append("userId", id ? id.toString() : "");
    data.append("role", UserRoles.Business.toString());
    // console.log("jsfslfjslkfjsd=====>>>>", data);
    UserService.UploadProfilePicture(data)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        navigate("/profile");
      })
      .catch((err) => console.log(err));
  };

  const updateProfilePictureUsingId = () => {
    UserService.ViewProfile()
      .then((res) => {
        const id = res.data.id;
        updateProfilePicture(id);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Container
        style={EditProfileStyle.container}
        className="d-flex justify-content-center"
      >
        {pageLoading ? (
          <Loader lg />
        ) : (
          <Card
            className="cardShadow px-sm-5 px-4 pt-4 pb-5"
            style={EditProfileStyle.card1}
          >
            <h3 style={EditProfileStyle.header}>
              {isEdit ? LangEN.EditCompanyInfo : LangEN.CreateYourProfile}
            </h3>
            <Form onSubmit={onSubmit} noValidate>
              <CustomImageInput
                setImageFile={setImageFile}
                image={image}
                setImage={setImage}
                setImageError={setImageError}
                imageError={imageError}
              />
              <div className="mt-2" style={EditProfileStyle.divider} />
              <Form.Group className="mt-4">
                <Form.Label>{LangEN.CompanyName} *</Form.Label>
                <Form.Control
                  style={EditProfileStyle.InputDesign2}
                  size="lg"
                  type="text"
                  value={companyName}
                  onChange={(e) => {
                    let value = e.target.value;
                    companyNameError &&
                      validateCompanyName(value, setCompanyNameError);
                    setCompanyName(value);
                  }}
                  onBlur={() => {
                    setCompanyName((pre) => {
                      let name = pre.replace(/\s{2,}/g, " ").trim();
                      validateCompanyName(name, setCompanyNameError);
                      return name;
                    });
                  }}
                  className="shadow-none"
                  isInvalid={!!companyNameError}
                />
                <Form.Control.Feedback type="invalid">
                  {companyNameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-4">
                <Form.Label>{LangEN.FoundedIn} *</Form.Label>
                <Form.Control
                  style={EditProfileStyle.InputDesignDate}
                  size="lg"
                  type="month"
                  value={foundedIn}
                  onChange={(e) => {
                    let value = e.target.value;
                    setFoundedIn(value);
                    foundedInError &&
                      validateFoundedIn(value, setFoundedInError);
                  }}
                  onBlur={() => {
                    validateFoundedIn(foundedIn, setFoundedInError);
                  }}
                  className="shadow-none blue-date"
                  isInvalid={!!foundedInError}
                />
                <Form.Control.Feedback type="invalid">
                  {foundedInError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-4">
                <Form.Label>{LangEN.AboutCompany}</Form.Label>
                <Form.Control
                  as={"textarea"}
                  size="lg"
                  value={aboutCompany}
                  type="text"
                  aria-multiline
                  style={EditProfileStyle.InputDesignMultiline}
                  onChange={(e) => {
                    let value = e.target.value.trimStart();
                    setAboutCompany(value);
                    aboutCompanyError &&
                      validateAboutCompany(value, setAboutCompanyError);
                  }}
                  onBlur={() => {
                    validateAboutCompany(aboutCompany, setAboutCompanyError);
                    setAboutCompany((pre) => pre.trim());
                  }}
                  className="shadow-none blue-date"
                  isInvalid={!!aboutCompanyError}
                />
                <Form.Control.Feedback type="invalid">
                  {aboutCompanyError}
                </Form.Control.Feedback>
              </Form.Group>
              {isEdit && (
                <>
                  <Row>
                    <Col>
                      <Form.Group className="mt-4">
                        <Form.Label>{LangEN.FirstName} *</Form.Label>
                        <Form.Control
                          disabled
                          size="lg"
                          value={firstName}
                          style={EditProfileStyle.InputDesign2}
                          type="text"
                          onChange={(e) => {
                            let value = e.target.value;
                            firstNameError &&
                              validateFirstName(value, setFirstNameError);
                            setFirstName(value);
                          }}
                          onBlur={() => {
                            setFirstName((pre) => {
                              let name = pre.replace(/\s{2,}/g, " ").trim();
                              validateFirstName(name, setFirstNameError);
                              return name;
                            });
                          }}
                          className="shadow-none"
                          isInvalid={!!firstNameError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {firstNameError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mt-4">
                        <Form.Label>{LangEN.LastName}</Form.Label>
                        <Form.Control
                          disabled
                          size="lg"
                          value={lastName}
                          style={EditProfileStyle.InputDesign2}
                          type="text"
                          onChange={(e) => {
                            let value = e.target.value;
                            lastNameError &&
                              validateLastName(value, setLastNameError);
                            setLastName(value);
                          }}
                          onBlur={() => {
                            setLastName((pre) => {
                              let name = pre.replace(/\s{2,}/g, " ").trim();
                              validateLastName(name, setLastNameError);
                              return name;
                            });
                          }}
                          className="shadow-none"
                          isInvalid={!!lastNameError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {lastNameError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6">
                      <Form.Group className="mt-4">
                        <Form.Label>{LangEN.Email} *</Form.Label>
                        <Form.Control
                          size="lg"
                          disabled
                          type="email"
                          value={email}
                          style={EditProfileStyle.InputDesign2}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/\s/g, "");
                            emailError && validateEmail(value, setEmailError);
                            setEmail(value);
                          }}
                          onBlur={() => {
                            validateEmail(email, setEmailError);
                          }}
                          isInvalid={!!emailError}
                          className="shadow-none"
                        />
                        <Form.Control.Feedback type="invalid">
                          {emailError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <Form.Group className="mt-4">
                        <Form.Label>{LangEN.Phone}</Form.Label>
                        <PhoneInput
                          style={EditProfileStyle.phoneInputStyle}
                          inputComponent={Form.Control}
                          numberInputProps={{
                            isInvalid: !!phoneError,
                            className: "shadow-none",
                          }}
                          value={phone}
                          onChange={(e: any) => {
                            phoneError && validatePhone(e, setPhoneError);
                            setPhone(e);
                          }}
                          onBlur={() => {
                            validatePhone(phone, setPhoneError);
                          }}
                          defaultCountry="US"
                        />
                        <label
                          className="invalid-feedback"
                          style={{
                            display: !phoneError ? "none" : "block",
                          }}
                        >
                          {phoneError}
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              <Row
                className={
                  isEdit
                    ? "mt-5 justify-content-end"
                    : "mt-5 justify-content-start"
                }
              >
                <Col className="col-auto">
                  <Button
                    style={EditProfileStyle.cancelButton}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {LangEN.Cancel}
                  </Button>
                </Col>
                <Col className="col-auto">
                  <CustomButton
                    isLoading={isLoading}
                    style={EditProfileStyle.submitButton}
                    disabled={!validated}
                    label={LangEN.Submit}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Container>
    </>
  );
}

export default EditBusinessProfile;
