import "./styles.css";
import { Col, Row, Card } from "react-bootstrap";
import TextAvatar from "../TextAvatar";
import { LightChip } from "../LightChip";
import CustomButton from "../CustomButton";
import { LangEN } from "../../../constants/Lang/LangEN";
import { useEffect, useState, useContext } from "react";
import week from "../../../assets/svg/icon/week.svg";
import time from "../../../assets/svg/icon/time.svg";
import greyPerson from "../../../assets/svg/icon/greyPerson.svg";
import greenPerson from "../../../assets/svg/icon/greenPerson.svg";
import saved from "../../../assets/svg/icon/saved.svg";
import save from "../../../assets/svg/icon/unsave.svg";
import {
  dateFromNow,
  mapJobDuration,
  mapJobRole,
  setCurrencyType,
  setPaymentDuration,
} from "../../../Utils/Mappers";
import JobSApplicationService from "../../../services/JobApplicationService";
import { jobApplicationStatus } from "../../../enums/JobApplicationStatus";
import { useNavigate } from "react-router-dom";
import Text from "../Text";
import { User } from "../../../App";
import ToastModal from "../../Modals/ToastModal";
import JobService from "../../../services/JobService";
import { resourceType } from "../../../enums/CreateJobEnums";
import Avatar from "../Avatar";

function JobListCard({
  job,
  isApplied,
  index,
  isAppliedSavedJob,
}: {
  job: any;
  isApplied?: Boolean;
  index: number;
  isAppliedSavedJob?: Boolean;
}) {
  const user = useContext(User);
  const navigate = useNavigate();
  const [applyLoading, setApplyLoading] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState<any>();
  const [isAppliedJob, setIsAppliedJob] = useState(isApplied);
  const [showToast, setShowToast] = useState(false);
  const [toastLabel, setToastLabel] = useState({
    label: "",
    buttonText: "",
    toastAction: () => {},
  });
  const [isJobSaved, setIsJobSaved] = useState(job?.savedJob?.length != 0);

  useEffect(() => {
    setProfileInfo(job);
  }, [job]);

  const applyJob = () => {
    setApplyLoading(true);
    let apiReq = {
      applicationStatus: jobApplicationStatus.Applied,
      jobId: job?.id,
    };
    JobSApplicationService.ApplyForJob(apiReq)
      .then((res) => {
        setApplyLoading(false);
        // setListRecords(res.data);
        setIsAppliedJob(true);
      })
      .catch((err) => {
        setApplyLoading(false);
        console.log(err);
      });
  };
  const revokeJob = () => {
    setRevokeLoading(true);
    JobSApplicationService.RevokeJobApplication(job?.id)
      .then((res) => {
        setRevokeLoading(false);
        setIsAppliedJob(false);
      })
      .catch((err) => {
        setRevokeLoading(false);
        console.log(err);
      });
  };

  const toastHandler = () => {
    if (!user.accStatus.isAccountVerified) {
      setToastLabel({
        label: LangEN.YourEmailNotVerified,
        buttonText: LangEN.VerifyYourEmail,
        toastAction: () => {
          navigate("/verification");
        },
      });
      setShowToast(true);
      return false;
    }
    if (!user.accStatus.isProfileComplete) {
      setToastLabel({
        label: LangEN.YourProfileIsIncomplete,
        buttonText: LangEN.PleaseCompleteYourProfile,
        toastAction: () => {
          navigate("/verification");
        },
      });
      setShowToast(true);
      return false;
    }
    if (!user.accStatus.isProfileVerified) {
      setToastLabel({
        label: LangEN.YourProfileIsUnderVerification,
        buttonText: LangEN.PleaseTryLater,
        toastAction: () => {},
      });
      setShowToast(true);
      return false;
    }
    return true;
  };

  const saveJob = () => {
    JobService.SaveJob(job?.id)
      .then((res) => {
        setIsJobSaved(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unSaveJob = () => {
    JobService.RemoveSavedJob(job?.id)
      .then((res) => {
        setIsJobSaved(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyJobButtons = () => {
    if (isAppliedJob) {
      return (
        <>
          <CustomButton
            style={{ height: 30 }}
            className="jobListCard_button3 me-2"
            label={LangEN.View}
          />{" "}
          <CustomButton
            isLoading={revokeLoading}
            style={{ height: 30 }}
            className="jobListCard_button4"
            label={LangEN.Revoke}
            onClick={(e: any) => {
              e.stopPropagation();
              revokeJob();
            }}
          />
        </>
      );
    } else {
      return (
        <CustomButton
          isLoading={applyLoading}
          style={{ width: 100, height: 30 }}
          className="jobListCard_button"
          label={LangEN.ApplyNow}
          onClick={(e: any) => {
            e.stopPropagation();
            if (!toastHandler()) return;
            applyJob();
          }}
        />
      );
    }
  };

  return (
    <Col xl={4} lg={4} md={4} sm={6} xs={12} className="p-2" key={index}>
      <ToastModal
        show={showToast}
        setShow={setShowToast}
        label={toastLabel.label}
        buttonText={toastLabel.buttonText}
        action={toastLabel.toastAction}
        type="error"
      />
      <Card
        onClick={() => {
          if (!toastHandler()) return;
          navigate("/jobs/details", { state: job });
        }}
        className="h-100 p-3 jobListContainer"
        style={{ cursor: "pointer" }}
      >
        <div
          style={{
            backgroundColor: "#E0E6F7",
            height: 30,
            aspectRatio: 1,
            borderRadius: 20,
            position: "absolute",
            right: 10,
            top: 10,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={(e: any) => {
            e.stopPropagation();
            isJobSaved ? unSaveJob() : saveJob();
          }}
        >
          <img src={isJobSaved ? saved : save} />
        </div>
        <Row className="me-3">
          <Col xs="auto" className="m-0 p-0 ms-3">
            <Avatar
              url={job.profilePicture}
              size={50}
              fontSize={22}
              firstName={"N/A"}
              lastName={"N/A"}
              isJob
            />
          </Col>
          <Col className="d-flex align-items-center">
            <div className="jobListCard_title d-flex align-items-center">
              {profileInfo?.project?.name}
              {user?.user?.applyAs === resourceType.Experts && !isApplied && (
                <div className="indicatorNotStaffedJob">
                  <div className="tooltip1">
                    {job.project.isStaffed
                      ? LangEN.IndicatorTooltipStaffed
                      : LangEN.IndicatorTooltip}
                  </div>
                  <img
                    width={job.project.isStaffed ? 24 : 20}
                    height={job.project.isStaffed ? 24 : 20}
                    src={job.project.isStaffed ? greenPerson : greyPerson}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <h3 className="mt-3 px-2 jobListCard_h3">
          {mapJobRole(job?.resourceType)}
        </h3>
        <Row className="p-2">
          <Col className="col-auto" style={styles.smallLabel}>
            <span className="me-2">
              <img src={week} width={17} />
            </span>
            {mapJobDuration(job?.jobDuration)}
          </Col>
          <Col className="col-auto" style={styles.smallLabel}>
            <span className="me-2">
              <img src={time} width={17} />
            </span>
            {dateFromNow(job?.createdAt)}
          </Col>
        </Row>
        <div className="jobListCard_description p-2 ">
          <Text line={3}>{job?.description ? job?.description : "JD N/A"}</Text>
        </div>
        <div className="jobListCard_tagContainer">
          {job?.customSkill.map((item: any, index: number) => (
            <LightChip index={index} label={item.skillName} sm haveBorder />
          ))}
          {job?.skills.map((item: any, index: number) => {
            if (index < 3)
              return (
                <LightChip
                  index={index}
                  label={item.skillName.name}
                  sm
                  haveBorder
                />
              );
          })}
          {job?.skills?.length > 3 && (
            <div
              className="d-flex align-items-center"
              style={{ color: "#3C65F5" }}
            >
              {LangEN.SeeMore}
            </div>
          )}
        </div>
        <Row className="d-flex flex-grow-1 align-items-end  mt-3">
          <Col sm={"auto"} className="jobListCard_cost">
            {`${setCurrencyType(job?.currencyType)}${job?.budget}`}
            <span className="jobListCard_costSpan">{`${setPaymentDuration(
              job?.paymentDuration
            )}`}</span>
          </Col>
          <Col className="d-flex justify-content-end">
            {!job.project.isStaffed &&
            user?.user?.applyAs === resourceType.Experts &&
            !isApplied &&
            !isAppliedSavedJob ? (
              <CustomButton
                isLoading={revokeLoading}
                className="jobListCard_button4"
                label={isJobSaved ? LangEN.UnSaved : LangEN.Save}
                onClick={(e: any) => {
                  e.stopPropagation();
                  isJobSaved ? unSaveJob() : saveJob();
                }}
              />
            ) : (
              applyJobButtons()
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

const styles = {
  smallLabel: {
    fontWeight: 500,
    fontSize: 15,
    color: " #66789C",
  },
};

export default JobListCard;
