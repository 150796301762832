import TextAvatar from "./TextAvatar";
import { useState } from "react";

function Avatar({
  url,
  firstName,
  lastName,
  size,
  fontSize,
  isJob,
}: {
  firstName: string;
  lastName: string | undefined;
  url: string | undefined;
  size: number;
  fontSize: number;
  isJob?: boolean;
}) {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {url && (
        <img
          onLoad={() => setLoaded(true)}
          style={{ display: "none" }}
          height={0}
          width={0}
          src={url}
        />
      )}
      {loaded ? (
        <img
          style={{ objectFit: "cover", borderRadius: size / 2 }}
          height={size}
          width={size}
          src={url}
        />
      ) : (
        <TextAvatar
          size={size}
          fontSize={fontSize}
          name={`${firstName}${lastName ? " " + lastName : ""}`}
          isJob={isJob}
        />
      )}
    </>
  );
}

export default Avatar;
