import { Row, Col } from "react-bootstrap";
import application from "../../assets/svg/icon/applications.svg";
import week from "../../assets/svg/icon/week.svg";
import time from "../../assets/svg/icon/time.svg";
import { LightChip } from "../../components/reusableComponents/LightChip";
import { Link, useNavigate } from "react-router-dom";
import { LangEN } from "../../constants/Lang/LangEN";
import moment from "moment";
import { MyProjectStyles } from "./MyProjectStyles";
import CustomModal from "../../components/Modals/CustomModal";
import {
  mapJobDuration,
  mapJobRole,
  setCurrencyType,
  setPaymentDuration,
} from "../../Utils/Mappers";
import { useState, useContext } from "react";
import JobInvitationService from "../../services/JobInvitationService";
import { invitationStatus } from "../../enums/InvitationStatus";
import SearchProfessionals from "../../components/Modals/SearchProfessional";
import { jobStatus, resourceType } from "../../enums/CreateJobEnums";
import { User } from "../../App";
import { jobApplicationStatus } from "../../enums/JobApplicationStatus";
import JobSApplicationService from "../../services/JobApplicationService";
import Loader from "../../components/reusableComponents/Loader";
import JobStatusChip from "../../components/reusableComponents/JobStatusChip";

function JobCard({
  notProjectRoute,
  job,
  index,
  id,
  isProfessional,
  isHired,
  userId,
  isJobs,
  role,
}: {
  notProjectRoute?: boolean;
  job: any;
  index: number;
  isJobs?: boolean;
  id: number;
  isProfessional?: boolean;
  isHired?: boolean;
  userId?: number | undefined;
  role?: number;
}) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [isAppliedJob, setIsAppliedJob] = useState(false);
  const user = useContext(User);
  const isExpert =
    user?.user?.applyAs && user.user.applyAs == resourceType.Experts;

  const navigate = useNavigate();
  const sendInvite = () => {
    let apiReq = {
      invitee: userId,
      jobId: job.id,
      invitationStatus: invitationStatus.Invited,
    };
    JobInvitationService.SendInvite(apiReq)
      .then((res) => {
        console.log(res.data);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyJob = () => {
    setApplyLoading(true);
    let apiReq = {
      applicationStatus: jobApplicationStatus.Applied,
      jobId: job?.id,
    };
    JobSApplicationService.ApplyForJob(apiReq)
      .then((res) => {
        setApplyLoading(false);
        // setListRecords(res.data);
        setIsAppliedJob(true);
      })
      .catch((err) => {
        setApplyLoading(false);
        console.log(err);
      });
  };

  return (
    <div
      key={index}
      className="py-4 px-3 px-md-4 my-2"
      style={styles.container}
    >
      <CustomModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        label={LangEN.InvitationSentSuccessfully}
        type="success"
        buttonText={LangEN.OK}
        action={() => {
          navigate(-1);
        }}
      />
      <SearchProfessionals
        show={showInviteModal}
        setShow={setShowInviteModal}
      />
      <Row className="mb-2">
        <Col style={styles.title}>
          {mapJobRole(job?.resourceType)}
          <span>
            {job?.jobStatus && <JobStatusChip status={job.jobStatus} />}
          </span>
        </Col>
        <Col style={styles.subTitle} className="d-flex justify-content-end">
          {`${setCurrencyType(job?.currencyType)}${
            job.budget
          }${setPaymentDuration(job?.paymentDuration)}`}
        </Col>
      </Row>
      <Row>
        <Col className="col-auto" style={styles.smallLabel}>
          <span className="me-2">
            <img src={week} width={17} />
          </span>
          {mapJobDuration(job?.jobDuration)}
        </Col>
        <Col className="col-auto" style={styles.smallLabel}>
          <span className="me-2">
            <img src={time} width={17} />
          </span>
          {moment(job.createdAt).fromNow()}
        </Col>
        {/* <Col className="col-auto" style={styles.smallLabel}>
          <span className="me-2">
            <img src={application} width={17} />
          </span>
          5 applicants
        </Col> */}
      </Row>
      <div style={styles.desc} className="my-3">
        {job?.description}
      </div>
      <Row>
        <Col className="d-flex gap-2 my-2  ">
          {job?.skills.slice(0, 4).map((item: any, index: number) => (
            <LightChip label={item.skillName.name} index={index} />
          ))}
          {job?.skills?.length < 4 &&
            job?.customSkill
              .slice(0, 4 - job.skills?.length)
              .map((item: any, index: number) => (
                <LightChip index={index} label={item.skillName} />
              ))}
          {job?.skills?.length + job?.customSkill.length > 4 && (
            <LightChip
              index={-1}
              label={
                "+" +
                (job?.skills?.length + job?.customSkill.length - 4).toString() +
                " more"
              }
            />
          )}
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <Row style={styles.link}>
            {!isProfessional && (
              <Col className="col-auto">
                <Link
                  to={"/projects/details/jobapplications"}
                  state={{ id: job.id, project: job.project }}
                >
                  {LangEN.ViewApplications}
                </Link>
              </Col>
            )}
            <Col className="col-auto">
              <Link
                to={{
                  pathname: notProjectRoute
                    ? `/professional/details/project/job`
                    : isProfessional && !isHired
                    ? `/jobs/details`
                    : "/projects/details/jobdetails",
                }}
                state={
                  notProjectRoute
                    ? {
                        id: job.id,
                        project: job.project,
                        userId: userId,
                        role: role,
                      }
                    : isProfessional && !isHired
                    ? job
                    : { userId: userId, id: job.id, project: job.project }
                }
              >
                {LangEN.ViewDetails}
              </Link>
            </Col>
            {isHired && !isExpert && (
              <Col className="col-auto">
                <Link
                  to={"/projects/details/jobapplications"}
                  state={{ id: job.id, project: job.project }}
                >
                  {LangEN.ViewApplications}
                </Link>
              </Col>
            )}
            {isProfessional &&
              !isHired &&
              !isAppliedJob &&
              !applyLoading &&
              !notProjectRoute &&
              job?.resourceType == user?.user?.applyAs && (
                <Col
                  className="col-auto p-relative"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    applyJob();
                  }}
                >
                  {LangEN.ApplyNow}
                </Col>
              )}
            {isAppliedJob && (
              <Col className="col-auto " style={MyProjectStyles.invite2}>
                {LangEN.Applied}
              </Col>
            )}
            {applyLoading && (
              <Col className="px-5 col-auto ">
                <Loader noMargin />
              </Col>
            )}
            {isHired && job.jobStatus == jobStatus.Active && (
              <Col
                className="col-auto"
                onClick={() => {
                  navigate(`/projects/professional`, {
                    state: {
                      projectid: id,
                      jobid: job.id,
                      resourceType: job.resourceType,
                    },
                  });
                }}
                style={MyProjectStyles.invite}
              >
                {LangEN.InviteProfessionals}
              </Col>
            )}
            {notProjectRoute &&
              role == job.resourceType &&
              job.jobStatus !== jobStatus.Expired &&
              job.jobStatus !== jobStatus.Complete && (
                <Col
                  className="col-auto"
                  style={MyProjectStyles.invite}
                  onClick={() => {
                    sendInvite();
                  }}
                >
                  {LangEN.SendInvite}
                </Col>
              )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const styles = {
  link: {
    fontSize: 16,
    fontWeight: 600,
    color: "#3C65F5",
  },
  container: {
    backgroundColor: "#F2F6FD",
    border: "1px solid rgba(90, 106, 163, 0.2)",
    borderRadius: 20,
  },
  title: {
    fontWeight: 700,
    fontSize: 25,
    color: "#05264E",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 25,
    color: " #3C65F5",
  },
  smallLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: " #66789C",
  },
  desc: {
    fontSize: 16,
    color: "#4F5E64",
  },
};

export default JobCard;
