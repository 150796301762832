import { useEffect, useState, useContext, useRef } from "react";
import "./styles.css";
import searchImg from "../../assets/svg/icon/whiteSearch.svg";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import Filter from "./Filter";
import close from "../../assets/svg/icon/cross.svg";
import JobListCard from "../../components/reusableComponents/ApplicationCard/JobListCard";
import JobService from "../../services/JobService";
import Loader from "../../components/reusableComponents/Loader";
import {
  resourceType,
  paymentDuration as payDuration,
  currencyType as currType,
} from "../../enums/CreateJobEnums";
import { debounce } from "lodash";
import { User } from "../../App";
import filterImg from "../../assets/svg/filter.svg";
import EmptyList from "../../components/reusableComponents/EmptyList";
import { Sorting } from "../../enums/Sorting";

function Jobs() {
  const user = useContext(User);
  const [roles, setRoles] = useState([0]);
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState<number>();
  const [availability, setAvailability] = useState<number>();
  const [currencyType, setCurrencyType] = useState(currType.Default);
  const [paymentDuration, setPaymentDuration] = useState<number>(
    payDuration.Default
  );
  const [hourlyRate, setHourlyRate] = useState([1, 1000]);
  const [jobs, setJobs] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [haveMoreData, setHaveMoreData] = useState(true);
  const scrollRef = useRef();
  const [filterModal, setFilterModal] = useState(false);
  const [sort, setSort] = useState(Sorting.DESC);
  const [applyAsVAlue, setApplyAsVAlue] = useState(
    user?.user?.applyAs ? user.user.applyAs : null
  );

  const handleScroll = () => {
    if (scrollLoading) return;
    if (!haveMoreData) return;
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const bottom = scrollTop + clientHeight >= scrollHeight - 10;
      if (bottom) {
        setScrollLoading(true);
        console.log("fetching more data", skip);
        fetchData();
      }
    }
  };

  useEffect(() => {
    if (!applyAsVAlue) return;
    setHaveMoreData(true);
    setSkip(0);
    let apiReq = {
      skip: 0,
      take: take,
      applyAs: applyAsVAlue,
      search: search,
      experience: experience,
      availability: availability,
      hourlyRateLowerLimit: hourlyRate[0],
      hourlyRateUpperLimit: hourlyRate[1],
      skills: skills,
      sort: sort,
      currencyType: currencyType === currType.Default ? null : currencyType,
      paymentDuration:
        paymentDuration === payDuration.Default ? null : paymentDuration,
    };
    setIsLoading(true);
    JobService.GetJobs(apiReq)
      .then((res) => {
        console.log(res.data);
        setJobs(res.data);
        setSkip(take);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        console.log(err);
      });
  }, [
    skills,
    availability,
    hourlyRate,
    experience,
    search,
    sort,
    paymentDuration,
    currencyType,
  ]);

  useEffect(() => {
    if (jobs.length == 0) return;
    handleScroll();
  }, [jobs]);

  const fetchData = () => {
    if (!applyAsVAlue) return; // for new users - not completed profile

    let apiReq = {
      skip: skip,
      take: take,
      applyAs: applyAsVAlue,
      search: search,
      experience: experience,
      availability: availability,
      hourlyRateLowerLimit: hourlyRate[0],
      hourlyRateUpperLimit: hourlyRate[1],
      skills: skills,
      sort: sort,
      currencyType: currencyType === currType.Default ? null : currencyType,
      paymentDuration:
        paymentDuration === payDuration.Default ? null : paymentDuration,
    };
    JobService.GetJobs(apiReq)
      .then((res) => {
        console.log(res.data);
        setScrollLoading(false);
        if (res.data.length == 0) return setHaveMoreData(false);
        setSkip((pre) => pre + take);
        setJobs((pre: any[]) => [...pre, ...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Container className="main_container">
      <Modal
        className="filterModal d-block d-lg-none"
        show={filterModal}
        onHide={() => setFilterModal(false)}
      >
        <Row className="d-block d-lg-none">
          <Col className="d-flex justify-content-end">
            <img
              src={close}
              width={20}
              onClick={() => setFilterModal(false)}
              style={{ cursor: "pointer", filter: "invert()" }}
            />{" "}
          </Col>
        </Row>
        <Filter
          currencyTypeValue={currencyType}
          setCurrencyTypeValue={setCurrencyType}
          paymentDurationValue={paymentDuration}
          setPaymentDurationValue={setPaymentDuration}
          skills={skills}
          setSkills={setSkills}
          experience={experience}
          setAvailability={setAvailability}
          setExperience={setExperience}
          setHourlyRate={setHourlyRate}
          setRoles={setRoles}
          hourlyRate={hourlyRate}
          availability={availability}
          roles={roles}
          isSearchJobs
        />
      </Modal>
      <Row>
        <Col className="job_header">
          {LangEN.SearchBest}
          <span className="job_headerSpan"> {LangEN.FreelancingJob}</span>
        </Col>
      </Row>
      <Row>
        <Col className="">
          <Card className="cardShadow p-2 ps-3 mx-2 mx-md-5 my-4">
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Control
                  className="job_search shadow-none"
                  placeholder={LangEN.SearchJobDescription}
                  onChange={debounce((e: any) => {
                    setSearch(e.target.value);
                  }, 1000)}
                />
              </Col>
              <Col xs="auto">
                <Button className="button_bg p-2 px-3">
                  <img src={searchImg} width={26} />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={3} className="d-none d-lg-block">
          <Filter
            currencyTypeValue={currencyType}
            setCurrencyTypeValue={setCurrencyType}
            paymentDurationValue={paymentDuration}
            setPaymentDurationValue={setPaymentDuration}
            skills={skills}
            setSkills={setSkills}
            experience={experience}
            setAvailability={setAvailability}
            setExperience={setExperience}
            setHourlyRate={setHourlyRate}
            setRoles={setRoles}
            hourlyRate={hourlyRate}
            availability={availability}
            roles={roles}
            isSearchJobs
          />
        </Col>
        <Col xs={12} lg={9}>
          <Row className="my-3 mb-2 mx-2">
            <Col className={"job_title"}>{LangEN.JobList}</Col>
            <Col className="col-auto d-flex gap-3">
              <img
                className="d-block d-lg-none"
                onClick={() => setFilterModal(true)}
                src={filterImg}
                width={26}
              />
              <div className="job_sortContainer">
                {LangEN.SortBy}
                <span>
                  <Form.Select
                    className="job_select shadow-none"
                    onChange={(e) => setSort(+e.target.value)}
                  >
                    <option selected value={Sorting.DESC}>
                      {LangEN.NewestPost}
                    </option>
                    <option value={Sorting.ASC}>{LangEN.OldestPost}</option>
                  </Form.Select>
                </span>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              overflowY: "auto",
              maxHeight: "80vh",
              minHeight: "400px",
            }}
            ref={scrollRef}
            onScroll={handleScroll}
          >
            {applyAsVAlue ? (
              isLoading ? (
                <Loader lg />
              ) : isError ? (
                <EmptyList error />
              ) : jobs.length == 0 ? (
                <EmptyList />
              ) : (
                <>
                  {jobs.map((item: any, index: number) => (
                    <JobListCard index={index} job={item} />
                  ))}
                  {scrollLoading && <Loader />}
                </>
              )
            ) : (
              <EmptyList jobApplyAsError />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Jobs;
