import { Modal, Card, Form, Row, Col } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomNumberInput from "../../reusableComponents/CustomNumberInput";
import CustomButton from "../../reusableComponents/CustomButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dateParser } from "../../../Utils/helper";
import "../../../screens/CreateProject/styles.css";
import ProjectService from "../../../services/ProjectServices";
import { resourceType } from "../../../enums/CreateJobEnums";
import { showToast } from "../../../App";

function EditProjectModal({
  show,
  setShow,
  data,
  setData,
}: {
  data: any;
  show: boolean;
  setShow: any;
  setData: Function;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [projectManagerCount, setProjectManagerCount] = useState(1);
  const [architectCount, setArchitectCount] = useState(1);
  const [expertCount, setExpertCount] = useState(1);
  const [projectTitle, setProjectTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isPMenabled, setIsPMenabled] = useState(true);
  const [isArchitectEnabled, setIsArchitectEnabled] = useState(false);
  const [isExpertEnabled, setIsExpertEnabled] = useState(false);
  const [resourceCountError, setResourceCountError] = useState("sdf");

  useEffect(() => {
    setProjectManagerCount(
      data?.resourceCount[resourceType.ProjectManager - 1]?.resourceCount
    );
    setArchitectCount(
      data?.resourceCount[resourceType.Architect - 1]?.resourceCount
    );
    setExpertCount(
      data?.resourceCount[resourceType.Experts - 1]?.resourceCount
    );
    setProjectTitle(data?.name);
    setDescription(data?.projectDescription);
    setStartDate(data?.projectStartDate);
    setEndDate(data?.projectEndDate);
    if (data?.resourceCount[resourceType.Architect - 1]?.resourceCount > 0)
      setIsArchitectEnabled(true);
    else setIsArchitectEnabled(false);
    if (data?.resourceCount[resourceType.Experts - 1]?.resourceCount > 0)
      setIsExpertEnabled(true);
    else setIsExpertEnabled(false);
    if (data?.resourceCount[resourceType.ProjectManager - 1]?.resourceCount > 0)
      setIsPMenabled(true);
    else setIsPMenabled(false);
  }, [show]);

  useEffect(() => {
    if (isPMenabled || isArchitectEnabled) {
      setResourceCountError("");
    } else {
      setResourceCountError(
        "At least 1 Project Manager or Architect is required in the project"
      );
    }
  }, [isPMenabled, isArchitectEnabled, isExpertEnabled]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;

    let reqBody = {
      id: data.id,
      name: projectTitle,
      projectDescription: description,
      projectStartDate: dateParser(startDate),
      projectEndDate: dateParser(endDate),
      projectMAnager: isPMenabled ? projectManagerCount : 0,
      architect: isArchitectEnabled ? architectCount : 0,
      expert: isExpertEnabled ? expertCount : 0,
    };
    setIsLoading(true);
    ProjectService.EditProject(reqBody)
      .then((res) => {
        // showToast(res.data.message, true);
        console.log(res.data);
        const data = res.data;
        setData((pre: any) => {
          data.jobs = pre.jobs;
          return data;
        });
        setShow(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
      contentClassName="p-4"
    >
      <Form onSubmit={onSubmit}>
        <Card.Title
          style={{ fontSize: 26, fontWeight: 600 }}
          className=" createProject_title py-1"
        >
          {LangEN.EditProject}
        </Card.Title>
        <Card.Title className=" createProject_title py-3">
          {LangEN.ProjectTitle}
        </Card.Title>
        <Form.Control
          required
          maxLength={100}
          value={projectTitle}
          onChange={(e) => setProjectTitle(e.target.value)}
          className="dim-placeholder createProject_input shadow-none"
          placeholder={LangEN.ProjectTitle}
        />
        <Card.Title className="mt-4 createProject_title py-3">
          {LangEN.Description}
        </Card.Title>
        <Form.Control
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          as={"textarea"}
          maxLength={500}
          className="dim-placeholder createProject_textArea shadow-none"
          placeholder={LangEN.AddProjectDescription}
        />
        <Row className="mt-4">
          <Col className="col-12 col-lg-5 mb-3">
            <Row className="mb-3">
              <Col className="createProject_title">{LangEN.Role}</Col>

              <Col className="createProject_title">{LangEN.Resource}</Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Check
                  className="createProject_check"
                  label={LangEN.ProjectManager}
                  checked={isPMenabled}
                  onChange={(e) => {
                    setIsPMenabled(e.target.checked);
                  }}
                />
              </Col>

              <Col>
                <CustomNumberInput
                  value={projectManagerCount}
                  setValue={setProjectManagerCount}
                  disabled={!isPMenabled}
                  minValue={data?.totalProjectManagerJobs}
                />
              </Col>
            </Row>
            <div className="horizontal-divider my-3" />
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Check
                  className="createProject_check"
                  label={LangEN.Architect}
                  checked={isArchitectEnabled}
                  onChange={(e) => {
                    setIsArchitectEnabled(e.target.checked);
                  }}
                />
              </Col>

              <Col>
                <CustomNumberInput
                  disabled={!isArchitectEnabled}
                  value={architectCount}
                  setValue={setArchitectCount}
                  minValue={data?.totalArchitectJobs}
                />
              </Col>
            </Row>
            <div className="horizontal-divider my-3" />
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Check
                  className="createProject_check"
                  label={LangEN.Experts}
                  checked={isExpertEnabled}
                  onChange={(e) => {
                    setIsExpertEnabled(e.target.checked);
                  }}
                />
              </Col>

              <Col>
                <CustomNumberInput
                  value={expertCount}
                  setValue={setExpertCount}
                  disabled={!isExpertEnabled}
                  minValue={data?.totalExpertJobs}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="invalid-feedback d-block pt-3">
                  {resourceCountError}
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="col-2 d-lg-flex justify-content-center d-none">
            <div className="vertical-divider" />
          </Col>
          <Col className="col-12 col-lg-5">
            <Card.Title className="mb-2 createProject_title">
              {LangEN.ProjectDuration}
            </Card.Title>
            <Row>
              <Col>
                <Card.Text className="mb-0 createProject_dateLabel">
                  {LangEN.StartDate}
                </Card.Text>
                <Form.Control
                  required
                  className="shadow-none dim-placeholder createProject_dateInput"
                  type="date"
                  max={endDate && endDate}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col>
                <Card.Text className="mb-0">{LangEN.EndDate}</Card.Text>
                <Form.Control
                  required
                  min={startDate && startDate}
                  className="shadow-none dim-placeholder createProject_dateInput"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col className=" d-flex justify-content-end">
                <CustomButton
                  className="createProject_cancelButton my-2"
                  label={LangEN.Cancel}
                  type="button"
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </Col>
              <Col className=" d-flex justify-content-end">
                <CustomButton
                  type={"submit"}
                  isLoading={isLoading}
                  disabled={
                    !(
                      startDate &&
                      endDate &&
                      projectTitle.trim() &&
                      description.trim() &&
                      !resourceCountError
                    )
                  }
                  className="createProject_createButton my-2"
                  label={LangEN.Update}
                  onClick={() => {}}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditProjectModal;
