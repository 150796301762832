import React from "react";
import "./styles.css";
import Header from "../../components/sharedComponents/Header";
import Footer from "../../components/sharedComponents/Footer";
import { Card, Container } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import { AriaAttributes, DOMAttributes } from "react";

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    name?: string;
  }
}
function Privacy() {
  return (
    <Container className="main_container">
      <div className="header mb-4">{LangEN.PrivacyPolicy}</div>
      <Card className="cardShadow p-lg-5 p-4">
      <div><h2 id="introduction">1. Introduction</h2>

<p>Welcome to the Beehived website (<strong>“Site”</strong>). Beehived LLC (<strong>“Beehived”</strong>, “<strong>we</strong>”, “<strong>us</strong>”, and/or “<strong>our</strong>”) operates this Site to provide our users (“<strong>you</strong>” and “<strong>your</strong>”) with information about Beehived and its platform.</p>

<p>“Beehived is used by professional freelancers (“<strong>Freelancers</strong>”) who wish to offer their development, architecting and other services (“<strong>Services</strong>”) and clients who need the Services (“<strong>Businesses</strong>”). Freelancers that have passed our screening process and are admitted to our network of professional Freelancers (“<strong>Network</strong>”) are referred to as <strong>“Project Managers”</strong>, <strong>“Architects”</strong> or <strong>“Experts”</strong>. For purposes of this policy the term “Freelancers” is broader in scope and includes both Project Managers, Architects and Freelancer Experts. The Personal Data we receive from Freelancers and Businesses and how we handle it differs, so we explain our data practices for Freelancers and Businesses in two distinct sections below. Click on the one that applies to you: <a href="#freelancers">Freelancers</a>; <a href="#clients">Businesses</a>.</p>

<hr></hr>

<br></br>
<h2 id="scope">2. Scope</h2>

<p>This Privacy Policy sets out how Beehived collects, retains, and uses information, including personal data (“<strong>Personal Data</strong>”), about Freelancers, Businesses, other Site visitors and individuals, including our vendors, partners, blog readers, and job applicants, as well as users of Beehived. This Privacy Policy also covers data that Beehived collects in-person, for instance at business conferences and trade shows.
<a id="freelancerssection"></a></p>

<hr></hr>

<br></br>
<h2 id="freelancers">3. Freelancers</h2>
<br></br>
<h3 id="information-we-collect">3.1. Information We Collect</h3>
<br></br>
<h4 id="a-information-that-you-provide-to-us">A. Information That You Provide To Us</h4>

<p>When you apply as a Freelancer on the Site, you are asking to be admitted to our Network. In order to admit you to the Network and match your qualifications with our Businesses’s needs, we need to perform a screening process, which evaluates your language skills, personality, domain-specific knowledge, competence and professionalism. We may also need to screen potential Freelancers for specific technical skills, as required by our Businesses. In this context, you will be asked to provide information about yourself, which includes:</p>

<ul>
  <li>your name</li>
  <li>contact information</li>
  <li>address</li>
  <li>location</li>
  <li>work experience</li>
  <li>education</li>
  <li>photo</li>
  <li>government-issued identification</li>
  <li>social network information</li>
  <li>family information</li>
  <li>billing information</li>
</ul>

<p><strong>Verifying Your Identity and Biometric Data</strong></p>

<p>If you are asking to be admitted to our Network, with your explicit written informed consent (which you may provide at the time of submitting a request to be admitted) we may collect biometric information about you to verify and confirm your identity and as further described below. We may ask you to take a photo of yourself and of your government-issued identification through your mobile phone (the “<strong>Verification Data</strong>”). The Verification Data will be provided to our biometric technology service provider (Mitek Systems, Inc. – “<strong>Mitek</strong>”), who will use facial recognition technology to analyze the geometry of your face through the photographs and extract biometric information or biometric identifiers relating to you (collectively, “<strong>Biometric Data</strong>”). The Verification Data and the Biometric Data will be used to verify and confirm your identity. However, Beehived will not receive the Biometric Data but will only receive scores that will indicate the authenticity of the government-issued identification, whether your identity has been verified and confirmed, and your name from your government-issued identification. Once the scores are returned to Beehived, Mitek will not retain and will permanently delete the Biometric Data.</p>

<p>Moreover, Mitek will use the Verification Data to improve the accuracy, reliability, usability, and functionality of its identity verification technology, for fraud prevention and incident investigation efforts; for such purposes, Mitek will retain your Verification Data for up to 180 days, after which the Verification Data will be permanently deleted. Beehived will retain your self-photo and your name for fraud prevention purposes based upon our legitimate interests. For more information about the retention of your Biometric Data please see our <a href="/retention">Biometric Data Retention Policy</a>.</p>

<p>Consenting to the collection and processing of your Biometric Data and your Verification Data as described in this Privacy Policy is voluntary and if you do not consent to the collection of your Biometric Data and Verification Data, we will allow you to provide us your government-issued identification and confirm your identity via video conference, and may ask you to provide us with a photo of yourself. Beehived will retain your self-photo and your name for fraud prevention purposes based upon our legitimate interests. If you consented to the collection of your Biometric Data and Verification Data, but later change your mind, you have the right to withdraw your consent by contacting us at <a href="mailto:privacy@Beehived.com">privacy@Beehived.com</a>. If you withdraw your consent, we will apply your preferences going forward and this will not affect the lawfulness of the processing of your Biometric Data or Verification Data prior to the effective date of your consent withdrawal. Your Biometric Data and Verification Data may be shared with third-party service providers who provide support services with respect to the biometric technology implemented by, and services provided by, Mitek. For more information on how Mitek may handle your Verification Data and Biometric Data, please review Mitek’s website and privacy policy. Moreover, your photo and name may be shared with third-party service providers who assist us with our IT, security, and fraud programs, our professional advisors, and as required by law or regulation.</p>

<p><strong>Background Check</strong></p>

<p>Our provider of background check services may collect additional data about you (including information about criminal convictions or offences) if and when we undertake vetting/background checks. If applicable to you, your consent to the collection of such additional data will be solicited at such time, pursuant to applicable laws.</p>

<p><strong>Information You Share</strong></p>

<p>When you participate in the community of Beehived’s experts on our Site, we will collect the information that you elect to share in that context.</p>

<p><strong>Profile Creation</strong></p>

<p>We will use certain of your Personal Data to enable you to create a profile to be displayed within the Services; this profile is searchable and may be viewed by Businesses seeking Freelancers.</p>

<p><strong>Businesses Feedback</strong></p>

<p>Once you are admitted to the Network and you are matched with a Business, we will receive from our Businesses information regarding the performance of your services to that Business, as necessary for maintaining standards of integrity and excellence in our Network.</p>

<h4 id="c-automatically-collected-data">B. Automatically Collected Data</h4>

<p>When you interact with Beehived through the Site or services, we collect automatically certain information. Please read the “<a href="#sitevisitors">Site Visitors</a>” section below for more information.</p>

<br></br>
<h3 id="how-we-use-the-information">3.2. How We Use the Information</h3>
<br></br>

<h4 id="a-to-provide-the-service-and-respond-to-requests">A. To Provide The Service And Respond To Requests</h4>

<p>Beehived uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we will use the Personal Data in connection with the reason for which it was provided.</p>

<p>In particular, we use Personal Data that you provide to us (described above) in order to process your request to become a Freelancer and administer our contract with you once you become a member of the Network. For individuals in the EU and the UK, our use of Personal Data that you provide to us is necessary in order to implement your request to become a Freelancer prior to entering into a
contract with us and, once you are admitted to our Network, to perform our contract with you.</p>

<h4 id="b-as-necessary-for-certain-legitimate-interests">B. As Necessary For Certain Legitimate Interests</h4>

<p>We use your Personal Data for the legitimate interests described below:</p>

<ul>
  <li>To send administrative information to you, for example, information regarding the Site and changes to our terms, conditions, and policies.</li>
  <li>To conduct analytics on how the Site and our Service are being used by you for our internal purposes, namely for providing, maintaining, benchmarking and improving our offerings, and identifying usage trends.</li>
  <li>We use data relating to your use of and interaction with the Site and the Services, including use of product features by you and information that we obtain through cookies and other technologies, to better understand your needs and interests in order to personalize your experience with our Services.</li>
  <li>To market our services and showcase Freelancers (in particular, we can feature certain profiles of Freelancers to drive traffic from potential Clients).</li>
  <li>To prevent fraud or criminal activity, misuses of our products or services, and ensure the security of our IT systems, architecture and networks.</li>
  <li>To (a) comply with legal obligations and legal process, (b) respond to requests from public and government authorities including public and government authorities outside your country of residence; (c) enforce our terms and conditions; (d) protect our operations; (e) protect our rights, privacy, safety or property, and/or that of you or others; and (f) allow us to pursue available remedies or limit the damages that we may sustain.</li>
  <li>If you ask us to delete your data and we are required to fulfill your request, to keep basic data to identify you and prevent further unwanted processing.</li>
</ul>

<h4 id="c-with-your-freely-given-informed-consent">C. With your freely given, informed consent</h4>

<p>If you participate in a video conference, with your prior consent, the conference may be recorded and transcribed. Consenting is voluntary, and you may withdraw your consent at any time. Information collected may include your voice, image, in-meeting messages, and any other Personal Data you choose to share. In addition, participants in a video conference may be able to view your display name, email address and profile picture. By using conference services provided by third parties, third parties may collect, use and share additional personal data pertaining to you, which will be used in accordance with their Privacy Statement.</p>

<p>For individuals in the EU and the UK, please see the “<a href="#euindividual">EU and UK Individuals</a>” section below for information on our legitimate interests and your rights.
<a id="clientssection"></a></p>

<hr></hr>

<br></br>
<h2 id="clients">4. Businesses</h2>
<br></br>
<h3 id="information-we-collect-1">4.1. Information We Collect</h3>
<br></br>
<h4 id="a-information-that-you-provide-to-us-1">A. Information that you provide to us</h4>

<p>We collect Personal Data from our prospective Businesses when we receive a request for hiring our Freelancers. This includes names and contact information of prospective Businesses’s representatives, and any information relating to the Businesses’s needs.</p>

<h4 id="b-information-obtained-from-third-parties-1">B. Information Obtained From Third Parties</h4>

<p>We may supplement the information that we collected from you (such as your email address) with additional information about you and your company obtained from publicly available and third-party databases or services that provide information about business people and companies (including an individual’s name, job title, business contact information, and company information).</p>

<h4 id="c-automatically-collected-data-1">C. Automatically Collected Data</h4>

<p>When you interact with Beehived through the Site or services, we automatically collect certain information. Please read the “<a href="#sitevisitors">Site Visitors</a>” section below for more information.</p>

<br></br>
<h3 id="how-we-use-the-information-1">4.2. How We Use the Information</h3>
<br></br>

<h4 id="a-to-provide-the-services-and-respond-to-requests">A. To Provide The Services And Respond To Requests</h4>

<p>Beehived uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such Services, maintain your account, contact you regarding your use of the Services and/or the Site or to notify you of important changes to the Services and/or the Site, and to monitor your use of such services. For individuals in the EU or UK, such use is necessary to respond to or implement your request and for the performance of the contract between you and us.</p>

<h4 id="b-for-marketing-purposes">B. For Marketing Purposes</h4>

<p>We may use your contact details to tell you about services we believe will be of interest to you, upcoming events or other promotions. If we do so, each marketing communication we send you will contain instructions permitting you to “opt out” of receiving future marketing communications. Note, however, that as a user of our services you cannot opt out of some administrative communications that are reasonably necessary to the services, such as billing or service notifications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.</p>

<p>Where required by applicable law (for example, if you are an individual in the EU or the UK), we will only send you marketing information by email if you consent to us doing so at the time you provide us with your Personal Data. When you provide us with your consent to be contacted for marketing purposes, you have the right to withdraw your consent at any time by following the instructions to “opt-out” of receiving marketing communication in each marketing email we send you or by contacting us as indicated below.</p>

<h4 id="c-as-necessary-for-certain-legitimate-interests">C. As Necessary For Certain Legitimate Interests</h4>

<p>We use your Personal Data for the legitimate interests described below:</p>

<ul>
  <li>To send administrative information to you, for example, information regarding the Site and changes to our terms, conditions, and policies.</li>
  <li>To respond to your inquiries and fulfill your requests, such as to send you requested materials and newsletters, as well as information and materials regarding our products and services.</li>
  <li>To conduct analytics on how the Site and our Service are being used by you for our internal purposes (namely for providing, maintaining, benchmarking and improving our offerings, identifying usage trends and determining the effectiveness of our promotional campaigns) and to inform our marketing strategy and personalize our communications with you (including providing information on our features and other marketing and service-related announcements relevant to the content and features you engage with).</li>
  <li>To supplement the information that we collected from you with information obtained from third parties (described above) in order to update, expand and analyze our records, identify new Businesses, and provide products and services that may be of interest to you.</li>
  <li>To prevent fraud or criminal activity, misuses of our products or services, and ensure the security of our IT systems, architecture and networks.</li>
  <li>To (a) comply with legal obligations and legal process, (b) respond to requests from public and government authorities including public and government authorities outside your country of residence; (c) enforce our terms and conditions; (d) protect our operations; (e) protect our rights, privacy, safety or property, and/or that of you or others; and (f) allow us to pursue available remedies or limit the damages that we may sustain.</li>
  <li>If you ask us to delete your data and we are required to fulfill your request, to keep basic data to identify you and prevent further unwanted processing.</li>
</ul>

<h4 id="d-with-your-freely-given-informed-consent">D. With your freely given, informed consent</h4>

<p>
  If you participate in a video conference, with your prior consent, the conference may be recorded and transcribed. Consenting is voluntary, and you may withdraw your consent at any time. Information collected may include your voice, image, in-meeting messages, and any other Personal Data you choose to share. In addition, participants in a video conference may be able to view your display name, email address and profile picture. By using conference services provided by third parties, third parties may collect, use and share additional personal data pertaining to you, which will be used in accordance with their Privacy Statement.
</p>

<p>For individuals in the EU and the UK, please see the “<a href="#euindividual">EU and UK Individuals</a>” section below for information on our legitimate interests and your rights.</p>

<p>If Beehived intends on using any Personal Data in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected and pursuant to the applicable law.
<a id="jobapplicantssection"></a></p>

<hr></hr>

<br></br>
<h2 id="job-applicants">5. Job Applicants</h2>
<br></br>

<h3 id="information-we-collect-2">5.1. Information We Collect</h3>
<br></br>

<h4 id="a-application-and-assessment-process">A. Application and assessment process</h4>

<p>When you apply for employment through our Site, our provider of recruiting services will collect the Personal Data that you provide in your resume and cover letter. It is for you to decide what you include in these documents. However, the kinds of information you may wish to include are: your name, contact details, employment history and education.</p>

<p>If you are invited to undertake further assessments (such as an interview) in connection with your application and you participate in such assessments, we will collect further Personal Data that you provide to us as part of that process. The kinds of information you may disclose include information about your qualifications and information about your employment experience. You may choose to disclose salary history or salary expectations. If you do not provide us with certain information when requested, it may impact our ability to assess your suitability for a role with us or we may not be able to make you an offer of employment. With your prior consent, we may record your interview(s) to better evaluate your candidacy and for training purposes. Your consent is voluntary, and you may withdraw your consent at any time, including during the interview.  If you withdraw your consent or do not consent, it will have no impact on your candidacy.</p>

<p>Throughout the recruitment process, we may create Personal Data in connection with the assessment of your application. For example, we may record the views of those considering your application about your suitability for the role for which you have applied and retain interview notes.</p>

<p>We have a legitimate interest in facilitating the interview process and communicating offers of employment to you, making informed recruitment decisions and selecting suitable candidates for roles with us, and improving our recruiting and hiring processes.</p>

<p>We will retain your contact details and resume to inform you of current and future career opportunities (unless you tell us that you do not want us to keep your details for that purpose) pursuant to our legitimate interest in doing so.</p>

<h4 id="b-if-your-application-is-successful">B. If your application is successful</h4>

<p>If your application is successful we will collect further Personal Data about you in connection with conducting reference and background checks where required or permitted by applicable local law (including if applicable to you special categories of Personal Data, and your consent to the collection of such additional data will be solicited at such time, pursuant to applicable laws). We will also collect copies of relevant identification documents from you (such as your passport or driving license, proof of address, a copy of your work permit (where applicable), a photograph and a copy of your signature). We use this information to comply with immigration requirements and to verify your identity for our own internal security purposes. We have a legitimate interest in collecting and using this data to comply with our legal obligations and for the performance of your employment contract with us.</p>

<p>If you are hired, your Personal Data will be used as part of your employee record under our employee privacy policies.</p>

<p>Please see the “<a href="#euindividual">EU and UK Individuals</a>” section below for information on your rights in relation to the Personal Data we hold about you.</p>

<h4 id="c-automatically-collected-data-2">C. Automatically Collected Data</h4>

<p>When you interact with Beehived through our Site in relation to your application, we collect automatically certain information. Please read the “Site Visitors” section below for more information.
<a id="sitevisitorssection"></a></p>

<hr></hr>

<br></br>
<h2 id="site-visitors">6. Site Visitors</h2>

<p>When you interact with Beehived through the Site or the Services (whether as a Freelancer, as a Business, a job applicant, a blog reader, software end user, or other visitor to our Site), we and our service providers acting on our behalf will automatically collect information about you through cookies (small text files placed on your device) and other technologies. Please see our <a href="/cookie-policy">Cookie Policy</a> to learn more about how we use cookies and other technologies.</p>

<p>As a visitor to our Site, our servers record information (“log data”), including information that your browser automatically sends whenever you visit the Site. This log data includes your Internet Protocol (“IP”) address (from which we understand the country you are connecting from at the time you visit the Site), browser type and settings, the date and time of your request.</p>

<p>Where the information that we collect automatically on our Site is Personal Data, our legal basis for the use of this information is that it is necessary for the purposes of our legitimate interests in maintaining the safe operation of our Site as well as in learning how Site visitors interact with our Site to improve your use of it.</p>

<hr></hr>

<br></br>
<h2 id="events">7. Events</h2>

<p>We may collect Personal Data from you when you attend one of our events, for instance at business conferences and trade shows. The data we collect includes information that you voluntarily give to us, such as your business card or contact details. We will use this information to develop a business relationship and in a manner consistent with the purpose you gave the information for.</p>

<p>Where required by applicable law (for example, if you are an individual in the EU or UK), we will ask your consent before or when sending you marketing information by email. When you provide us with your consent to be contacted for marketing purposes, you have the right to withdraw your consent at any time by following the instructions to “opt-out” of receiving marketing communication in each marketing email we send you or by contacting us as indicated below.
<a id="datadisclosuresection"></a></p>

<hr></hr>
<br></br>
<h2 id="our-disclosure-of-your-personal-data-and-other-information">8. Our Disclosure of Your Personal Data and Other Information</h2>

<p>Beehived is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties, as set forth below:</p>

<h3 id="business-transfers">Business Transfers</h3>

<p>As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</p>

<h3 id="agents-consultants-and-other-service-providers">Agents, Consultants and Other Service Providers</h3>

<p>Beehived, like many businesses, sometimes hires other companies to perform certain business-related functions. These parties include website analytics companies, providers of digital advertising services, our hosting and cloud computing service providers, providers of CRM, marketing and sales software solutions, providers of billing and processing payments functions, providers of background check services. When we employ another company to perform a function of this nature, we only provide them with the information that they need to perform their specific function. Pursuant to our instructions, these parties may access, process or store Personal Data in the course of performing their duties to us and solely in order to perform the services we have hired them to provide.</p>

<h3 id="legal-requirements">Legal Requirements</h3>

<p>Beehived may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Beehived, (iii) act in urgent circumstances to protect the personal safety of users of the Site or the public, or (iv) protect against legal liability.
<a id="euindividualsection"></a></p>

<hr></hr>
<br></br>
<h2 id="eu-and-uk-individuals">9. EU and UK Individuals</h2>

<h3 id="scope-1">Scope</h3>

<p>This section applies solely to individuals in the UK and EU (for these purposes, reference to the EU also includes the European Economic Area countries of Iceland, Liechtenstein and Norway and, where applicable, Switzerland). Our Privacy Policy describes why and how Beehived collects, uses and stores your Personal Data, the lawful basis on which your Personal Data is processed, and what your rights and our obligations are in relation to such processing (please see “Your Rights” section below).</p>

<h3 id="data-controller">Data Controller</h3>

<p>Beehived is the data controller for processing your Personal Data. The data controller is responsible for deciding how Personal Data about you is used. Please see the “Contacting Beehived” section below to find out how to contact us, which also provides the contact details of our representative in the EU and the UK for purposes of the General Data Protection Regulation.</p>

<h3 id="your-rights">Your Rights</h3>

<p>Subject to applicable EU and UK law, you have the following rights in relation to your Personal Data:</p>

<ul>
  <li>
<strong>Right of access.</strong> If you ask us, we will confirm whether we are processing your Personal Data and, if so, provide you with a copy of that Personal Data along with certain other details. If you require additional copies, we may need to charge a reasonable fee.</li>
  <li>
<strong>Right to rectification.</strong> If your Personal Data is inaccurate or incomplete, you are entitled to ask that we correct or complete it. If we shared your Personal Data with others, we will tell them about the correction where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your Personal Data so you can contact them directly.</li>
  <li>
<strong>Right to erasure.</strong> You may ask us to delete or remove your Personal Data, such as where you withdraw your consent. If we shared your data with others, we will tell them about the erasure where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your Personal Data with so you can contact them directly.</li>
  <li>
<strong>Right to restrict processing.</strong> You may ask us to restrict or ‘block’ the processing of your Personal Data in certain circumstances, such as where you contest the accuracy of the data or object to us processing it. We will tell you before we lift any restriction on processing. If we shared your Personal Data with others, we will tell them about the restriction where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your Personal Data so you can contact them directly.</li>
  <li>
<strong>Right to data portability.</strong> You have the right to obtain your Personal Data from us that you consented to give us or that was provided to us as necessary in connection with our contract with you. We will give you your Personal Data in a structured, commonly used and machine-readable format. You may reuse it elsewhere.</li>
  <li>
<strong>Right to object.</strong> You may ask us at any time to stop processing your Personal Data, and we will do so: (1) If we are relying on a legitimate interest to process your Personal Data – unless we demonstrate compelling legitimate grounds for the processing or (2) If we are processing your Personal Data for direct marketing.</li>
  <li>
<strong>Rights in relation to automated decision-making and profiling.</strong> You have the right to be free from decisions based solely on automated processing of your Personal Data, including profiling, which produce a significant legal effect on you, unless such profiling is necessary for entering into, or the performance of, a contract between you and us, or with your explicit consent. We are not currently processing your Personal Data for such type of automated decision-making, including profiling, but if we elect to do so in the future we will provide you with notice and choice, in accordance with EU or UK data protection law.</li>
  <li>
<strong>Right to withdraw consent.</strong> If we rely on your consent to process your Personal Data, you have the right to withdraw that consent at any time, but this will not affect any processing of your data that has already taken place.</li>
  <li>
<strong>Right to lodge a complaint with the data protection authority.</strong> If you have a concern about our privacy practices, including the way we handled your Personal Data, you can report it to the data protection authority that is authorized to hear those concerns.</li>
</ul>

<p>You may exercise your rights by contacting us as indicated under “Contacting Beehived” section below.</p>

<h3 id="legitimate-interest">Legitimate Interest</h3>

<p>“Legitimate interests” means our interests in conducting and managing our organization and delivering the best Services to you. This Privacy Policy describes when we process your Personal Data for our legitimate interests, what these interests are and your rights. We will not use your Personal Data for activities where the impact on you overrides our interests, unless we have your consent or those activities are otherwise required or permitted by law. You have the right to object to processing that is based on our legitimate interests. For more information on your rights, please see “Your Rights” section above.</p>

<h3 id="data-transfers">Data Transfers</h3>

<p>Beehived is based in the United States. When you apply as a Freelancer or use our services as a Business, or otherwise use our Site, your Personal Data will be transmitted to servers in the United States as necessary to provide you with the services that you requested, administer our contract with you or to respond to your requests as described in this Privacy Policy, and the data may be transmitted to our service providers supporting our business operations (described above). The United States may have data protection laws less stringent than or otherwise different from the laws in effect in the country in which you are located. Where we transfer your Personal Data out of the European Economic Area (EEA) or United Kingdom we will take steps to ensure that your Personal Data receives an adequate level of protection where it is processed and your rights continue to be protected.</p>

<h3 id="data-retention">Data Retention</h3>

<p>Our policy is to keep your Personal Data only for as long as is reasonably necessary to fulfill the purposes for which it was collected and processed, including for the purposes of satisfying any legal, regulatory, accounting or reporting requirements. If you have elected to receive marketing communications from us, we retain information about your marketing preferences until you opt out of receiving these communications and in accordance with our policies.</p>

<p>To determine the appropriate retention period for your Personal Data, we will consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we use your Personal Data and whether we can achieve those purposes through other means, and the applicable legal requirements. In some circumstances we may anonymize your Personal Data so that it can no longer be associated with you, in which case it is no longer Personal Data.</p>

<hr></hr>
<br></br>
<h2 id="your-choices">10. Your Choices</h2>

<p>You can use the Site without providing any Personal Data. If you choose not to provide any Personal Data, you may not be able to use certain services.</p>

<hr></hr>
<br></br>
<h2 id="exclusions">11. Exclusions</h2>

<p>This Privacy Policy does not apply to any Personal Data collected by Beehived other than Personal Data collected through the Site or services. This Privacy Policy shall not apply to any unsolicited information you provide to Beehived through this Site or through any other means (without prejudice to your rights under the applicable law). This includes, but is not limited to, information posted to any public areas of the Site, such as forums (collectively, “<strong>Public Areas</strong>”), any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “<strong>Unsolicited Information</strong>”). All Unsolicited Information shall be deemed to be non-confidential and Beehived shall be free to reproduce, use, disclose, distribute and exploit such Unsolicited Information without limitation or attribution.</p>

<hr></hr>
<br></br>
<h2 id="children">13. Children</h2>

<p>Beehived does not knowingly collect Personal Data from children under the age of 13. If you are under the age of 13, please do not submit any Personal Data through the Site. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on this Site without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to Beehived through this Site, please contact us, and we will endeavor to delete that information from our databases.</p>

<hr></hr>
<br></br>
<h2 id="links-to-other-web-sites">13. Links to Other Web Sites</h2>

<p>This Site may contain links to other websites not operated or controlled by Beehived (“<strong>Third Party Sites</strong>”). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed the Third Party Sites. We suggest contacting those sites directly for information on their privacy practices and policies.</p>

<hr></hr>
<br></br>
<h2 id="security">14. Security</h2>

<p>Beehived takes reasonable and appropriate steps to protect the Personal Data provided via the Site or the services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet, email or other electronic transmission is ever fully secure or error free, so you should take special care in deciding what information you send to us in this way.</p>

<hr></hr>
<br></br>
<h2 id="other-terms-and-conditions">15. Other Terms and Conditions</h2>

<p>Your access to and use of this Site is subject to our <a href="/tos">Website Terms &amp; Conditions</a>.</p>

<hr></hr>
<br></br>
<h2 id="changes-to-Beehiveds-privacy-policy">16. Changes to Beehived’s Privacy Policy</h2>

<p>The Site and our business may change from time to time. As a result, at times it may be necessary for Beehived to make changes to this Privacy Policy. Beehived reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice, unless otherwise required by the applicable law. Please review this policy periodically, and especially before you provide any Personal Data. Your continued use of the Site after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy, without prejudice to your rights under the applicable law.
<a id="contactsection"></a></p>

<hr></hr>
<br></br>
<h2 id="contacting-Beehived">17. Contacting Beehived</h2>

<p>Please contact us if you have any questions about Beehived’s Privacy Policy or the information practices of this Site.</p>

<p>We have appointed a data protection officer who is responsible for overseeing questions regarding this Privacy Policy. You may contact the DPO as follows: by email at <a href="mailto:privacy@Beehived.com">privacy@Beehived.com</a>, or by post at:</p>

<p>Beehived, LLC</p>

<p>If you are an individual in the EU or UK, you can also contact VeraSafe, who has been appointed as Beehived’s representative in the EU and UK for data protection matters, pursuant to Article 27 of the EU General Data Protection Regulation and the UK General Data Protection Regulation. You may contact VeraSafe directly about the processing of your Personal Data by using this contact <a href="https://verasafe.com/public-resources/contact-data-protection-representative">form</a>. Alternatively, VeraSafe can be contacted at:</p>

<p><u>EU</u><br></br>
VeraSafe Ireland Ltd<br></br>
Unit 3D North Point House<br></br>
North Point Business Park<br></br>
New Mallow Road<br></br>
Cork T23AT2P<br></br>
Ireland<br></br>
</p>
<p><u>UK</u><br></br>
VeraSafe United Kingdom Ltd.<br></br>
37 Albert Embankment<br></br>
London SE1 7TL<br></br>
United Kingdom</p>
</div>      </Card>
    </Container>
  );
}

export default Privacy;
