import "../../screens/CreateProject/styles.css";
import "./InviteOnProject/styles.css";
import { Modal, Row, Col, Form, Button, Card } from "react-bootstrap";
import search from "../../assets/svg/icon/whiteSearch.svg";
import { LangEN } from "../../constants/Lang/LangEN";
import { useEffect, useState } from "react";
import InviteUserCard from "../reusableComponents/InviteUserCard";
import UserService from "../../services/UserService";
import Loader from "../reusableComponents/Loader";

function SearchProfessionals({
  show,
  setShow,
}: {
  show: boolean;
  setShow: any;
}) {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    UserService.GetProfessionals()
      .then((res) => {
        setProfessionals(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(searchValue);
  }, [searchValue]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
      contentClassName="p-4 inviteProject_container"
    >
      <div className="inviteProject_innerContainer my-4">
        <Row className="mb-3">
          <Col className="me-0 pe-0">
            <Card className="h-100 cardShadow">
              <Form.Control
                className="h-100 shadow-none"
                style={{ outline: "none", border: "none" }}
                placeholder={LangEN.SearchProfessionals}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Card>
          </Col>
          <Col xs="auto">
            <Button className="p-2 px-4">
              <img src={search} style={{ margin: 5 }} width={28} />
            </Button>
          </Col>
        </Row>
        <div className="inviteProject_listScroll">
          {isLoading ? (
            <Loader />
          ) : (
            professionals.map((item: any, index: number) => {
              return <InviteUserCard index={index} item={item} />;
            })
          )}
        </div>
      </div>
    </Modal>
  );
}

export default SearchProfessionals;
