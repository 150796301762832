import { isValidPhoneNumber } from "react-phone-number-input";
import { LangEN } from "../constants/Lang/LangEN";
export function validateEmail(email: string, setEmailError: any) {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //email regular expression
  if (email == "") {
    setEmailError(LangEN.EmailRequired);
    return;
  }
  if (!re.test(email)) {
    setEmailError(LangEN.ValidEmail);
    return;
  }
  setEmailError("");
  return;
}

export function validateName(name: string, setNameError: any) {
  var re = /^[a-zA-Z ]+$/;
  if (name == "") {
    setNameError(LangEN.NameRequired);
    return;
  }
  if (!re.test(name)) {
    setNameError(LangEN.NameSpecialCharacters);
    return;
  }
  if (name.length < 2) {
    setNameError(LangEN.NameLengthLow);
    return;
  }
  if (name.length > 35) {
    setNameError(LangEN.NameLengthExceeded);
    return;
  }
  setNameError("");
  return;
}

export function validateFirstName(name: string, setNameError: any) {
  var re = /^[a-zA-Z ]+$/;
  if (name == "") {
    setNameError(LangEN.FirstNameRequired);
    return;
  }
  if (!re.test(name)) {
    setNameError(LangEN.FirstNameSpecialCharacters);
    return;
  }
  if (name.length < 2) {
    setNameError(LangEN.FirstNameLengthLow);
    return;
  }
  if (name.length > 15) {
    setNameError(LangEN.FirstNameLengthExceeded);
    return;
  }
  setNameError("");
  return;
}

export function validateLastName(name: string, setNameError: any) {
  var re = /^[a-zA-Z ]+$/;
  if (name == "") {
    setNameError("");
    return;
  }
  if (!re.test(name)) {
    setNameError(LangEN.LastNameSpecialCharacters);
    return;
  }
  if (name.length < 2) {
    setNameError(LangEN.LastNameLengthLow);
    return;
  }
  if (name.length > 15) {
    setNameError(LangEN.LastNameLengthExceeded);
    return;
  }
  setNameError("");
  return;
}

export function validateCompanyName(name: string, setNameError: any) {
  // var re = /^[a-zA-Z ]+$/;
  if (name == "") {
    setNameError(LangEN.CompanyNameRequired);
    return;
  }
  // if (!re.test(name)) {
  //   setNameError(LangEN.CompanyNameSpecialCharacters);
  //   return;
  // }
  if (name.length < 2) {
    setNameError(LangEN.CompanyNameLengthLow);
    return;
  }
  if (name.length > 50) {
    setNameError(LangEN.CompanyNameLengthExceeded);
    return;
  }
  setNameError("");
  return;
}

export function validateAboutCompany(about: string, setAboutError: any) {
  if (about.length > 500) {
    setAboutError(LangEN.CompanyAboutLengthExceeded);
    return;
  }
  setAboutError("");
  return;
}

export function validateFoundedIn(value: string, setError: any) {
  if (value == "") {
    setError(LangEN.FoundedInRequired);
    return;
  }
  setError("");
  return;
}

export function validatePassword(password: string, setPasswordError: any) {
  var re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`|}{[\]:;\\<>?,./-])[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;\\<>?,./-]{8,}$/;
  if (password == "") {
    setPasswordError(LangEN.PasswordRequired);
    return;
  }
  if (password.includes(" ")) {
    setPasswordError(LangEN.PasswordContainsEmptySpaces);
    return;
  }
  if (password.length < 8) {
    setPasswordError(LangEN.PasswordLengthError);
    return;
  }
  if (password.length > 20) {
    setPasswordError(LangEN.PasswordLengthExceedError);
    return;
  }
  if (!re.test(password)) {
    setPasswordError(LangEN.PasswordValidationError);
    return;
  }
  setPasswordError("");
  return;
}

export function validateConfirmPassword(
  confirmPassword: string,
  password: string,
  setConfirmPasswordError: any
) {
  if (confirmPassword == "") {
    setConfirmPasswordError(LangEN.ConfirmPasswordRequired);
    return;
  }
  if (password !== confirmPassword) {
    setConfirmPasswordError(LangEN.PasswordNotMatch);
    return;
  }
  setConfirmPasswordError("");
  return;
}

export function validatePhone(phone: string, setPhoneError: any) {
  if (!phone) {
    setPhoneError("");
    return;
  }
  if (!isValidPhoneNumber(phone)) {
    setPhoneError(LangEN.ValidPhone);
    return;
  }
  setPhoneError("");
  return;
}

export function validateCurrentRole(role: string, setRoleError: any) {
  if (role == "") {
    setRoleError(LangEN.RoleRequired);
    return;
  }
  if (role.length > 50) {
    setRoleError(LangEN.RoleLengthExceeded);
    return;
  }
  setRoleError("");
  return;
}
