export enum notificationType {
  ProfileVerification = 0,
  JobApplicationReceived = 1,
  HiredOnJob = 2,
  NotHiredOnJob = 3,
  JobCompleted = 4,
  AcceptJobInvitation = 5,
  RejectJobInvitation = 6,

  JobApplicationAccepted = 7,
  JobApplicationRejected = 8,
  NotHiredForJob = 9,
  JobInvitation = 10,
}
