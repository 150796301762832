import { useState, useEffect } from "react";
import { Col, Container, Form, Row, Image } from "react-bootstrap";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import { LoginStyles } from "../Login/LoginStyle";
import gandalfBlue from "../../../assets/svg/Color logo-verticle.svg";
import backArrow from "../../../assets/svg/icon/backArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { UserRoles } from "../../../enums/UserRoles";
import { LangEN } from "../../../constants/Lang/LangEN";
import { validateEmail } from "../../../Utils/Validators";
import OtpInput from "react18-input-otp";
import AuthService from "../../../services/AuthService";
import ResetPassword from "../ResetPassword/ResetPassword";
import UserService from "../../../services/UserService";

export default function ForgotPassword({ isBusiness }: any) {
  const navigate = useNavigate();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState("");
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isWaitingForResend, setIsWaitingForResend] = useState(false);

  const resendOtp = () => {
    setIsLoadingResend(true);
    console.log("resend-clicked");

    let apiReq = {
      email: email,
    };

    AuthService.SendForgotPasswordOTP(apiReq)
      .then((res) => {
        console.log("res", res.data);
        setIsLoadingResend(false);
        setResendTimer(180);
        setIsWaitingForResend(true);
        const interval = setInterval(() => {
          setResendTimer((pre) => {
            if (pre == 1) {
              clearInterval(interval);
              setIsWaitingForResend(false);
            }
            return pre - 1;
          });
        }, 1000);
      })
      .catch((err) => {
        setIsLoadingResend(false);
        if (err.response.data == "OTP sent successfully")
          console.log(err.response.data);
        console.log(err);
      });
  };

  useEffect(() => {
    validate();
  }, [email, emailError]);

  function validate() {
    // check to enable submission of the form
    if (email && !emailError) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  const getTimeMinute = (time: number) => {
    let minutes = Math.floor(time / 60);
    let sec = time % 60;
    return minutes ? "(" + minutes + "min " + sec + " s)" : "(" + sec + " s)";
  };

  const handleSubmit = () => {
    console.log("submit-clicked");

    setIsLoading(true);
    if (isOtpSent) {
      let apiReq = {
        email: email,
        otp: otp,
      };

      AuthService.VerifyForgotPasswordOTP(apiReq)
        .then((res) => {
          setIsLoading(false);
          // navigate("/resetpassword", {
          //   state: { email: email },
          // });
          localStorage.setItem("loginToken", res.data.token);
          setIsResetPassword(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });

      return;
    }
    validate();
    if (!validated) {
      return;
    }

    let apiReq = {
      email: email,
    };

    AuthService.SendForgotPasswordOTP(apiReq)
      .then((res) => {
        console.log("res", res.data);
        setIsLoading(false);
        setIsOtpSent(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  if (isResetPassword)
    return <ResetPassword email={email} isBusiness={isBusiness} />;
  return (
    <>
      <Container fluid>
        <Row className="d-md-block d-lg-none" style={LoginStyles.row1}>
          <Col>
            <Link
              to={isBusiness ? "/businesslogin" : "/login"}
              style={LoginStyles.img1}
            >
              <Image src={backArrow} />
            </Link>
            <div style={LoginStyles.img2}>
              <Image
                width="30%"
                height="30%"
                style={{ marginBottom: 30 }}
                src={gandalfBlue}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-lg-block" style={LoginStyles.col1}>
            <Row style={LoginStyles.row2}>
              <div style={LoginStyles.labelDiv}>
                <label style={LoginStyles.label2}>
                  {LangEN.ForgotPassword}
                </label>
              </div>
            </Row>
          </Col>
          <Col style={LoginStyles.col2}>
            <label style={LoginStyles.label3}>
              <div className={"auth_label_title"}>
                {LangEN.ForgotYourPasswordTitle}
              </div>
              <div className="auth_labelUnderline" />
            </label>
            <label className="my-2" style={LoginStyles.subLabel}>
              {isOtpSent
                ? LangEN.EmailSentSubtitle
                : LangEN.EnterEmailBelowForOTP}
              {isOtpSent && <span style={{ fontWeight: 600 }}>{email}</span>}
            </label>
            <Form
              style={LoginStyles.form}
              noValidate
              onSubmit={(e): any => {
                console.log("form-submit");
                e.preventDefault();
              }}
            >
              {!isOtpSent ? (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    size="lg"
                    type="email"
                    className="shadow-none"
                    style={LoginStyles.formControl}
                    value={email}
                    placeholder={`${LangEN.EnterYouEmail} *`}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/\s/g, "");
                      emailError && validateEmail(value, setEmailError);
                      setEmail(value);
                    }}
                    onBlur={() => {
                      validateEmail(email, setEmailError);
                    }}
                    isInvalid={!!emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                <>
                  <OtpInput
                    onChange={(e: any) => {
                      setOtpError("");
                      setOtp(e);
                      if (e.length == 6) {
                        setOtpValidated(true);
                      } else setOtpValidated(false);
                    }}
                    value={otp}
                    numInputs={6}
                    isInputNum
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    containerStyle={{
                      marginBottom: 20,
                    }}
                    inputStyle={{
                      width: "36px",
                      height: "45px",
                      borderRadius: "6px",
                      borderColor: "#ccc",
                      borderStyle: "solid",
                      borderWidth: 1,
                    }}
                  />
                  <div className="invalid-feedback d-block">{otpError}</div>
                </>
              )}

              {isOtpSent ? (
                <div className="d-flex gap-3">
                  <CustomButton
                    variant="primary"
                    style={LoginStyles.button2}
                    size="lg"
                    isLoading={isLoadingResend}
                    label={`${LangEN.ResendOtp} ${
                      resendTimer ? getTimeMinute(resendTimer) : ""
                    }`}
                    disabled={isWaitingForResend}
                    onClick={resendOtp}
                  />
                  <CustomButton
                    variant="primary"
                    type="submit"
                    style={LoginStyles.button3}
                    size="lg"
                    disabled={!(otpValidated && !otpError)}
                    label={LangEN.VerifyOTP}
                    onClick={handleSubmit}
                  />
                </div>
              ) : (
                <CustomButton
                  label={LangEN.GetOTP}
                  isLoading={isLoading}
                  variant="primary"
                  style={LoginStyles.button}
                  size="lg"
                  onClick={handleSubmit}
                  disabled={!validated}
                />
              )}
              {!isOtpSent && (
                <div style={LoginStyles.labelDiv2}>
                  <label style={LoginStyles.label5}>
                    {LangEN.AlreadyHaveAccount}{" "}
                    <Link to={isBusiness ? "/businesslogin" : "/login"}>
                      {LangEN.Login}
                    </Link>
                  </label>
                  <label style={LoginStyles.label5}>
                    {LangEN.AuthCopyright}
                  </label>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
