import { useEffect, useState } from "react";
import "./styles.css";
import searchLogo from "../../assets/svg/icon/search.svg";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import JobListCard from "../../components/reusableComponents/ApplicationCard/JobListCard";
import JobService from "../../services/JobService";
import Loader from "../../components/reusableComponents/Loader";
import { Sorting } from "../../enums/Sorting";
import EmptyList from "../../components/reusableComponents/EmptyList";
import { debounce } from "lodash";

function SavedJobs() {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState(Sorting.DESC);
  const [search, setSearch] = useState("");
  useEffect(() => {
    setIsLoading(true);
    JobService.GetSavedJobs(sort, search)
      .then((res) => {
        console.log(res.data);
        setJobs(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sort, search]);
  return (
    <Container className="main_container">
      <Row>
        <Col className="job_header">
          Search best
          <span className="job_headerSpan"> freelancing job</span>
        </Col>
      </Row>
      <Row>
        <Col className="">
          <Card className="cardShadow p-2 ps-3 mx-2 mx-md-5 my-4">
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Control
                  className="job_search shadow-none"
                  placeholder={LangEN.SearchJobDescription}
                  onChange={debounce((e) => {
                    setSearch(e.target.value);
                  }, 500)}
                />
              </Col>
              <Col xs="auto">
                <Button className="p-2 px-3">
                  <img
                    src={searchLogo}
                    width={26}
                    style={{ filter: "invert()" }}
                  />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="my-3 mb-2 mx-2">
            <Col className={"job_title"}>{LangEN.SavedJobList}</Col>
            <Col className="col-auto d-flex">
              <div className="job_sortContainer">
                {LangEN.SortBy}
                <span>
                  <Form.Select
                    className="job_select shadow-none"
                    onChange={(e) => setSort(+e.target.value)}
                  >
                    <option selected value={Sorting.DESC}>
                      {LangEN.NewestPost}
                    </option>
                    <option value={Sorting.ASC}>{LangEN.OldestPost}</option>
                  </Form.Select>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            {isLoading ? (
              <Loader lg />
            ) : jobs.length == 0 ? (
              <EmptyList saved />
            ) : (
              jobs.map((item: any, index) => {
                if (item.job.project.jobs.length > 0) {
                  item.job.project.isStaffed = true;
                }
                return (
                  <JobListCard
                    index={index}
                    job={item.job}
                    isAppliedSavedJob={item.isJobApplied == 1 ? true : false}
                    isApplied={item.isJobApplied == 1 ? true : false}
                  />
                );
              })
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SavedJobs;
