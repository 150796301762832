import React, { useState, useEffect } from "react";
import Theme from "../../../constants/Theme";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import {
  Container,
  Form,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
} from "react-bootstrap";
import { SignUpStyles } from "./SignUpStyle";
import gandalfBlue from "../../../assets/svg/Color logo-verticle.svg";
import backArrow from "../../../assets/svg/icon/backArrow.svg";
import eyeOff from "../../../assets/svg/icon/eye.svg";
import eye from "../../../assets/svg/icon/eye-off.svg";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import { UserRoles } from "../../../enums/UserRoles";
import CustomToast from "../../../components/reusableComponents/CustomToast";
import { LangEN } from "../../../constants/Lang/LangEN";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useContext } from "react";
import { User } from "../../../App";
import {
  validateConfirmPassword,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateName,
  validatePassword,
  validatePhone,
} from "../../../Utils/Validators";

export default function BusinessSignUp() {
  const user = useContext(User);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [toastShown, setToastShown] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  useEffect(() => {
    validate();
  }, [
    firstName,
    lastName,
    email,
    phone,
    password,
    confirmPassword,
    emailError,
    passwordError,
  ]);

  function validate() {
    // check to enable form submission
    if (
      firstName &&
      email &&
      password &&
      confirmPassword &&
      !emailError &&
      !firstNameError &&
      !lastNameError &&
      !passwordError &&
      !phoneError
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    validate();
    if (validated && confirmPassword === password) {
      setIsLoading(true);
      let apiReq = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        contact: phone,
        role: UserRoles.Business,
        password: password,
      };

      AuthService.SignUp(apiReq)
        .then((res) => {
          localStorage.setItem("loginToken", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          // sessionStorage.setItem("session", "true");
          localStorage.setItem("session", "true");
          setIsLoading(false);
          user.setRole(UserRoles.Business);
          navigate("/verification");
        })
        .catch((err) => {
          if (err.response.data.message == "User already exists") {
            setToastMessage("User already exists!");
          } else {
            setToastMessage("Something went wrong!");
          }
          setToastShown(true);
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <CustomToast //toast for error messages
        isShown={toastShown}
        hide={() => {
          setToastShown(false);
        }}
        message={toastMessage}
      />
      <Container fluid>
        <Row className="d-md-block d-lg-none" style={SignUpStyles.row1}>
          <Col>
            <Link to="/" style={SignUpStyles.img1}>
              <Image src={backArrow} />
            </Link>
            <div style={SignUpStyles.img2}>
              <Image
                width="30%"
                height="30%"
                style={{ marginBottom: 30 }}
                src={gandalfBlue}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-lg-block" style={SignUpStyles.col1}>
            <Row style={SignUpStyles.row2}>
              <div style={SignUpStyles.labelDiv}>
                {" "}
                <label style={SignUpStyles.label1}>{LangEN.Welcome}</label>
                <label style={SignUpStyles.label2}>
                  {LangEN.Business + " " + LangEN.SignUp}
                </label>
              </div>
            </Row>
          </Col>
          <Col style={SignUpStyles.col2}>
            <label style={SignUpStyles.label3}>
              <div className={"auth_label_title"}>
                {LangEN.Business + " " + LangEN.SignUp}
              </div>
              <div className="auth_labelUnderline" />
            </label>
            <Form style={SignUpStyles.form} noValidate onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      style={SignUpStyles.formControl}
                      size="lg"
                      type="text"
                      placeholder={`${LangEN.FirstName} *`}
                      value={firstName}
                      onChange={(e) => {
                        let value = e.target.value;
                        firstNameError &&
                          validateFirstName(value, setFirstNameError);
                        setFirstName(value.trim());
                      }}
                      onBlur={() => {
                        setFirstName((pre) => {
                          let name = pre.replace(/\s{2,}/g, " ").trim();
                          validateFirstName(name, setFirstNameError);
                          return name.trim();
                        });
                      }}
                      className="shadow-none"
                      isInvalid={!!firstNameError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {firstNameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      style={SignUpStyles.formControl}
                      size="lg"
                      type="text"
                      placeholder={`${LangEN.LastName}`}
                      value={lastName}
                      onChange={(e) => {
                        let value = e.target.value;
                        lastNameError &&
                          validateLastName(value, setLastNameError);
                        setLastName(value.trim());
                      }}
                      onBlur={() => {
                        setLastName((pre) => {
                          let name = pre.replace(/\s{2,}/g, " ").trim();
                          validateLastName(name, setLastNameError);
                          return name.trim();
                        });
                      }}
                      className="shadow-none"
                      isInvalid={!!lastNameError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {lastNameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="col-12 col-md-7">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      style={SignUpStyles.formControl}
                      size="lg"
                      type="email"
                      value={email}
                      placeholder={`${LangEN.Email} *`}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/\s/g, "");
                        emailError && validateEmail(value, setEmailError);
                        setEmail(value);
                      }}
                      onBlur={() => {
                        validateEmail(email, setEmailError);
                      }}
                      isInvalid={!!emailError}
                      className="shadow-none"
                    />
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-12 col-md-5">
                  <Form.Group className="mb-3" controlId="formBasicPhone">
                    <PhoneInput
                      style={{
                        width: "100%",
                        height: "60px",
                        borderRadius: "5px",
                        marginLeft: 0,
                        paddingLeft: 10,
                        border: "1px solid #ccc",
                      }}
                      placeholder={`${LangEN.Phone} `}
                      value={phone}
                      inputComponent={Form.Control}
                      numberInputProps={{
                        isInvalid: !!phoneError,
                        className: "shadow-none",
                      }}
                      onChange={(e: any) => {
                        phoneError && validatePhone(e, setPhoneError);
                        setPhone(e);
                      }}
                      onBlur={() => {
                        validatePhone(phone, setPhoneError);
                      }}
                      defaultCountry="US"
                    />
                    <label
                      className="invalid-feedback"
                      style={{
                        display: !phoneError ? "none" : "block",
                      }}
                    >
                      {phoneError}
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <Form.Control
                    size="lg"
                    style={SignUpStyles.formControl}
                    type={passwordShown ? "text" : "password"}
                    placeholder={`${LangEN.Password} *`}
                    value={password}
                    onChange={(e) => {
                      let value = e.target.value;
                      setPassword(value);
                      passwordError &&
                        validatePassword(value, setPasswordError);
                    }}
                    onBlur={() => {
                      validatePassword(password, setPasswordError);
                    }}
                    isInvalid={!!passwordError}
                    className="shadow-none password-field"
                  />
                  <Button
                    className="password-eye-button"
                    onClick={() => {
                      setPasswordShown((pre) => !pre);
                    }}
                  >
                    <img
                      src={passwordShown ? eyeOff : eye}
                      width={30}
                      height={25}
                      className={"password-eye"}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                <InputGroup>
                  <Form.Control
                    size="lg"
                    style={SignUpStyles.formControl}
                    type={confirmPasswordShown ? "text" : "password"}
                    isInvalid={!!confirmPasswordError}
                    placeholder={`${LangEN.ConfirmPassword} *`}
                    value={confirmPassword}
                    onChange={(e) => {
                      let value = e.target.value;
                      setConfirmPassword(value);
                      setConfirmPasswordError("");
                    }}
                    onBlur={() => {
                      validateConfirmPassword(
                        confirmPassword,
                        password,
                        setConfirmPasswordError
                      );
                    }}
                    className="shadow-none password-field"
                  />
                  <Button
                    className="password-eye-button"
                    onClick={() => {
                      setConfirmPasswordShown((pre) => !pre);
                    }}
                  >
                    <img
                      src={confirmPasswordShown ? eyeOff : eye}
                      width={30}
                      height={25}
                      className={"password-eye"}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {confirmPasswordError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <CustomButton
                // href="/login"
                type="submit"
                isLoading={isLoading}
                label={LangEN.SignUp}
                size="lg"
                style={SignUpStyles.button}
                disabled={!validated}
              />
              <div style={SignUpStyles.labelDiv2}>
                <label style={SignUpStyles.label5}>
                  {LangEN.AlreadyHaveAccount}{" "}
                  <Link to="/businesslogin">{LangEN.Login}</Link>
                </label>
                <label style={SignUpStyles.label5}>
                  {LangEN.AuthCopyright}
                </label>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
