import Theme from "../../constants/Theme";

export const NavigationHeaderStyles = {
  nav: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  navItem: {
    fontSize: "20px",
    color: "black",
    padding: "10px",
    textDecoration: "none",
    cursor: "pointer",
  },

  navItem2: {
    fontSize: "20px",
    color: "black",
    paddingLeft: "10px",
    textDecoration: "none",
  },
  navItemActive: {
    fontSize: "20px",
    color: "#3C65f5",
    padding: "10px",
    textDecoration: "none",
  },
  navItem2Active: {
    fontSize: "20px",
    paddingLeft: "10px",
    color: "#3C65f5",
    textDecoration: "none",
  },
  navBar: {
    backgroundColor: "#ffffff",
    border: "none",
    boxShadow: "0px 1px 10px #999",
    padding: "10px 5%",
    position: "relative" as "relative",
  },
  navBrand: {
    color: Theme.colors.WHITE,
  },
  notificationButton: {
    border: "none",
    backgroundColor: "transparent",
    marginRight: 8,
  },
  divider: {
    width: 1,
    backgroundColor: "#999",
    height: "40%",
    alignSelf: "center",
  },
  dropdownToggle: {
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    marginLeft: 12,
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  infoIconContainer: {
    alignItems: "center",
    border: "1px #aaa solid",
    borderRadius: 20,
    margin: 10,
    padding: 5,
  },
  navButton2: {
    fontWeight: 600,
    letterSpacing: 0.8,
    textDecoration: "none",
  },
  headerContainer: { display: "flex", alignItems: "center" },
  dropdownToggle2: {
    alignSelf: "center",
    border: "none",
    padding: 5,
    margin: 5,
    backgroundColor: "#fff",
  },
  navButton: {
    textDecoration: "none",
    fontSize: 20,
    fontWeight: 500,
    color: "black",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  col: {
    display: "flex",
    alignItems: "center",
  },
};
