import { useState, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../../components/sharedComponents/Footer";
import Header1 from "../../components/sharedComponents/NavigationHeader";
import { useEffect } from "react";
import UserService from "../../services/UserService";
import { BreadCrumbsContext, User } from "../../App";
import { getMessagingToken } from "../../firebase";
import NotificationService from "../../services/NotificationService";
import browserId from "browser-id/lib/browserID";
import BreadCrumbs from "../../components/reusableComponents/BreadCrumbs/BreadCrumbs";
import Loader from "../../components/reusableComponents/Loader";
import { ErrorBoundary } from "react-error-boundary";
import ErrorNavigator from "./ErrorNavigator";

function ProtectedRoutes() {
  const bread = useContext(BreadCrumbsContext);
  const [loaded, setLoaded] = useState(false);
  const [loadedUserData, setLoadedUserData] = useState(false);
  const user = useContext(User);
  const [token, setToken] = useState(localStorage.getItem("loginToken"));
  const [sessionStorageRememberMe, setSessionStorageRememberMe] = useState(
    sessionStorage.getItem("session")
  );
  const [localStorageRememberMe, setLocalStorageRememberMe] = useState(
    localStorage.getItem("session")
  );

  useEffect(() => {
    if (!sessionStorageRememberMe && !localStorageRememberMe) {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (!token) return;
    console.log("requesting notification permissions");
    if (!getMessagingToken)
      console.log("firebase messing function not found!!!!");
    getMessagingToken()
      .then((res) => {
        saveNotificationToken(res);
        console.log("notification token ==> ", res);
      })
      .catch((err) => {
        console.log("error in retrieving notification token ", err);
      });
  }, []);

  useEffect(() => {
    if (!token) return;
    let oldData = localStorage.getItem("accStatus");
    if (oldData) {
      user.setAccStatus(JSON.parse(oldData));
      setLoaded(true);
      return;
    }
    UserService.GetAccountVerification()
      .then((res) => {
        console.log(res.data, "--------->>>");
        user.setAccStatus(res.data);
        localStorage.setItem("accStatus", JSON.stringify(res.data));
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!token) return;
    let oldData = localStorage.getItem("userData");
    if (oldData) {
      console.log(JSON.parse(oldData));
      user.setUser(JSON.parse(oldData));
      setLoadedUserData(true);
      return;
    }
    UserService.ViewProfile()
      .then((res) => {
        console.log("--------->>", res.data);
        user.setUser(res.data);
        localStorage.setItem("userData", JSON.stringify(res.data));
        setLoadedUserData(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!token) return;
    initBackgroundListener();
  }, []);

  async function initBackgroundListener() {
    // setting fcm background listener
    console.log("setting background fcm listener");
    if ("serviceWorker" in navigator) {
      console.log("trying adding service worker");
      try {
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        console.log("registrationpf SW=>", registration);
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        } else {
          console.log(registration);
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    } else console.log("no serviceWorker");
  }

  const saveNotificationToken = (token: string) => {
    console.log("sending notification token to backend to save in db");
    let apiReq = {
      deviceToken: token,
      deviceId: browserId(),
    };
    NotificationService.SaveFCMToken(apiReq)
      .then((res) => {
        console.log("notification token saved in db");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!token || (!sessionStorageRememberMe && !localStorageRememberMe)) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <ErrorBoundary fallback={<ErrorNavigator />}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
            height: "100vh",
            width: "100vw",
            backgroundColor: "#f1f3f6",
          }}
        >
          <Header1 />
          <div
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            {loaded && loadedUserData ? (
              <>
                <BreadCrumbs data={bread.data} />
                <div style={{ flexGrow: 1 }}>
                  <Outlet />
                </div>
              </>
            ) : (
              <Loader lg />
            )}
          </div>
          <Footer />
        </div>
      </ErrorBoundary>
    );
  }
}

export default ProtectedRoutes;
