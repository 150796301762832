import Http from "./HttpService";
import { API } from "../constants/ApiDetails";

class CustomSkillService {
  public static async AddCustomProfileSkills(apiReq: any) {
    const url = `${API.CUSTOM_PROFILE_SKILLS}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async DeleteCustomProfileSkills(id: any) {
    const url = `${API.CUSTOM_PROFILE_SKILLS}/${id}`;
    const response = await Http.delete(url);
    return response;
  }

  public static async AddCustomJobSkills(apiReq: any) {
    const url = `${API.CUSTOM_JOB_SKILLS}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async DeleteCustomJobSkills(id: any) {
    const url = `${API.CUSTOM_JOB_SKILLS}/${id}`;
    const response = await Http.delete(url);
    return response;
  }
}

export default CustomSkillService;
