import Theme from "../../constants/Theme";

export const WelcomeStyles = {
  container1: {
    backgroundColor: Theme.colors.SECONDARY,
  },
  row1: {
    display: "flex",
    alignItems: "center",
    padding: "5% 0 5% 0",
  },
  col1: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
  button: {
    width: "100%",
    borderRadius: "30px",
  },
  paperino: {
    width: "100%",
    borderRadius: "30px",
    backgroundColor: Theme.backgroundColor.HOVER,
  },
  label1: {
    color: Theme.colors.WHITE,
    fontSize: "50px",
    fontWeight: 300,
  },
  label2: {
    color: Theme.colors.WHITE,
    fontSize: "23px",
    fontWeight: 300,
    paddingBottom: "15px",
  },
  col2: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
  image: {
    marginTop: "21.5%",
  },
  label3: {
    fontSize: "70px",
    fontWeight: 300,
  },
  label4: {
    fontSize: "22px",
    fontWeight: 300,
  },
  row2: {
    display: "flex",
    justifyContent: "center",
  },
  col3: {
    padding: "5% 2.5% 5% 2.5%",
  },
  imgOverlay: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
