import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import "./ChangePasswordModalStyles.css";
import eyeOff from "../../assets/svg/icon/eye.svg";
import eye from "../../assets/svg/icon/eye-off.svg";
import { useState } from "react";
import { validatePassword } from "../../Utils/Validators";
import CustomButton from "../reusableComponents/CustomButton";
import { useEffect } from "react";
import AuthService from "../../services/AuthService";

function ChangePasswordModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: Function;
}) {
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [currentPassError, setCurrentPassError] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [confirmNewPassError, setConfirmNewPassError] = useState("");
  const [currentPassShown, setCurrentPassShown] = useState(false);
  const [newPassShown, setNewPassShown] = useState(false);
  const [confirmNewPassShown, setConfirmNewPassShown] = useState(false);
  const [validated, setValidated] = useState(false);
  const [passChangeError, setPassChangeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formMapper = [
    {
      label: LangEN.TypeYourCurrentPassword + "*",
      value: currentPass,
      shown: currentPassShown,
      setShown: setCurrentPassShown,
      setValue: setCurrentPass,
      error: currentPassError,
      setError: setCurrentPassError,
      validate: validatePassword,
    },
    {
      label: LangEN.TypeYourNewPassword + "*",
      value: newPass,
      shown: newPassShown,
      setShown: setNewPassShown,
      setValue: setNewPass,
      error: newPassError,
      setError: setNewPassError,
      validate: validatePassword,
    },
    {
      label: LangEN.RetypeYourNewPassword + "*",
      value: confirmNewPass,
      shown: confirmNewPassShown,
      setShown: setConfirmNewPassShown,
      setValue: setConfirmNewPass,
      error: confirmNewPassError,
      setError: setConfirmNewPassError,
      validate: validatePassword,
    },
  ];
  useEffect(() => {
    setCurrentPass("");
    setNewPass("");
    setConfirmNewPass("");
    setCurrentPassShown(false);
    setNewPassShown(false);
    setConfirmNewPassShown(false);
    setCurrentPassError("");
    setNewPassError("");
    setConfirmNewPassError("");
  }, [show]);

  const validate = () => {
    setPassChangeError("");
    if (
      !confirmNewPassError &&
      !newPassError &&
      !currentPassError &&
      !!confirmNewPass &&
      !!newPass &&
      !!currentPass
    )
      setValidated(true);
    else setValidated(false);
  };

  useEffect(() => {
    validate();
  }, [
    currentPass,
    newPass,
    confirmNewPass,
    currentPassError,
    newPassError,
    confirmNewPassError,
  ]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (newPass !== confirmNewPass) {
      setConfirmNewPassError(LangEN.PasswordNotMatch);
      return;
    }
    setIsLoading(true);
    let apiReq = {
      oldPassword: currentPass,
      newPassword: newPass,
      confirmNewPassword: confirmNewPass,
    };

    AuthService.ChangePassword(apiReq)
      .then((res) => {
        setShow(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setPassChangeError(err.response.data.message);
        setIsLoading(false);
      });

    console.log("submitted");
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <div className="changePass_container m-4 my-4">
        <div className="blueHeader my-2">{LangEN.ChangePassword}</div>
        <div className="header mb-3">{LangEN.ResetYourPassword}</div>
        <Form onSubmit={onSubmit} noValidate style={{ width: "100%" }}>
          {formMapper.map((item: any, index: number) => (
            <Form.Group className="my-3" key={index}>
              <Form.Label className="label">{item.label}</Form.Label>
              <InputGroup
                className="inputContainer"
                style={{ marginBottom: item.error ? 40 : 0 }}
              >
                <Form.Control
                  value={item.value}
                  type={item.shown ? "text" : "password"}
                  onChange={(e) => {
                    item.setValue(e.target.value);
                    item.error && item.validate(e.target.value, item.setError);
                  }}
                  onBlur={() => {
                    item.validate(item.value, item.setError);
                  }}
                  isInvalid={!!item.error}
                  className="input shadow-none password-field"
                />
                <Button
                  className="password-eye-button"
                  onClick={() => {
                    item.setShown((pre: any) => !pre);
                  }}
                >
                  <img
                    src={item.shown ? eyeOff : eye}
                    width={30}
                    height={25}
                    className={"password-eye"}
                  />
                </Button>
                <Form.Control.Feedback type="invalid">
                  {item.error}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          ))}

          <p style={{ color: "red" }}>{passChangeError}</p>

          <CustomButton
            disabled={!validated}
            label={LangEN.SavePassword}
            className="button mt-3"
            isLoading={isLoading}
          />
        </Form>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
