import plus from "../../assets/svg/icon/plus.svg";

function SkillChip({ label, index }: { label: string; index: number }) {
  return (
    <div
      key={index}
      className="ellipsis"
      style={{
        backgroundColor: "#e0e6f7",
        display: "flex",
        color: "#6473a9",
        padding: "8px 12px",
        borderRadius: 6,
        alignItems: "center",
        fontSize: 16,
        gap: 10,
      }}
    >
      {label}
      <img width={15} src={plus} />
    </div>
  );
}

export default SkillChip;
