import "./styles.css";
import { Col, Row } from "react-bootstrap";
import TextAvatar from "../TextAvatar";
import { LightChip } from "../LightChip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  mapJobRole,
  setCurrencyType,
  setPaymentDuration,
} from "../../../Utils/Mappers";
import { LangEN } from "../../../constants/Lang/LangEN";
import Text from "../Text";
import StatusChip from "../StatusChip";
import UserService from "../../../services/UserService";
import { useEffect, useState } from "react";
import Avatar from "../Avatar";

function ProfessionalCard({
  user,
  isProfessional,
  isProjectRoute,
}: {
  isProjectRoute?: boolean;
  user: any;
  isProfessional?: boolean;
}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    UserService.IsProfessionalBusy(user?.user?.id)
      .then((res) => {
        setIsBusy(res.data.isBusy);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Col lg={4} md={4} sm={6} xs={12} className="p-2">
      <div
        className="cardShadow applicationCard_shadowCard p-3"
        onClick={() => {
          if (isProjectRoute) {
            navigate("details", {
              state: {
                projectid: state.projectid,
                jobid: state.jobid,
                id: user?.user?.id,
                application: { id: 1, jobSorting: true },
                showAction: false,
              },
            });
            return;
          }
          navigate("/professional/details", {
            state: {
              application: { id: 1, jobSorting: true },
              showAction: false,
              id: user?.user?.id,
              role: user?.applyAs,
            },
          });
        }}
      >
        <Row className="d-flex align-items-center">
          <Col xs="auto" className="m-0 p-0 ms-3">
            <Avatar
              url={user.profilePicture}
              size={50}
              fontSize={22}
              firstName={user.user.firstName}
              lastName={user.user.lastName}
            />
          </Col>
          <Col>
            <Row className="applicationCard_title d-flex gap-2">
              <Col className="ellipsis">
                {user.user.firstName + " "}{" "}
                {user?.user?.lastName ? user.user.lastName : ""}
              </Col>
              <Col xs="auto">
                {isProfessional && (
                  <StatusChip status={isBusy ? "busy" : "available"} />
                )}
              </Col>
            </Row>
            <div className="applicationCard_subtitle">
              {mapJobRole(user?.applyAs)}
            </div>
          </Col>
        </Row>
        <div className="w-100 applicationCard_description p-2 my-2 ellipsis-3 ">
          {user?.aboutMe}
        </div>
        <div className="applicationCard_tagContainer">
          {user?.skill?.map((item: any, index: number) => {
            if (index < 3)
              return (
                <LightChip
                  haveBorder
                  index={index}
                  label={item.skill.name}
                  sm
                />
              );
          })}
          {user?.skill?.length <= 3 &&
            user?.customSkill?.map((item: any, index: number) => {
              if (index < 3 - user.skill.length)
                return (
                  <LightChip
                    haveBorder
                    index={index}
                    label={item.skillName}
                    sm
                  />
                );
            })}
          {user?.skill?.length + user?.customSkill?.length > 3 && (
            <div
              className="d-flex align-items-center"
              style={{ color: "#3C65F5" }}
            >
              {LangEN.SeeMore}
            </div>
          )}
        </div>
        <Row
          className="d-flex justify-content-end mt-3"
          style={{
            flexGrow: 1,
            alignItems: "end",
          }}
        >
          <Col className="applicationCard_cost mt-2">
            {`${setCurrencyType(user?.currencyType)}${user.hourlyRate}`}
            <span className="applicationCard_costSpan">
              {setPaymentDuration(user?.paymentDuration)}
            </span>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export default ProfessionalCard;
