import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { FooterStyles } from "./FooterStyle";
import fb from "../../assets/svg/fb.svg";
import insta from "../../assets/svg/insta.svg";
import twitter from "../../assets/svg/twitter.svg";
import linkedin from "../../assets/svg/linkedin.svg";
import { LangEN } from "../../constants/Lang/LangEN";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <Container style={FooterStyles.root}>
      <Row style={FooterStyles.row1}>
        <Col className="col-12 col-md-8" style={FooterStyles.col1}>
          <label
            onClick={() => {
              navigate("/about");
            }}
            style={FooterStyles.label}
          >
            {LangEN.AboutUs}
          </label>
          <label
            onClick={() => {
              navigate("/faq");
            }}
            style={FooterStyles.label}
          >
            {LangEN.FAQ}
          </label>
          <label
            onClick={() => {
              navigate("/termofservice");
            }}
            style={FooterStyles.label}
          >
            {LangEN.TermsOfService}
          </label>
          <label
            onClick={() => {
              navigate("/privacypolicy");
            }}
            style={FooterStyles.label}
          >
            {LangEN.PrivacyPolicy}
          </label>
          <label
            onClick={() => {
              navigate("/site");
            }}
            style={FooterStyles.label}
          >
            {LangEN.SiteMap}
          </label>
        </Col>
        <Col
          className="col-12 col-md-4 p-md-0 p-4 gap-3 justify-content-center justify-content-md-end"
          style={FooterStyles.col3}
        >
          <label style={FooterStyles.label2}>{LangEN.FollowUs}</label>
          <Image src={fb} />
          <Image src={twitter} />
          <Image
            style={FooterStyles.Image}
            src={linkedin}
            onClick={() => {
              window.location.href =
                "https://www.linkedin.com/company/beehived-io";
            }}
          />
          <Image
            style={FooterStyles.Image}
            src={insta}
            onClick={() => {
              window.location.href = "https://www.instagram.com/beehived.io/";
            }}
          />
        </Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col style={FooterStyles.col2}>
          <label style={FooterStyles.label2}>{LangEN.Copyright}</label>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
