import { useState, useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../components/reusableComponents/CustomButton";
import folder from "../../assets/svg/icon/folder.svg";
import info from "../../assets/svg/icon/i.svg";
import infoIcon from "../../assets/svg/icon/info.svg";
import mail from "../../assets/svg/icon/mail.svg";
import company from "../../assets/svg/icon/company.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MyProjectStyles } from "./MyProjectStyles";
import { LangEN } from "../../constants/Lang/LangEN";
import { getName, getEmail } from "../../Utils/helper";
import TextAvatar from "../../components/reusableComponents/TextAvatar";
import ProjectCard from "../../components/reusableComponents/ProjectCard";
import { useEffect } from "react";
import ProjectService from "../../services/ProjectServices";
import moment from "moment";
import UserService from "../../services/UserService";
import Loader from "../../components/reusableComponents/Loader";
import { User } from "../../App";
import ToastModal from "../../components/Modals/ToastModal";
import { getTimeBetweenDates } from "../../Utils/Mappers";
import { resourceType } from "../../enums/CreateJobEnums";
import StatusChip from "../../components/reusableComponents/StatusChip";
import Avatar from "../../components/reusableComponents/Avatar";
export default function MyProject() {
  const user = useContext(User);
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState<any>();
  const [toastShown, setToastShown] = useState(false);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastLabel, setToastLabel] = useState({
    label: "",
    buttonText: "",
    toastAction: () => {},
  });

  useEffect(() => {
    ProjectService.ProjectList()
      .then((res) => {
        console.log(res.data);
        setProjectList(res.data);
        setLoader2(false);
      })
      .catch((err) => console.log(err));
    UserService.ViewProfile()
      .then((res) => {
        setData(res.data);
        setLoader1(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const getWeeks = (startDate: string, endDate: string) => {
    let projectStartDate: any = moment(new Date(startDate));
    let projectEndDate: any = moment(new Date(endDate));
    let diff = moment.duration(projectEndDate.diff(projectStartDate));
    return `${Math.floor(diff.asWeeks())} Weeks`;
  };

  const getProjectMAnagerCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 1 ? item.resourceCount : null;
    });
  };

  const getExpertsCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 3 ? item.resourceCount : null;
    });
  };

  const getArchitectCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 2 ? item.resourceCount : null;
    });
  };

  const toastHandler = () => {
    if (!user.accStatus.isAccountVerified) {
      setToastLabel({
        label: LangEN.YourAccountNotVerified,
        buttonText: LangEN.VerifyYourAccount,
        toastAction: () => {
          navigate("/verification");
        },
      });
      setShowToast(true);
      return false;
    }
    if (!user.accStatus.isProfileComplete) {
      setToastLabel({
        label: LangEN.YourProfileIsIncomplete,
        buttonText: LangEN.PleaseCompleteYourProfile,
        toastAction: () => {
          navigate("/verification");
        },
      });
      setShowToast(true);
      return false;
    }
    if (!user.accStatus.isProfileVerified) {
      setToastLabel({
        label: LangEN.YourProfileIsUnderVerification,
        buttonText: LangEN.PleaseTryLater,
        toastAction: () => {},
      });
      setShowToast(true);
      return false;
    }
    return true;
  };

  return (
    <>
      <ToastModal
        show={showToast}
        setShow={setShowToast}
        label={toastLabel.label}
        buttonText={toastLabel.buttonText}
        action={toastLabel.toastAction}
        type="error"
      />
      <Container style={MyProjectStyles.container}>
        {loader1 ? (
          <Loader lg />
        ) : (
          <Row className="py-5 px-0">
            <Col className="col-12 col-xl-9">
              {!projectList.length ? (
                <>
                  <h3 className="mb-4" style={MyProjectStyles.heading}>
                    {LangEN.YourProjects}
                  </h3>
                  <Card className="p-4" style={MyProjectStyles.card}>
                    <img width={100} height={100} src={folder} />
                    <Card.Title
                      style={MyProjectStyles.cardTitle}
                      className="m-4"
                    >
                      {LangEN.NoProjectsCreated}
                    </Card.Title>
                    <Card.Subtitle style={MyProjectStyles.cardSubTitle}>
                      {LangEN.CreateProjectSubtitle}
                    </Card.Subtitle>

                    <CustomButton
                      style={{ fontSize: 18 }}
                      className="m-4 px-4"
                      label={LangEN.CreateProject}
                      onClick={() => {
                        if (!toastHandler()) return;
                        navigate("/projects/create");
                      }}
                    />
                  </Card>
                </>
              ) : (
                <>
                  <Row
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Col className="col-auto">
                      <h3 className="mb-0" style={MyProjectStyles.heading}>
                        {LangEN.MyProjects}
                      </h3>
                    </Col>
                    <Col className="col-auto">
                      <CustomButton
                        style={{
                          fontSize: 16,

                          border: "1px solid #3C65F5 ",
                          backgroundColor: "white",
                          color: "#3C65F5",
                        }}
                        className="px-3"
                        label={LangEN.CreateProject}
                        onClick={() => {
                          if (!toastHandler()) return;
                          navigate("/projects/create");
                        }}
                      />
                    </Col>
                  </Row>
                  {loader2 ? (
                    <Loader lg />
                  ) : (
                    projectList.map((project: any, index: number) => {
                      // getting resource count from project data
                      const pmLeft =
                        project.resourceCount.filter(
                          (item: any) =>
                            item.resourceType == resourceType.ProjectManager
                        )[0].resourceCount - project.totalProjectManagerJobs;

                      const architectLeft =
                        project.resourceCount.filter(
                          (item: any) =>
                            item.resourceType == resourceType.Architect
                        )[0].resourceCount - project.totalArchitectJobs;

                      const expertLeft =
                        project.resourceCount.filter(
                          (item: any) =>
                            item.resourceType == resourceType.Experts
                        )[0].resourceCount - project.totalExpertJobs;

                      console.log(pmLeft, architectLeft, expertLeft);
                      return (
                        <ProjectCard
                          projectId={project.id}
                          title={project.name}
                          subtitle={project.projectDescription}
                          requiredArchitect={getArchitectCount(project)}
                          requiredExperts={getExpertsCount(project)}
                          requiredPM={getProjectMAnagerCount(project)}
                          projectDuration={
                            project.projectStartDate == project.projectEndDate
                              ? "a day"
                              : getTimeBetweenDates(
                                  project.projectStartDate,
                                  project.projectEndDate
                                )
                          }
                          unHiredArchitectCount={architectLeft}
                          unHiredExpertCount={expertLeft}
                          unHiredPMCount={pmLeft}
                        />
                      );
                    })
                  )}
                </>
              )}
            </Col>
            <Col
              className="col-3 d-none d-xl-flex ps-4"
              style={{ flexDirection: "column" }}
            >
              <h3 className="mb-4" style={MyProjectStyles.heading}>
                {LangEN.ProfileInfo}
              </h3>

              <Card style={MyProjectStyles.card1} className="p-4">
                <div>
                  <Avatar
                    url={data?.profilePicture}
                    size={70}
                    fontSize={26}
                    firstName={getName().split(" ")[0]}
                    lastName={getName().split(" ")[1]}
                  />

                  {!user.accStatus.isProfileVerified && (
                    <div className="profileIncomplete">
                      <div className="tooltip">
                        {LangEN.YourProfileIsUnderVerification + " please wait"}
                      </div>
                      <img
                        src={info}
                        width={25}
                        style={MyProjectStyles.infoIcon}
                      />
                    </div>
                  )}
                </div>
                <Row>
                  <Col>
                    <Card.Title className="mt-2" style={MyProjectStyles.title}>
                      {getName()}
                    </Card.Title>
                  </Col>
                  {user.accStatus.isProfileVerified && (
                    <Col xs="auto" className="d-flex align-items-center">
                      <StatusChip status="verified" />
                    </Col>
                  )}
                </Row>
                <div className="my-3" style={MyProjectStyles.divider} />
                <Row style={MyProjectStyles.row}>
                  <Col className="col-auto">
                    <img src={mail} width={18} />
                  </Col>
                  <Col className={"p-0 ellipsis"}>
                    <Card.Subtitle
                      className="my-2 ellipsis"
                      style={MyProjectStyles.subTitle}
                    >
                      {getEmail()}
                    </Card.Subtitle>
                  </Col>
                </Row>
                <Row style={MyProjectStyles.row}>
                  <Col className="col-auto">
                    <img src={company} width={18} />
                  </Col>
                  <Col className={"ellipsis p-0"}>
                    <Card.Subtitle
                      className="my-2 ellipsis"
                      style={MyProjectStyles.subTitle}
                    >
                      {data?.companyName
                        ? data.companyName
                        : LangEN.NotSpecified}
                    </Card.Subtitle>
                  </Col>
                </Row>
                {!user.accStatus.isProfileComplete && (
                  <>
                    <div className="my-3" style={MyProjectStyles.divider} />
                    <Row style={MyProjectStyles.row}>
                      <Col className="col-auto">
                        <img src={infoIcon} width={18} />
                      </Col>
                      <Col className={"p-0"}>
                        <Link style={MyProjectStyles.nav} to="/profile/edit">
                          {LangEN.CompleteYourProfile}
                        </Link>
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
