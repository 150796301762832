import { LangEN } from "../../constants/Lang/LangEN";
import search from "../../assets/svg/icon/search.svg";
import Select from "react-select";
import { Col, Row, Form } from "react-bootstrap";
import { useState, useRef } from "react";
import { useEffect } from "react";
import UserService from "../../services/UserService";
import { Chip } from "./Chip";
import { debounce } from "lodash";

const MenuItemCustom = ({ index, item, selectedValues }: any) => {
  const [isChecked, setIsChecked] = useState(
    !!selectedValues.current.includes(item.id)
  );

  const clickHandler = () => {
    if (isChecked) {
      let arr = selectedValues.current.filter(function (id: any) {
        return id !== item.id;
      });
      selectedValues.current = [...arr];
    } else {
      selectedValues.current = [...selectedValues.current, item.id];
    }
    console.log("==>", selectedValues.current);
    setIsChecked((pre) => {
      return !pre;
    });
  };
  return (
    <Row
      key={index}
      className="dropdown-item-custom mx-2 py-2 ellipsis"
      onClick={clickHandler}
    >
      <Col className="ellipsis">{item.name ? item.name : item.value}</Col>
      <Col className="col-auto ellipsis">
        <div
          key={index}
          style={{
            width: 20,
            height: 20,
            borderRadius: 6,
            border: "1px solid #3c66f5",
            padding: 10,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Check
            type={"checkbox"}
            checked={isChecked}
            onChange={() => {}}
          />
        </div>
      </Col>
    </Row>
  );
};

function CustomTagInput({
  selectedValues,
  setSelectedValues,
  isSkills,
  hideLabel,
  customSkill,
  setCustomSkill,
}: {
  selectedValues: any;
  setSelectedValues: any;
  isSkills?: boolean;
  hideLabel?: boolean;
  customSkill?: string[];
  setCustomSkill?: Function;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAllSearched, setIsAllSearched] = useState(true);
  const inputRef = useRef<any>();
  const inputParentRef = useRef<any>();
  const currentSelection: any = useRef([]);
  const currentCustomSelection: any = useRef([]);
  const closeRef: any = useRef();

  const [options, setOptions] = useState<any>({});
  const getLabel = (id: string | any) => {
    let label = "";
    if (options?.length > 0)
      options?.map((item: any) => {
        if (id == item.id) {
          if (isSkills) label = item.name;
          else label = item.value;
        }
      });
    return label;
  };

  useEffect(() => {
    if (isAllSearched) return;
    inputRef.current.focus();
  }, [options]);

  const searchOptions = debounce((value: string) => {
    // if (!value.trim()) return;
    if (isSkills) {
      UserService.FetchSkills(value)
        .then((res) => {
          setIsAllSearched(false);
          setOptions(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      UserService.FetchQualifications(value)
        .then((res) => {
          setIsAllSearched(false);
          setOptions(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, 500);

  const getAllOptions = () => {
    if (isSkills) {
      UserService.FetchSkills()
        .then((res) => {
          setIsAllSearched(true);
          setOptions(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      UserService.FetchQualifications()
        .then((res) => {
          setIsAllSearched(true);
          setOptions(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getAllOptions();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <input
        ref={closeRef}
        placeholder=""
        onFocus={() => {
          console.log("close==>", currentSelection.current);
        }}
        style={{ maxWidth: 5, maxHeight: 1, position: "absolute" }}
        className="opacity-0"
      />
      {isSkills && !hideLabel && (
        <div style={{ color: "#999", fontSize: 14 }}>
          {LangEN.AddYourSkills}
        </div>
      )}
      <Select
        onBlur={() => {
          console.log("main-blur");
        }}
        menuIsOpen={menuOpen}
        openMenuOnFocus
        isClearable={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        menuPlacement="auto"
        onFocus={() => {
          console.log("main-focus");
          setMenuOpen(true);
          let arr = [...selectedValues, ...currentSelection.current];
          arr = arr.filter((item, index) => arr.indexOf(item) == index);
          currentSelection.current = arr;
          if (customSkill) currentCustomSelection.current = [...customSkill];
        }}
        onMenuClose={() => {
          setMenuOpen(false);
          getAllOptions();
          console.log("main-closed");
          setSelectedValues([...currentSelection.current]);
          if (setCustomSkill)
            setCustomSkill([...currentCustomSelection.current]);
        }}
        onMenuOpen={() => {
          console.log("main-open");
          inputRef.current.focus();
          let arr = [...selectedValues, ...currentSelection.current];
          arr = arr.filter((item, index) => arr.indexOf(item) == index);
          currentSelection.current = arr;
          if (customSkill) currentCustomSelection.current = [...customSkill];
        }}
        isMulti
        options={[]}
        components={{
          Control: ({ children }) => (
            <div
              className="d-flex"
              style={{
                alignItems: "center",
                border: "1px solid #ccd",
                borderRadius: isSkills && !hideLabel ? 0 : 8,
                width: "100%",
                height: isSkills ? 40 : 50,
                paddingTop: hideLabel ? 25 : 0,
                paddingBottom: hideLabel ? 25 : 0,
                flexDirection: isSkills ? "row" : "row-reverse",
              }}
            >
              <img
                src={search}
                width={20}
                className={isSkills ? "me-0 ms-3" : "mx-3"}
                style={{ opacity: isSkills ? 0.4 : 0.8 }}
              />
              {children}
            </div>
          ),
          Input: (props: any) => (
            <form
              onChange={(e: any) => {
                searchOptions(e.target.value.trim());
                console.log("edit=>", currentSelection.current);
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == 13) {
                  console.log("enter pressed");
                  e.preventDefault();
                  e.returnValue = false;
                }
              }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                ref={inputRef}
                {...props}
                className="dim-placeholder"
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  outline: "none",
                }}
                placeholder={
                  isSkills ? LangEN.SearchSkills : LangEN.EnterQualification
                }
              />
            </form>
          ),
          IndicatorsContainer: () => <></>,
          Placeholder: () => <></>,
          MenuList: () => (
            <div className="my-2" style={{ maxHeight: 250, overflowY: "auto" }}>
              {options?.length > 0 &&
                options?.map((item: any, index: any) => (
                  <MenuItemCustom
                    index={index}
                    item={item}
                    selectedValues={currentSelection}
                  />
                ))}
              {inputRef.current.value.length > 0 &&
                isSkills &&
                (!customSkill ? (
                  options.length == 0 && (
                    <div style={{ textAlign: "center" }}>
                      {LangEN.NoSkillsFound}
                    </div>
                  )
                ) : currentCustomSelection.current.includes(
                    inputRef.current.value
                  ) ? (
                  <div style={{ textAlign: "center" }}>
                    {inputRef.current.value} already added
                  </div>
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      if (setCustomSkill) {
                        const label = inputRef.current.value;
                        if (label.trim()) {
                          currentCustomSelection.current = [
                            ...currentCustomSelection.current,
                            label,
                          ];
                        }
                        console.log(currentSelection.current);
                        console.log(closeRef.current.focus()); //for closing dropdown
                        // setMenuOpen(false);
                      }
                    }}
                  >
                    {LangEN.CreateNewSkill} "{inputRef.current.value}"
                  </div>
                ))}
              {options?.length == 0 && !isSkills && (
                <div style={{ textAlign: "center" }}>
                  {LangEN.NoQualificationFound}
                </div>
              )}
            </div>
          ),
        }}
      />
      <div
        className="my-2"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        {selectedValues.map((item: any, index: number) => (
          <Chip
            index={index}
            label={getLabel(item)}
            onClose={() => {
              currentSelection.current = [];
              setSelectedValues((pre: any) => {
                let arr = pre.filter(function (id: any) {
                  return id !== item;
                });
                return arr;
              });
            }}
          />
        ))}
        {customSkill?.map((item: any, index: number) => (
          <Chip
            index={index}
            label={item}
            onClose={() => {
              if (setCustomSkill) {
                currentCustomSelection.current = [];
                setCustomSkill((pre: any) => {
                  let arr = pre.filter(function (name: any) {
                    return name !== item;
                  });
                  return arr;
                });
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default CustomTagInput;
