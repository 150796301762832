import Http from "./HttpService";
import { API } from "../constants/ApiDetails";
import { ICreateProject } from "../Interfaces/ICreateProject";
import { IProjectList } from "../Interfaces/IProjectList";

class ProjectService {
  public static async ProjectList(projectListData?: IProjectList) {
    let url = `${API.MY_PROJECTS}`;
    if (projectListData) {
      if (projectListData.title) {
        url = url + `?title=${projectListData.title}`;
      }
      if (projectListData.skip) {
        url = url + `&skip=${projectListData.skip}`;
      }
      if (projectListData.take) {
        url = url + `&take=${projectListData.take}`;
      }
    }
    const response = await Http.get(url);
    return response;
  }

  public static async CreateProject(projectDetails: ICreateProject) {
    const url = `${API.MY_PROJECTS}`;
    const response = await Http.post(url, projectDetails);
    return response;
  }
  public static async EditProject(projectDetails: ICreateProject) {
    const url = `${API.MY_PROJECTS}`;
    const response = await Http.put(url, projectDetails);
    return response;
  }
  public static async ProjectDetail(id: string | undefined) {
    const url = `${API.MY_PROJECTS}/${id}`;
    const response = await Http.get(url);
    return response;
  }

  public static async ProjectTeamMembers(id: string | undefined) {
    const url = `${API.GET_TEAM_MEMBERS}${id}`;
    const response = await Http.get(url);
    return response;
  }
}

export default ProjectService;
