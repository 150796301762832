import React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../reusableComponents/CustomButton";
import success from "../../assets/svg/icon/okay.svg";
import { NavLink } from "react-router-dom";

function CustomModal({
    show,
    setShow,
    label,
    type,
    action,
    buttonText,
}: {
    show: boolean;
    setShow: any;
    label: string;
    type: string;
    action: Function;
    buttonText: string;
}) {
    return (
        <Modal show={show} centered>
            <Modal.Body className="p-4 py-5 centered">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img src={success} width={70} />
                    <div
                        style={{
                            fontSize: 25,
                            color: "#05264E",
                            textAlign: "center",
                            fontWeight: 600,
                        }}
                        className="p-2"
                    >
                        {label}
                    </div>
                    <CustomButton
                        className="m-3 px-4"
                        style={{ height: 50 }}
                        onClick={() => action()}
                        label={buttonText}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CustomModal;
