import Http from "./HttpService";
import { API } from "../constants/ApiDetails";
import { IOtpVerificationDetails } from "../Interfaces/IOtpVerificationDetails";
import { ICreateBusinessProfile } from "../Interfaces/ICreateBusinessProfile";
import { ICreateProfessionalProfile } from "../Interfaces/ICreateProfessionalProfile";
import { IEditProfessionalProfile } from "../Interfaces/IEditProfessionalPage";
import { IEditBusinessProfile } from "../Interfaces/IEditBusinessProfile";
import { UserRoles } from "../enums/UserRoles";

class UserService {
  public static async ViewProfile() {
    const url = `${API.VIEW_PROFILE}`;
    const response = await Http.get(url);
    return response;
  }
  public static async ResendOtp() {
    const url = `${API.RESEND_OTP}`;
    const response = await Http.get(url);
    return response;
  }
  public static async VerifyOtp(verificationDetails: IOtpVerificationDetails) {
    const url = `${API.VERIFY_OTP}`;
    const response = await Http.post(url, verificationDetails);
    return response;
  }
  public static async CreateBusinessProfile(
    createDetails: ICreateBusinessProfile
  ) {
    const url = `${API.CREATE_BUSINESS_PROFILE}`;
    const response = await Http.post(url, createDetails);
    return response;
  }
  public static async EditBusinessProfile(createDetails: IEditBusinessProfile) {
    const url = `${API.CREATE_BUSINESS_PROFILE}`;
    const response = await Http.put(url, createDetails);
    return response;
  }
  public static async EditProfessionalProfile(
    createDetails: IEditProfessionalProfile
  ) {
    const url = `${API.CREATE_BUSINESS_PROFILE}`;
    const response = await Http.put(url, createDetails);
    return response;
  }
  public static async GetAccountVerification() {
    const url = `${API.ACCOUNT_VERIFICATION}`;
    const response = await Http.get(url);
    return response;
  }

  public static async CreateProfessionalProfile(
    createDetails: ICreateProfessionalProfile
  ) {
    const url = `${API.CREATE_BUSINESS_PROFILE}`;
    const response = await Http.post(url, createDetails);
    return response;
  }
  public static async FetchSkills(query: string = "") {
    let url = `${API.SKILLS}`;
    if (query != "") {
      url += `?name=${query}`;
    }
    const response = await Http.get(url);
    return response;
  }
  public static async FetchQualifications(query: string = "") {
    let url = `${API.QUALIFICATION}`;
    if (query != "") {
      url += `?name=${query}`;
    }
    const response = await Http.get(url);
    return response;
  }

  public static async GetProfileById(id: string | undefined) {
    const url = `${API.GET_PROFILE_BY_ID}/${id}/${UserRoles.Professional}`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetCompanyProfileById(id: string | undefined) {
    const url = `${API.GET_PROFILE_BY_ID}/${id}/${UserRoles.Business}`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetProfessionals(search?: string) {
    const url = `${API.GET_PROFESSIONALS}${UserRoles.Professional}`;
    const response = await Http.get(url);
    return response;
  }

  public static async IsProfessionalBusy(id: string | undefined) {
    const url = `${API.IS_USER_BUSY}${id}`;
    const response = await Http.get(url);
    return response;
  }

  public static async ProfessionalFilter(apiReq: any) {
    const url = `${API.PROFESSIONAL_FILTER}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async UploadProfilePicture(data: any) {
    const url = `${API.PROFILE_PICTURE_UPDATE}`;
    const response = await Http.putMultipart(url, data);
    return response;
  }
  public static async UploadCV(data: any) {
    const url = `${API.CV_UPDATE}`;
    const response = await Http.putMultipart(url, data);
    return response;
  }
  public static async GetReferralCode() {
    const url = `${API.GET_REFERRAL_CODE}`;
    const response = await Http.get(url);
    return response;
  }
}

export default UserService;
