import React, { useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import img1 from "../../assets/svg/img1.svg";
import img2 from "../../assets/svg/img2.svg";
import okay from "../../assets/svg/icon/okay.svg";

import CustomButton from "../../components/reusableComponents/CustomButton";
import OtpInput from "react18-input-otp";
import { Link } from "react-router-dom";
import { LangEN } from "../../constants/Lang/LangEN";
import { getEmail } from "../../Utils/helper";
import { EmailVerificationStyles } from "./EmailVerificationStyles";
import UserService from "../../services/UserService";
import { useEffect, useContext } from "react";
import { User } from "../../App";
import { UserRoles } from "../../enums/UserRoles";

export default function EmailVerification() {
  const user = useContext(User);
  const [otp, setOtp] = useState("");
  const [otpValidated, setOtpValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [isWaitingForResend, setIsWaitingForResend] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    setIsEmailVerified(user.accStatus.isAccountVerified);
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);

    UserService.VerifyOtp({ otp: otp })
      .then((res) => {
        console.log(res.data.message);
        setIsLoading(false);
        setIsEmailVerified(true);
        const userData: any = localStorage.getItem("user");
        let data = JSON.parse(userData);
        data.isVerified = true;
        localStorage.setItem("user", JSON.stringify(data));
        user.setAccStatus((pre: any) => {
          pre.isAccountVerified = true;
          localStorage.setItem("accStatus", JSON.stringify(pre));
          return pre;
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message == "Invalid OTP") {
          setOtpError("OTP is invalid");
        }
        console.log(err);
      });
  };
  const getTimeMinute = (time: number) => {
    let minutes = Math.floor(time / 60);
    let sec = time % 60;
    return minutes ? "(" + minutes + "min " + sec + " s)" : "(" + sec + " s)";
  };

  const resendOtp = () => {
    setIsLoadingResend(true);

    UserService.ResendOtp()
      .then((res) => {
        console.log(res.data.message);
        setIsLoadingResend(false);
        setResendTimer(180);
        setIsWaitingForResend(true);
        const interval = setInterval(() => {
          setResendTimer((pre) => {
            if (pre == 1) {
              clearInterval(interval);
              setIsWaitingForResend(false);
            }
            return pre - 1;
          });
        }, 1000);
      })
      .catch((err) => {
        setIsLoadingResend(false);
        if (err.response.data == "OTP sent successfully")
          console.log(err.response.data);
      });
  };

  return (
    <>
      <Container style={EmailVerificationStyles.container} fluid>
        <Row style={EmailVerificationStyles.row1}>
          <Col style={{ maxWidth: 700 }} className="col-12 col-lg-6">
            <Card style={EmailVerificationStyles.card}>
              <Card.Img width={252} height={190} variant="top" src={img1} />
              <Card.Body style={EmailVerificationStyles.cardBody}>
                <Card.Title style={EmailVerificationStyles.cardTitle}>
                  {!isEmailVerified
                    ? LangEN.VerifyEmail
                    : "Email Verified Successfully"}
                </Card.Title>
                {!isEmailVerified && (
                  <Card.Subtitle
                    className="mb-2 text-muted"
                    style={EmailVerificationStyles.cardSubTitle}
                  >
                    {LangEN.OtpSentOn}
                  </Card.Subtitle>
                )}
                {!!isEmailVerified && (
                  <img src={okay} width={60} className="my-3" />
                )}
                <Card.Subtitle
                  className="mb-2 text-muted"
                  style={EmailVerificationStyles.cardSubTitle2}
                >
                  <span className="black_color">{getEmail()}</span>
                </Card.Subtitle>
                <Card.Text>
                  {!isEmailVerified && (
                    <>
                      <OtpInput
                        onChange={(e: any) => {
                          setOtpError("");
                          setOtp(e);
                          if (e.length == 6) {
                            setOtpValidated(true);
                          } else setOtpValidated(false);
                        }}
                        value={otp}
                        numInputs={6}
                        isInputNum
                        separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                        inputStyle={EmailVerificationStyles.otpInput}
                      />
                      <div className="invalid-feedback d-block">{otpError}</div>
                    </>
                  )}
                </Card.Text>
                <Col>
                  {!isEmailVerified && (
                    <div style={{ display: "flex" }}>
                      <CustomButton
                        label={`${LangEN.ResendOtpCP} ${
                          resendTimer ? getTimeMinute(resendTimer) : ""
                        }`}
                        style={EmailVerificationStyles.resendButton}
                        isLoading={isLoadingResend}
                        onClick={resendOtp}
                        disabled={isWaitingForResend}
                      />
                      <CustomButton
                        disabled={!(otpValidated && !otpError)}
                        label={LangEN.SubmitOtpCP}
                        isLoading={isLoading}
                        style={{
                          backgroundColor: "#fff",
                          color: otpValidated ? "#0d6efd" : "#666",
                          fontWeight: 600,
                          fontSize: 14,
                          border: "none",
                        }}
                        onClick={handleSubmit}
                      />
                    </div>
                  )}
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ maxWidth: 700 }}>
            <Card style={EmailVerificationStyles.card}>
              <Card.Img width={252} height={190} variant="top" src={img2} />
              <Card.Body style={EmailVerificationStyles.cardBody}>
                {user.accStatus.isProfileComplete ? (
                  <>
                    <Card.Title style={EmailVerificationStyles.cardTitle}>
                      {LangEN.ProfileAlreadyCompleted}
                    </Card.Title>
                    <img src={okay} width={60} className="my-3" />
                  </>
                ) : (
                  <>
                    <Card.Title style={EmailVerificationStyles.cardTitle}>
                      {LangEN.PleaseCompleteYourProfile}
                    </Card.Title>
                    <Card.Text
                      className="mb-2 text-muted"
                      style={EmailVerificationStyles.cardText}
                    >
                      {LangEN.CompleteYourProfileSubtitle}
                    </Card.Text>

                    <Col>
                      <CustomButton
                        href="/profile/edit"
                        label={LangEN.CompleteProfile}
                        style={EmailVerificationStyles.completeProfileButton}
                      />
                    </Col>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={EmailVerificationStyles.skipNowContainer}>
            <Link
              style={{ color: "#000", fontSize: 22 }}
              to={user.role == UserRoles.Business ? "/projects" : "/jobs"}
            >
              {LangEN.SkipNow}
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
