import * as React from "react";
import menuThree from "../assets/svg/icon/menuThree.svg";

export const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <img
                style={{
                    backgroundColor: "#fff",
                    border: "1px solid #3C65F5",
                    borderRadius: 30,
                    aspectRatio: 1,
                    padding: 3,
                }}
                src={menuThree}
                width={35}
                height={35}
            />
        </a>
    )
);
