import { Container } from "react-bootstrap";
import error from "../../assets/svg/icon/noFound.svg";
import { LangEN } from "../../constants/Lang/LangEN";

function ErrorPage() {
    return (
        <Container
            style={{
                minWidth: "100vw",
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 40,
                paddingInline: "5vw",
                backgroundColor: "#fff",
            }}
        >
            <img src={error} style={{ width: "clamp(100px , 10vw , 200px)" }} />
            <label
                className="m-2"
                style={{ fontWeight: 600, fontSize: 25, color: "#66789C" }}
            >
                {LangEN.PageNotFound}
            </label>
            <label
                style={{
                    fontSize: 18,
                    paddingInline: 20,
                    textAlign: "center",
                    maxWidth: 500,
                    color: "#A0ABB8",
                }}
            >
                {LangEN.PageNotFoundSubtitle}
            </label>
        </Container>
    );
}

export default ErrorPage;
