import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Image,
  InputGroup,
  Button,
} from "react-bootstrap";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import Theme from "../../../constants/Theme";
import { LoginStyles } from "./LoginStyle";
import gandalfBlue from "../../../assets/svg/Color logo-verticle.svg";
import backArrow from "../../../assets/svg/icon/backArrow.svg";
import eyeOff from "../../../assets/svg/icon/eye.svg";
import eye from "../../../assets/svg/icon/eye-off.svg";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import { UserRoles } from "../../../enums/UserRoles";
import CustomToast from "../../../components/reusableComponents/CustomToast";
import { LangEN } from "../../../constants/Lang/LangEN";
import { validateEmail } from "../../../Utils/Validators";
import { useContext } from "react";
import { SignUpEnabledContext, User } from "../../../App";

export default function Login({ isBusiness }: any) {
  const isSignUpEnabled = useContext(SignUpEnabledContext);
  const user = useContext(User);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState("true");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [toastShown, setToastShown] = useState(false);
  const [toastMessage, setToastMessage] = useState("this is a toast example");
  const [passwordShown, setPasswordShown] = useState(false);
  useEffect(() => {
    validate();
  }, [email, password, emailError]);

  function validate() {
    // check to enable submission of the form
    if (email && password && !emailError) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  function validatePassword(password: string) {
    if (password == "") {
      setPasswordError(LangEN.PasswordRequired);
      return;
    }
    setPasswordError("");
    return;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    validate();
    if (!validated) {
      return;
    }
    setIsLoading(true);

    let apiReq = {
      email: email,
      password: password,
      role: isBusiness ? UserRoles.Business : UserRoles.Professional,
    };

    AuthService.LogIn(apiReq)
      .then((res) => {
        localStorage.setItem("loginToken", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        if (rememberMe == "true") {
          localStorage.setItem("session", "true");
        } else {
          sessionStorage.setItem("session", "true");
        }
        setIsLoading(false);
        user.setRole(isBusiness ? UserRoles.Business : UserRoles.Professional);
        if (!res.data.user.isVerified) {
          navigate("/verification");
        } else {
          isBusiness ? navigate("/projects") : navigate("/jobs");
        }
      })
      .catch((err) => {
        // if (err.response.data.message == "Invalid credentials") {
        //   setToastMessage("Invalid credentials!");
        // } else if (err.response.status == 404) {
        //   setToastMessage("User not found!");
        // } else {
        //   setToastMessage("Something went wrong!");
        // }
        // setToastShown(true);
        setIsLoading(false);

        console.log(err);
      });
    console.log(email, password);
  };
  return (
    <>
      <CustomToast // toast for error messages
        isShown={toastShown}
        hide={() => {
          setToastShown(false);
        }}
        message={toastMessage}
      />
      <Container fluid>
        <Row className="d-md-block d-lg-none" style={LoginStyles.row1}>
          <Col>
            <Link to="/" style={LoginStyles.img1}>
              <Image src={backArrow} />
            </Link>
            <div style={LoginStyles.img2}>
              <Image
                width="30%"
                height="30%"
                style={{ marginBottom: 30 }}
                src={gandalfBlue}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-lg-block" style={LoginStyles.col1}>
            <Row style={LoginStyles.row2}>
              <div style={LoginStyles.labelDiv}>
                {" "}
                <label style={LoginStyles.label1}>{LangEN.WelcomeBack}</label>
                <label style={LoginStyles.label2}>
                  {`${isBusiness ? LangEN.Business : LangEN.Professional} ${
                    LangEN.Login
                  }`}
                </label>
              </div>
            </Row>
          </Col>
          <Col style={LoginStyles.col2}>
            <label style={LoginStyles.label3}>
              <div className={"auth_label_title"}>
                {`${isBusiness ? LangEN.Business : LangEN.Professional} ${
                  LangEN.Login
                }`}
              </div>
              <div className="auth_labelUnderline" />
            </label>
            <Form style={LoginStyles.form} noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  size="lg"
                  type="email"
                  className="shadow-none"
                  style={LoginStyles.formControl}
                  value={email}
                  placeholder={`${LangEN.Email} *`}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/\s/g, "");
                    emailError && validateEmail(value, setEmailError);
                    setEmail(value);
                  }}
                  onBlur={() => {
                    validateEmail(email, setEmailError);
                  }}
                  isInvalid={!!emailError}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <Form.Control
                    type={passwordShown ? "text" : "password"}
                    size="lg"
                    style={LoginStyles.formControl}
                    placeholder={`${LangEN.Password} *`}
                    value={password}
                    onChange={(e) => {
                      let value = e.target.value;
                      setPassword(value);
                      passwordError && validatePassword(value);
                    }}
                    onBlur={() => {
                      validatePassword(password);
                    }}
                    isInvalid={!!passwordError}
                    className="shadow-none password-field"
                  />
                  <Button
                    className="password-eye-button"
                    onClick={() => {
                      setPasswordShown((pre) => !pre);
                    }}
                  >
                    <img
                      src={passwordShown ? eyeOff : eye}
                      width={30}
                      height={25}
                      className={"password-eye"}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group
                className="mb-3"
                style={LoginStyles.checkBox}
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  style={{
                    opacity: rememberMe == "true" ? 1 : 0.5,
                  }}
                  label={LangEN.RememberMe}
                  checked={rememberMe == "true"}
                  value={rememberMe}
                  onChange={(e) => {
                    setRememberMe(e.target.checked.toString());
                  }}
                />
                <Link
                  to={
                    isBusiness ? "/businessforgotpassword" : "/forgotpassword"
                  }
                >
                  <label style={LoginStyles.label4}>
                    {LangEN.ForgotPassword}?
                  </label>
                </Link>
              </Form.Group>
              <CustomButton
                // href="/professional"
                isLoading={isLoading}
                variant="primary"
                type="submit"
                label={LangEN.Login}
                style={LoginStyles.button}
                size="lg"
                disabled={!validated}
              />
              <div style={LoginStyles.labelDiv2}>
                {isSignUpEnabled && (
                  <label style={LoginStyles.label5}>
                    {LangEN.DoNotHaveAccount}{" "}
                    <Link to={isBusiness ? "/businessregister" : "/register"}>
                      {LangEN.SignUp}
                    </Link>
                  </label>
                )}
                <label style={LoginStyles.label5}>{LangEN.AuthCopyright}</label>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
