import { Container, Card, Col, Row } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import week from "../../assets/svg/icon/week.svg";
import time from "../../assets/svg/icon/time.svg";
import "./styles.css";
import TextAvatar from "../../components/reusableComponents/TextAvatar";
import { LightChip } from "../../components/reusableComponents/LightChip";
import CustomButton from "../../components/reusableComponents/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useEffect, useState, useContext } from "react";
import {
  dateFromNow,
  getTimeBetweenDates,
  getWeeks,
  jobStartBy,
  mapJobDuration,
  mapJobRole,
  requiredResourceType,
  setCurrencyType,
  setPaymentDuration,
  skillsLevelRequired,
} from "../../Utils/Mappers";
import JobSApplicationService from "../../services/JobApplicationService";
import { jobApplicationStatus } from "../../enums/JobApplicationStatus";
import Text from "../../components/reusableComponents/Text";
import JobService from "../../services/JobService";
import { invitationStatus } from "../../enums/InvitationStatus";
import JobInvitationService from "../../services/JobInvitationService";
import CustomToast from "../../components/reusableComponents/CustomToast";
import Skeleton from "react-loading-skeleton";
import { BreadCrumbsContext, User } from "../../App";
import { resourceType } from "../../enums/CreateJobEnums";
import moment from "moment";
import {
  remoteResourceArray,
  typeOfProjectArray,
} from "../../constants/enumLabel";
import JobStatusChip from "../../components/reusableComponents/JobStatusChip";
import { mapWorkExperience } from "../../Utils/Mappers";
import Avatar from "../../components/reusableComponents/Avatar";

function JobDetailsProfessional({
  isInvitationFlow,
}: {
  isInvitationFlow?: boolean;
}) {
  const navigate = useNavigate();
  const bread = useContext(BreadCrumbsContext);
  const user = useContext(User);
  const { state } = useLocation();
  const [applyLoading, setApplyLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<any>();
  const [companyLoading, setCompanyLoading] = useState(true);
  const [jobStatus, setJobStatus] = useState<any>();
  const [isJobStatusLoading, setIsJobStatusLoading] = useState(true);
  const [isJobApplied, setIsJobApplied] = useState<null | number>(null);
  const [isJobSaved, setIsJobSaved] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [jobInvitationStatus, setJobInvitationStatus] = useState<
    null | number
  >();
  const [toastShown, setToastShown] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const breadData = [
    {
      label: LangEN.Jobs,
      route: "/jobs/search",
    },
    {
      label: LangEN.JobDetails,
      route: "/jobs/details",
    },
  ];

  useEffect(() => {
    bread.setData(breadData);

    return () => {
      bread.setData([]);
    };
  }, []);

  useEffect(() => {
    UserService.GetCompanyProfileById(state?.project?.createdBy)
      .then((res) => {
        setCompanyInfo(res.data);
        setCompanyLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    JobSApplicationService.GetJobApplicationStatus(state?.id)
      .then((res) => {
        setJobStatus(res.data);
        if (res.data) {
          setIsJobApplied(res.data?.applicationStatus);
        }
        setIsJobStatusLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    JobService.IsJobSave(state?.id)
      .then((res) => {
        setIsJobSaved(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (isInvitationFlow) {
      let apiReq = {
        jobId: state?.id,
        invitedBy: state?.invitedBy,
      };

      JobInvitationService.GetStatus(apiReq)
        .then((res) => {
          setJobInvitationStatus(res.data.invitationStatus);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const applyJob = () => {
    setApplyLoading(true);
    let apiReq = {
      applicationStatus: jobApplicationStatus.Applied,
      jobId: state?.id,
    };
    JobSApplicationService.ApplyForJob(apiReq)
      .then((res) => {
        setApplyLoading(false);
        setIsJobApplied(jobApplicationStatus.Applied);
      })
      .catch((err) => {
        setApplyLoading(false);
        console.log(err);
      });
  };
  const revokeJob = () => {
    setApplyLoading(true);
    JobSApplicationService.RevokeJobApplication(state?.id)
      .then((res) => {
        setApplyLoading(false);
        setIsJobApplied(jobApplicationStatus.Rejected);
      })
      .catch((err) => {
        setApplyLoading(false);
        console.log(err);
      });
  };

  const saveJob = () => {
    setSaveLoading(true);
    JobService.SaveJob(state?.id)
      .then((res) => {
        setSaveLoading(false);
        setIsJobSaved(true);
      })
      .catch((err) => {
        setSaveLoading(false);
        console.log(err);
      });
  };

  const unSaveJob = () => {
    setSaveLoading(true);
    JobService.RemoveSavedJob(state?.id)
      .then((res) => {
        setSaveLoading(false);
        setIsJobSaved(false);
      })
      .catch((err) => {
        setSaveLoading(false);
        console.log(err);
      });
  };

  const updateInvitationStatus = (status: number) => {
    if (
      jobInvitationStatus === invitationStatus.Accepted ||
      jobInvitationStatus === invitationStatus.Rejected
    ) {
      setToastMessage("Cannot accept/reject invitation.");
      setToastShown(true);
      return;
    }
    let apiReq = {
      jobId: state?.id,
      invitedBy: state?.invitedBy,
      invitationStatus: status,
      userId: user.id,
    };

    JobInvitationService.UpdateStatus(apiReq)
      .then((res) => {
        setJobInvitationStatus(status);
        // if (status === invitationStatus.Accepted) applyJob();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="main_container">
      <Row>
        <Col xs={12} lg={8}>
          <Card className="cardShadow my-3 p-3 p-md-4">
            <Card.Title className="jobDetails_title">
              {` ${mapJobRole(state?.resourceType)}, ${requiredResourceType(
                state?.requiredResourceType
              )}`}
              {/* <span className="mx-2">
                {state?.jobStatus && <JobStatusChip status={state.jobStatus} />}
              </span> */}
            </Card.Title>
            <Row className="my-2">
              {/* <Col className="col-auto" style={styles.smallLabel}>
                <span className="me-2">
                  <img src={week} width={17} />
                </span>
                {getTimeBetweenDates(
                  state?.project?.projectStartDate,
                  state?.project?.projectEndDate
                )}
              </Col> */}
              <Col className="col-auto" style={styles.smallLabel}>
                {/* <span className="me-2">
                  <img src={time} width={17} />
                </span> */}
                {LangEN.PostedOn}
                {moment(state?.createdAt).format("Do MMM YYYY")}
              </Col>
            </Row>
            <div className="horizontal-divider my-4"></div>
            <h3 className="jobDetails_heading">{LangEN.JobDescription}</h3>
            <p className="jobDetails_details">
              {state?.description ? state?.description : "JD N/A"}
            </p>
            <div className="horizontal-divider my-4"></div>
            <h3 className="jobDetails_heading">{LangEN.AboutCompany}</h3>
            <Row className="d-flex gap-3 align-items-center">
              <Col className="col-auto">
                <h3 className="jobDetails_headingSmall mt-2  d-flex align-items-center gap-2">
                  {!companyLoading ? (
                    <Avatar
                      url={companyInfo?.profilePicture}
                      size={32}
                      fontSize={14}
                      firstName={companyInfo.companyName.split(" ")[0]}
                      lastName={companyInfo.companyName.split(" ")[1]}
                    />
                  ) : (
                    <Skeleton circle style={{ width: 32, aspectRatio: 1 }} />
                  )}
                  {companyLoading ? (
                    <Skeleton style={{ width: 100 }} />
                  ) : (
                    companyInfo?.companyName
                  )}
                </h3>
              </Col>
              <Col className="col-auto">
                {!companyLoading ? (
                  <>
                    <label className="jobDetails_label">
                      {LangEN.FoundedIn} -{" "}
                    </label>
                    <span className="jobDetails_labelSpan">
                      {/* {new Date(companyInfo?.foundedin).getFullYear()} */}
                      {companyInfo?.foundedin
                        ? " " +
                          moment(companyInfo.foundedin).format("MMMM YYYY")
                        : LangEN.NotSpecified}
                    </span>
                  </>
                ) : (
                  <Skeleton style={{ width: 120 }} />
                )}
              </Col>
            </Row>
            <p className="jobDetails_details">
              {companyLoading ? <Skeleton count={2} /> : companyInfo?.about}
            </p>
            <div className="horizontal-divider my-4"></div>
            <h3 className="jobDetails_heading">
              {LangEN.EmploymentInformation}
            </h3>
            <Row className="my-3">
              <Col className="col-12 col-md-5 ">
                {/* <Row className="my-3">
              <Col className="col-5 jobDetails_blueText">{LangEN.Category}</Col>
              <Col className="col-7 jobDetails_text">Development</Col>
            </Row> */}
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.Salary}
                  </Col>
                  <Col className="col-7 jobDetails_text">{`${setCurrencyType(
                    state?.currencyType
                  )}${state?.budget}${setPaymentDuration(
                    state?.paymentDuration
                  )}`}</Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.ResourceNeed}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {" "}
                    {mapJobDuration(state?.jobDuration)}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.SkillRequired}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    <div className="d-flex gap-2" style={{ flexWrap: "wrap" }}>
                      {state?.customSkill?.map((item: any, index: number) => (
                        <LightChip index={index} label={item.skillName} sm />
                      ))}
                      {state?.skills?.map((item: any, index: number) => (
                        <LightChip
                          index={index}
                          label={item.skillName.name}
                          sm
                        />
                      ))}
                      {state?.customSkill?.length + state?.skills?.length ==
                        0 && LangEN.NotSpecified}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-2 d-none d-md-flex justify-content-center">
                <div className="vertical-divider"></div>
              </Col>
              <Col className="col-12 col-md-5">
                <Row className="mb-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.WhenStart}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {jobStartBy(state?.jobStartBy)}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.Experience}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {mapWorkExperience(state?.skillLevel)}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">{LangEN.WFH}</Col>
                  <Col className="col-7 jobDetails_text">
                    {remoteResourceArray[state?.remoteResource]}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.ProjectType}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {typeOfProjectArray[state?.projectType]}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} lg={4}>
          <Card className="cardShadow my-3 p-3 p-md-4">
            <Row>
              <Col>
                <CustomButton
                  isLoading={saveLoading}
                  iconType={isJobSaved ? "saved" : "save"}
                  style={{ width: "100%" }}
                  className="jobDetails_cardButton3"
                  label={isJobSaved ? LangEN.UnSaved : LangEN.Saved}
                  onClick={() => {
                    isJobSaved ? unSaveJob() : saveJob();
                  }}
                />
              </Col>
              {!isJobStatusLoading &&
              !isInvitationFlow &&
              user?.user?.applyAs == state?.resourceType &&
              (state?.project?.isStaffed ||
                user?.user?.applyAs !== resourceType.Experts) ? (
                <Col>
                  {isJobApplied === null ? (
                    <CustomButton
                      isLoading={applyLoading}
                      className="jobDetails_cardButton2"
                      label={LangEN.ApplyNow}
                      onClick={() => {
                        applyJob();
                      }}
                    />
                  ) : null}

                  {isJobApplied === jobApplicationStatus.Applied && (
                    <CustomButton
                      isLoading={applyLoading}
                      className="jobDetails_cardButton2"
                      label={LangEN.Revoke}
                      onClick={() => {
                        revokeJob();
                      }}
                    />
                  )}
                </Col>
              ) : null}
            </Row>
            <br></br>
            {isInvitationFlow ? (
              <Row>
                <Col>
                  <CustomButton
                    className="jobDetails_cardButton2"
                    label={
                      jobInvitationStatus === invitationStatus.Accepted
                        ? LangEN.Accepted
                        : LangEN.Accept
                    }
                    onClick={() => {
                      updateInvitationStatus(invitationStatus.Accepted);
                    }}
                  />
                </Col>

                <Col>
                  <CustomButton
                    className="jobDetails_cardButton2"
                    label={
                      jobInvitationStatus === invitationStatus.Rejected
                        ? LangEN.Rejected
                        : LangEN.Reject
                    }
                    onClick={() => {
                      updateInvitationStatus(invitationStatus.Rejected);
                    }}
                  />
                </Col>
              </Row>
            ) : null}

            <div className="horizontal-divider my-4"></div>
            <Card className="p-4  jobDetails_card2">
              <h3 className="jobDetails_title2 my-3">
                <Text line={1}>
                  {state?.project?.name
                    ? state.project.name
                    : LangEN.NotSpecified}
                </Text>
              </h3>
              <p className="jobDetails_subTitle2">
                <Text line={3}>
                  {state?.project?.projectDescription
                    ? state.project.projectDescription
                    : LangEN.NotSpecified}
                </Text>
              </p>
              <CustomButton
                iconType="continue"
                className="jobDetails_cardButton mb-3"
                onClick={() => {
                  navigate("project", {
                    state: { id: state.projectId, job: state },
                  });
                }}
                label={LangEN.ViewProjectDetails}
              />
            </Card>
          </Card>
        </Col>
      </Row>
      <CustomToast
        isShown={toastShown}
        hide={() => {
          setToastShown(false);
        }}
        message={toastMessage}
      />
    </Container>
  );
}

const styles = {
  smallLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: " #66789C",
  },
};

export default JobDetailsProfessional;
