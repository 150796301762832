import { Col, Row, Form } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import CustomCheckButtons from "../../../components/reusableComponents/CustomCheckButton/CustomCheckButtons";
import { jobSkillsRequired, jobStartsIn } from "../../../enums/CreateJobEnums";
import { mapWorkExperience } from "../../../Utils/Mappers";
function Step4({
  step,
  setStep,
  showModal,
  jobStartByValue,
  setJobStartValue,
  skillLevelValue,
  setSkillLevelValue,
}: {
  step: number;
  setStep: Function;
  showModal: Function;
  jobStartByValue: number;
  skillLevelValue: number;
  setJobStartValue: Function;
  setSkillLevelValue: Function;
}) {
  return (
    <div className="createJob_container2 px-sm-5 px-2">
      <Row className="createJob_contentContainer mt-2 mb-4">
        <Col className="col-12 col-md-5">
          <Row className="createJob_container_title py-4">
            <Col>{LangEN.WhenYouWantStart}</Col>
          </Row>
          <Row className="createJob_contentContainer">
            <Form.Group>
              <CustomCheckButtons
                label={LangEN.Immediately}
                id="step4-1"
                checked={jobStartByValue == jobStartsIn.Immediately}
                onChange={() => {
                  setJobStartValue(jobStartsIn.Immediately);
                }}
              />
              <CustomCheckButtons
                label={LangEN.InOneTwoWeeks}
                checked={jobStartByValue == jobStartsIn.OneTwoWeeks}
                id="step4-2"
                onChange={() => {
                  setJobStartValue(jobStartsIn.OneTwoWeeks);
                }}
              />
              <CustomCheckButtons
                label={LangEN.MoreThanTwoWeeks}
                checked={jobStartByValue == jobStartsIn.MoreThanTwoWeeks}
                onChange={() => {
                  setJobStartValue(jobStartsIn.MoreThanTwoWeeks);
                }}
                id="step4-3"
              />
            </Form.Group>
          </Row>
        </Col>
        <Col className="col-2 d-none d-md-block createJob_center py-0 py-md-5">
          <div className="vertical-divider " />
        </Col>
        <Col className="col-12 col-md-5">
          <Row className="createJob_container_title py-4">
            <Col>{LangEN.LevelTeamContribute}</Col>
          </Row>
          <Row className="createJob_contentContainer">
            <Form.Group>
              <CustomCheckButtons
                label={mapWorkExperience(0)}
                id="step4-1-1"
                checked={skillLevelValue == jobSkillsRequired.Beginner}
                onChange={() => {
                  setSkillLevelValue(jobSkillsRequired.Beginner);
                }}
              />
              <CustomCheckButtons
                label={mapWorkExperience(1)}
                id="step4-1-2"
                checked={skillLevelValue == jobSkillsRequired.Intermediate}
                onChange={() => {
                  setSkillLevelValue(jobSkillsRequired.Intermediate);
                }}
              />
              <CustomCheckButtons
                label={mapWorkExperience(2)}
                id="step4-1-3"
                checked={
                  skillLevelValue == jobSkillsRequired.MidLevelManagement
                }
                onChange={() => {
                  setSkillLevelValue(jobSkillsRequired.MidLevelManagement);
                }}
              />

              <CustomCheckButtons
                label={mapWorkExperience(3)}
                id="step4-1-4"
                checked={
                  skillLevelValue ==
                  jobSkillsRequired.ExecutiveOrSeniorLeadership
                }
                onChange={() => {
                  setSkillLevelValue(
                    jobSkillsRequired.ExecutiveOrSeniorLeadership
                  );
                }}
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex align-item-center createJob_spaceBetween gy-2">
        <Col md={6} lg={5}>
          <CustomButton
            className="createJob_cancel px-4"
            label={LangEN.Cancel}
            onClick={() => showModal()}
          />
        </Col>
        <Col
          md={6}
          lg={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="col-auto createJob_index"
        >{`${LangEN.Step} ${step} ${LangEN.Of} 5`}</Col>
        <Col md={12} lg={5} className="d-flex justify-content-end">
          <Row className="d-flex justify-content-end gy-2">
            <Col>
              <CustomButton
                className="createJob_previous px-4"
                label={LangEN.Previous}
                onClick={() => {
                  setStep(3);
                }}
                iconType="previous"
              />
            </Col>
            <Col>
              <CustomButton
                className="createJob_next px-4"
                label={LangEN.Next}
                iconType="next"
                onClick={() => {
                  setStep(6);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step4;
