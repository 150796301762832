import { Col, Row } from "react-bootstrap";
import pencil from "../../assets/svg/icon/pencil-blue.svg";
import { useState } from "react";
import { useEffect } from "react";
import UserService from "../../services/UserService";
import { Chip } from "./Chip";

function JobPreviewFields({
  title,
  subtitle,
  setStep,
  step,
  lg,
  skills,
  setSkills = () => {},
  customSkills,
  setCustomSkills = () => {},
  hideEdit,
}: {
  title: string;
  subtitle?: string;
  step: number;
  setStep: Function;
  lg?: boolean;
  skills?: any;
  setSkills?: Function;
  customSkills?: any;
  setCustomSkills?: Function;
  hideEdit?: boolean;
}) {
  const [options, setOptions] = useState<any>([]);

  const getLabel = (id: string | any) => {
    let label = "";
    if (options?.length > 0)
      options?.map((item: any) => {
        if (id == item.id) {
          label = item.name;
        }
      });
    return label;
  };

  useEffect(() => {
    if (!skills) return;
    UserService.FetchSkills()
      .then((res: any) => {
        setOptions(res.data);
      })
      .catch((err: any) => console.log(err));
  }, []);
  return (
    <Row className="my-3 mb-5 ">
      <Col>
        <h3 style={{ fontWeight: 600, fontSize: lg ? 25 : 20 }}>{title}</h3>
        <div
          style={{
            fontWeight: 500,
            fontSize: "16px",
            color: "#555555",
          }}
        >
          {skills || customSkills ? (
            <div className="createJob_skillContainer">
              {skills.map((item: any, index: any) => (
                <Chip
                  index={index}
                  label={getLabel(item)}
                  onClose={() => {
                    console.log("this");
                    setSkills((pre: any) => {
                      let arr = pre.filter(function (id: any) {
                        return id !== item;
                      });
                      return arr;
                    });
                  }}
                />
              ))}
              {customSkills?.map((item: any, index: any) => (
                <Chip
                  index={index}
                  label={item}
                  onClose={() => {
                    console.log("this");
                    setCustomSkills((pre: any) => {
                      let arr = pre.filter(function (name: any) {
                        return name !== item;
                      });
                      return arr;
                    });
                  }}
                />
              ))}
            </div>
          ) : (
            subtitle
          )}
        </div>
      </Col>
      {!hideEdit && (
        <Col className="col-auto">
          <div className="createJob_edit" onClick={() => setStep(step)}>
            <img src={pencil} width={20} height={20} />
          </div>
        </Col>
      )}
    </Row>
  );
}

export default JobPreviewFields;
