import "./styles.css";
import { Card, Container } from "react-bootstrap";
import StepIndicator from "../../components/reusableComponents/StepIndicator";
import { useState, useContext } from "react";
import Step1 from "./StepperFormComponents/Step1";
import Step2 from "./StepperFormComponents/Step2";
import Step3 from "./StepperFormComponents/Step3";
import Step4 from "./StepperFormComponents/Step4";
import Step5 from "./StepperFormComponents/Step5";
import JobPreview from "./StepperFormComponents/JobPreview";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { LangEN } from "../../constants/Lang/LangEN";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BreadCrumbsContext } from "../../App";
import Loader from "../../components/reusableComponents/Loader";
import JobService from "../../services/JobService";

function CreateJob({ isEdit }: { isEdit?: boolean }) {
  const bread = useContext(BreadCrumbsContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [step, setStep] = useState(isEdit ? 7 : 1);
  const [resourceType, setResourceType] = useState(1);
  const [projectType, setProjectType] = useState(0);
  const [jobDuration, setJobDuration] = useState(0);
  const [requiredResourceType, setRequiredResourceType] = useState(0);
  const [skills, setSkills] = useState<any>([]);
  const [jobStartBy, setJobStartBy] = useState(0);
  const [jobSkillsRequired, setJobSkillsRequired] = useState(0);
  const [requiredResources, setRequiredResources] = useState(0);
  const [remoteResource, setRemoteResource] = useState(0);
  const [budget, setBudget] = useState(45);
  const [paymentDuration, setPaymentDuration] = useState(0);
  const [currencyType, setCurrencyType] = useState(0);
  const [description, setDescription] = useState("");
  const [customSkills, setCustomSkills] = useState<any>([]);
  const [customSkillsPrevious, setCustomSkillsPrevious] = useState<any>([]);

  const [modalShown, setModalShown] = useState(false);
  useEffect(() => {
    console.log(state);
    if (!isEdit) return setLoading(false);
    JobService.GetJobById(state.jobId)
      .then((res) => {
        const job = res.data;
        setLoading(false);
        setResourceType(job.resourceType);
        setProjectType(job.projectType);
        setJobDuration(job.jobDuration);
        setSkills([]);
        if (job.customSkill) {
          setCustomSkillsPrevious(job.customSkill);
          let s = job.customSkill;
          setCustomSkills([]);
          s.map((item: any) =>
            setCustomSkills((pre: any) => [...pre, item.skillName])
          );
        }
        job.skills.map((item: any) =>
          setSkills((pre: any) => [...pre, item.skillId])
        );
        setRequiredResourceType(job.requiredResourceType);
        setJobStartBy(job.jobStartBy);
        setJobSkillsRequired(job.skillsRequired);
        setBudget(job.budget);
        setCurrencyType(job.currencyType);
        setPaymentDuration(job.paymentDuration);
        setRemoteResource(job.remoteResource);
        setDescription(job.description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const breadData = isEdit
    ? [
        {
          label: LangEN.MyProjects,
          route: "/projects",
        },
        {
          label: LangEN.ProjectDetails,
          route: "/projects/details",
          state: { id: state.id },
        },

        {
          label: LangEN.JobDetails,
          route: -1,
          state: {},
        },
        {
          label: LangEN.EditJobDetails,
          route: "/",
        },
      ]
    : [
        {
          label: LangEN.MyProjects,
          route: "/projects",
        },
        {
          label: LangEN.CreateAJob,
          route: "/",
        },
      ];

  useEffect(() => {
    bread.setData(breadData);
    return () => {
      bread.setData([]);
    };
  }, []);
  return (
    <>
      <ConfirmationModal
        show={modalShown}
        setShow={setModalShown}
        label={LangEN.AreYouSureCancel}
        subLabel={LangEN.IfYouLeaveBeforeSaving}
        buttonText={LangEN.Yes}
        cancelButtonText={LangEN.No}
        action={() => {
          navigate(-1);
        }}
      />
      {loading ? (
        <Loader lg />
      ) : (
        <Container className="main_container">
          {step == 7 && (
            <h3 className="createJob_header mb-4">{LangEN.JobPreview}</h3>
          )}
          <Card className="cardShadow p-4 createJob_card">
            {step != 7 && <StepIndicator step={step} />}
            {step == 1 && (
              <Step1
                isEdit={isEdit}
                step={step}
                setStep={setStep}
                showModal={() => setModalShown(true)}
                resourceTypeValue={resourceType}
                setResourceTypeValue={setResourceType}
              />
            )}
            {step == 2 && (
              <Step2
                step={step}
                setStep={setStep}
                showModal={() => setModalShown(true)}
                projectTypeValue={projectType}
                setProjectTypeValue={setProjectType}
                jobDurationValue={jobDuration}
                setJobDurationValue={setJobDuration}
              />
            )}
            {step == 3 && (
              <Step3
                step={step}
                setStep={setStep}
                showModal={() => setModalShown(true)}
                skills={skills}
                setSkills={setSkills}
                professionalAvailabilityValue={requiredResourceType}
                setProfessionalAvailabilityValue={setRequiredResourceType}
                customSkills={customSkills}
                setCustomSkills={setCustomSkills}
              />
            )}
            {step == 4 && (
              <Step4
                step={step}
                setStep={setStep}
                showModal={() => setModalShown(true)}
                jobStartByValue={jobStartBy}
                setJobStartValue={setJobStartBy}
                skillLevelValue={jobSkillsRequired}
                setSkillLevelValue={setJobSkillsRequired}
              />
            )}
            {step == 6 && (
              <Step5
                step={step}
                setStep={setStep}
                showModal={() => setModalShown(true)}
                remoteResourceValue={remoteResource}
                setRemoteResourceValue={setRemoteResource}
                budgetValue={budget}
                setBudgetValue={setBudget}
                setCurrencyTypeValue={setCurrencyType}
                currencyTypeValue={currencyType}
                setPaymentDurationValue={setPaymentDuration}
                paymentDurationValue={paymentDuration}
              />
            )}
            {step == 7 && (
              <JobPreview
                step={step}
                setStep={setStep}
                setSkills={setSkills}
                setCustomSkills={setCustomSkills}
                customSkillsPrevious={customSkillsPrevious}
                setCustomSkillsPrevious={setCustomSkillsPrevious}
                description={description}
                setDescription={setDescription}
                customSkills={customSkills}
                isEdit={isEdit}
                showModal={() => setModalShown(true)}
                data={{
                  resourceType: resourceType,
                  projectType: projectType,
                  jobDuration: jobDuration,
                  requiredResourceType: requiredResourceType,
                  skills: skills,
                  customSkill: customSkills,
                  jobStartBy: jobStartBy,
                  remoteResource: remoteResource,
                  skillLevel: jobSkillsRequired,
                  skillsRequired: jobSkillsRequired,
                  requiredResource: requiredResources,
                  budget: budget,
                  paymentDuration: paymentDuration,
                  currencyType: currencyType,
                }}
              />
            )}
          </Card>
        </Container>
      )}
    </>
  );
}

export default CreateJob;
