import { Player } from "@lottiefiles/react-lottie-player";
import LoaderJson from "../../assets/json/loader1.json";

function Loader({ lg, noMargin }: { lg?: boolean; noMargin?: boolean }) {
  return (
    <div
      style={{
        width: "100%",
        height: "30px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "relative",
        marginTop: noMargin ? 0 : "5vh",
      }}
    >
      <Player
        src={LoaderJson}
        style={{
          aspectRatio: 1,
          height: lg ? 120 : 80,
          position: "absolute",
          top: -25,
          translate: "-50%",
        }}
        autoplay
        loop
      />
    </div>
  );
}

export default Loader;
