import moment from "moment";
import {
  currencyType,
  jobDuration,
  jobSkillsRequired,
  jobStartsIn,
  paymentDuration,
  resourceType,
  workExperience,
} from "../enums/CreateJobEnums";
import { professionalAvailability } from "../enums/ProfessionalAvailability";

export const mapJobRole = (role: number) => {
  if (resourceType.Architect === role) {
    return "Architect";
  } else if (resourceType.Experts === role) {
    return "Expert";
  } else if (resourceType.ProjectManager === role) {
    return "Project Manager";
  } else {
    return "N/A";
  }
};

export const mapJobDuration = (duration: number) => {
  if (jobDuration.MoreThanSixMonths === duration) {
    return "More than 6 Months";
  } else if (jobDuration.OneFourWeeks === duration) {
    return "1 to 4 Weeks";
  } else if (jobDuration.OneWeek === duration) {
    return "1 week";
  } else if (jobDuration.ThreeSixMonths === duration) {
    return "3 to 6 Months";
  } else if (jobDuration.OneThreeMonths === duration) {
    return "1 to 3 Months";
  } else {
    return "N/A";
  }
};

export const setCurrencyType = (currency: number) => {
  if (currencyType.Dinar === currency) {
    return "د.ك";
  } else if (currencyType.Dollar === currency) {
    return "$";
  } else if (currencyType.Euro === currency) {
    return "€";
  } else if (currencyType.Rupee === currency) {
    return "₹";
  } else {
    return "N/A";
  }
};

export const setPaymentDuration = (payDuration: number) => {
  if (paymentDuration.Daily === payDuration) {
    return "/daily";
  } else if (paymentDuration.Hourly === payDuration) {
    return "/Hr";
  } else {
    return "N/A";
  }
};

export const requiredResourceType = (resourceType: number) => {
  if (professionalAvailability.PartTime === resourceType) {
    return "Part Time";
  } else if (professionalAvailability.FullTime === resourceType) {
    return "Full Time";
  } else if (professionalAvailability.Hourly === resourceType) {
    return "Hourly";
  } else {
    return "N/A";
  }
};

export const jobStartBy = (startBy: number) => {
  if (jobStartsIn.Immediately === startBy) {
    return "Immediately";
  } else if (jobStartsIn.MoreThanTwoWeeks === startBy) {
    return "More than 2 weeks";
  } else if (jobStartsIn.OneFourWeeks === startBy) {
    return "In 1-4 Weeks";
  } else if (jobStartsIn.OneTwoWeeks === startBy) {
    return "In 1-2 weeks";
  } else {
    return "N/A";
  }
};

export const skillsLevelRequired = (skillLevel: number) => {
  if (jobSkillsRequired.Beginner === skillLevel) {
    return "0-2 years";
  } else if (jobSkillsRequired.ExecutiveOrSeniorLeadership === skillLevel) {
    return "2-5 years";
  } else if (jobSkillsRequired.Intermediate === skillLevel) {
    return "5-10 years";
  } else if (jobSkillsRequired.MidLevelManagement === skillLevel) {
    return "10+ years";
  } else {
    return "N/A";
  }
};

export const mapWorkExperience = (experience: number) => {
  if (workExperience.ZeroToTwoYears === experience) {
    return "0-2 Years";
  } else if (workExperience.TwoToFiveYears === experience) {
    return "2-5 Years";
  } else if (workExperience.FiveToTenYears === experience) {
    return "5-10 Years";
  } else if (workExperience.MoreThenTenYears === experience) {
    return "10+ Years";
  } else {
    return "N/A";
  }
};

export const dateFromNow = (date: string) => {
  return moment(date).fromNow();
};

export const getWeeks = (startDate: string, endDate: string) => {
  let projectStartDate: any = moment(new Date(startDate));
  let projectEndDate: any = moment(new Date(endDate));
  let diff = moment.duration(projectEndDate.diff(projectStartDate));
  return `${Math.floor(diff.asWeeks())} Weeks`;
};

export const getTimeBetweenDates = (startDate: string, endDate: string) => {
  let projectStartDate: any = moment(new Date(startDate));
  let projectEndDate: any = moment(new Date(endDate));
  let diff = projectEndDate.to(projectStartDate, true);
  return diff;
};
