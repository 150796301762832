import { Container, Card, Col, Row, Dropdown } from "react-bootstrap";
import CustomButton from "../../components/reusableComponents/CustomButton";
import { LangEN } from "../../constants/Lang/LangEN";
import "./styles.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApplicationCard from "../../components/reusableComponents/ApplicationCard/ApplicationCard";
import { useEffect, useState, useContext } from "react";
import { jobStatusMenu } from "../../enums/JobStatus";
import JobSApplicationService from "../../services/JobApplicationService";
import EditJob from "../../assets/svg/icon/EditJob.svg";
import DeleteJob from "../../assets/svg/icon/DeletJob.svg";
import JobExpired from "../../assets/svg/icon/JobExpired.svg";
import { CustomToggle } from "../../Utils/CustomDropdownToggle";
import JobService from "../../services/JobService";
import job from "../../assets/svg/icon/applications.svg";
import { BreadCrumbsContext, User } from "../../App";
import { UserRoles } from "../../enums/UserRoles";
import { resourceType } from "../../enums/CreateJobEnums";
import ErrorPage from "../Error/ErrorPage";
import Loader from "../../components/reusableComponents/Loader";
import { MyProjectStyles } from "../MyProject/MyProjectStyles";
import ApplicationDetails from "./ApplicationDetails";

function JobApplication({ isProfessional }: { isProfessional?: boolean }) {
  const bread = useContext(BreadCrumbsContext);
  const navigate = useNavigate();
  const user = useContext(User);
  // const { id } = useParams();
  const { state } = useLocation();
  const [jobSorting, setJobSorting] = useState(0);
  const [loading, setLoading] = useState(true);
  const [listRecords, setListRecords] = useState([]);
  const isExpert =
    user?.user?.applyAs && user.user.applyAs == resourceType.Experts;

  const breadData = [
    {
      label: LangEN.MyProjects,
      route: "/projects",
    },
    {
      label: LangEN.ProjectDetails,
      route: "/projects/details",
      state: { id: state.project.id },
    },

    {
      label: LangEN.Applications,
      route: "/",
    },
  ];

  useEffect(() => {
    bread.setData(breadData);

    return () => {
      bread.setData([]);
    };
  }, []);

  useEffect(() => {
    if (isExpert) return;
    JobSApplicationService.GetJobApplications(state.id)
      .then((res) => {
        setListRecords(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteJob = () => {
    JobService.DeleteJob(state.id)
      .then((res) => {
        navigate("/projects");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReceivedJobApplication = () => {
    setLoading(true);
    setJobSorting(jobStatusMenu.Received);
    JobSApplicationService.GetJobApplications(state.id)
      .then((res) => {
        setListRecords(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAcceptedJobApplications = () => {
    setLoading(true);
    setJobSorting(jobStatusMenu.Accepted);
    JobSApplicationService.GetAcceptedJobApplications(state.id)
      .then((res) => {
        setListRecords(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRejectedJobApplications = () => {
    setLoading(true);
    setJobSorting(jobStatusMenu.Rejected);
    JobSApplicationService.GetRejectedJobApplications(state.id)
      .then((res) => {
        setListRecords(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShortlistedJobApplications = () => {
    setLoading(true);
    user.role === UserRoles.Business
      ? setJobSorting(jobStatusMenu.Shortlisted)
      : setJobSorting(jobStatusMenu.Accepted);
    JobSApplicationService.GetShortlistedJobApplications(state.id)
      .then((res) => {
        setListRecords(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isExpert) return <ErrorPage />;
  return (
    <Container className="main_container">
      <Row className="gap-2">
        <Col className="col-auto">
          <CustomButton
            onClick={() => {
              navigate("/projects/details/jobdetails", {
                state: { id: state.id, project: state.project },
              });
            }}
            className="jobDetails_secondaryButton"
            label={LangEN.JobDetails}
          />
        </Col>
        <Col className="col-auto">
          <CustomButton
            className="jobDetails_primaryButton"
            label={LangEN.JobApplications}
          />
        </Col>
        {/* {!isProfessional && (
          <Col className="d-flex justify-content-end">
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-basic"
              ></Dropdown.Toggle>

              <Dropdown.Menu className="mt-3">
                <Dropdown.Item
                  onClick={() => {
                    navigate("/projects/details/editjob" ,{state:{id:id}});
                  }}
                >
                  <Row
                    className="d-flex gap-0 my-2"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Col
                      xs="auto"
                      className="d-flex align-items-center me-0 pe-0"
                    >
                      <img src={EditJob} width={20} />
                    </Col>
                    <Col className="d-flex align-items-center jobDetails_items">
                      {LangEN.EditJobDetails}
                    </Col>
                  </Row>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Row
                    className="d-flex gap-0 my-2"
                    style={{ flexWrap: "nowrap" }}
                    onClick={() => deleteJob()}
                  >
                    <Col
                      xs="auto"
                      className="d-flex align-items-center me-0 pe-0"
                    >
                      <img src={DeleteJob} width={20} />
                    </Col>
                    <Col className="d-flex align-items-center jobDetails_items">
                      {LangEN.DeleteJob}
                    </Col>
                  </Row>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Row
                    className="d-flex gap-0 my-2"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Col
                      xs="auto"
                      className="d-flex align-items-center me-0 pe-0"
                    >
                      <img src={JobExpired} width={20} />
                    </Col>
                    <Col className="d-flex align-items-center jobDetails_items">
                      {LangEN.MarkJobExpired}
                    </Col>
                  </Row>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )} */}
      </Row>
      <Card style={{ minHeight: "55vh" }} className="cardShadow my-3 py-3">
        <div className="jobApplications_topBar ps-md-4 ps-2 gap-2">
          {user.role === UserRoles.Business ? (
            <div
              onClick={() => {
                getShortlistedJobApplications();
              }}
              className={`job_topNav ${
                jobSorting == jobStatusMenu.Shortlisted
                  ? "job_topNav_active"
                  : ""
              }`}
            >
              {LangEN.Shortlisted}
            </div>
          ) : null}
          <div
            onClick={() => {
              getReceivedJobApplication();
            }}
            className={`job_topNav ${
              jobSorting == jobStatusMenu.Received ? "job_topNav_active" : ""
            }`}
          >
            {LangEN.Received}
          </div>

          <div
            onClick={() => {
              user.role === UserRoles.Business
                ? getAcceptedJobApplications()
                : getShortlistedJobApplications();
            }}
            className={`job_topNav ${
              jobSorting == jobStatusMenu.Accepted ? "job_topNav_active" : ""
            }`}
          >
            {LangEN.Accepted}
          </div>
          <div
            onClick={() => {
              getRejectedJobApplications();
            }}
            className={`job_topNav ${
              jobSorting == jobStatusMenu.Rejected ? "job_topNav_active" : ""
            }`}
          >
            {LangEN.Rejected}
          </div>
        </div>

        {!listRecords.length && !loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px",
            }}
          >
            <img width={50} src={job} />
            <Card.Title style={MyProjectStyles.cardTitle} className="m-4">
              {LangEN.NoApplicationFound}
            </Card.Title>
          </div>
        ) : (
          <Row className="m-4">
            {loading ? (
              <Loader lg />
            ) : (
              listRecords.map((item: any, index: number) => (
                <ApplicationCard
                  jobSorting={jobSorting}
                  onClick={() => {
                    navigate("/projects/details/jobapplication/details", {
                      state: {
                        id: item.appliedBy,
                        application: item,
                        showAction:
                          jobSorting === jobStatusMenu.Received ? true : false,
                      },
                    });
                  }}
                  application={item}
                />
              ))
            )}
          </Row>
        )}
      </Card>
    </Container>
  );
}

const styles = {
  smallLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: " #66789C",
  },
};

export default JobApplication;
