import { Form } from "react-bootstrap";
import "./styles.css";

function CustomCheckButtons({
  label,
  subtitle,
  id,
  checked,
  onChange = () => {},
  disable,
}: {
  label: string;
  subtitle?: string;
  id: string;
  checked?: boolean;
  onChange?: Function;
  disable?: boolean;
}) {
  return (
    <Form.Check
      id={id}
      type="radio"
      checked={checked}
      className="customCheckButton_container py-2 pe-2 my-4"
      style={{
        backgroundColor: checked ? "#E0E6F7" : "white",
      }}
    >
      <Form.Check.Input
        disabled={disable}
        className="ms-0 me-3 mt-2 mb-2 flex-shrink-0"
        checked={checked}
        onChange={(e) => {
          if (e.target.checked) onChange();
        }}
        type="radio"
        isValid
      />
      <Form.Check.Label className="customCheckButton_label">
        {subtitle ? (
          <div>
            <div className="customCheckButton_labelLarge">{label}</div>
            <div className="customCheckButton_labelSmall">{subtitle}</div>
          </div>
        ) : (
          label
        )}
      </Form.Check.Label>
    </Form.Check>
  );
}

export default CustomCheckButtons;
