import Welcome from "./screens/Welcome/Welcome";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./screens/auth/Login/Login";
import ProfessionalSignUp from "./screens/auth/SignUp/ProfessionalSignUp";
import ForgotPassword from "./screens/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./screens/auth/ResetPassword/ResetPassword";
import BusinessSignUp from "./screens/auth/SignUp/BusinessSignUp";
import EditProfile from "./screens/EditProfile/EditProfile";
import MyProject from "./screens/MyProject/MyProject";
import UnProtectedRoutes from "./Utils/Router/UnProtectedRoutes";
import ProtectedRoutes from "./Utils/Router/ProtectedRoutes";
import EditBusinessProfile from "./screens/EditProfile/EditBusinessProfile";
import ErrorPage from "./screens/Error/ErrorPage";
import EmailVerificationRouter from "./Utils/Router/EmailVerficationRouter";
import CreateProject from "./screens/CreateProject/CreateProject";
import CreateJob from "./screens/CreateJob/CreateJob";
import ProjectDetails from "./screens/MyProject/ProjectDetails";
import JobDetails from "./screens/JobDetails/JobDetails";
import JobApplication from "./screens/JobDetails/JobApplication";
import ApplicationDetails from "./screens/JobDetails/ApplicationDetails";
import Jobs from "./screens/Jobs/Jobs";
import JobDetailsProfessional from "./screens/JobDetails/JobDetailsProfessional";
import AppliedJobs from "./screens/Jobs/AppliedJobs";
import SavedJobs from "./screens/Jobs/SavedJobs";
import MyProjectProfessional from "./screens/MyProject/MyProjectProfessional";
import React, { useState } from "react";
import { UserRoles } from "./enums/UserRoles";
import Profile from "./screens/Profile/Profile";
import ProfessionalProfile from "./screens/Profile/ProfessionalProfile";
import JobRouter from "./Utils/Router/JobRouter";
import Professionals from "./screens/Professionals/Professionals";
import EmailVerification from "./screens/EmailVerifications/EmailVerifications";
import CustomToast from "./components/reusableComponents/CustomToast";
import About from "./screens/StaticPages/About";
import FAQ from "./screens/StaticPages/FAQ";
import Privacy from "./screens/StaticPages/Privacy";
import TermsCondition from "./screens/StaticPages/TermsCondition";
import StaticPageRouter from "./Utils/Router/StaticPageRouter";
import Redirect from "./screens/Redirect/Redirect";

export let showToast: any;

export const User = React.createContext<any>(undefined);
export const SignUpEnabledContext = React.createContext<boolean | undefined>(
  undefined
);
export const BreadCrumbsContext = React.createContext<any>(undefined);

function App() {
  const [accStatus, setAccStatus] = useState<any>({});
  const [user, setUser] = useState<any>({});
  const [isSignUpEnabled, setIsSignUpEnabled] = useState(true); // if true : sign up buttons are visible on ui
  const [breadData, setBreadData] = useState([]);
  const [role, setRole] = useState<number>(() => {
    const user = localStorage.getItem("user");
    let role = UserRoles.Business;
    if (user) {
      role = JSON.parse(user).role;
    }
    return role;
  });
  const [toastShown, setToastShown] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  showToast = (message: string) => {
    console.log("toast active");
    setToastMessage(message);
    !toastShown && setToastShown(true);
  };

  return (
    <User.Provider
      value={{ role, setRole, accStatus, setAccStatus, user, setUser }}
    >
      <SignUpEnabledContext.Provider value={isSignUpEnabled}>
        <BreadCrumbsContext.Provider
          value={{ data: breadData, setData: setBreadData }}
        >
          <CustomToast
            isShown={toastShown}
            message={toastMessage}
            hide={() => setToastShown(false)}
          />
          <BrowserRouter>
            <Routes>
              {/* Landing Page, Login, SignUp */}
              <Route element={<UnProtectedRoutes />}>
                <Route path="/" element={<Welcome />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<ProfessionalSignUp />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route
                  path="businessforgotpassword"
                  element={<ForgotPassword isBusiness />}
                />
                {/* <Route path="resetpassword" element={<ResetPassword />} /> */}
                <Route path="businessregister" element={<BusinessSignUp />} />
                <Route path="businesslogin" element={<Login isBusiness />} />
              </Route>
              {/* Authorized Pages */}
              <Route element={<ProtectedRoutes />}>
                <Route element={<EmailVerificationRouter />}></Route>
                <Route
                  path="redirect/:jobId/:invitedBy"
                  element={<Redirect type="invite" />}
                />
                <Route
                  path="redirect/:jobId"
                  element={<Redirect type="received" />}
                />

                {/* Professional Routes */}
                <Route
                  path="professional"
                  element={
                    role == UserRoles.Business ? (
                      <Professionals />
                    ) : (
                      <Professionals isProfessional />
                    )
                  }
                />
                <Route
                  path="professional/details"
                  element={
                    role == UserRoles.Business ? (
                      <ApplicationDetails
                        isProfessional
                        notProjectRoute
                        flow={UserRoles.Business}
                      />
                    ) : (
                      <ApplicationDetails
                        isProfessional
                        notProjectRoute
                        isSearch
                        flow={UserRoles.Professional}
                      />
                    )
                  }
                />

                <Route
                  path="/professional/details/project/job"
                  element={<JobDetails notProjectRoute />}
                />
                <Route
                  path="professional/details/project"
                  element={<ProjectDetails isProfessional notProjectRoute />}
                />
                <Route
                  path="professional/details/project/professional"
                  element={<ApplicationDetails ownProject />}
                />

                <Route path="verification" element={<EmailVerification />} />

                {/* Profile Routes */}
                <Route
                  path="profile"
                  element={
                    role == UserRoles.Business ? (
                      <Profile />
                    ) : (
                      <ProfessionalProfile />
                    )
                  }
                />
                <Route
                  path="profile/edit"
                  element={
                    role == UserRoles.Business ? (
                      <EditBusinessProfile />
                    ) : (
                      <EditProfile />
                    )
                  }
                />

                {/* Project Routes */}
                <Route
                  path="projects"
                  element={
                    role == UserRoles.Business ? (
                      <MyProject />
                    ) : (
                      <MyProjectProfessional />
                    )
                  }
                />
                <Route
                  path="projects/create"
                  element={
                    role == UserRoles.Business ? (
                      <CreateProject />
                    ) : (
                      <ErrorPage />
                    )
                  }
                />
                <Route
                  path="projects/details"
                  element={
                    role == UserRoles.Business ? (
                      <ProjectDetails />
                    ) : (
                      <ProjectDetails isProfessional isHired />
                    )
                  }
                />
                <Route
                  path="projects/details/professional"
                  element={<ApplicationDetails ownProject />}
                />
                <Route
                  path="projects/details/jobdetails"
                  element={
                    role == UserRoles.Business ? (
                      <JobDetails />
                    ) : (
                      <JobDetails isProfessional />
                    )
                  }
                />
                <Route
                  path="projects/details/jobapplications"
                  element={
                    role == UserRoles.Business ? (
                      <JobApplication />
                    ) : (
                      <JobApplication isProfessional />
                    )
                  }
                />
                <Route
                  path="projects/details/jobapplication/details"
                  element={<ApplicationDetails />}
                />
                <Route
                  path="projects/details/createjob"
                  element={
                    role == UserRoles.Business ? <CreateJob /> : <ErrorPage />
                  }
                />
                <Route
                  path="projects/details/editjob"
                  element={
                    role == UserRoles.Business ? (
                      <CreateJob isEdit />
                    ) : (
                      <ErrorPage />
                    )
                  }
                />
                <Route
                  path="projects/professional"
                  element={
                    role == UserRoles.Business ? (
                      <ErrorPage />
                    ) : (
                      <Professionals isProfessional isProjectRoute />
                    )
                  }
                />
                <Route
                  path="projects/professional/details"
                  element={
                    role == UserRoles.Business ? (
                      <ErrorPage />
                    ) : (
                      <ApplicationDetails
                        isProfessional
                        notProjectRoute
                        projectRoute
                        isSearch
                        flow={UserRoles.Professional}
                      />
                    )
                  }
                />

                {/* Job Routing */}
                <Route element={<JobRouter role={role} />}>
                  <Route path="jobs" element={<Navigate to="search" />} />
                  <Route path="jobs/search" element={<Jobs />} />
                  <Route path="jobs/applied" element={<AppliedJobs />} />
                  <Route path="jobs/saved" element={<SavedJobs />} />
                  <Route
                    path="jobs/details"
                    element={<JobDetailsProfessional />}
                  />
                  <Route
                    path="jobs/invitation"
                    element={<JobDetailsProfessional isInvitationFlow />}
                  />
                  <Route
                    path="jobs/invitation/project"
                    element={<ProjectDetails isJobs isProfessional />}
                  />
                  <Route
                    path="jobs/details/project"
                    element={<ProjectDetails isJobs isProfessional />}
                  />
                </Route>
                <Route path="*" element={<ErrorPage />} />
              </Route>
              {/* Static Pages */}
              <Route element={<StaticPageRouter />}>
                <Route path="/about" element={<About />} />
                <Route path="/termofservice" element={<TermsCondition />} />
                <Route path="/privacypolicy" element={<Privacy />} />
                <Route path="/faq" element={<FAQ />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </BreadCrumbsContext.Provider>
      </SignUpEnabledContext.Provider>
    </User.Provider>
  );
}

export default App;
