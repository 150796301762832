import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Offcanvas,
  Form,
  Dropdown,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import gandalfBlue from "../../assets/svg/Color logo2 - no background.svg";
import notification from "../../assets/svg/icon/notification.svg";
import notificationEmpty from "../../assets/svg/icon/notificationIconEmpty.svg";
import backArrow from "../../assets/svg/icon/backArrow.svg";
import menu from "../../assets/svg/icon/menu.svg";
import infoIcon from "../../assets/svg/icon/info.svg";
import search from "../../assets/svg/icon/search.svg";
import appliedJobs from "../../assets/svg/icon/appliedJobs.svg";
import logoutIcon from "../../assets/svg/icon/logoutIcon.svg";
import password from "../../assets/svg/icon/pass.svg";
import savedJobs from "../../assets/svg/icon/savedJobs.svg";
import warningIcon from "../../assets/svg/icon/warning.svg";
import profile from "../../assets/png/profile.png";
import { NavigationHeaderStyles } from "./NavigationHeaderStyles";
import { LangEN } from "../../constants/Lang/LangEN";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { UserRoles } from "../../enums/UserRoles";
import { getAvatarInitials, getName } from "../../Utils/helper";
import TextAvatar from "../reusableComponents/TextAvatar";
import UserService from "../../services/UserService";
import NotificationModal from "../Modals/NotificationModal/NotificationModal";
import { messaging } from "../../firebase";
import ChangePasswordModal from "../Modals/CangePasswordModal";
import { onMessage } from "firebase/messaging";
import { mapJobRole } from "../../Utils/Mappers";
import { useContext } from "react";
import { User } from "../../App";
import { notificationType } from "../../enums/NotificationTypes";
import NotificationService from "../../services/NotificationService";
import browserId from "browser-id/lib/browserID";
import ConfirmationModal from "../Modals/ConfirmationModal";
import Avatar from "../reusableComponents/Avatar";

export default function NavigationHeader() {
  const navigate = useNavigate();
  const user = useContext(User);
  const [role, setRole] = useState(0);
  const [data, setData] = useState<any>();
  const [changePassModalShow, setChangePassModalShow] = useState(false);
  const [notifications, setNotifications] = useState<any>([]);
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [isUnreadNotification, setIsUnreadNotification] = useState(false);
  // const [isProfileCompleted, setIsProfileCompleted] = useState(false);

  const channelBroadcast = new BroadcastChannel("nChannel");

  const sendNotificationAction = (payload: any) => {
    // console.log("no+>", payload);
    if (payload.notificationType == notificationType.ProfileVerification) {
      user.setAccStatus((pre: any) => {
        console.log("==> ==> user verified -->>", pre);
        const newData = {
          ...pre,
          isProfileVerified: true,
        };
        localStorage.setItem("accStatus", JSON.stringify(newData));
        return newData;
      });
    }
  };

  const showNotification = (payload: any) => {
    var options: any = {
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    };
    var notification = new Notification("Beehived - " + options.title, options);
  };

  const restoreNotificationIdFromServer = () => {
    NotificationService.GetNotifications()
      .then((res) => {
        setNotifications(res.data);
        const verificationArr = res.data.filter(
          (item: any) =>
            item.notificationType == notificationType.ProfileVerification
        );
        verificationArr.length > 0 &&
          sendNotificationAction(verificationArr[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBackgroundNotifications = (payload: any) => {
    const newPayload = {
      id: payload?.notification?.id,
      data: payload?.notification?.data,
      notificationType: payload?.data?.notificationInfo
        ? JSON.parse(payload.data.notificationInfo).notificationType
        : null,
      isRead: false,
      title: payload?.notification?.title,
      message: payload?.notification?.body,
    };
    // setNotifications((pre: any) => [newPayload, ...pre]);
    restoreNotificationIdFromServer();
    sendNotificationAction(newPayload);
  };

  useEffect(() => {
    channelBroadcast.onmessage = (event) => {
      getBackgroundNotifications(event.data);
    };
  }, []);

  useEffect(() => {
    const messagingFirebase = onMessage(messaging, (payload: any) => {
      console.log("payload", payload);
      console.log("this==>", JSON.parse(payload.data.notificationInfo));
      const newPayload = {
        id: payload?.notification?.id,
        data: payload?.notification?.data,
        notificationType: payload?.data?.notificationInfo
          ? JSON.parse(payload.data.notificationInfo).notificationType
          : null,
        isRead: false,
        title: payload?.notification?.title,
        message: payload?.notification?.body,
      };
      showNotification(payload);
      sendNotificationAction(newPayload);
      restoreNotificationIdFromServer();
    });

    return () => {
      messagingFirebase(); //unsubscribing fcm listener
    };
  }, []);

  useEffect(() => {
    if (notifications.length == 0) return setIsUnreadNotification(false);
    let unReadArr = notifications.filter((item: any) => !item.isRead);
    if (unReadArr.length == 0) setIsUnreadNotification(false);
    else setIsUnreadNotification(true);
  }, [notifications]);

  useEffect(() => {
    const user: any = localStorage.getItem("user");
    if (user) setRole(JSON.parse(user).role);
    // UserService.GetAccountVerification()
    //   .then((res) => setIsProfileCompleted(res.data.isProfileComplete))
    //   .catch((err) => console.log(err));

    function handleResize() {
      if (window.innerWidth >= 1200) {
        setExpanded(false);
      }
    }
    getUserData();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getUserData = () => {
    UserService.ViewProfile()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const logoutFromServer = () => {
    setLogoutLoading(true);
    NotificationService.DeleteFCMToken(browserId())
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        setLogoutLoading(false);
        navigate("/");
      })
      .catch(() => {
        setLogoutLoading(false);
      });
  };
  const logout = () => {
    setModalShown(true);
  };
  return (
    <Navbar
      sticky="top"
      key={"xl"}
      style={NavigationHeaderStyles.navBar}
      expand={"xl"}
      expanded={expanded}
      onToggle={(e) => {
        setExpanded(e);
      }}
    >
      <ConfirmationModal
        show={modalShown}
        setShow={setModalShown}
        label={LangEN.Logout}
        subLabel={LangEN.AreYouSureLogout}
        buttonText={LangEN.Yes}
        cancelButtonText={LangEN.No}
        action={logoutFromServer}
        loading={logoutLoading}
      />
      <NotificationModal
        show={notificationModalShow}
        setShow={setNotificationModalShow}
        notification={notifications}
        setNotification={setNotifications}
        sendNotificationAction={sendNotificationAction}
      />
      <ChangePasswordModal
        setShow={setChangePassModalShow}
        show={changePassModalShow}
      />
      <Container style={{ minWidth: "100%" }}>
        <Navbar.Brand style={NavigationHeaderStyles.navBrand}>
          <NavLink to={role == UserRoles.Professional ? "jobs" : "projects"}>
            <img
              src={gandalfBlue}
              height="70"
              className="d-inline-block align-top"
              alt="logo"
              style={{ marginRight: "1vw" }}
            />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          className={"shadow-none"}
          style={{ border: "none", outline: "none" }}
          aria-controls={`offcanvasNavbar-expand-${"xl"}`}
        >
          <img src={menu} width={35} />
        </Navbar.Toggle>

        <Navbar.Offcanvas
          className="d-xl-none"
          id={`offcanvasNavbar-expand-${"xl"}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
          placement="end"
          style={{ maxWidth: "min(100vw , 300px)" }}
        >
          <Offcanvas.Body
            className="py-5"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Nav>
              <Button
                onClick={() => {
                  setExpanded(false);
                }}
                className="mb-3f"
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  width: 20,
                }}
              >
                <img src={backArrow} width={20} />
              </Button>
              <Row className="my-3 gx-3 pe-2">
                <Col className="col-auto">
                  <Avatar
                    url={user.user?.profilePicture}
                    size={60}
                    fontSize={24}
                    firstName={getName().split(" ")[0]}
                    lastName={getName().split(" ")[1]}
                  />
                </Col>
                <Col className="ellipsis">
                  <Row className="ellipsis">
                    <Col
                      className="ellipsis"
                      style={NavigationHeaderStyles.title}
                    >
                      {getName()}
                    </Col>
                  </Row>
                  <Row className="ellipsis">
                    <Col className="ellipsis">
                      {data?.companyName && data.companyName}
                      {data?.applyAs && mapJobRole(data.applyAs)}
                    </Col>
                  </Row>
                </Col>
              </Row>

              {user?.accStatus && !user?.accStatus?.isProfileVerified && (
                <div
                  style={{
                    backgroundColor: "#FEF7D1",
                    color: "#553311",
                    borderRadius: 20,
                    height: 40,
                    alignItems: "center",
                    paddingInline: 10,
                    justifyContent: "center",
                    alignSelf: "center",
                    fontWeight: 700,
                    border: "1px solid #F7C752",
                    display: "flex",
                    fontSize: 14,
                    gap: 5,
                  }}
                >
                  <img src={warningIcon} height={22} />
                  <div className="ellipsis">Profile Under Verification</div>
                </div>
              )}

              <div
                className="my-3"
                style={{
                  width: "100%",
                  backgroundColor: "#bbb",
                  height: 1,
                  alignSelf: "center",
                }}
              />
              {role == UserRoles.Professional && (
                <>
                  {/* <NavLink
                                        style={({ isActive }) =>
                                            isActive
                                                ? NavigationHeaderStyles.navItemActive
                                                : NavigationHeaderStyles.navItem
                                        }
                                        to="/jobs"
                                        onClick={() => setExpanded(false)}
                                    >
                                        {LangEN.Jobs}
                                    </NavLink> */}
                  <NavLink
                    onClick={() => setExpanded(false)}
                    style={({ isActive }) =>
                      isActive
                        ? NavigationHeaderStyles.navItemActive
                        : NavigationHeaderStyles.navItem
                    }
                    to="jobs/search"
                  >
                    {LangEN.SearchJobs}
                  </NavLink>
                  <NavLink
                    onClick={() => setExpanded(false)}
                    style={({ isActive }) =>
                      isActive
                        ? NavigationHeaderStyles.navItemActive
                        : NavigationHeaderStyles.navItem
                    }
                    to="jobs/applied"
                  >
                    {LangEN.AppliedJobs}
                  </NavLink>
                  <NavLink
                    onClick={() => setExpanded(false)}
                    style={({ isActive }) =>
                      isActive
                        ? NavigationHeaderStyles.navItemActive
                        : NavigationHeaderStyles.navItem
                    }
                    to="jobs/saved"
                  >
                    {LangEN.SavedJobs}
                  </NavLink>

                  <NavLink
                    style={({ isActive }) =>
                      isActive
                        ? NavigationHeaderStyles.navItemActive
                        : NavigationHeaderStyles.navItem
                    }
                    to="/professional"
                    onClick={() => setExpanded(false)}
                  >
                    {LangEN.SearchProfessionals}
                  </NavLink>
                </>
              )}
              <NavLink
                style={({ isActive }) =>
                  isActive
                    ? NavigationHeaderStyles.navItemActive
                    : NavigationHeaderStyles.navItem
                }
                to="/projects"
                onClick={() => setExpanded(false)}
              >
                {LangEN.MyProjects}
              </NavLink>

              {role == UserRoles.Business && (
                <NavLink
                  style={({ isActive }) =>
                    isActive
                      ? NavigationHeaderStyles.navItemActive
                      : NavigationHeaderStyles.navItem
                  }
                  to="/professional"
                  onClick={() => setExpanded(false)}
                >
                  {LangEN.Professionals}
                </NavLink>
              )}
              <NavLink
                style={NavigationHeaderStyles.navItem}
                to="#"
                onClick={() => {
                  setExpanded(false);
                  setNotificationModalShow(true);
                }}
              >
                {LangEN.Notification}
              </NavLink>
              <NavLink
                style={({ isActive }) =>
                  isActive
                    ? NavigationHeaderStyles.navItemActive
                    : NavigationHeaderStyles.navItem
                }
                to="/profile"
                onClick={() => setExpanded(false)}
              >
                {LangEN.MyProfile}
              </NavLink>
              <div
                style={NavigationHeaderStyles.navItem}
                onClick={() => {
                  setExpanded(false);
                  setChangePassModalShow(true);
                }}
              >
                {LangEN.ChangePassword}
              </div>
              {!user.accStatus.isProfileComplete && (
                <Row style={NavigationHeaderStyles.infoIconContainer}>
                  <Col className="col-auto">
                    <img src={infoIcon} width={18} />
                  </Col>
                  <Col className={"p-0"}>
                    <NavLink
                      style={NavigationHeaderStyles.navButton2}
                      to="/profile/edit"
                      onClick={() => setExpanded(false)}
                    >
                      {LangEN.CompleteYourProfile}
                    </NavLink>
                  </Col>
                </Row>
              )}
            </Nav>
            <Nav>
              <div style={NavigationHeaderStyles.navItem} onClick={logout}>
                {LangEN.Logout}
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Navbar.Offcanvas
          className="d-none d-xl-block"
          id={`offcanvasNavbar2-expand-${"xl"}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
          placement="end"
        >
          <Offcanvas.Body>
            <Nav className="justify-content-space-between gap-4 flex-grow-1 pe-3">
              {role == UserRoles.Professional && (
                <div style={NavigationHeaderStyles.headerContainer}>
                  <NavLink
                    style={({ isActive }) =>
                      isActive
                        ? NavigationHeaderStyles.navItem2Active
                        : NavigationHeaderStyles.navItem2
                    }
                    to="/jobs"
                    onClick={() => setExpanded(false)}
                  >
                    {LangEN.Jobs}
                  </NavLink>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-button-dark-example1"
                      variant="light"
                      style={NavigationHeaderStyles.dropdownToggle2}
                    />

                    <Dropdown.Menu
                      variant="light"
                      className="p-3 fade"
                      style={{
                        translate: -120,
                        width: 210,
                      }}
                    >
                      <Dropdown.Item>
                        <NavLink
                          style={NavigationHeaderStyles.navButton}
                          to="jobs/search"
                        >
                          <Row style={NavigationHeaderStyles.row}>
                            <Col
                              className="col-auto p-0"
                              style={NavigationHeaderStyles.col}
                            >
                              <img width={20} src={search} />
                            </Col>
                            <Col className="p-0 px-3">{LangEN.SearchJobs}</Col>
                          </Row>
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavLink
                          style={NavigationHeaderStyles.navButton}
                          to="jobs/applied"
                        >
                          <Row style={NavigationHeaderStyles.row}>
                            <Col
                              className="col-auto p-0"
                              style={NavigationHeaderStyles.col}
                            >
                              <img width={20} src={appliedJobs} />
                            </Col>
                            <Col className="p-0 px-3">{LangEN.AppliedJobs}</Col>
                          </Row>
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavLink
                          style={NavigationHeaderStyles.navButton}
                          to="jobs/saved"
                        >
                          <Row style={NavigationHeaderStyles.row}>
                            <Col
                              className="col-auto p-0"
                              style={NavigationHeaderStyles.col}
                            >
                              <img width={20} src={savedJobs} />
                            </Col>
                            <Col className="p-0 px-3">{LangEN.SavedJobs}</Col>
                          </Row>
                        </NavLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {role == UserRoles.Professional && (
                <NavLink
                  style={({ isActive }) =>
                    isActive
                      ? NavigationHeaderStyles.navItemActive
                      : NavigationHeaderStyles.navItem
                  }
                  to="/professional"
                >
                  {LangEN.SearchProfessionals}
                </NavLink>
              )}
              <NavLink
                style={({ isActive }) =>
                  isActive
                    ? NavigationHeaderStyles.navItemActive
                    : NavigationHeaderStyles.navItem
                }
                to="/projects"
              >
                {LangEN.MyProjects}
              </NavLink>
              {role == UserRoles.Business && (
                <NavLink
                  style={({ isActive }) =>
                    isActive
                      ? NavigationHeaderStyles.navItemActive
                      : NavigationHeaderStyles.navItem
                  }
                  to="/professional"
                >
                  {LangEN.Professionals}
                </NavLink>
              )}
            </Nav>
            <Nav>
              {user?.accStatus && !user?.accStatus?.isProfileVerified && (
                <div
                  className="warning-under-verification"
                  style={{
                    backgroundColor: "#FEF7D1",
                    color: "#553311",
                    borderRadius: 20,
                    height: 40,
                    alignItems: "center",
                    paddingInline: 10,
                    justifyContent: "center",
                    alignSelf: "center",
                    fontWeight: 700,
                    border: "1px solid #F7C752",
                    display: "flex",
                    fontSize: 14,
                    gap: 5,
                  }}
                >
                  <img src={warningIcon} height={22} />
                  <div className="warning-under-verification-text">
                    Profile Under Verification
                  </div>
                </div>
              )}
              <Button
                onClick={() => setNotificationModalShow(true)}
                style={NavigationHeaderStyles.notificationButton}
              >
                <img
                  src={!isUnreadNotification ? notificationEmpty : notification}
                  height={26}
                />
              </Button>
              <div style={NavigationHeaderStyles.divider} />
              <Dropdown>
                <Dropdown.Toggle
                  style={NavigationHeaderStyles.dropdownToggle}
                  id="dropdown-button-dark-example1"
                  variant="light"
                >
                  <div style={{ margin: "5px 8px" }}>
                    <Avatar
                      url={user.user?.profilePicture}
                      size={40}
                      fontSize={18}
                      firstName={getName().split(" ")[0]}
                      lastName={getName().split(" ")[1]}
                    />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  variant="light"
                  className="p-3 fade"
                  style={{
                    translate: -130,
                    width: 260,
                  }}
                >
                  <Dropdown.Item>
                    <NavLink
                      to="/profile"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                      }}
                    >
                      <Row>
                        <Col className="my-3 px-0">
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <div className=" px-0 pe-2">
                              <Avatar
                                url={user.user?.profilePicture}
                                size={60}
                                fontSize={24}
                                firstName={getName().split(" ")[0]}
                                lastName={getName().split(" ")[1]}
                              />
                            </div>
                            <div
                              className="ellipsis"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="ellipsis"
                                style={NavigationHeaderStyles.title}
                              >
                                {getName()}
                              </div>

                              <div className="ellipsis">
                                {data?.companyName && data.companyName}
                                {data?.applyAs && mapJobRole(data.applyAs)}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => {
                      setChangePassModalShow(true);
                    }}
                  >
                    <Row style={NavigationHeaderStyles.row}>
                      <Col
                        className="col-auto p-0 px-2"
                        style={NavigationHeaderStyles.col}
                      >
                        <img width={18} src={password} />
                      </Col>
                      <Col className="p-0 px-3">{LangEN.ChangePassword}</Col>
                    </Row>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>
                    <Row style={NavigationHeaderStyles.row}>
                      <Col
                        className="col-auto p-0 px-2"
                        style={NavigationHeaderStyles.col}
                      >
                        <img width={18} src={logoutIcon} />
                      </Col>
                      <Col className="p-0 px-3">{LangEN.Logout}</Col>
                    </Row>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
