import "./styles.css";
import React from "react";
import Footer from "../../components/sharedComponents/Footer";
import Header from "../../components/sharedComponents/Header";
import BannerImage1 from "../../assets/png/BannerImage1.png";
import BannerImage2 from "../../assets/png/BannerImage2.png";
import projectManager from "../../assets/png/projectManager.png";
import archietect from "../../assets/png/archietect.png";
import experts from "../../assets/png/experts.png";
import CustomButton from "../../components/reusableComponents/CustomButton";
import { WelcomeStyles } from "./WelcomeStyle";
import { Col, Container, Row, Image, Card } from "react-bootstrap";

function Welcome() {
  return (
    <>
      <Header />
      <Container fluid style={WelcomeStyles.container1}>
        <Row style={WelcomeStyles.row1}>
          <Col style={WelcomeStyles.col1} sm={true}>
            <label style={WelcomeStyles.label1}>
              Find the perfect freelance team
              <br /> for your business
            </label>
            <label style={WelcomeStyles.label2}>
              Work with talented people at the most <br />
              affordable price to get the most out of your time and cost.
            </label>
            <CustomButton label="Get Started" className="welcome_button" />
          </Col>
          <Col style={WelcomeStyles.col2} sm={true}>
            <Col className="d-none d-lg-flex gap-3">
              <Image style={WelcomeStyles.image} src={BannerImage1} />
              <Image src={BannerImage2} />
            </Col>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Row>
            <label style={WelcomeStyles.label3}>
              Browse Talent by category
            </label>
            <label style={WelcomeStyles.label4}>
              Discover the many ways in which our clients have embraced the
              benefits of the Beehived network.
            </label>
          </Row>
          <Row style={WelcomeStyles.row2}>
            <Col style={WelcomeStyles.col3} sm>
              <Card className="bg-light text-white">
                <Card.Img src={projectManager} alt="Card image" />
                <Card.ImgOverlay style={WelcomeStyles.imgOverlay}>
                  <Card.Title>Project Managers</Card.Title>
                  <Card.Text>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col style={WelcomeStyles.col3} sm>
              <Card className="bg-light text-white">
                <Card.Img src={archietect} alt="Card image" />
                <Card.ImgOverlay style={WelcomeStyles.imgOverlay}>
                  <Card.Title>Architect</Card.Title>
                  <Card.Text>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col style={WelcomeStyles.col3} sm>
              <Card className="bg-light text-white">
                <Card.Img src={experts} alt="Card image" />
                <Card.ImgOverlay style={WelcomeStyles.imgOverlay}>
                  <Card.Title>Experts</Card.Title>
                  <Card.Text>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Welcome;
