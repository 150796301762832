import React from "react";
import { Navigate } from "react-router-dom";
import { showToast } from "../../App";

function ErrorNavigator() {
  showToast("Page error! Redirecting...");
  return <Navigate to="/" />;
}

export default ErrorNavigator;
