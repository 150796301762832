import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { LangEN } from "../../constants/Lang/LangEN";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../App";

function ProjectCard({
  notProjectRoute,
  title,
  subtitle,
  requiredArchitect,
  requiredExperts,
  requiredPM,
  projectDuration,
  projectId,
  isProfessional,
  userId,
  role,
  unHiredPMCount,
  unHiredArchitectCount,
  unHiredExpertCount,
}: {
  notProjectRoute?: boolean;
  isProfessional?: boolean;
  projectId: number;
  title: string;
  subtitle: string;
  requiredPM: number;
  requiredArchitect: number;
  requiredExperts: number;
  userId?: number | undefined;
  projectDuration: string;
  role?: number;
  unHiredPMCount?: number;
  unHiredArchitectCount?: number;
  unHiredExpertCount?: number;
}) {
  const navigate = useNavigate();
  return (
    <Card className="p-4" style={styles.card}>
      <Card.Title style={styles.title}>{title}</Card.Title>
      <Card.Subtitle style={styles.subTitle}>{subtitle}</Card.Subtitle>
      <div className="horizontal-divider my-3" />
      <Row>
        <Col className="col-4">
          <Card.Title style={styles.title2}>
            {LangEN.RequiredResource}
          </Card.Title>
          <Row className="my-3" styles={styles.label}>
            <Col style={styles.label}>{LangEN.ProjectManager}:</Col>
            <Col className="col-auto" style={styles.blueText}>
              {requiredPM}
            </Col>
          </Row>
          <Row className="my-3">
            <Col style={styles.label}>{LangEN.Architect}:</Col>
            <Col className="col-auto" style={styles.blueText}>
              {requiredArchitect}
            </Col>
          </Row>
          <Row className="my-3">
            <Col style={styles.label}>{LangEN.Experts}:</Col>
            <Col className="col-auto" style={styles.blueText}>
              {requiredExperts}
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <div className="vertical-divider mx-4"></div>
        </Col>
        <Col style={styles.flexBox}>
          <div>
            <Card.Title style={styles.title2}>
              {LangEN.ProjectDuration}
            </Card.Title>
            <div className="my-3" style={styles.blueText}>
              {projectDuration}
            </div>
          </div>
          <Row className="  d-flex justify-content-end">
            {!isProfessional && (
              <Col className="col-auto">
                <CustomButton
                  onClick={() => {
                    navigate("/projects/details", { state: { id: projectId } });
                  }}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #3C65F5",
                    color: "#3C65F5",
                  }}
                  label={LangEN.ViewDetails}
                />
              </Col>
            )}
            <Col className="col-auto">
              <CustomButton
                onClick={() => {
                  if (isProfessional) {
                    if (notProjectRoute) {
                      navigate("/professional/details/project", {
                        state: { userId: userId, id: projectId, role: role },
                      });
                    } else {
                      navigate("/projects/details", {
                        state: { id: projectId },
                      });
                    }
                  } else {
                    if (
                      unHiredArchitectCount != undefined &&
                      unHiredPMCount != undefined &&
                      unHiredExpertCount != undefined &&
                      (unHiredArchitectCount > 0 ||
                        unHiredExpertCount > 0 ||
                        unHiredPMCount > 0)
                    ) {
                      navigate("/projects/details/createjob", {
                        state: {
                          id: projectId,
                          unHiredArchitectCount,
                          unHiredExpertCount,
                          unHiredPMCount,
                        },
                      });
                    } else {
                      showToast(LangEN.NoMoreJobToCreate);
                    }
                  }
                }}
                label={isProfessional ? LangEN.ViewDetails : LangEN.CreateAJob}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

const styles = {
  card: {
    width: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    color: "#05264E",
    fontWeight: 600,
    marginBottom: 15,
  },
  subTitle: {
    fontSize: 18,
    color: "#66789C",
    lineHeight: 1.6,
  },
  title2: {
    fontWeight: 600,
  },
  label: {
    fontSize: 18,
  },
  blueText: {
    color: "#3C65F5",
    fontSize: 18,
  },
  flexBox: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between" as "space-between",
  },
};

export default ProjectCard;
