import { Col, Form, Row } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import CustomCheckButtons from "../../../components/reusableComponents/CustomCheckButton/CustomCheckButtons";
import { resourceType } from "../../../enums/CreateJobEnums";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function Step1({
  step,
  setStep,
  showModal,
  resourceTypeValue,
  setResourceTypeValue,
  isEdit,
}: {
  step: number;
  setStep: Function;
  showModal: Function;
  resourceTypeValue: number;
  setResourceTypeValue: Function;
  isEdit?: boolean;
}) {
  const { state } = useLocation();
  const pmRequired = state?.unHiredPMCount ? state.unHiredPMCount > 0 : false;
  const architectRequired = state?.unHiredArchitectCount
    ? state.unHiredArchitectCount > 0
    : false;
  const expertRequired = state?.unHiredExpertCount
    ? state.unHiredExpertCount > 0
    : false;

  useEffect(() => {
    if (pmRequired) setResourceTypeValue(resourceType.ProjectManager);
    else if (architectRequired) setResourceTypeValue(resourceType.Architect);
    else if (expertRequired) setResourceTypeValue(resourceType.Experts);
  }, []);
  return (
    <div className="createJob_container">
      <Row className="createJob_container_title py-4">
        <Col>{LangEN.WhatRoleYouWouldLikeToHire}</Col>
      </Row>
      <Row className="createJob_contentContainer">
        <Form.Group>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <CustomCheckButtons
                disable={isEdit || !pmRequired}
                label={LangEN.ProjectManager}
                subtitle={LangEN.ProjectManagerSubtitle}
                id={"1"}
                checked={resourceType.ProjectManager == resourceTypeValue}
                onChange={() =>
                  setResourceTypeValue(resourceType.ProjectManager)
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <CustomCheckButtons
                disable={isEdit || !expertRequired}
                label={LangEN.Experts}
                subtitle={LangEN.ExpertsSubtitle}
                id={"2"}
                checked={resourceType.Experts == resourceTypeValue}
                onChange={() => setResourceTypeValue(resourceType.Experts)}
              />
            </Col>
            <Col xs={12} md={6}>
              <CustomCheckButtons
                disable={isEdit || !architectRequired}
                label={LangEN.Architect}
                subtitle={LangEN.ArchitectSubtitle}
                id={"3"}
                checked={resourceType.Architect == resourceTypeValue}
                onChange={() => setResourceTypeValue(resourceType.Architect)}
              />
            </Col>
          </Row>
        </Form.Group>
      </Row>
      <Row className="d-flex align-item-center createJob_spaceBetween gy-3">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="col-auto createJob_index"
        >{`${LangEN.Step} ${step} ${LangEN.Of} 5`}</Col>
        <Col className="col-auto">
          <Row className="gy-2">
            <Col>
              <CustomButton
                className="createJob_cancel px-4"
                label={LangEN.Cancel}
                onClick={() => showModal()}
              />
            </Col>
            <Col>
              <CustomButton
                className="createJob_next px-4"
                label={LangEN.Next}
                iconType="next"
                onClick={() => {
                  setStep(2);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step1;
