import Theme from "../../constants/Theme";

export const ProfileStyles = {
  container: {
    minWidth: "100vw",
    backgroundColor: Theme.backgroundColor.CONTAINERBACKGROUND,
    paddingInline: "5vw",
  },
  container2: {
    minWidth: "100vw",
    backgroundColor: Theme.backgroundColor.CONTAINERBACKGROUND,
    padding: "50px",
    paddingInline: "5vw",
  },
  heading: { fontWeight: 600, fontSize: 22 },
  card: {
    width: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    minHeight: "70vh",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  card1: {
    minWidth: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    borderRadius: 10,
    maxWidth: 280,
    paddingInline: 30,
  },
  card2: {
    maxWidth: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    borderRadius: 10,
    // textAlign: "center" as "center",
  },
  card3: {
    width: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    minHeight: "50vh",
    borderRadius: 10,
  },
  card4: {
    maxWidth: 800,
    minWidth: "min(100vw , 500px)",
  },
  cardTitle: { fontWeight: 700, fontSize: 22 },
  cardSubTitle: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center" as "center",
  },
  FAB: {
    position: "absolute" as "absolute",
    right: 15,
    top: 15,
    borderRadius: 30,
    width: 40,
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    aspectRatio: 1,
    backgroundColor: "#3c65f5",
    boxShadow: "0 10px 10px #3c65f555",
  },
  FAB2: {
    position: "absolute" as "absolute",
    right: 30,
    top: 20,
    borderRadius: 30,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    aspectRatio: 1,
    backgroundColor: "#3c65f5",
    boxShadow: "0 10px 10px #3c65f555",
  },
  title: { fontWeight: 700, fontSize: 22 },
  subTitle: { color: "#888" },
  divider: {
    width: "100%",
    backgroundColor: "#ddd",
    height: 1,
    alignSelf: "center",
  },
  subTitle2: { color: "#222" },
  title2: { fontSize: 24, fontWeight: 600 },
  subTitle3: {
    fontSize: 14,
    color: "#888",
    lineHeight: 1.7,
  },
  skillsContainer: {
    display: "flex",
    maxWidth: 700,
    flexWrap: "wrap" as "wrap",
    gap: 15,
    fontSize: 14,
    color: "#888",
  },
  detailsContainer: {
    fontWeight: 500,
    color: "#66789c",
    fontSize: 17,
    maxWidth: 650,
  },
  spanText: {
    color: "#000",
    fontWeight: "bold",
  },
  text: {
    fontWeight: 500,
    color: "#66789c",
    fontSize: 17,
  },
  header: {
    textDecoration: "underline",
    fontWeight: "600",
    color: "#66789c",
    fontSize: 22,
    letterSpacing: 0.9,
  },
  title3: { fontWeight: 600, fontSize: 17 },
  title4: { fontWeight: 600, fontSize: 18 },
  title5: { fontSize: 20, fontWeight: 600 },
  subTitle4: {
    fontSize: 15,
    color: "#66789c",
    lineHeight: 1.5,
  },
  subTitle5: { color: "#66789c" },
};
