import Http from "./HttpService";
import { API } from "../constants/ApiDetails";
import { ISignUp } from "../Interfaces/ISignUp";
import { ILogIn } from "../Interfaces/ILogIn";
import { IChangePassword } from "../Interfaces/IChangePassword";

class AuthService {
  public static async SignUp(signUpDetails: ISignUp) {
    const url = `${API.SIGN_UP}`;
    const response = await Http.post(url, signUpDetails);
    return response;
  }
  public static async LogIn(logInDetails: ILogIn) {
    const url = `${API.LOGIN}`;
    const response = await Http.post(url, logInDetails);
    return response;
  }

  public static async SendForgotPasswordOTP(apiReq: any) {
    const url = `${API.SEND_FORGOT_PASSWORD_OTP}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async VerifyForgotPasswordOTP(apiReq: any) {
    const url = `${API.VERIFY_FORGOT_PASSWORD_OTP}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async UpdatePassword(apiReq: any) {
    const url = `${API.UPDATE_PASSWORD}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async ChangePassword(apiReq: IChangePassword) {
    const url = `${API.CHANGE_PASSWORD}`;
    const response = await Http.post(url, apiReq);
    return response;
  }
}

export default AuthService;
