import React, { useEffect, useState } from "react";
import { Container, Card, Col, Row, Dropdown } from "react-bootstrap";
import CustomButton from "../../components/reusableComponents/CustomButton";
import { LangEN } from "../../constants/Lang/LangEN";
import week from "../../assets/svg/icon/week.svg";
import time from "../../assets/svg/icon/time.svg";
import EditJob from "../../assets/svg/icon/EditJob.svg";
import DeleteJob from "../../assets/svg/icon/DeletJob.svg";
import JobExpired from "../../assets/svg/icon/JobExpired.svg";

import "./styles.css";
import TextAvatar from "../../components/reusableComponents/TextAvatar";
import { LightChip } from "../../components/reusableComponents/LightChip";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import JobService from "../../services/JobService";
import moment from "moment";
import UserService from "../../services/UserService";
import { CustomToggle } from "../../Utils/CustomDropdownToggle";
import CustomModal from "../../components/Modals/CustomModal";
import {
  jobStartBy,
  mapJobDuration,
  mapJobRole,
  mapWorkExperience,
  requiredResourceType,
  setCurrencyType,
  setPaymentDuration,
  skillsLevelRequired,
} from "../../Utils/Mappers";
import JobInvitationService from "../../services/JobInvitationService";
import { invitationStatus } from "../../enums/InvitationStatus";
import Loader from "../../components/reusableComponents/Loader";
import { useContext } from "react";
import { User, BreadCrumbsContext } from "../../App";
import {
  jobStatus,
  resourceType,
  projectType,
} from "../../enums/CreateJobEnums";
import Skeleton from "react-loading-skeleton";
import { Prev } from "react-bootstrap/esm/PageItem";
import {
  remoteResourceArray,
  typeOfProjectArray,
} from "../../constants/enumLabel";
import JobStatusChip from "../../components/reusableComponents/JobStatusChip";
import Avatar from "../../components/reusableComponents/Avatar";

function JobDetails({
  isProfessional,
  notProjectRoute,
}: {
  notProjectRoute?: boolean;
  isProfessional?: boolean;
}) {
  const bread = useContext(BreadCrumbsContext);
  const { state } = useLocation();
  const user = useContext(User);
  const [jobDetail, setJobDetail] = useState<any>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<any>();
  const [companyLoading, setCompanyLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  // const { id } = useParams();
  const navigate = useNavigate();
  const isExpert =
    user?.user?.applyAs && user.user.applyAs == resourceType.Experts;

  useEffect(() => {
    const breadDataProjectRoute = [
      {
        label: LangEN.MyProjects,
        route: "/projects",
      },
      {
        label: LangEN.ProjectDetails,
        route: "/projects/details",
        state: { id: state.project.id },
      },

      {
        label: LangEN.JobDetails,
        route: "/",
      },
    ];
    JobService.GetJobById(state.id)
      .then((res) => {
        setJobDetail(res.data);
        console.log(res.data);
        const breadData = [
          {
            label: LangEN.Professionals,
            route: "/professional",
          },
          {
            label: LangEN.ProfessionalDetails,
            route: "/professional/details",
            state: {
              id: state.userId,
              application: { id: 1, jobSorting: true },
              showAction: false,
            },
          },
          {
            label: LangEN.Invite,
            route: "/professional/details/project",
            state: {
              id: res.data?.projectId,
            },
          },
          {
            label: LangEN.SendInvite,
            route: "/",
          },
        ];
        if (notProjectRoute) {
          bread.setData(breadData);
        } else {
          bread.setData(breadDataProjectRoute);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      bread.setData([]);
    };
  }, []);

  useEffect(() => {
    UserService.GetCompanyProfileById(state?.project?.createdBy)
      .then((res) => {
        setCompanyInfo(res.data);
        setCompanyLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sendInvite = () => {
    let apiReq = {
      invitee: state.userId,
      jobId: jobDetail.id,
      invitationStatus: invitationStatus.Invited,
    };
    JobInvitationService.SendInvite(apiReq)
      .then((res) => {
        setShowSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteJob = () => {
    JobService.DeleteJob(state.id)
      .then((res) => {
        navigate("/projects");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markJobExpired = () => {
    JobService.MarkJobExpired(state.id)
      .then((res) => {
        setJobDetail((prevState: any) => ({
          ...prevState,
          jobStatus: jobStatus.Expired,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markJobActive = () => {
    JobService.MarkJobActive(state.id)
      .then((res) => {
        setJobDetail((prevState: any) => ({
          ...prevState,
          jobStatus: jobStatus.Active,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="main_container">
      <CustomModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        label={LangEN.InvitationSentSuccessfully}
        type="success"
        buttonText={LangEN.OK}
        action={() => {
          navigate(-2);
        }}
      />
      {loading ? (
        <Loader lg />
      ) : (
        <>
          {!notProjectRoute && !isExpert && (
            <Row className="gap-2">
              <Col className="col-auto">
                <CustomButton
                  className="jobDetails_primaryButton"
                  label={LangEN.JobDetails}
                />
              </Col>
              <Col className="col-auto">
                <CustomButton
                  onClick={() => {
                    navigate("/projects/details/jobapplications", {
                      state: { id: state.id, project: state.project },
                    });
                  }}
                  className="jobDetails_secondaryButton"
                  label={LangEN.JobApplications}
                />
              </Col>
              {!isProfessional && (
                <Col className="d-flex justify-content-end">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-basic"
                    ></Dropdown.Toggle>

                    <Dropdown.Menu className="mt-3">
                      {jobDetail?.isJobEditable ? (
                        <Dropdown.Item
                          onClick={() => {
                            navigate("/projects/details/editjob", {
                              state: {
                                jobId: state.id,
                                id: state.project.id,
                              },
                            });
                          }}
                        >
                          <Row
                            className="d-flex gap-0 my-2"
                            style={{ flexWrap: "nowrap" }}
                          >
                            <Col
                              xs="auto"
                              className="d-flex align-items-center me-0 pe-0"
                            >
                              <img src={EditJob} width={20} />
                            </Col>
                            <Col className="d-flex align-items-center jobDetails_items">
                              {LangEN.EditJobDetails}
                            </Col>
                          </Row>
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      <Dropdown.Item>
                        <Row
                          className="d-flex gap-0 my-2"
                          style={{ flexWrap: "nowrap" }}
                          onClick={() => deleteJob()}
                        >
                          <Col
                            xs="auto"
                            className="d-flex align-items-center me-0 pe-0"
                          >
                            <img src={DeleteJob} width={20} />
                          </Col>
                          <Col className="d-flex align-items-center jobDetails_items">
                            {LangEN.DeleteJob}
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      {/* {jobDetail?.jobStatus !== jobStatus.Expired ? ( */}
                      <Dropdown.Item>
                        <Row
                          className="d-flex gap-0 my-2"
                          style={{ flexWrap: "nowrap" }}
                          onClick={() => {
                            jobDetail?.jobStatus !== jobStatus.Expired
                              ? markJobExpired()
                              : markJobActive();
                          }}
                        >
                          <Col
                            xs="auto"
                            className="d-flex align-items-center me-0 pe-0"
                          >
                            <img src={JobExpired} width={20} />
                          </Col>
                          <Col className="d-flex align-items-center jobDetails_items">
                            {jobDetail?.jobStatus !== jobStatus.Expired
                              ? LangEN.MarkJobExpired
                              : LangEN.MarkJobActive}
                          </Col>
                        </Row>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
            </Row>
          )}
          <Card className="cardShadow my-3 p-3 p-md-4">
            <Row>
              <Col>
                <Card.Title className="jobDetails_title">
                  {`${mapJobRole(
                    jobDetail?.resourceType
                  )}, ${requiredResourceType(jobDetail?.requiredResourceType)}`}
                  <span className="mx-2">
                    {jobDetail?.jobStatus && (
                      <JobStatusChip status={jobDetail.jobStatus} />
                    )}
                  </span>
                </Card.Title>
              </Col>
              {/* {isProfessional && (
              <Col className="d-flex align-items-center" xs="auto">
                <CustomButton label={LangEN.SendInvitation} />
              </Col>
            )} */}
              {notProjectRoute &&
                state.role == jobDetail.resourceType &&
                jobDetail.jobStatus == jobStatus.Active && (
                  <Col className="d-flex align-items-center" xs="auto">
                    <CustomButton
                      onClick={() => sendInvite()}
                      label={LangEN.SendInvitation}
                    />
                  </Col>
                )}
            </Row>
            <Row className="my-2">
              {/* <Col className="col-auto" style={styles.smallLabel}>
              <span className="me-2">
                <img src={week} width={17} />
              </span>
              {mapJobDuration(jobDetail?.jobDuration)}
            </Col> */}
              <Col className="col-auto" style={styles.smallLabel}>
                {/* <span className="me-2">
                <img src={time} width={17} />
              </span> */}
                {LangEN.PostedOn}
                {/* {moment(jobDetail?.createdAt).fromNow(true)} */}
                {moment(jobDetail?.createdAt).format("Do MMM YYYY")}
              </Col>
            </Row>
            <div className="horizontal-divider my-4"></div>
            <h3 className="jobDetails_heading">{LangEN.JobDescription}</h3>
            <p className="jobDetails_details">{jobDetail?.description}</p>
            <div className="horizontal-divider my-4"></div>
            <h3 className="jobDetails_heading">{LangEN.AboutCompany}</h3>
            <Row className="d-flex gap-3 align-items-center">
              <Col className="col-auto">
                <h3 className="jobDetails_headingSmall mt-2  d-flex align-items-center gap-2">
                  {!companyLoading ? (
                    <Avatar
                      url={companyInfo?.profilePicture}
                      size={32}
                      fontSize={14}
                      firstName={companyInfo.companyName.split(" ")[0]}
                      lastName={companyInfo.companyName.split(" ")[1]}
                    />
                  ) : (
                    <Skeleton circle style={{ width: 32, aspectRatio: 1 }} />
                  )}
                  {companyLoading ? (
                    <Skeleton style={{ width: 100 }} />
                  ) : (
                    companyInfo?.companyName
                  )}
                </h3>
              </Col>
              <Col className="col-auto">
                {!companyLoading ? (
                  <>
                    <label className="jobDetails_label">
                      {LangEN.FoundedIn} -{" "}
                    </label>
                    <span className="jobDetails_labelSpan">
                      {/* {new Date(companyInfo?.foundedin).getFullYear()} */}
                      {companyInfo?.foundedin
                        ? " " +
                          moment(companyInfo.foundedin).format("MMMM YYYY")
                        : LangEN.NotSpecified}
                    </span>
                  </>
                ) : (
                  <Skeleton style={{ width: 120 }} />
                )}
              </Col>
            </Row>
            <p className="jobDetails_details">
              {companyLoading ? <Skeleton count={2} /> : companyInfo?.about}
            </p>
            <div className="horizontal-divider my-4"></div>
            <h3 className="jobDetails_heading">
              {LangEN.EmploymentInformation}
            </h3>
            <Row className="my-3">
              <Col className="col-12 col-md-5 ">
                {/* <Row className="my-3">
              <Col className="col-5 jobDetails_blueText">{LangEN.Category}</Col>
              <Col className="col-7 jobDetails_text">Development</Col>
            </Row> */}
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.Budget}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {" "}
                    {`${setCurrencyType(jobDetail?.currencyType)}${
                      jobDetail?.budget
                    }${setPaymentDuration(jobDetail?.paymentDuration)}`}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.ResourceNeed}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {mapJobDuration(jobDetail?.jobDuration)}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.SkillRequired}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {jobDetail?.skills?.length == 0 &&
                      jobDetail?.customSkill.length == 0 &&
                      LangEN.NotSpecified}
                    <div className="d-flex gap-2" style={{ flexWrap: "wrap" }}>
                      {jobDetail?.skills.map((item: any, index: number) => (
                        <LightChip index={index} label={item.skillName.name} />
                      ))}
                      {jobDetail?.customSkill.map(
                        (item: any, index: number) => (
                          <LightChip index={index} label={item.skillName} />
                        )
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-2 d-none d-md-flex justify-content-center">
                <div className="vertical-divider"></div>
              </Col>
              <Col className="col-12 col-md-5">
                <Row className="mb-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.WhenStart}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {jobStartBy(jobDetail?.jobStartBy)}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.Experience}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {mapWorkExperience(jobDetail?.skillLevel)}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">{LangEN.WFH}</Col>
                  <Col className="col-7 jobDetails_text">
                    {remoteResourceArray[jobDetail?.remoteResource]}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="col-5 jobDetails_blueText">
                    {LangEN.ProjectType}
                  </Col>
                  <Col className="col-7 jobDetails_text">
                    {typeOfProjectArray[jobDetail?.projectType]}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </Container>
  );
}

const styles = {
  smallLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: " #66789C",
  },
};

export default JobDetails;
