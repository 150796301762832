import { useState } from "react";
import { Outlet } from "react-router-dom";
import EmailVerification from "../../screens/EmailVerifications/EmailVerifications";

function EmailVerificationRouter() {
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    if (!isEmailVerified) {
        return <EmailVerification />;
    } else {
        return <Outlet />;
    }
}

export default EmailVerificationRouter;
