import React from "react";
import { LangEN } from "../../constants/Lang/LangEN";

function StatusChip({ status }: { status: String }) {
  if (status == "busy")
    return (
      <div
        style={{
          border: "1px solid #FF0000",
          borderRadius: "30px",
          color: "#FF0000",
          fontSize: 13,
          width: "fit-content",
          padding: "2px 20px",
          height: "fit-content",
        }}
      >
        {LangEN.Busy}
      </div>
    );
  if (status == "verified")
    return (
      <div
        style={{
          border: "1px solid #00A938",
          borderRadius: "30px",
          color: "#00A938",
          fontSize: 13,
          width: "fit-content",
          padding: "2px 15px",
          height: "fit-content",
        }}
      >
        {LangEN.Verified}
      </div>
    );

  return (
    <div
      style={{
        border: "1px solid #00A938",
        borderRadius: "30px",
        color: "#00A938",
        fontSize: 13,
        width: "fit-content",
        padding: "2px 15px",
        height: "fit-content",
      }}
    >
      {LangEN.Available}
    </div>
  );
}

export default StatusChip;
