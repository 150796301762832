import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import CustomButton from "../../components/reusableComponents/CustomButton";
import "./styles.css";
import { useContext, useState, useEffect } from "react";
import CustomModal from "../../components/Modals/CustomModal";
import CustomNumberInput from "../../components/reusableComponents/CustomNumberInput";
import ProjectService from "../../services/ProjectServices";
import { dateParser } from "../../Utils/helper";
import { useNavigate } from "react-router-dom";
import { BreadCrumbsContext } from "../../App";

function CreateProject() {
  const bread = useContext(BreadCrumbsContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [projectManagerCount, setProjectManagerCount] = useState(1);
  const [architectCount, setArchitectCount] = useState(1);
  const [expertCount, setExpertCount] = useState(1);
  const [projectTitle, setProjectTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isPMenabled, setIsPMenabled] = useState(true);
  const [isArchitectEnabled, setIsArchitectEnabled] = useState(false);
  const [isExpertEnabled, setIsExpertEnabled] = useState(false);
  const [resourceCountError, setResourceCountError] = useState("sdf");

  const breadData = [
    {
      label: LangEN.MyProjects,
      route: "/projects",
    },
    {
      label: LangEN.Create,
      route: "/",
    },
  ];

  useEffect(() => {
    if (isPMenabled || isArchitectEnabled) {
      setResourceCountError("");
    } else {
      setResourceCountError(
        "At least 1 Project Manager or Architect is required in the project"
      );
    }
  }, [isPMenabled, isArchitectEnabled, isExpertEnabled]);

  useEffect(() => {
    bread.setData(breadData);

    return () => {
      bread.setData([]);
    };
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;

    let reqBody = {
      name: projectTitle,
      projectDescription: description,
      projectStartDate: dateParser(startDate),
      projectEndDate: dateParser(endDate),
      projectMAnager: isPMenabled ? projectManagerCount : 0,
      architect: isArchitectEnabled ? architectCount : 0,
      expert: isExpertEnabled ? expertCount : 0,
    };
    setIsLoading(true);
    ProjectService.CreateProject(reqBody)
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        setModalShown(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Container className="main_container">
      <CustomModal
        show={modalShown}
        setShow={setModalShown}
        label={LangEN.ProjectCreatedSuccessfully}
        type="success"
        buttonText={LangEN.OK}
        action={() => {
          navigate("/projects");
        }}
      />
      <h3 className="mb-4 createProject_header">{LangEN.CreateProjectOnly}</h3>
      <Card className="px-4 py-4 px-md-5 cardShadow">
        <Form onSubmit={onSubmit}>
          <Card.Title className=" createProject_title">
            {LangEN.ProjectTitle}
          </Card.Title>
          <Form.Control
            required
            maxLength={100}
            value={projectTitle}
            onChange={(e) => setProjectTitle(e.target.value)}
            className="dim-placeholder createProject_input shadow-none"
            placeholder={LangEN.ProjectTitle}
          />
          <Card.Title className="mt-4 createProject_title">
            {LangEN.Description}
          </Card.Title>
          <Form.Control
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            as={"textarea"}
            maxLength={500}
            className="dim-placeholder createProject_textArea shadow-none"
            placeholder={LangEN.AddProjectDescription}
          />
          <Row className="mt-4">
            <Col className="col-12 col-lg-5 mb-3">
              <Row className="mb-3">
                <Col className="createProject_title">{LangEN.Role}</Col>

                <Col className="createProject_title">{LangEN.Resource}</Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center">
                  <Form.Check
                    className="createProject_check"
                    label={LangEN.ProjectManager}
                    checked={isPMenabled}
                    onChange={(e) => {
                      setIsPMenabled(e.target.checked);
                    }}
                  />
                </Col>

                <Col>
                  <CustomNumberInput
                    value={projectManagerCount}
                    setValue={setProjectManagerCount}
                    disabled={!isPMenabled}
                  />
                </Col>
              </Row>
              <div className="horizontal-divider my-3" />
              <Row>
                <Col className="d-flex align-items-center">
                  <Form.Check
                    className="createProject_check"
                    label={LangEN.Architect}
                    checked={isArchitectEnabled}
                    onChange={(e) => {
                      setIsArchitectEnabled(e.target.checked);
                    }}
                  />
                </Col>

                <Col>
                  <CustomNumberInput
                    disabled={!isArchitectEnabled}
                    value={architectCount}
                    setValue={setArchitectCount}
                  />
                </Col>
              </Row>
              <div className="horizontal-divider my-3" />
              <Row>
                <Col className="d-flex align-items-center">
                  <Form.Check
                    className="createProject_check"
                    label={LangEN.Experts}
                    checked={isExpertEnabled}
                    onChange={(e) => {
                      setIsExpertEnabled(e.target.checked);
                    }}
                  />
                </Col>

                <Col>
                  <CustomNumberInput
                    value={expertCount}
                    setValue={setExpertCount}
                    disabled={!isExpertEnabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="invalid-feedback d-block pt-3">
                    {resourceCountError}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="col-2 d-lg-flex justify-content-center d-none">
              <div className="vertical-divider" />
            </Col>
            <Col className="col-12 col-lg-5">
              <Card.Title className="mb-2 createProject_title">
                {LangEN.ProjectDuration}
              </Card.Title>
              <Row>
                <Col>
                  <Card.Text className="mb-0 createProject_dateLabel">
                    {LangEN.StartDate}
                  </Card.Text>
                  <Form.Control
                    required
                    className="shadow-none dim-placeholder createProject_dateInput"
                    type="date"
                    max={endDate && endDate}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Col>
                <Col>
                  <Card.Text className="mb-0">{LangEN.EndDate}</Card.Text>
                  <Form.Control
                    required
                    min={startDate && startDate}
                    className="shadow-none dim-placeholder createProject_dateInput"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col className=" d-flex justify-content-end">
                  <CustomButton
                    className="createProject_cancelButton my-2 "
                    label={LangEN.Cancel}
                    onClick={() => navigate(-1)}
                  />
                </Col>
                <Col className=" d-flex justify-content-end col-auto">
                  <CustomButton
                    isLoading={isLoading}
                    disabled={
                      !(
                        startDate &&
                        endDate &&
                        projectTitle.trim() &&
                        description.trim() &&
                        !resourceCountError
                      )
                    }
                    className="createProject_createButton my-2"
                    label={LangEN.Create}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
}

export default CreateProject;
