import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import TextAvatar from "./TextAvatar";
import CustomButton from "./CustomButton";
import { LangEN } from "../../constants/Lang/LangEN";

function InviteUserCard({ index, item }: { item: any; index: number }) {
  return (
    <Card className="cardShadow my-4 p-2 px-4 ">
      <Row key={index} className="my-3">
        <Col xs="auto">
          <TextAvatar size={70} fontSize={22} name="A B" />
        </Col>
        <Col className="d-flex align-items-center">
          <Row>
            <Col className="ps-1">
              <div style={{ fontWeight: 600, fontSize: 18, color: "#05264E" }}>
                Andrew Verma
              </div>
              <div style={{ fontSize: 14, color: "#66789C" }}>
                Project Manager
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex align-items-center" xs="auto">
          <CustomButton
            style={{
              height: 50,
              paddingInline: "4vw",
              backgroundColor: "#3C65F5",
              boxShadow: "0px 15px 10px rgba(60, 101, 245, 0.2)",
            }}
            label={LangEN.Invite}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default InviteUserCard;
