import React from "react";
import { INotification } from "../Interfaces/INotification";
import { notificationType } from "../enums/NotificationTypes";
import { useNavigate } from "react-router-dom";
import JobService from "../services/JobService";
import moment from "moment";
import NotificationService from "../services/NotificationService";

function NotificationCard({
  index,
  item,
  setNotification,
  onClick,
}: {
  item: INotification;
  index: number;
  onClick: Function;
  setNotification: Function;
}) {
  const navigate = useNavigate();

  const notificationRouting = (item: INotification) => {
    onClick(item);
    readNotification(item.id);
    if (item.notificationType == notificationType.JobInvitation) {
      let { jobId, invitedBy } = JSON.parse(item.data);
      navigate(`/redirect/${jobId}/${invitedBy}`);
    } else if (
      item.notificationType == notificationType.JobApplicationReceived
    ) {
      let { jobId } = JSON.parse(item.data);
      navigate(`/redirect/${jobId}`);
    } else {
      console.log("item=========>", item);
    }
  };

  const readNotification = (id: number) => {
    NotificationService.MarkSingleNotificationsRead(id)
      .then((res) => {
        // setNotification(res.data);
        setNotification((pre: any) => {
          let newArr: any = [];
          pre.map((notification: any) => {
            if (item.id == notification.id) {
              notification.isRead = true;
            }
            newArr.push(notification);
          });
          return newArr;
        });
        item.isRead = true;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {!!index && <div className="horizontal-divider"></div>}
      <div
        style={{
          cursor: "pointer",
          background: item.isRead ? "#FFF" : "#eee",
        }}
        className="p-3 px-4"
        onClick={() => notificationRouting(item)}
      >
        <div className="title2 mb-2">{item.title}</div>
        <div className="subtitle">{item.message}</div>
        <div
          style={{ opacity: 0.7, fontSize: 14 }}
          className="d-flex justify-content-end"
        >
          {" "}
          {moment(item?.createdAt).fromNow(true)}
          {" ago"}
        </div>
      </div>
    </>
  );
}

export default NotificationCard;
