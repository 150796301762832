import cross from "../../assets/svg/icon/cross.svg";
export function Chip({
    label,
    index,
    onClose,
}: {
    label: string;
    index: number;
    onClose?: any;
}) {
    return (
        <div
            key={index + label}
            style={{
                backgroundColor: "#3C65F5",
                display: "flex",
                color: "#fff",
                padding: !onClose ? "5px 10px" : "4px 8px",
                borderRadius: 6,
                alignItems: "center",
                fontSize: 16,
                gap: 8,
            }}
        >
            {label}
            {onClose && (
                <img
                    onClick={() => {
                        if (onClose) onClose();
                    }}
                    width={12}
                    src={cross}
                />
            )}
        </div>
    );
}
