export function LightChip({
  label,
  index,
  sm,
  haveBorder,
}: {
  label: string;
  index: number;
  sm?: boolean;
  haveBorder?: boolean;
}) {
  return (
    <div
      key={index + label}
      className="ellipsis"
      style={{
        background: haveBorder ? " #F2F6FD" : "#FFFFFF",
        border: "1px solid rgba(90, 106, 163, 0.1)",
        boxShadow: !haveBorder ? "0px 2px 2px rgba(0, 0, 0, 0.1)" : "none",
        borderRadius: 5,
        display: "flex",
        color: "#4F5E64",
        padding: "4px 8px",
        alignItems: "center",
        fontSize: 15,
        height: sm ? 25 : 40,
        gap: 8,
      }}
    >
      {label}
    </div>
  );
}
