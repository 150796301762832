import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";

function UnProtectedRoutes() {
  const [token, setToken] = useState(localStorage.getItem("loginToken"));
  const [user, setUser] = useState(localStorage.getItem("user"));

  useEffect(() => {
    if (!user) {
      localStorage.clear();
      sessionStorage.clear();
    }
    if (token) return;
    removeSW();
  }, []);

  const removeSW = () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      registrations.forEach(function (registration) {
        registration.unregister();
        console.log("==>", "removed sw");
      });
    });
  };

  if (token && user) {
    return <Navigate to="/projects" replace />;
  } else {
    return <Outlet />;
  }
}

export default UnProtectedRoutes;
