import { Outlet } from "react-router-dom";
import { UserRoles } from "../../enums/UserRoles";
import ErrorPage from "../../screens/Error/ErrorPage";
const JobRouter = ({ role }: { role: number }) => {
    if (role == UserRoles.Professional) {
        return <Outlet />;
    } else return <ErrorPage />;
};

export default JobRouter;
