import React from "react";
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import error from "../../assets/svg/icon/errorRed.svg";

function CustomToast({
  isShown,
  hide,
  message,
}: {
  isShown: boolean;
  hide: Function;
  message: string;
}) {
  return (
    <ToastContainer style={{ margin: "10px" }} position={"bottom-center"}>
      <Toast
        style={{
          backgroundColor: "white",
        }}
        show={isShown}
        onClose={() => hide()}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <Row>
            <Col className="px-3" xs="auto">
              <img src={error} height={22} />
            </Col>
            <Col
              className="d-flex align-items-center"
              style={{
                color: "#05264E",
                fontWeight: 600,
                fontSize: 16,
                lineHeight: 1,
              }}
            >
              {message}
            </Col>
          </Row>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default CustomToast;
