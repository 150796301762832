import { useEffect, useState, useRef } from "react";
import "../Jobs/styles.css";
import searchIcon from "../../assets/svg/icon/whiteSearch.svg";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import Filter from "../Jobs/Filter";
import Loader from "../../components/reusableComponents/Loader";
import ProfessionalCard from "../../components/reusableComponents/ApplicationCard/ProfessionalCard";
import close from "../../assets/svg/icon/cross.svg";
import UserService from "../../services/UserService";
import filterImg from "../../assets/svg/filter.svg";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import EmptyList from "../../components/reusableComponents/EmptyList";
import {
  currencyType as currType,
  paymentDuration as payDuration,
} from "../../enums/CreateJobEnums";

function Professionals({
  isProfessional,
  isProjectRoute,
}: {
  isProjectRoute?: boolean;
  isProfessional?: boolean;
}) {
  const { state } = useLocation();
  const [roles, setRoles] = useState<any>(
    state?.resourceType ? [state.resourceType] : []
  );
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState<number>();
  const [availability, setAvailability] = useState<number>();
  const [currencyType, setCurrencyType] = useState(currType.Default);
  const [paymentDuration, setPaymentDuration] = useState(payDuration.Default);
  const [hourlyRate, setHourlyRate] = useState([1, 1000]);
  const [isLoading, setIsLoading] = useState(true);
  const [professionals, setProfessionals] = useState<any>([]);
  const [take, setTake] = useState(30);
  const [skip, setSkip] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const [haveMoreData, setHaveMoreData] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const scrollRef = useRef();
  const [search, setSearch] = useState("");

  const handleScroll = () => {
    if (scrollLoading) return;
    if (!haveMoreData) return;
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const bottom = scrollTop + clientHeight >= scrollHeight - 10;
      if (bottom) {
        setScrollLoading(true);
        console.log("fetching more data", skip);
        fetchData();
      }
    }
  };

  useEffect(() => {
    //Payload
    setHaveMoreData(true);
    setSkip(0);
    let apiReq = {
      skip: 0,
      take: take,
      search: search,
      filterRole: roles,
      experience: experience,
      availability: availability,
      hourlyRateLowerLimit: hourlyRate[0],
      hourlyRateUpperLimit: hourlyRate[1],
      skills: skills,
      currencyType: +currencyType === currType.Default ? null : +currencyType,
      paymentDuration:
        +paymentDuration === payDuration.Default ? null : +paymentDuration,
    };
    setIsLoading(true);

    UserService.ProfessionalFilter(apiReq)
      .then((res) => {
        setProfessionals(res.data);
        setSkip(take);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    skills,
    availability,
    hourlyRate,
    experience,
    search,
    roles,
    currencyType,
    paymentDuration,
  ]);

  const fetchData = () => {
    let apiReq = {
      skip: skip,
      take: take,
      search: search,
      filterRole: roles,
      experience: experience,
      availability: availability,
      hourlyRateLowerLimit: hourlyRate[0],
      hourlyRateUpperLimit: hourlyRate[1],
      skills: skills,
      currencyType: +currencyType === currType.Default ? null : +currencyType,
      paymentDuration:
        +paymentDuration === payDuration.Default ? null : +paymentDuration,
    };
    UserService.ProfessionalFilter(apiReq)
      .then((res) => {
        setScrollLoading(false);
        if (res.data.length == 0) return setHaveMoreData(false);
        setSkip((pre) => pre + take);
        setProfessionals((pre: any[]) => [...pre, ...res.data]);
      })
      .catch((err) => {
        setScrollLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (professionals.length == 0) return;
    handleScroll();
  }, [professionals]);

  return (
    <Container className="main_container">
      <Modal
        className="filterModal d-block d-lg-none"
        show={filterModal}
        onHide={() => setFilterModal(false)}
      >
        <Row className="d-block d-lg-none">
          <Col className="d-flex justify-content-end">
            <img
              src={close}
              width={20}
              onClick={() => setFilterModal(false)}
              style={{ cursor: "pointer", filter: "invert()" }}
            />{" "}
          </Col>
        </Row>
        <Filter
          currencyTypeValue={currencyType}
          setCurrencyTypeValue={setCurrencyType}
          paymentDurationValue={paymentDuration}
          setPaymentDurationValue={setPaymentDuration}
          skills={skills}
          setSkills={setSkills}
          experience={experience}
          setAvailability={setAvailability}
          setExperience={setExperience}
          setHourlyRate={setHourlyRate}
          setRoles={setRoles}
          hourlyRate={hourlyRate}
          availability={availability}
          roles={roles}
          roleDisabled={!!state?.resourceType}
        />
      </Modal>
      <Row>
        <Col className="job_header">
          {LangEN.SearchOnly}
          <span className="job_headerSpan">{LangEN.Professionals}</span>
        </Col>
      </Row>
      <Row>
        <Col className="">
          <Card className="cardShadow p-2 ps-3 mx-2 mx-md-5 my-4">
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Control
                  className="job_search shadow-none"
                  placeholder={LangEN.SearchName}
                  onChange={debounce((e: any) => {
                    setSearch(e.target.value);
                  }, 1000)}
                />
              </Col>
              <Col xs="auto">
                <Button className="p-2 px-3">
                  <img src={searchIcon} width={26} />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ pointerEvents: isLoading ? "none" : undefined }}
          className="d-none d-lg-block"
          xs={3}
        >
          <Filter
            currencyTypeValue={currencyType}
            setCurrencyTypeValue={setCurrencyType}
            paymentDurationValue={paymentDuration}
            setPaymentDurationValue={setPaymentDuration}
            skills={skills}
            setSkills={setSkills}
            experience={experience}
            setAvailability={setAvailability}
            setExperience={setExperience}
            setHourlyRate={setHourlyRate}
            setRoles={setRoles}
            hourlyRate={hourlyRate}
            availability={availability}
            roles={roles}
            roleDisabled={!!state?.resourceType}
          />
        </Col>
        <Col xs={12} lg={9}>
          <Row className="my-3 mb-2 mx-2">
            <Col className={"job_title"}>{LangEN.Professionals}</Col>
            <Col className="col-auto d-flex gap-3">
              <img
                className="d-block d-lg-none"
                onClick={() => setFilterModal(true)}
                src={filterImg}
                width={26}
              />
            </Col>
          </Row>
          <Row
            style={{
              overflowY: "auto",
              maxHeight: "125vh",
              minHeight: "400px",
            }}
            ref={scrollRef}
            onScroll={handleScroll}
          >
            {isLoading ? (
              <Loader lg />
            ) : professionals.length == 0 ? (
              <EmptyList />
            ) : (
              <>
                {professionals.map((item: any, index: number) => (
                  <ProfessionalCard
                    isProjectRoute={isProjectRoute}
                    isProfessional={isProfessional}
                    user={item}
                  />
                ))}
                {scrollLoading && <Loader />}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Professionals;
