import { Col, Form, Row } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import CustomCheckButtons from "../../../components/reusableComponents/CustomCheckButton/CustomCheckButtons";
import { projectType, jobDuration } from "../../../enums/CreateJobEnums";

function Step2({
    step,
    setStep,
    showModal,
    projectTypeValue,
    setProjectTypeValue,
    jobDurationValue,
    setJobDurationValue,
}: {
    step: number;
    setStep: Function;
    showModal: Function;
    projectTypeValue: number;
    setProjectTypeValue: Function;
    jobDurationValue: number;
    setJobDurationValue: Function;
}) {
    return (
        <div className="createJob_container2 px-sm-5 px-2">
            <Row className="createJob_contentContainer mt-2 mb-4 gy-0">
                <Col className="col-12 col-md-5">
                    <Row className="createJob_container_title py-4">
                        <Col>{LangEN.WhatTypeOfProjectAreYouHiringFor}</Col>
                    </Row>
                    <Row className="createJob_contentContainer">
                        <Form.Group>
                            <CustomCheckButtons
                                label={LangEN.NewIdeaOrProject}
                                id="step1-1"
                                checked={
                                    projectTypeValue == projectType.NewProject
                                }
                                onChange={() => {
                                    setProjectTypeValue(projectType.NewProject);
                                }}
                            />
                            <CustomCheckButtons
                                label={
                                    LangEN.ExistingProjectThatNeedsMoreResources
                                }
                                checked={
                                    projectTypeValue ==
                                    projectType.ExistingProject
                                }
                                id="step1-2"
                                onChange={() => {
                                    setProjectTypeValue(
                                        projectType.ExistingProject
                                    );
                                }}
                            />
                            <CustomCheckButtons
                                label={LangEN.OngoingAssistanceOrConsultation}
                                checked={
                                    projectTypeValue ==
                                    projectType.OngoingProject
                                }
                                onChange={() => {
                                    setProjectTypeValue(
                                        projectType.OngoingProject
                                    );
                                }}
                                id="step1-3"
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col className="col-2 d-none d-md-block createJob_center py-0 py-md-5">
                    <div className="vertical-divider " />
                </Col>
                <Col className="col-12 col-md-5">
                    <Row className="createJob_container_title py-4">
                        <Col>{LangEN.HowLongYouNeedTheResource}</Col>
                    </Row>
                    <Row className="createJob_contentContainer">
                        <Form.Group>
                            <Row>
                                <Col>
                                    <CustomCheckButtons
                                        label={LangEN.LessThanWeek}
                                        id="step1-1-1"
                                        checked={
                                            jobDurationValue ==
                                            jobDuration.OneWeek
                                        }
                                        onChange={() => {
                                            setJobDurationValue(
                                                jobDuration.OneWeek
                                            );
                                        }}
                                    />
                                    <CustomCheckButtons
                                        label={LangEN.OneWeeks}
                                        id="step1-1-2"
                                        checked={
                                            jobDurationValue ==
                                            jobDuration.OneFourWeeks
                                        }
                                        onChange={() => {
                                            setJobDurationValue(
                                                jobDuration.OneFourWeeks
                                            );
                                        }}
                                    />
                                    <CustomCheckButtons
                                        label={LangEN.OneMonths}
                                        id="step1-1-3"
                                        checked={
                                            jobDurationValue ==
                                            jobDuration.OneThreeMonths
                                        }
                                        onChange={() => {
                                            setJobDurationValue(
                                                jobDuration.OneThreeMonths
                                            );
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <CustomCheckButtons
                                        label={LangEN.ThreeMonths}
                                        id="step1-1-4"
                                        checked={
                                            jobDurationValue ==
                                            jobDuration.ThreeSixMonths
                                        }
                                        onChange={() => {
                                            setJobDurationValue(
                                                jobDuration.ThreeSixMonths
                                            );
                                        }}
                                    />
                                    <CustomCheckButtons
                                        label={LangEN.LongerThanMonths}
                                        id="step1-1-5"
                                        checked={
                                            jobDurationValue ==
                                            jobDuration.MoreThanSixMonths
                                        }
                                        onChange={() => {
                                            setJobDurationValue(
                                                jobDuration.MoreThanSixMonths
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="d-flex align-item-center createJob_spaceBetween gy-2">
                <Col md={6} lg={5}>
                    <CustomButton
                        className="createJob_cancel px-4"
                        label={LangEN.Cancel}
                        onClick={() => showModal()}
                    />
                </Col>
                <Col
                    md={6}
                    lg={2}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    className="col-auto createJob_index"
                >{`${LangEN.Step} ${step} ${LangEN.Of} 5`}</Col>
                <Col md={12} lg={5} className="d-flex justify-content-end">
                    <Row className="d-flex justify-content-end gy-2">
                        <Col>
                            <CustomButton
                                className="createJob_previous px-4"
                                label={LangEN.Previous}
                                onClick={() => {
                                    setStep(1);
                                }}
                                iconType="previous"
                            />
                        </Col>
                        <Col>
                            <CustomButton
                                className="createJob_next px-4"
                                label={LangEN.Next}
                                iconType="next"
                                onClick={() => {
                                    setStep(3);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Step2;
