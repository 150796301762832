import React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../reusableComponents/CustomButton";
import error from "../../assets/svg/icon/error.svg";

function ConfirmationModal({
  show,
  setShow,
  label,
  action,
  buttonText,
  cancelButtonText,
  subLabel,
  loading,
}: {
  show: boolean;
  setShow: any;
  label: string;
  action: Function;
  buttonText: string;
  cancelButtonText: string;
  subLabel: string;
  loading?: boolean;
}) {
  return (
    <Modal show={show} centered>
      <Modal.Body className="p-4 py-5 centered">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={error} width={70} />
          <div
            style={{
              fontSize: 25,
              color: "#05264E",
              textAlign: "center",
              fontWeight: 600,
            }}
            className="p-2"
          >
            {label}
          </div>
          <div
            style={{
              fontSize: 16,
              color: "#05264E",
              textAlign: "center",
            }}
            className="p-2"
          >
            {subLabel}
          </div>
          <div className="d-flex">
            <CustomButton
              className="m-3 px-5"
              style={{
                height: 50,
                backgroundColor: "white",
                color: "#000",
                border: "1px solid #aab",
              }}
              onClick={() => setShow(false)}
              label={cancelButtonText}
              disabled={loading}
            />
            <CustomButton
              className="m-3 px-5"
              style={{ height: 50 }}
              onClick={() => action()}
              label={buttonText}
              isLoading={loading}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationModal;
