import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import TextAvatar from "../../components/reusableComponents/TextAvatar";
import { LangEN } from "../../constants/Lang/LangEN";
import { Chip } from "../../components/reusableComponents/Chip";
import experience from "../../assets/svg/experience.svg";
import rate from "../../assets/svg/rate.svg";
import availability from "../../assets/svg/availability.svg";
import projects from "../../assets/svg/projects.svg";
import CustomButton from "../../components/reusableComponents/CustomButton";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { jobApplicationStatus } from "../../enums/JobApplicationStatus";
import JobSApplicationService from "../../services/JobApplicationService";
import moment from "moment";
import InviteOnProject from "../../components/Modals/InviteOnProject/InviteOnProject";
import StatusChip from "../../components/reusableComponents/StatusChip";
import Loader from "../../components/reusableComponents/Loader";
import {
  mapJobRole,
  mapWorkExperience,
  requiredResourceType,
  setCurrencyType,
  setPaymentDuration,
} from "../../Utils/Mappers";
import { UserRoles } from "../../enums/UserRoles";
import JobInvitationService from "../../services/JobInvitationService";
import { invitationStatus } from "../../enums/InvitationStatus";
import CustomModal from "../../components/Modals/CustomModal";
import { useContext } from "react";
import { BreadCrumbsContext } from "../../App";
import { ProfileStyles } from "../Profile/ProfileStyle";
import Avatar from "../../components/reusableComponents/Avatar";

function ApplicationDetails({
  isProfessional,
  notProjectRoute,
  projectRoute,
  isSearch,
  ownProject,
  flow,
}: {
  projectRoute?: boolean;
  isSearch?: boolean;
  notProjectRoute?: boolean;
  isProfessional?: boolean;
  flow?: number;
  ownProject?: boolean;
}) {
  const navigate = useNavigate();
  const bread = useContext(BreadCrumbsContext);
  // const { id, jobid, projectid } = useParams();
  const { state } = useLocation();
  const [profileInfo, setProfileInfo] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [totalJobsCompleted, setTotalJobsCompleted] = useState(0);

  const breadData = [
    {
      label: LangEN.Professionals,
      route: "/professional",
    },
    {
      label: LangEN.ProfessionalDetails,
      route: "/",
    },
  ];

  const breadDataProject = [
    {
      label: LangEN.MyProjects,
      route: "/projects",
    },
    {
      label: LangEN.ProjectDetails,
      route: "/projects/details",
      state: { id: state.projectId },
    },
    {
      label: LangEN.Professional,
      route: "/",
    },
  ];

  useEffect(() => {
    JobSApplicationService.TotalCompletedJobsById(state.id)
      .then((res) => {
        setTotalJobsCompleted(res.data.totalJobsCompleted);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sendInvite = () => {
    let apiReq = {
      invitee: state.id,
      jobId: state.jobid,
      invitationStatus: invitationStatus.Invited,
    };
    JobInvitationService.SendInvite(apiReq)
      .then((res) => {
        console.log(res.data);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (ownProject) {
      bread.setData(breadDataProject);
    } else {
      bread.setData(breadData);
    }

    return () => {
      bread.setData([]);
    };
  }, []);

  // useEffect(() => {
  //   if (!stat
  // },[])

  useEffect(() => {
    // if (isProfessional) return;
    UserService.GetProfileById(state.id)
      .then((res) => {
        setProfileInfo(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    UserService.IsProfessionalBusy(state.id)
      .then((res) => {
        setIsBusy(res.data.isBusy);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateJobApplicationStatus = (status: number) => {
    let apiReq = {
      id: state.application.id,
      applicationStatus: status,
    };
    JobSApplicationService.UpdateJobApplicationStatus(apiReq)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="main_container">
      <CustomModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        label={LangEN.InvitationSentSuccessfully}
        type="success"
        buttonText={LangEN.OK}
        action={() => {
          navigate("/projects/details", { state: { id: state.projectid } });
        }}
      />
      <InviteOnProject
        notProjectRoute={notProjectRoute}
        setShow={setShowModal}
        show={showModal}
        userId={profileInfo?.user?.id}
        role={state.role}
        flow={
          flow === UserRoles.Professional
            ? UserRoles.Professional
            : UserRoles.Business
        }
      />
      {loading ? (
        <Loader lg />
      ) : (
        <Card className="cardShadow p-3 p-md-4 px-md-5 px-3">
          <Row>
            <Col className="col-md-8 col-12">
              <Avatar
                url={profileInfo?.profilePicture}
                size={70}
                fontSize={24}
                firstName={profileInfo?.user?.firstName}
                lastName={profileInfo?.user?.lastName}
              />
              <Row className="my-2 d-flex align-items-center">
                <Col xs="auto">
                  <Card.Title className="applicationDetails_title">
                    {`${profileInfo?.user?.firstName} ${
                      profileInfo?.user?.lastName
                        ? profileInfo.user.lastName
                        : ""
                    }`}
                  </Card.Title>
                </Col>
                {!isProfessional ? (
                  <Col>
                    {!ownProject && (
                      <Card.Subtitle className="applicationDetails_subtitle">
                        {LangEN.ApplicationReceived} -{" "}
                        <span className="applicationDetails_span">
                          ({moment(state.application.createdAt).fromNow(true)})
                        </span>
                      </Card.Subtitle>
                    )}
                  </Col>
                ) : isSearch ? (
                  <Col>
                    <StatusChip status={isBusy ? "busy" : "available"} />{" "}
                  </Col>
                ) : null}
              </Row>
              <Card.Text className="applicationDetails_heading mb-5">
                {mapJobRole(profileInfo?.applyAs)}
              </Card.Text>
              <Card.Title className="applicationDetails_h2">
                {LangEN.Description}
              </Card.Title>
              <Card.Text className="applicationDetails_text">
                {profileInfo?.aboutMe}
              </Card.Text>
              <Card.Title className="applicationDetails_h2 mt-5">
                {LangEN.Skills}
              </Card.Title>
              <div className="d-flex gap-2 my-3" style={{ flexWrap: "wrap" }}>
                {profileInfo?.skill.map((item: any, index: number) => (
                  <Chip label={item.skill.name} index={index} />
                ))}
                {profileInfo?.customSkill.map((item: any, index: number) => (
                  <Chip label={item.skillName} index={index} />
                ))}
                {profileInfo?.skill?.length +
                  profileInfo?.customSkill?.length ==
                  0 && LangEN.NotSpecified}
              </div>

              <Card.Title className="applicationDetails_h2 mt-5">
                {LangEN.Qualification}
              </Card.Title>
              <div className="d-flex gap-2 my-3" style={{ flexWrap: "wrap" }}>
                {profileInfo?.qualification.map((item: any, index: number) => (
                  <Chip label={item.qualification.value} index={index} />
                ))}
                {profileInfo?.qualification?.length == 0 && LangEN.NotSpecified}
              </div>

              <Row>
                <Col xs={"5"} style={ProfileStyles.spanText}>
                  <Card.Title className="applicationDetails_h2 mt-5">
                    Linkedin
                  </Card.Title>
                  {profileInfo?.linkedinURL ? (
                    <a
                      target="_blank"
                      href={
                        profileInfo.linkedinURL.slice(0, 4) == "http"
                          ? profileInfo.linkedinURL
                          : "//" + profileInfo.linkedinURL
                      }
                    >
                      {profileInfo.linkedinURL}
                    </a>
                  ) : (
                    LangEN.NotSpecified
                  )}
                </Col>

                {profileInfo?.cv ? (
                  <Col xs={5}>
                    {" "}
                    <Card.Title className="applicationDetails_h2 mt-5">
                      Curriculum Vitae
                    </Card.Title>
                    <a className="mb-5" href={profileInfo.cv}>
                      Download CV
                    </a>
                    <div className="mb-4"></div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
            <Col
              className={"col-1 d-md-flex justify-content-center d-none py-4"}
            >
              <div className="vertical-divider"></div>
            </Col>
            <Col className="col-md-3 col-12 pt-3">
              <Card.Title className="applicationDetails_title">
                {LangEN.Overview}
              </Card.Title>
              <div className="horizontal-divider my-3"></div>
              <Row className="my-4">
                <Col xs="auto">
                  <img src={experience} width={20} />
                </Col>
                <Col>
                  <div className="applicationDetails_subtitle">
                    {LangEN.Experience}
                  </div>
                  <div className="applicationDetails_span">
                    {mapWorkExperience(profileInfo?.workExperience)}
                  </div>
                </Col>
              </Row>
              {/* <Row className="my-4">
              <Col xs="auto">
                <img src={qualification} width={20} />
              </Col>
              <Col>
                <div className="applicationDetails_subtitle">
                  {LangEN.Qualification}
                </div>
                <div className="applicationDetails_span">Master's Degree</div>
              </Col>
            </Row> */}
              <Row className="my-4">
                <Col xs="auto">
                  <img src={rate} width={20} />
                </Col>
                <Col>
                  <div className="applicationDetails_subtitle">
                    {LangEN.Rate}
                  </div>
                  <div className="applicationDetails_span">
                    {`${setCurrencyType(profileInfo?.currencyType)}${
                      profileInfo.hourlyRate
                    }${setPaymentDuration(profileInfo?.paymentDuration)}`}
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col xs="auto">
                  <img src={availability} width={20} />
                </Col>
                <Col>
                  <div className="applicationDetails_subtitle">
                    {LangEN.Availability}
                  </div>
                  <div className="applicationDetails_span">
                    {requiredResourceType(profileInfo?.availability)}
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col xs="auto">
                  <img src={projects} width={20} />
                </Col>
                <Col>
                  <div className="applicationDetails_subtitle">
                    {LangEN.TotalJobsCompleted}
                  </div>
                  <div className="applicationDetails_span">
                    {totalJobsCompleted}
                  </div>
                </Col>
              </Row>
              {state.showAction ? (
                <Row className=" gap-3 px-3 my-5">
                  <Col xs="auto" className="m-0 p-0">
                    <CustomButton
                      className="applicationDetails_button2 px-3"
                      label={LangEN.Accept}
                      onClick={() => {
                        updateJobApplicationStatus(
                          jobApplicationStatus.Accepted
                        );
                      }}
                    />
                  </Col>
                  <Col xs="auto" className="m-0 p-0">
                    <CustomButton
                      className="applicationDetails_button px-3"
                      label={LangEN.Reject}
                      onClick={() => {
                        updateJobApplicationStatus(
                          jobApplicationStatus.Rejected
                        );
                      }}
                    />
                  </Col>
                </Row>
              ) : null}
              {isProfessional && (
                <Row>
                  <Col>
                    <CustomButton
                      label={LangEN.InviteOnProject}
                      onClick={() => {
                        if (projectRoute) {
                          sendInvite();
                          return;
                        }
                        setShowModal(true);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      )}
    </Container>
  );
}

export default ApplicationDetails;
