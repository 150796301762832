export const LangEN = {
  // global
  AppName: "Beehived",
  Login: "Login",
  SignUp: "Sign Up",
  Submit: "Submit",
  Welcome: "Welcome",
  WelcomeBack: "Welcome Back",
  Business: "Business",
  Professional: "Professional",
  Email: "Email",
  Name: "Name",
  FirstName: "First Name",
  LastName: "Last Name",
  PostedOn: "Posted on ",
  Password: "Password",
  ConfirmPassword: "Confirm Password",
  Verified: "Verified",
  Phone: "Phone No.",
  Referral: "Referral Code",
  RememberMe: "Remember Me",
  NoSkillsFound: "No Skill Found!",
  Reset: "Reset",
  Notification: "Notification",
  Notifications: "Notifications",
  AddYourSkills: "Add your skills",
  SearchSkills: "Search Skills",
  EnterQualification: "Enter Education",
  UpdateImage: "Upload image",
  EditCompanyInfo: "Edit Company Info",
  CreateYourProfile: "Create Your Profile",
  CompanyName: "Company Name",
  AboutCompany: "About Company",
  FoundedIn: "Incorporation Date",
  Cancel: "Cancel",
  EditProfile: "Edit Profile",
  ApplyAs: "Apply as",
  ProjectManager: "Project Manager",
  Architect: "Architect",
  Applications: "Applications",
  Experts: "Experts",
  ZeroYears: "0 - 2 Years",
  TwoYears: "2 - 5 Years",
  IndicatorTooltip: "Project Manager or Architect is not hired on this project",
  IndicatorTooltipStaffed:
    "Project Manager or Architect is hired on this project",
  FiveYears: "5 - 10 Years",
  TenYears: "More than 10 Years",
  PartTime: "Part Time",
  FullTime: "Full Time",
  Hourly: "Hourly",
  CurrentRole: "Current Role",
  Skills: "Skills",
  WorkExperience: "Work Experience",
  Description: "Description",
  ProjectTitle: "Project Title",
  AddProjectDescription: "Add Project description",
  Resource: "Resource",
  Role: "Role",
  ProjectDuration: "Project Duration",
  StartDate: "Start Date",
  EndDate: "End Date",
  Create: "Create",
  ProjectCreatedSuccessfully: "Project created successfully",
  RequiredResource: "Required Resource",
  CreateProjectOnly: "Create Project",
  EnterCurrentRole: "Enter your current role",
  OK: "OK",
  EditProject: "Edit Project",
  Update: "Update",
  WhatRoleYouWouldLikeToHire: "What role would you like to hire?",
  Of: "of",
  Step: "Step",
  Next: "Next",
  Previous: "Previous",
  JobPreview: "Job Preview",
  PostThisJob: "Post This Job",
  AreYouSureCancel: "Are you sure you want to cancel?",
  AreYouSureLogout: "Are you sure you want to logout?",

  ProfileNotCompleted: "Profile Incomplete",
  CompleteProfileToSearchJobs: "Please complete your profile to search jobs.",
  No: "No",
  Yes: "Yes",
  TermCondition: "Term & Condition",
  IfYouLeaveBeforeSaving:
    "If you leave before saving, your changes will be lost",
  HowLongYouNeedTheResource: "How long do you need the resource?",
  WhatTypeOfProjectAreYouHiringFor: "What type of project are you hiring for?",
  NewIdeaOrProject: "New idea or project",
  ExistingProjectThatNeedsMoreResources:
    "Existing project that needs more resources",
  OngoingAssistanceOrConsultation: "Ongoing assistance or consultation",
  LessThanWeek: "Less than 1 week",
  ThreeMonths: "3-6 months",
  OneWeeks: "1-4 weeks",
  OneMonths: "1-3 months",
  LongerThanMonths: "Longer than 6 month",
  LevelCommitmentRequiredFromResource: "Level of commitment from resources",
  TypeOfSkillsLookingFor: "Type of skills you're looking for",
  WhenYouWantStart: "When do you want to start?",
  LevelTeamContribute:
    "Level of team contribution you're looking from the resource",
  AreYouOpenToWorkWithRemoteResources:
    "Are you open to work with remote resources?",
  WhatYourBudgetForThisRole: "What is your Budget for this role?",
  SelectCurrencyType: "Select currency type",
  PaymentDuration: "Payment Duration",
  Immediately: "Immediately",
  InOneTwoWeeks: "In 1-2 weeks",
  MoreThanTwoWeeks: "More than 2 weeks",
  Beginner: "Beginner",
  Intermediate: "Intermediate",
  MidLevelManagement: "Mid-level management",
  ExecutiveSeniorLeadership: "Executive/Senior Leadership",
  NotSure: "Not sure",
  Dollar: "Dollar $",
  Rupee: "Rupee ₹",
  Dinar: "Dinar د.ك",
  Euro: "Euro €",
  Daily: "Daily",
  Budget: "Budget",
  Duration: "Duration",
  ProjectType: "Project Type",
  ResourceTime: "Resource Time",
  WFH: "WFH",
  ContributionLevel: "Contribution Level",
  WhenToStart: "WhenToStart",
  JobDescription: "Job Description",
  ProjectManagerSubtitle:
    "Digital Product Managers, Product Owners, and Business Analysts",
  ExpertsSubtitle:
    "Financial Modelers, Fundraising Advisors, M&A and FP&A Experts",
  ArchitectSubtitle: "Web, Mobile, UI/UX, Branding, and Visual Designers",
  CreatedJobsList: "Created Jobs List",
  CreateAJob: "Create a Job",
  ViewApplications: "View Applications",
  ViewDetails: "View Details",
  JobDetails: "Job Details",
  EmploymentInformation: "Employment Information",
  Category: "Category",
  SkillRequired: "Skills Required",
  WhenStart: "When Start",
  Experience: "Experience",
  ResourceNeed: "Resource Required for",
  JobApplications: "Job Applications",
  Received: "Received",
  Shortlisted: "Shortlisted",
  Accepted: "Accepted",
  Rejected: "Rejected",
  Reject: "Reject",
  Accept: "Accept",
  JobPostedSuccessfully: "Job posted successfully",
  JobUpdatedSuccessfully: "Job updated successfully",
  ApplicationReceived: "Application Received",
  Overview: "Overview",
  Qualification: "Education",
  Rate: "Rate",
  TotalProjectCompleted: "Total Projects Completed",
  TotalJobsCompleted: "Total Jobs Completed",
  Roles: "Roles",
  All: "All",
  Search: "Search...",
  SearchJobDescription: "Search by description",
  SearchName: "Search by name...",
  ApplyNow: "Apply Now",
  Salary: "Salary",
  Saved: "Save",
  UnSaved: "Saved",
  ViewProjectDetails: "View Project Details",
  Filter: "Filter",
  JobList: "Job List",
  AppliedJobList: "Applied Job List",
  SavedJobList: "Saved Job List",
  SortBy: "Sort by:",
  NewestPost: "Newest",
  View: "View",
  Revoke: "Revoke",
  CreateNewSkill: "Create new skill",
  NoQualificationFound: "No Education Found",
  SeeMore: "See more",
  EditJobDetails: "Edit job details",
  DeleteJob: "DeleteJob",
  MarkJobExpired: "Mark job as Expired",
  MarkJobActive: "Mark job as Active",
  UpdateJob: "Update Job",
  InviteProfessionals: "Invite Professionals",
  SendInvitation: "Send Invitation",
  SearchBest: "Search best",
  FreelancingJob: "freelancing job",
  SearchOnly: "Search",
  InviteOnProject: "Invite on project",
  SearchProjects: "Search Projects",
  YourEmailNotVerified: "Your email is not verified",
  VerifyYourEmail: "Verify your email",
  NoResultsFound: "Oops!... no results found",
  PleaseTryAnotherSearch: "Please try another search",
  NoSaveJobs: "No saved job, please save some",
  NoAppliedJob: "No applied job, please apply some",
  ApplyForAJob: "Apply for a job to join a project.",
  NoProjectJoined: "No Project Joined",
  NoApplicationFound: "No Application Found",
  ProjectList: "Project List",
  SendInvite: "Send Invite",
  InvitationSentSuccessfully: "Invitation sent successfully",
  YourProfileIsIncomplete: "Your Profile is incomplete",
  PleaseCompleteYourProfile: "Please complete your profile",
  YourAccountNotVerified: "Your Account is not verified",
  OldestPost: "Oldest",
  VerifyYourAccount: "Verify your account",
  YourProfileIsUnderVerification: "Your Profile is under verification",
  PleaseTryLater: "Please try later!",
  Invite: "Invite",
  ProfileAlreadyCompleted: "Profile is already completed",
  SomethingWentWrong: "Something went wrong",
  PleaseTryAgain: "Please try again",
  Retry: "Retry",
  Applied: "Applied",

  //  Nav links
  Home: "Home",
  AboutUs: "About Us",
  Jobs: "Jobs",
  SearchProfessionals: "Search Professionals",
  MyProjects: "My Projects",
  ProjectDetails: "Project Details",
  Professionals: "Professionals",
  ChangePassword: "Change Password",
  Logout: "Logout",
  FAQ: "FAQ",

  TermsOfService: "Terms of Services",
  ProfessionalDetails: "Professional Details",
  PrivacyPolicy: "Privacy Policy",
  SiteMap: "Site Map",
  FollowUs: "Follow Us:",
  MyProfile: "My Profile",
  SearchJobs: "Search Jobs",
  AppliedJobs: "Applied Jobs",
  SavedJobs: "Saved Jobs",
  ContinueProfessional: "Continue as a Professional",
  ContinueBusiness: "Continue as a Business",
  EnterLinkdin: "Enter your Linkedin URL",
  HourlyRate: "Rate",
  Availability: "Availability",
  AboutMe: "About Me",
  Available: "Available",
  Busy: "Busy",
  TeamMembers: "Team Members",
  TypeYourCurrentPassword: "Type your current password",
  TypeYourNewPassword: "Type your new password",
  RetypeYourNewPassword: "Retype your new password",
  SavePassword: "Save Password",

  //others
  MyReferralCode: "My referral code",
  NoCurrentRoleSpecified: "No Current Role specified",
  NoDescription: "No description",
  NoSkillsAdded: "No Skills Added",
  NoQualificationAdded: "No Education Added",
  EmailSentSubtitle: "We just sent an OTP to the address: ",
  NotSpecified: "Not Specified",
  OtpRequired: "OTP is required!",
  ProfileDetails: "Profile Details",
  ForgotYourPasswordTitle: "Forgot your password?",
  PasswordResetSuccessfully: "Password reset successfully",
  GetOTP: "Get OTP",
  VerifyOTP: "Verify OTP",

  EnterEmailBelowForOTP:
    "Enter your email address below and we will send  you OTP",

  // Auth Screens
  ForgotPassword: "Forgot Password",
  ForgotYourPassword: "Forgot your Password",
  EmailForOtp: "Enter your email address below and we will send you OTP",
  EnterYouEmail: "Enter Your Email",
  GetOtp: "Get Otp",
  DoNotHaveAccount: "Don't have an account?",
  AuthCopyright: "© Copyright 2023 Beehived.io",
  EnterOtpHere: "Enter your OTP here",
  OtpSentTo: "We just sent an OTP to the address:",
  ResendOtp: "Resend code",
  ResetPassword: "Reset Password",
  ResetYourPassword: "Reset your Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  AlreadyHaveAccount: "Already have an account?",
  NoMoreJobToCreate: "Resource count exceeded",

  // Validation Errors
  EmailRequired: "Email is required.",
  ValidEmail: "Please enter a valid email.",
  PasswordRequired: "Password is required.",
  PasswordLengthError: "Password should be 8 characters at least.",
  PasswordLengthExceedError: "Password should be less than 20 characters.",
  PasswordContainsEmptySpaces: "Password cannot contain empty spaces",
  PasswordValidationError:
    "Password should contain at least one uppercase letter, one lowercase letter, one number and one special character.",
  NameRequired: "Name is required.",
  FirstNameRequired: "First name is required.",
  NameSpecialCharacters:
    "Name should not include special characters or numbers.",
  LastNameSpecialCharacters:
    "Last name should not include special characters or numbers.",
  FirstNameSpecialCharacters:
    "First name should not include special characters or numbers.",
  NameLengthLow: "Name should be of 2 characters at least.",
  FirstNameLengthLow: "First name should be of 2 characters at least.",
  LastNameLengthLow: "Last name should be of 2 characters at least.",
  NameLengthExceeded: "Name should be less than 35 characters.",
  FirstNameLengthExceeded: "First name should be less than 15 characters.",
  LastNameLengthExceeded: "Last name should be less than 15 characters.",
  CompanyNameRequired: "Company name is required.",
  CompanyNameSpecialCharacters:
    "Company name should not include special characters or numbers.",
  CompanyNameLengthLow: "Company name should be of 2 characters at least.",
  CompanyNameLengthExceeded: "Company Name should be less than 50 characters.",
  CompanyAboutLengthExceeded:
    "About company should be less than 500 characters.",
  ConfirmPasswordRequired: "Confirm Password is required.",
  PasswordNotMatch: "Password does not match.",
  PhoneRequired: "Phone no. is required.",
  ValidPhone: "Please enter a valid Phone no.",
  FoundedInRequired: "Incorporation date is required.",
  FileUnsupported: "File format not supported.",
  RoleRequired: "Current Role is required.",
  RoleLengthExceeded: "Role should be less then 50 character.",

  // Professional Screen
  VerifyEmail: "Please verify your email",
  OtpSentOn: "Enter the verification OTP we have sent on",
  ResendOtpCP: "RESEND OTP",
  SubmitOtpCP: "SUBMIT OTP",
  CompleteYourProfileSubtitle:
    " Please fill the required details to complete your profile for further processing & verification",
  CompleteProfile: "Complete Profile",
  SkipNow: "Skip for Now",

  //ErrorPage
  PageNotFound: "Page not found",
  PageNotFoundSubtitle:
    "The page you are looking for might have been removed or had its name changed or is temporary unavailable.",

  // footer
  Copyright: "Copyright 2010 - 2023 Beehived.io",

  // Profile
  YourProjects: "Your Projects",
  NoProjectsCreated: "No Project created yet",
  CreateProjectSubtitle:
    "Create a project to the marketplace and let talent come to you.",
  CreateProject: "Create a Project",
  ProfileInfo: "Profile Info",
  CompleteYourProfile: "Complete your profile",
  Save: "Save",
  Default: "Default",
};
