import { Col, Form, Row } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import CustomCheckButtons from "../../../components/reusableComponents/CustomCheckButton/CustomCheckButtons";
import { professionalAvailability } from "../../../enums/ProfessionalAvailability";
import CustomTagInput from "../../../components/reusableComponents/CustomTagInput";
function Step3({
  step,
  setStep,
  showModal,
  professionalAvailabilityValue,
  setProfessionalAvailabilityValue,
  skills,
  setSkills,
  customSkills,
  setCustomSkills,
}: {
  step: number;
  setStep: Function;
  showModal: Function;
  professionalAvailabilityValue: number;
  setProfessionalAvailabilityValue: Function;
  skills: any;
  setSkills: Function;
  customSkills: string[];
  setCustomSkills: Function;
}) {
  return (
    <div className="createJob_container2 px-sm-5 px-2">
      <Row className="createJob_contentContainer mt-2 mb-4">
        <Col className="col-12 col-md-5">
          <Row className="createJob_container_title py-4">
            <Col>{LangEN.LevelCommitmentRequiredFromResource}</Col>
          </Row>
          <Row className="createJob_contentContainer">
            <Form.Group>
              <CustomCheckButtons
                label={LangEN.FullTime}
                id="step3-1"
                checked={
                  professionalAvailabilityValue ==
                  professionalAvailability.FullTime
                }
                onChange={() => {
                  setProfessionalAvailabilityValue(
                    professionalAvailability.FullTime
                  );
                }}
              />
              <CustomCheckButtons
                label={LangEN.PartTime}
                checked={
                  professionalAvailabilityValue ==
                  professionalAvailability.PartTime
                }
                id="step3-2"
                onChange={() => {
                  setProfessionalAvailabilityValue(
                    professionalAvailability.PartTime
                  );
                }}
              />
              <CustomCheckButtons
                label={LangEN.Hourly}
                checked={
                  professionalAvailabilityValue ==
                  professionalAvailability.Hourly
                }
                onChange={() => {
                  setProfessionalAvailabilityValue(
                    professionalAvailability.Hourly
                  );
                }}
                id="step3-3"
              />
            </Form.Group>
          </Row>
        </Col>
        <Col className="col-2 d-none d-md-block createJob_center py-0 py-md-5">
          <div className="vertical-divider " />
        </Col>
        <Col className="col-12 col-md-5 mb-4">
          <Row className="createJob_container_title py-4">
            <Col>{LangEN.TypeOfSkillsLookingFor}</Col>
          </Row>
          <Row className="createJob_contentContainer">
            <Form.Group>
              <CustomTagInput
                setSelectedValues={setSkills}
                selectedValues={skills}
                isSkills
                hideLabel
                customSkill={customSkills}
                setCustomSkill={setCustomSkills}
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex align-item-center createJob_spaceBetween gy-2">
        <Col md={6} lg={5}>
          <CustomButton
            className="createJob_cancel px-4"
            label={LangEN.Cancel}
            onClick={() => showModal()}
          />
        </Col>
        <Col
          md={6}
          lg={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="col-auto createJob_index"
        >{`${LangEN.Step} ${step} ${LangEN.Of} 5`}</Col>
        <Col md={12} lg={5} className="d-flex justify-content-end">
          <Row className="d-flex justify-content-end gy-2">
            <Col>
              <CustomButton
                className="createJob_previous px-4"
                label={LangEN.Previous}
                onClick={() => {
                  setStep(2);
                }}
                iconType="previous"
              />
            </Col>
            <Col>
              <CustomButton
                className="createJob_next px-4"
                label={LangEN.Next}
                iconType="next"
                onClick={() => {
                  setStep(4);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step3;
