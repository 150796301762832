import Http from "./HttpService";
import { API } from "../constants/ApiDetails";
import { ICreateJob } from "../Interfaces/ICreateJob";

class JobService {
  public static async GetJobByProjectId() {
    const url = `${API.GET_JOB_BY_PROJECT_ID}`;
    const response = await Http.get(url);
    return response;
  }
  public static async CreateJob(projectDetails: ICreateJob) {
    const url = `${API.CREATE_JOB}`;
    const response = await Http.post(url, projectDetails);
    return response;
  }

  public static async EditJob(projectDetails: ICreateJob) {
    const url = `${API.CREATE_JOB}`;
    const response = await Http.put(url, projectDetails);
    return response;
  }
  public static async GetJobById(id: any) {
    const url = `${API.GET_JOB_BY_PROJECT_ID}${"/"}${id}`;
    const response = await Http.get(url);
    return response;
  }
  public static async GetJobs(filter: any) {
    const url = `${API.JOB_FILTERS}`;
    const response = await Http.post(url, filter);
    return response;
  }

  public static async GetAppliedJobs(sort: number, search: string) {
    const url = `${API.GET_APPLIED_ON_JOBS}/${sort}${
      search != "" ? "/" + search : ""
    }`;
    const response = await Http.get(url);
    return response;
  }

  public static async GetSavedJobs(sort: number, search: string) {
    const url = `${API.GET_SAVED_JOBS}/${sort}${
      search != "" ? "/" + search : ""
    }`;
    const response = await Http.get(url);
    return response;
  }

  public static async DeleteJob(id: string | undefined) {
    const url = `${API.CREATE_JOB}/${id}`;
    const response = await Http.delete(url);
    return response;
  }

  public static async SaveJob(jobId: number) {
    let apiReq = {
      jobId,
    };
    const url = `${API.GET_SAVED_JOBS}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async IsJobSave(jobId: number) {
    let apiReq = {
      jobId,
    };
    const url = `${API.IS_JOB_SAVED}`;
    const response = await Http.post(url, apiReq);
    return response;
  }

  public static async RemoveSavedJob(jobId: number) {
    const url = `${API.GET_SAVED_JOBS}/${jobId}`;
    const response = await Http.delete(url);
    return response;
  }

  public static async MarkJobExpired(id: string | undefined) {
    const url = `${API.MARK_JOB_AS_EXPIRED}/${id}`;
    const response = await Http.get(url);
    return response;
  }

  public static async MarkJobActive(id: string | undefined) {
    const url = `${API.MARK_JOB_AS_Active}/${id}`;
    const response = await Http.get(url);
    return response;
  }
}

export default JobService;
