import React, { useState, useEffect } from "react";
import Theme from "../../../constants/Theme";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import {
  Container,
  Form,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
} from "react-bootstrap";
import { SignUpStyles } from "../SignUp/SignUpStyle";
import gandalfBlue from "../../../assets/svg/Color logo-verticle.svg";
import backArrow from "../../../assets/svg/icon/backArrow.svg";
import eyeOff from "../../../assets/svg/icon/eye.svg";
import eye from "../../../assets/svg/icon/eye-off.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LangEN } from "../../../constants/Lang/LangEN";
import "react-phone-number-input/style.css";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../../Utils/Validators";
import CustomModal from "../../../components/Modals/CustomModal";
import AuthService from "../../../services/AuthService";
import { UserRoles } from "../../../enums/UserRoles";

export default function ResetPassword({
  isBusiness,
  email,
}: {
  isBusiness?: boolean;
  email: string;
}) {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [role, setRole] = useState<number | null>(null);

  useEffect(() => {
    validate();
  }, [password, confirmPassword, passwordError]);

  function validate() {
    // check to enable form submission
    if (password && confirmPassword && !passwordError) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (validated && confirmPassword === password) {
      let apiReq = {
        password: password,
        email: email,
      };

      AuthService.UpdatePassword(apiReq)
        .then((res) => {
          localStorage.clear();
          sessionStorage.clear();
          console.log(res);
          setRole(res.data.role);
          setShowSuccessModal(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <CustomModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        label={LangEN.PasswordResetSuccessfully}
        type="success"
        buttonText={LangEN.Login}
        action={() => {
          if (role === UserRoles.Business) {
            navigate("/businesslogin");
          } else if (role === UserRoles.Professional) {
            navigate("/login");
          } else {
            navigate("/");
          }
        }}
      />
      <Container fluid>
        <Row className="d-md-block d-lg-none" style={SignUpStyles.row1}>
          <Col>
            <Link to="/" style={SignUpStyles.img1}>
              <Image src={backArrow} />
            </Link>
            <div style={SignUpStyles.img2}>
              <Image
                width="30%"
                height="30%"
                style={{ marginBottom: 30 }}
                src={gandalfBlue}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-lg-block" style={SignUpStyles.col1}>
            <Row style={SignUpStyles.row2}>
              <div style={SignUpStyles.labelDiv}>
                <label style={SignUpStyles.label2}>
                  {LangEN.ResetPassword}
                </label>
              </div>
            </Row>
          </Col>
          <Col style={SignUpStyles.col2}>
            <label style={SignUpStyles.label3}>
              <div className={"auth_label_title"}>
                {LangEN.ResetYourPassword}
              </div>
              <div className="auth_labelUnderline" />
            </label>
            <Form style={SignUpStyles.form} noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <InputGroup>
                  <Form.Control
                    size="lg"
                    style={SignUpStyles.formControl}
                    type={passwordShown ? "text" : "password"}
                    placeholder={`${LangEN.NewPassword} *`}
                    value={password}
                    onChange={(e) => {
                      let value = e.target.value;
                      setPassword(value);
                      passwordError &&
                        validatePassword(value, setPasswordError);
                    }}
                    onBlur={() => {
                      validatePassword(password, setPasswordError);
                    }}
                    isInvalid={!!passwordError}
                    className="shadow-none password-field"
                  />
                  <Button
                    className="password-eye-button"
                    onClick={() => {
                      setPasswordShown((pre) => !pre);
                    }}
                  >
                    <img
                      src={passwordShown ? eyeOff : eye}
                      width={30}
                      height={25}
                      className={"password-eye"}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                <InputGroup>
                  <Form.Control
                    size="lg"
                    style={SignUpStyles.formControl}
                    type={confirmPasswordShown ? "text" : "password"}
                    isInvalid={!!confirmPasswordError}
                    placeholder={`${LangEN.ConfirmNewPassword} *`}
                    value={confirmPassword}
                    onChange={(e) => {
                      let value = e.target.value;
                      setConfirmPassword(value);
                      setConfirmPasswordError("");
                    }}
                    onBlur={() => {
                      validateConfirmPassword(
                        confirmPassword,
                        password,
                        setConfirmPasswordError
                      );
                    }}
                    className="shadow-none password-field"
                  />
                  <Button
                    className="password-eye-button"
                    onClick={() => {
                      setConfirmPasswordShown((pre) => !pre);
                    }}
                  >
                    <img
                      src={confirmPasswordShown ? eyeOff : eye}
                      width={30}
                      height={25}
                      className={"password-eye"}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {confirmPasswordError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <CustomButton
                // href="/login"
                type="submit"
                isLoading={isLoading}
                label={LangEN.Reset}
                size="lg"
                style={SignUpStyles.button}
                disabled={!validated}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
