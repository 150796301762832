import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCi5FSDP5FwEzPet5nAkiYQWyD08kKnNhM",
  authDomain: "gandalf-80a52.firebaseapp.com",
  projectId: "gandalf-80a52",
  storageBucket: "gandalf-80a52.appspot.com",
  messagingSenderId: "731379583034",
  appId: "1:731379583034:web:40049d33bc6f31c4584d03",
  measurementId: "G-DH5N6MDS53",
};

const vapid =
  "BBcod49CyWRFRwqWL8MnNr1LKEEbmRCizDaHCCK-nwZqKd3-bO0DoIGa2nkq8ZijmI_iyvH33iNtQfbe3WqyyKE";

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export async function getMessagingToken() {
  console.log("calling get token function from firebase ");
  const token = await getToken(messaging, { vapidKey: vapid });
  return token;
  // .then((currentToken) => {
  //   if (currentToken) {
  //     console.log("current token for client: ", currentToken);
  //     setTokenFound(true);
  //     // Track the token -> client mapping, by sending to backend server
  //     // show on the UI that permission is secured
  //     return currentToken;
  //   } else {
  //     console.log(
  //       "No registration token available. Request permission to generate one."
  //     );
  //     setTokenFound(false);
  //     // shows on the UI that permission is required
  //   }
  // })
  // .catch((err) => {
  //   console.log("An error occurred while retrieving token. ", err);
  //   // catch error while creating client token
  // });
}

export function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
