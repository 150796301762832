import { useState, useEffect, useContext } from "react";
import mail from "../../assets/svg/icon/mail.svg";
import phone from "../../assets/svg/icon/phone.svg";
import pencil from "../../assets/svg/icon/pencil.svg";
import verified from "../../assets/svg/icon/verified.svg";
import { Card, Col, Container, Row } from "react-bootstrap";
import Theme from "../../constants/Theme";
import { NavLink } from "react-router-dom";
import UserService from "../../services/UserService";
import { ProfileStyles } from "./ProfileStyle";
import { LangEN } from "../../constants/Lang/LangEN";
import Loader from "../../components/reusableComponents/Loader";
import TextAvatar from "../../components/reusableComponents/TextAvatar";
import moment from "moment";
import { User } from "../../App";
import Avatar from "../../components/reusableComponents/Avatar";

export default function Profile() {
  const user = useContext(User);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>({});
  useEffect(() => {
    UserService.ViewProfile()
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);
        console.log(res.data);
        user.setUser(res.data);
        localStorage.setItem("userData", JSON.stringify(res.data));
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Container
        style={ProfileStyles.container2}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <Loader lg />
        ) : (
          <Row>
            <Card
              className="cardShadow px-sm-5 px-4 pt-4 pb-5"
              style={ProfileStyles.card4}
            >
              <NavLink to="/profile/edit" style={ProfileStyles.FAB2}>
                <img src={pencil} width={20} />
              </NavLink>
              <h3 className="mb-3" style={ProfileStyles.header}>
                {LangEN.ProfileDetails}
              </h3>

              <Avatar
                url={userData?.profilePicture}
                size={80}
                fontSize={24}
                firstName={userData?.user?.firstName}
                lastName={userData?.user?.lastName}
              />
              <Card.Title
                className="my-2"
                style={{ fontWeight: 600, fontSize: 25 }}
              >
                {userData?.companyName
                  ? userData.companyName
                  : LangEN.CompanyName}
                <span>
                  {userData?.user?.isProfileVerified && (
                    <img
                      src={verified}
                      height={20}
                      style={{
                        paddingLeft: 25,
                        translate: "0 -2.5px",
                      }}
                    />
                  )}
                </span>
              </Card.Title>
              <Card.Text className="mt-3" style={ProfileStyles.title3}>
                {LangEN.FoundedIn + " "}
                <span style={{ color: "#3c65f5" }}>
                  -{" "}
                  {userData?.foundedin
                    ? moment(userData.foundedin).format("MMMM YYYY")
                    : // ? userData.foundedin.split("-")[0]
                      LangEN.NotSpecified}
                </span>
              </Card.Text>
              <Card.Title style={ProfileStyles.title4} className="my-3">
                {LangEN.AboutCompany}
              </Card.Title>
              <Card.Subtitle style={ProfileStyles.subTitle4}>
                {userData?.about ? userData.about : LangEN.NoDescription}
              </Card.Subtitle>
              <div className="my-3" style={ProfileStyles.divider} />
              <Card.Title style={{ fontWeight: 700, fontSize: 20 }}>
                {userData?.user?.firstName && userData.user.firstName}{" "}
                {userData?.user?.lastName && userData.user.lastName}
              </Card.Title>
              <Row style={{ alignItems: "center" }}>
                <Col className="col-auto">
                  <img src={mail} width={18} />
                </Col>
                <Col className={"p-0"}>
                  <Card.Subtitle
                    className="my-2"
                    style={ProfileStyles.subTitle5}
                  >
                    {userData?.user?.email && userData.user.email}
                  </Card.Subtitle>
                </Col>
              </Row>
              <Row style={{ alignItems: "center" }}>
                <Col className="col-auto">
                  <img src={phone} height={18} width={18} />
                </Col>
                <Col className={"p-0"}>
                  <Card.Subtitle
                    className="my-2"
                    style={ProfileStyles.subTitle5}
                  >
                    {userData?.user?.contact
                      ? userData.user.contact
                      : LangEN.NotSpecified}
                  </Card.Subtitle>
                </Col>
              </Row>
            </Card>
          </Row>
        )}
      </Container>
    </>
  );
}
