export enum projectType {
  NewProject = 0,
  ExistingProject = 1,
  OngoingProject = 2,
}

export enum jobStatus {
  Active = 1,
  Complete = 3,
  Expired = 2,
}
export enum jobDuration {
  OneWeek = 0,
  OneFourWeeks = 1,
  OneThreeMonths = 2,
  ThreeSixMonths = 3,
  MoreThanSixMonths = 4,
}

export enum jobStartsIn {
  Immediately = 0,
  OneFourWeeks = 1,
  OneTwoWeeks = 2,
  MoreThanTwoWeeks = 3,
}

export enum remoteResource {
  Yes = 0,
  No = 1,
  NotSure = 2,
}
export enum paymentDuration {
  Hourly = 0,
  Daily = 1,
  Default = 3,
}

export enum currencyType {
  Dollar = 0,
  Rupee = 1,
  Dinar = 2,
  Euro = 3,
  Default = 4,
}

export enum jobSkillsRequired {
  Beginner = 0,
  Intermediate = 1,
  MidLevelManagement = 2,
  ExecutiveOrSeniorLeadership = 3,
}

export enum professionalApplyAs {
  ProjectManager = 1,
  Architect = 2,
  Expert = 3,
}

export enum workExperience {
  ZeroToTwoYears = 0,
  TwoToFiveYears = 1,
  FiveToTenYears = 2,
  MoreThenTenYears = 3,
}

export enum resourceType {
  ProjectManager = 1,
  Architect = 2,
  Experts = 3,
}
