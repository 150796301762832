import { useState, useEffect, useContext } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import pencil from "../../assets/svg/icon/pencil.svg";
import mail from "../../assets/svg/icon/mail.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ProfileStyles } from "./ProfileStyle";
import { LangEN } from "../../constants/Lang/LangEN";
import CustomToast from "../../components/reusableComponents/CustomToast";
import UserService from "../../services/UserService";
import SkillChip from "../../components/reusableComponents/SkillChip";
import { professionalAvailability } from "../../enums/ProfessionalAvailability";
import TextAvatar from "../../components/reusableComponents/TextAvatar";
import Loader from "../../components/reusableComponents/Loader";
import { User } from "../../App";
import {
  mapJobRole,
  mapWorkExperience,
  requiredResourceType,
  setCurrencyType,
  setPaymentDuration,
} from "../../Utils/Mappers";
import { LightChip } from "../../components/reusableComponents/LightChip";
import JobSApplicationService from "../../services/JobApplicationService";
import Avatar from "../../components/reusableComponents/Avatar";

export default function ProfessionalProfile() {
  const navigate = useNavigate();
  const user = useContext(User);
  const [toastShown, setToastShown] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState("");
  const [totalJobsCompleted, setTotalJobsCompleted] = useState();
  useEffect(() => {
    UserService.ViewProfile()
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);
        user.setUser(res.data);
        localStorage.setItem("userData", JSON.stringify(res.data));
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    UserService.GetReferralCode()
      .then((res) => {
        setCode(res.data.referralCode);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    JobSApplicationService.TotalCompletedJobs()
      .then((res) => {
        setTotalJobsCompleted(res.data.totalJobsCompleted);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <CustomToast // toast for error messages
        isShown={toastShown}
        hide={() => {
          setToastShown(false);
        }}
        message={"Complete your Profile before creating a project."}
      />
      <Container style={ProfileStyles.container}>
        {loading ? (
          <Loader lg />
        ) : (
          <Row className="py-5 px-0 gy-3">
            <Col
              className="col-12 col-xl-4 ps-4 pe-4"
              style={{
                flexDirection: "column",
              }}
            >
              <Card style={ProfileStyles.card1} className="py-4">
                <NavLink to="/profile/edit" style={ProfileStyles.FAB}>
                  <img src={pencil} width={15} />
                </NavLink>
                <div>
                  <Avatar
                    url={userData?.profilePicture}
                    size={70}
                    fontSize={28}
                    firstName={userData?.user?.firstName}
                    lastName={userData?.user?.lastName}
                  />
                </div>

                <Card.Title className="mt-2" style={ProfileStyles.title}>
                  {`${
                    userData?.user?.firstName ? userData.user.firstName : ""
                  }${
                    userData?.user?.lastName ? " " + userData.user.lastName : ""
                  }`}
                </Card.Title>
                <Card.Subtitle style={ProfileStyles.subTitle}>
                  {/* {userData?.currentRole && userData.currentRole} */}
                  {userData?.applyAs && mapJobRole(userData.applyAs)}
                </Card.Subtitle>
                <div className="my-3" style={ProfileStyles.divider} />

                <Card.Subtitle className="my-2" style={ProfileStyles.subTitle2}>
                  <Row
                    className="d-flex align-items-center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Col className="col-auto  ">
                      <img src={mail} width={18} />
                    </Col>
                    <Col className={"p-0 ellipsis"}>
                      {userData?.user?.email && userData.user.email}
                    </Col>
                  </Row>
                </Card.Subtitle>

                {/* <Card.Subtitle className="my-2" style={ProfileStyles.subTitle2}>
                  <Row className="d-flex align-items-center">
                    <Col className="col-auto">
                      <img src={qualification} width={18} />
                    </Col>
                    <Col className={"p-0"}>
                      Graduate
                      
                    </Col>
                  </Row>
                </Card.Subtitle> */}

                {!user.accStatus.isProfileComplete && (
                  <>
                    <div className="my-3" style={ProfileStyles.divider} />
                    <Button
                      style={{ height: 45 }}
                      className="mt-2 "
                      onClick={() => [navigate("/profile/edit")]}
                    >
                      {LangEN.CompleteProfile}
                    </Button>
                  </>
                )}
              </Card>
              <Card className="my-3 px-3" style={ProfileStyles.card2}>
                <Card.Text className="p-3">
                  {LangEN.MyReferralCode} :{" "}
                  <span style={{ color: "blue" }}>
                    {code ? code.toLocaleUpperCase() : "N/A"}
                  </span>
                </Card.Text>
              </Card>
            </Col>
            <Col className="col-12 col-xl-8 px-4">
              <Card className="py-4" style={ProfileStyles.card3}>
                <Card.Title className="px-5 pt-4" style={ProfileStyles.title2}>
                  {userData?.currentRole
                    ? userData.currentRole
                    : LangEN.NoCurrentRoleSpecified}
                </Card.Title>
                <Card.Subtitle
                  className="px-5 pt-4"
                  style={ProfileStyles.subTitle3}
                >
                  {userData?.aboutMe ? userData.aboutMe : LangEN.NoDescription}
                </Card.Subtitle>
                <div className="my-4" style={ProfileStyles.divider} />
                <Card.Title className="px-5" style={ProfileStyles.title5}>
                  {LangEN.Skills}
                </Card.Title>
                <div
                  style={ProfileStyles.skillsContainer}
                  className="px-5 my-3"
                >
                  {userData?.skill &&
                    userData.skill.map((item: any, index: number) => (
                      <SkillChip index={index} label={item.skill.name} />
                    ))}
                  {userData?.customSkill &&
                    userData.customSkill.map((item: any, index: number) => (
                      <SkillChip index={index} label={item.skillName} />
                    ))}
                  {userData?.skill?.length + userData.customSkill?.length ==
                    0 && LangEN.NotSpecified}
                  {userData?.skill == undefined &&
                    userData?.customSkill == undefined &&
                    LangEN.NotSpecified}
                </div>
                <Card.Title className="px-5 pt-3" style={ProfileStyles.title5}>
                  {LangEN.Qualification}
                </Card.Title>
                <div
                  style={ProfileStyles.skillsContainer}
                  className="px-5 my-3"
                >
                  {userData?.qualification &&
                    userData.qualification.map((item: any, index: number) => (
                      <LightChip
                        haveBorder
                        index={index}
                        label={item.qualification.value}
                      />
                    ))}
                  {userData?.qualification?.length == 0 && LangEN.NotSpecified}
                  {userData?.qualification == undefined && LangEN.NotSpecified}
                </div>
                <Row
                  className="p-4 px-5 gap-4 gap-sm-0"
                  style={ProfileStyles.detailsContainer}
                >
                  <Col className="col-12 col-sm-6">
                    Rate :{" "}
                    <span style={ProfileStyles.spanText}>
                      {userData?.hourlyRate
                        ? `${setCurrencyType(userData?.currencyType)}${
                            userData.hourlyRate
                          }${setPaymentDuration(userData?.paymentDuration)}`
                        : LangEN.NotSpecified}
                    </span>
                  </Col>
                  <Col className="col-12 col-sm-6">
                    Availability :{" "}
                    <span style={ProfileStyles.spanText}>
                      {userData?.availability !== undefined
                        ? requiredResourceType(userData.availability)
                        : LangEN.NotSpecified}
                    </span>
                  </Col>
                </Row>
                <Row
                  className="p-4 px-5 gap-4 gap-sm-0"
                  style={ProfileStyles.detailsContainer}
                >
                  <Col className="col-12 col-sm-6">
                    Total Jobs Completed :{" "}
                    <span style={ProfileStyles.spanText}>
                      {totalJobsCompleted}
                    </span>
                  </Col>
                  <Col className="col-12 col-sm-6">
                    Work Experience :{" "}
                    <span style={ProfileStyles.spanText}>
                      {userData?.workExperience != undefined
                        ? mapWorkExperience(userData.workExperience)
                        : LangEN.NotSpecified}
                    </span>
                  </Col>
                </Row>
                <Row
                  className="p-4 px-5 gap-4 gap-sm-0"
                  style={ProfileStyles.detailsContainer}
                >
                  <Col>
                    Linkedin :{" "}
                    <span style={ProfileStyles.spanText}>
                      {userData?.linkedinURL ? (
                        <a
                          target="_blank"
                          href={
                            userData.linkedinURL.slice(0, 4) == "http"
                              ? userData.linkedinURL
                              : "//" + userData.linkedinURL
                          }
                        >
                          {userData.linkedinURL}
                        </a>
                      ) : (
                        LangEN.NotSpecified
                      )}
                    </span>
                  </Col>
                </Row>
                {userData?.cv ? (
                  <Row
                    className="p-4 px-5 gap-4 gap-sm-0"
                    style={ProfileStyles.detailsContainer}
                  >
                    <Col
                    // onClick={() => {
                    //   window.open(userData.cv);
                    // }}
                    // style={{ cursor: "pointer" }}
                    >
                      {/* <img
                        style={{ borderRadius: 5, objectFit: "cover" }}
                        height={100}
                        width={100}
                        alt="CV"
                        src={userData.cv}
                      /> */}
                      Curriculum Vitae : <a href={userData.cv}>Download CV</a>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
