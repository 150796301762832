import { Form } from "react-bootstrap";
import { imageConverter } from "../../Utils/helper";
import download from "../../assets/svg/icon/download.svg";
import dashedBorder from "../../assets/png/dashedBorder.png";
import { LangEN } from "../../constants/Lang/LangEN";

function CustomImageInput({
  image,
  setImage,
  setImageError,
  imageError,
  setImageFile,
}: {
  image: any;
  setImage: any;
  setImageError: any;
  imageError: string;
  setImageFile: Function;
}) {
  return (
    <Form.Group>
      <div className="my-3 mt-5">
        <label
          htmlFor="img-upload"
          style={{
            width: 120,
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            imageConverter(e.dataTransfer, setImage, setImageError);
          }}
          onDragEnter={(e) => {
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            e.preventDefault();
          }}
        >
          {image ? (
            <img
              src={image?.src}
              width={120}
              height={120}
              style={{ objectFit: "cover" }}
            />
          ) : (
            <img width={24} src={download} />
          )}
          <img
            width={120}
            src={dashedBorder}
            style={{
              position: "absolute",
            }}
          />
        </label>
      </div>
      <input
        style={{
          width: 120,
          height: 120,
          position: "absolute",
          translate: "0 -140px ",
          display: "none",
        }}
        id={"img-upload"}
        type={"file"}
        accept="image/*"
        onChange={(e: any) => {
          if (e.target.files[0]?.size > 5000000) {
            setImageError("Image size must be less than 5mb");
          } else {
            setImageFile(e.target.files);
            setImageError("");
            imageConverter(e.target, setImage, setImageError);
          }
        }}
      />
      <Form.Label>{LangEN.UpdateImage}</Form.Label>
      <label
        className="invalid-feedback mb-4"
        style={{
          display: !imageError ? "none" : "block",
        }}
      >
        {imageError}
      </label>
    </Form.Group>
  );
}

export default CustomImageInput;
