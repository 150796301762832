import Theme from "../../constants/Theme";

export const HeaderStyles = {
    navBrand: {
        color: Theme.colors.WHITE,
    },
    navItem: {
        fontSize: "25px",
        color: "#fff",
        padding: "10px",
        textDecoration: "none",
    },

    navItemActive: {
        fontWeight: 600,
        fontSize: "25px",
        color: "#3C65f5",
        padding: "10px",
        textDecoration: "none",
    },
    navLink: {
        color: Theme.colors.WHITE,
        fontSize: "20px",
        fontWeight: "300",
    },
    darkButton: {
        paddingRight: "20px",
        paddingLeft: "20px",
        // width: "100%",
        backgroundColor: Theme.backgroundColor.DARKBUTTON,
        borderRadius: "30px",
        color: Theme.colors.WHITE,
        fontSize: "18px",
        borderWidth: 0,
    },
    whiteButton: {
        paddingRight: "20px",
        paddingLeft: "20px",
        // width: "100%",
        color: Theme.colors.SECONDARY,
        borderRadius: "30px",
        backgroundColor: Theme.colors.WHITE,
        borderWidth: 0,
        fontSize: "18px",
    },
    lightButton: {
        color: Theme.colors.WHITE,
        borderRadius: "30px",
        width: "100%",
        fontSize: "18px",
    },
};
