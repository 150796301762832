import "../../../screens/CreateProject/styles.css";
import { Modal, Row, Col, Form, Button, Card } from "react-bootstrap";
import "./styles.css";
import searchLogo from "../../../assets/svg/icon/whiteSearch.svg";
import { LangEN } from "../../../constants/Lang/LangEN";
import ProjectCard from "../../reusableComponents/ProjectCard";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import ProjectService from "../../../services/ProjectServices";
import JobSApplicationService from "../../../services/JobApplicationService";
import { UserRoles } from "../../../enums/UserRoles";
import Loader from "../../reusableComponents/Loader";
import { getTimeBetweenDates } from "../../../Utils/Mappers";
import { debounce } from "lodash";

function InviteOnProject({
  show,
  setShow,
  notProjectRoute,
  userId,
  flow,
  role,
}: {
  notProjectRoute?: boolean;
  show: boolean;
  setShow: any;
  userId: number | undefined;
  flow?: number;
  role?: number;
}) {
  const [projectList, setProjectList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(1);

  useEffect(() => {
    if (!show) {
      setSearchValue("");
      return;
    }
    setLoading(true);
    if (flow === UserRoles.Professional) {
      JobSApplicationService.GetHiredJob(searchValue)
        .then((res) => {
          console.log(res.data);
          setProjectList(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      ProjectService.ProjectList({ title: searchValue })
        .then((res) => {
          console.log(res.data);
          setProjectList(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [show, searchValue]);

  const getWeeks = (startDate: string, endDate: string) => {
    let projectStartDate: any = moment(new Date(startDate));
    let projectEndDate: any = moment(new Date(endDate));
    let diff = moment.duration(projectEndDate.diff(projectStartDate));
    return `${Math.floor(diff.asWeeks())} Weeks`;
  };

  const getProjectMAnagerCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 1 ? item.resourceCount : null;
    });
  };

  const getExpertsCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 3 ? item.resourceCount : null;
    });
  };

  const getArchitectCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 2 ? item.resourceCount : null;
    });
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
      contentClassName="p-4 inviteProject_container"
    >
      <div className="inviteProject_innerContainer ">
        <div className="inviteProject_title my-2">{LangEN.SearchProjects}</div>
        <Row className="mb-3">
          <Col className="me-0 pe-0">
            <Card className="h-100">
              <Form.Control
                style={{ outline: "none", border: "none" }}
                className="h-100 shadow-none"
                placeholder={LangEN.Search}
                onChange={debounce((e) => setSearchValue(e.target.value), 500)}
              />
            </Card>
          </Col>
          <Col xs="auto">
            <Button className="p-2 px-3">
              <img src={searchLogo} width={26} />
            </Button>
          </Col>
        </Row>
        <div className="inviteProject_title my-2">{LangEN.ProjectList}</div>
        {loading ? (
          <Loader lg />
        ) : (
          <div className="inviteProject_listScroll">
            {projectList.length == 0 ? (
              <div
                style={{
                  minWidth: "100%",
                  minHeight: "100%",
                  fontSize: 24,
                  marginTop: 100,
                  textAlign: "center",
                }}
              >
                {flow === UserRoles.Professional
                  ? "No project joined"
                  : "No project created"}
              </div>
            ) : (
              ""
            )}
            {projectList.map((project: any, index: number) => {
              return (
                <ProjectCard
                  notProjectRoute={notProjectRoute}
                  isProfessional
                  projectId={
                    flow === UserRoles.Professional
                      ? project.job?.project.id
                      : project.id
                  }
                  title={
                    flow === UserRoles.Professional
                      ? project.job?.project.name
                      : project.name
                  }
                  subtitle={
                    flow === UserRoles.Professional
                      ? project.job?.project.projectDescription
                      : project.projectDescription
                  }
                  requiredArchitect={
                    flow === UserRoles.Professional
                      ? getArchitectCount(project.job?.project)
                      : getArchitectCount(project)
                  }
                  requiredExperts={
                    flow === UserRoles.Professional
                      ? getExpertsCount(project.job?.project)
                      : getExpertsCount(project)
                  }
                  requiredPM={
                    flow === UserRoles.Professional
                      ? getProjectMAnagerCount(project.job?.project)
                      : getProjectMAnagerCount(project)
                  }
                  projectDuration={
                    flow === UserRoles.Professional
                      ? project.job?.project.projectStartDate ==
                        project.job?.project.projectEndDate
                        ? "a day"
                        : getTimeBetweenDates(
                            project.job?.project.projectStartDate,
                            project.job?.project.projectEndDate
                          )
                      : project.projectStartDate == project.projectEndDate
                      ? "a day"
                      : getTimeBetweenDates(
                          project.projectStartDate,
                          project.projectEndDate
                        )
                  }
                  userId={userId}
                  role={role}
                />
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default InviteOnProject;
