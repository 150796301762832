import Loader from "../../components/reusableComponents/Loader";
import { useEffect } from "react";
import JobService from "../../services/JobService";
import { useNavigate, useParams } from "react-router-dom";

function Redirect({ type }: { type?: string }) {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (type == "invite") {
      let { jobId, invitedBy } = params;
      JobService.GetJobById(jobId)
        .then((res) => {
          navigate("/jobs/invitation", {
            state: { ...res.data, invitedBy: invitedBy },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type == "received") {
      const { jobId } = params;
      JobService.GetJobById(jobId)
        .then((res) => {
          navigate("/projects/details/jobapplications", {
            state: { id: jobId, project: res.data.project },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return <Loader lg />;
}

export default Redirect;
