import { LangEN } from "./Lang/LangEN";
export const paymentDurationArray = ["hr", "day"];
export const currencyTypeArray = ["$", "₹", "د.ك", "€"];
export const currentRoleArray = [
  "",
  LangEN.ProjectManager,
  LangEN.Architect,
  LangEN.Experts,
];
export const currentRoleSubtitleArray = [
  "",
  LangEN.ProjectManagerSubtitle,
  LangEN.ArchitectSubtitle,
  LangEN.ExpertsSubtitle,
];
export const typeOfProjectArray = [
  LangEN.NewIdeaOrProject,
  LangEN.ExistingProjectThatNeedsMoreResources,
  LangEN.OngoingAssistanceOrConsultation,
];

export const resourceDurationArray = [
  LangEN.LessThanWeek,
  LangEN.OneWeeks,
  LangEN.OneMonths,
  LangEN.ThreeMonths,
  LangEN.LongerThanMonths,
];
export const levelOfCommitmentArray = [
  LangEN.PartTime,
  LangEN.FullTime,
  LangEN.Hourly,
];
export const whenToStartArray = [
  LangEN.Immediately,
  "",
  LangEN.InOneTwoWeeks,
  LangEN.MoreThanTwoWeeks,
];
export const contributionLevelArray = [
  LangEN.Beginner,
  LangEN.Intermediate,
  LangEN.MidLevelManagement,
  LangEN.ExecutiveSeniorLeadership,
];
export const remoteResourceArray = [LangEN.Yes, LangEN.No, LangEN.NotSure];
