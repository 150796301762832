import React from "react";
import { Button } from "react-bootstrap";
import { Player } from "@lottiefiles/react-lottie-player";
import OtpInput from "react18-input-otp";
import Loader from "../../assets/json/loader1.json";
import next from "../../assets/svg/icon/next.svg";
import saved from "../../assets/svg/icon/saved.svg";
import unSave from "../../assets/svg/icon/unsave.svg";
import continueIcon from "../../assets/svg/icon/continue.svg";
import { useNavigate } from "react-router-dom";

interface ButtonInterface {
  label: string;
  onClick?: any;
  style?: any;
  variant?: string;
  href?: string;
  type?: any;
  className?: string;
  size?: "sm" | "lg" | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  iconType?: string;
}

const CustomButton = (props: ButtonInterface) => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        disabled={props.disabled}
        variant={props.variant}
        // href={props.href}
        style={props.style}
        onClick={(e: any) => {
          if (props.isLoading) return;
          if (props.href) navigate(props.href);
          props.onClick && props.onClick(e);
        }}
        type={props.type}
        className={props.className}
        size={props.size}
      >
        <div
          style={{
            width: "100%",
            height: "30px",
            justifyContent: "center",
            alignItems: "center",
            display: props.isLoading ? "flex" : "none",
            position: "relative",
          }}
        >
          <Player
            src={Loader}
            style={{
              aspectRatio: 1,
              height: 80,
              position: "absolute",
              top: -25,
              translate: "-50%",
            }}
            autoplay
            loop
          />
        </div>
        <div
          style={{
            display: "flex",
            height: props.isLoading ? 0 : undefined,
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          {props.iconType == "previous" && (
            <img
              src={next}
              width={10}
              style={{ rotate: "180deg", filter: "invert()" }}
            />
          )}
          <div style={{ flexShrink: 0, paddingInline: 5 }}>{props.label}</div>
          {props.iconType == "next" && <img src={next} width={10} />}
          {props.iconType == "continue" && (
            <img src={continueIcon} width={15} />
          )}
          {props.iconType == "save" && <img src={unSave} width={15} />}
          {props.iconType == "saved" && <img src={saved} width={15} />}
        </div>
      </Button>
    </div>
  );
};

export default CustomButton;
