import { Col, Form, Row } from "react-bootstrap";
import { LangEN } from "../../../constants/Lang/LangEN";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import JobPreviewFields from "../../../components/reusableComponents/JobPreviewFields";
import {
  currentRoleArray,
  resourceDurationArray,
  typeOfProjectArray,
  levelOfCommitmentArray,
  whenToStartArray,
} from "../../../constants/enumLabel";
import {
  remoteResourceArray,
  currentRoleSubtitleArray,
} from "../../../constants/enumLabel";
import {
  paymentDurationArray,
  currencyTypeArray,
} from "../../../constants/enumLabel";
import { useState } from "react";
import JobService from "../../../services/JobService";
import CustomModal from "../../../components/Modals/CustomModal";
import { contributionLevelArray } from "../../../constants/enumLabel";
import { jobStatus } from "../../../enums/CreateJobEnums";
import CustomSkillService from "../../../services/CustomSkillService";
import { mapWorkExperience } from "../../../Utils/Mappers";

function JobPreview({
  step,
  setStep,
  data,
  setSkills,
  showModal,
  description,
  setDescription,
  isEdit,
  setCustomSkills,
  customSkillsPrevious,
  setCustomSkillsPrevious,
  customSkills,
}: {
  isEdit?: Boolean;
  step: number;
  setStep: Function;
  description: string;
  setDescription: Function;
  data: any;
  setSkills: Function;
  customSkills: any;
  setCustomSkills: Function;
  showModal: Function;
  customSkillsPrevious: any;
  setCustomSkillsPrevious: Function;
}) {
  const navigate = useNavigate();
  // const { id } = useParams();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const createJob = () => {
    data.description = description.trimEnd();
    // data.skillLevel = 1;
    data.jobStatus = jobStatus.Active;
    data.projectId = parseInt(state.id || "");
    console.log("-->", data);
    setIsLoading(true);
    JobService.CreateJob(data)
      .then((res) => {
        console.log(res);
        // setShowSuccessModal(true);
        // setIsLoading(false);
        createCustomSkill(res.data.id);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const EditJob = () => {
    data.description = description.trimEnd();
    // data.skillLevel = 1;
    data.jobStatus = jobStatus.Active;
    data.id = state.jobId;
    data.projectId = parseInt(state.id || "");
    console.log("-->", data);

    const deletedCustomSkills = customSkillsPrevious.filter((item: any) => {
      return !customSkills.includes(item.skillName);
    });

    deletedCustomSkills.map((item: any) => {
      deleteCustomSkills(item.id);
    });

    const customSkillsAdded = customSkills.filter((item: any) => {
      let match = 0;
      customSkillsPrevious.map((innerItem: any) => {
        if (innerItem.skillName == item) {
          match += 1;
        }
      });
      return match == 0;
    });

    console.log("deleted =>", deletedCustomSkills);
    console.log("added =>", customSkillsAdded);

    setIsLoading(true);

    updateCustomSkills(state.jobId, customSkillsAdded);
    JobService.EditJob(data)
      .then((res) => {
        console.log(res);
        setShowSuccessModal(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updateCustomSkills = (jobId: any, customSkills: any) => {
    let apiReq = {
      jobId: jobId,
      customSkill: customSkills,
    };

    CustomSkillService.AddCustomJobSkills(apiReq)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createCustomSkill = (id: number) => {
    let apiReq = {
      jobId: id,
      customSkill: data.customSkill,
    };

    CustomSkillService.AddCustomJobSkills(apiReq)
      .then((res) => {
        setShowSuccessModal(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteCustomSkills = (id: any) => {
    CustomSkillService.DeleteCustomJobSkills(id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="createJob_container2 px-lg-5">
      <CustomModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        label={
          isEdit ? LangEN.JobUpdatedSuccessfully : LangEN.JobPostedSuccessfully
        }
        type="success"
        buttonText={LangEN.OK}
        action={() => {
          navigate(-1);
        }}
      />
      <Row className="createJob_contentContainer">
        <Col>
          <JobPreviewFields
            title={currentRoleArray[data.resourceType]}
            subtitle={currentRoleSubtitleArray[data.resourceType]}
            step={1}
            setStep={setStep}
            lg
            hideEdit={!!isEdit}
          />
          <div className="createJob_title">{LangEN.JobDescription}*</div>
          <Form.Control
            as="textarea"
            className="createJob_input my-2"
            maxLength={500}
            value={description}
            onChange={(e) => setDescription(e.target.value.trimStart())}
          />
          <div className="horizontal-divider my-4"></div>
          <JobPreviewFields
            title={LangEN.Skills}
            step={3}
            setStep={setStep}
            skills={data.skills}
            setSkills={setSkills}
            customSkills={data.customSkill}
            setCustomSkills={setCustomSkills}
          />
          <Row>
            <Col className="col-12 col-md-5">
              <JobPreviewFields
                title={LangEN.Budget}
                subtitle={`${currencyTypeArray[data.currencyType]} ${
                  data.budget
                }/ ${paymentDurationArray[data.paymentDuration]}`}
                step={6}
                setStep={setStep}
              />
              <JobPreviewFields
                title={LangEN.Duration}
                subtitle={resourceDurationArray[data.jobDuration]}
                step={2}
                setStep={setStep}
              />
              <JobPreviewFields
                title={LangEN.ProjectType}
                subtitle={typeOfProjectArray[data.projectType]}
                step={2}
                setStep={setStep}
              />
              <JobPreviewFields
                title={LangEN.ResourceTime}
                subtitle={levelOfCommitmentArray[data.requiredResourceType]}
                step={3}
                setStep={setStep}
              />
            </Col>
            <Col className="col-2 d-none d-md-block createJob_center ">
              <div className="vertical-divider" />
            </Col>
            <Col className="col-12 col-md-5">
              <JobPreviewFields
                title={LangEN.WFH}
                subtitle={remoteResourceArray[data.remoteResource]}
                step={6}
                setStep={setStep}
              />
              <JobPreviewFields
                title={LangEN.ContributionLevel}
                subtitle={mapWorkExperience(data.skillLevel)}
                step={4}
                setStep={setStep}
              />
              <JobPreviewFields
                title={LangEN.WhenToStart}
                subtitle={whenToStartArray[data.jobStartBy]}
                step={4}
                setStep={setStep}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="d-flex align-item-center createJob_spaceBetween">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="col-auto createJob_index"
        ></Col>
        <Col className="col-auto">
          <Row className="gy-3 gx-1">
            <Col>
              <CustomButton
                className="createJob_cancel px-4"
                label={LangEN.Cancel}
                onClick={() => showModal()}
              />
            </Col>
            <Col>
              <CustomButton
                disabled={!description}
                className="createJob_next "
                label={isEdit ? LangEN.UpdateJob : LangEN.PostThisJob}
                isLoading={isLoading}
                onClick={() => {
                  isEdit ? EditJob() : createJob();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default JobPreview;
