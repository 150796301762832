import "../../../screens/CreateProject/styles.css";
import { Modal } from "react-bootstrap";
import "./styles.css";
import { LangEN } from "../../../constants/Lang/LangEN";
import arrow from "../../../assets/svg/icon/backArrow.svg";
import NotificationCard from "../../NotificationCard";
import NotificationService from "../../../services/NotificationService";
import { useEffect, useState } from "react";
import { notificationType } from "../../../enums/NotificationTypes";

function NotificationModal({
  show,
  setShow,
  notification,
  setNotification,
  sendNotificationAction,
}: {
  show: boolean;
  setShow: Function;
  notification: any;
  setNotification: Function;
  sendNotificationAction: Function;
}) {
  useEffect(() => {
    NotificationService.GetNotifications()
      .then((res) => {
        setNotification(res.data);
        const verificationArr = res.data.filter(
          (item: any) =>
            item.notificationType == notificationType.ProfileVerification
        );
        verificationArr.length > 0 &&
          sendNotificationAction(verificationArr[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const readAllNotifications = () => {
    NotificationService.MarkAllNotificationsRead()
      .then((res) => {
        setNotification([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
      className="NM_modal"
      animation={true}
      transition="fade"
    >
      <div className="header m-4">
        <div className="title">{LangEN.Notifications}</div>
        <img
          onClick={() => setShow(false)}
          src={arrow}
          width={18}
          style={{ rotate: "180deg", cursor: "pointer" }}
          alt={"notification"}
        />
      </div>
      <div className=" ms-4 horizontal-divider" />
      {notification.length ? (
        <div
          onClick={() => readAllNotifications()}
          className="px-3 pt-2 readAll"
        >
          Mark all as read
        </div>
      ) : (
        <div className="p-5">No new notification</div>
      )}
      <div className="py-4 pt-0 overflow-auto">
        {notification.map((item: any, index: number) => (
          <>
            <NotificationCard
              item={item}
              setNotification={setNotification}
              index={index}
              onClick={(index: number) => {
                setShow(false);
              }}
            />
          </>
        ))}
      </div>
    </Modal>
  );
}

export default NotificationModal;
