import notFound from "../../assets/svg/notFoundSearch.svg";
import errorWarning from "../../assets/svg/errorWarning.svg";
import { LangEN } from "../../constants/Lang/LangEN";
import CustomButton from "./CustomButton";

function EmptyList({
  error,
  jobApplyAsError,
  saved,
  applied,
}: {
  jobApplyAsError?: boolean;
  error?: boolean;
  saved?: boolean;
  applied?: boolean;
}) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: 20,
        marginBottom: 40,
      }}
    >
      <img
        src={error ? errorWarning : notFound}
        style={{ width: "min( max( 35vw , 180px ) , 500px )" }}
      />
      <div
        style={{
          color: "#05264E",
          fontSize: 32,
          fontWeight: 600,
          padding: 10,
          textAlign: "center",
        }}
      >
        {error
          ? LangEN.SomethingWentWrong
          : jobApplyAsError
          ? LangEN.ProfileNotCompleted
          : LangEN.NoResultsFound}
      </div>
      <div style={{ color: "#66789C", fontSize: 22, textAlign: "center" }}>
        {error
          ? LangEN.PleaseTryAgain
          : jobApplyAsError
          ? LangEN.CompleteProfileToSearchJobs
          : saved
          ? LangEN.NoSaveJobs
          : applied
          ? LangEN.NoAppliedJob
          : LangEN.PleaseTryAnotherSearch}
      </div>

      {jobApplyAsError ? (
        <CustomButton
          href="/profile/edit"
          label={LangEN.CompleteProfile}
          style={{ margin: 40, padding: "10px 20px", fontSize: 20 }}
        />
      ) : (
        !saved &&
        !applied && (
          <CustomButton
            className="m-3"
            label={LangEN.Retry}
            onClick={() => window.location.reload()}
          />
        )
      )}
    </div>
  );
}

export default EmptyList;
