import React from "react";
import { jobStatus } from "../../enums/CreateJobEnums";

function JobStatusChip({ status }: any) {
  if (status == jobStatus.Active)
    return (
      <span
        style={{
          fontSize: 13,
          fontWeight: 400,
          borderRadius: 5,
          backgroundColor: "green",
          color: "white",
          padding: "3px 10px",
          marginInline: 10,
        }}
      >
        Active
      </span>
    );
  if (status == jobStatus.Complete)
    return (
      <span
        style={{
          fontSize: 13,
          fontWeight: 600,
          borderRadius: 5,
          backgroundColor: "yellow",
          border: "1px solid #777",
          color: "#444",
          padding: "3px 10px",
          marginInline: 10,
        }}
      >
        Completed
      </span>
    );

  return (
    <span
      style={{
        fontSize: 13,
        fontWeight: 400,
        borderRadius: 5,
        backgroundColor: "red",
        color: "white",
        padding: "3px 10px",
        marginInline: 10,
      }}
    >
      Expired
    </span>
  );
}

export default JobStatusChip;
