import Http from "./HttpService";
import { API } from "../constants/ApiDetails";

class JobInvitationService {
  public static async SendInvite(invitation: any) {
    const url = `${API.SEND_JON_INVITATION}`;
    const response = await Http.post(url, invitation);
    return response;
  }

  public static async IsInvited() {
    const url = `${API.SEND_JON_INVITATION}`;
    const response = await Http.get(url);
    return response;
  }

  public static async UpdateStatus(apiReq: any) {
    const url = `${API.UPDATE_JON_INVITATION_STATUS}`;
    const response = await Http.put(url, apiReq);
    return response;
  }

  public static async GetStatus(apiReq: any) {
    const url = `${API.GET_JON_INVITATION_STATUS}`;
    const response = await Http.put(url, apiReq);
    return response;
  }

  public static async IsInvitedForJob(apiReq: any) {
    const url = `${API.IS_INVITED}`;
    const response = await Http.post(url, apiReq);
    return response;
  }
}

export default JobInvitationService;
