export const EditProfileStyle = {
  InputDesign2: { borderRadius: 4, borderColor: "#CCCCCC", height: "60px" },
  InputDesignDate: {
    borderRadius: 4,
    height: "60px",
    borderColor: "#3c65f5",
    borderWidth: 2,
    color: "#3c65f5",
    fontWeight: 500,
  },
  InputDesignMultiline: {
    borderRadius: 4,
    minHeight: "80px",
    height: "120px",
    borderColor: "#CCCCCC",
    maxHeight: "180px",
    fontSize: 17,
    color: "#888",
  },
  container: {
    padding: "40px",
    paddingInline: "5vw",
    position: "relative" as "relative",
    minWidth: "100vw",
  },
  card: {
    minWidth: "min(1200px , 100%)",
    maxWidth: 1200,
  },
  card1: {
    minWidth: "min(800px , 100%)",
    maxWidth: 800,
  },
  label1: {
    fontWeight: 700,
    fontSize: "clamp(25px , 15vw, 32px)",
  },
  DragAndDrop: {
    width: 120,
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  input1: {
    width: 120,
    height: 120,
    position: "absolute" as "absolute",
    translate: "0 -140px ",
    display: "none",
  },
  label2: {
    fontSize: 22,
    fontWeight: 500,
  },
  col2: {
    display: "flex",
    justifyContent: "space-around",
  },
  header: {
    textDecoration: "underline",
    fontWeight: "600",
    color: "#66789c",
    fontSize: 22,
    letterSpacing: 0.9,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#ccc",
  },
  verticalDivider: {
    width: 1,
    height: "100%",
    backgroundColor: "#ddd",
  },
  phoneInputStyle: {
    width: "100%",
    height: "60px",
    borderRadius: "5px",
    marginLeft: 0,
    paddingLeft: 10,
    border: "1px solid #ccc",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#0c64e6",
    width: "100px",
    borderRadius: 4,
  },
  submitButton: {
    width: "100px",
    borderRadius: 4,
  },
  inputField: { borderRadius: 0, height: 50 },
  checkButton: { color: "#66789c" },
  labelBlue: {
    fontSize: 20,
    color: "#3c64f3",
    fontWeight: 600,
  },
  railStyle: {
    backgroundColor: "#ddd",
    height: 8,
  },
  trackStyle: {
    backgroundColor: "#3c64f3",
    height: 8,
  },
  handleStyle: {
    borderColor: "#3c64f3",
    borderWidth: 7,
    height: 24,
    width: 24,
    marginLeft: 5,
    marginTop: -8,
    backgroundColor: "#fff",
    opacity: 1,
  },
  textArea: {
    padding: 15,
    minHeight: 80,
    maxHeight: 200,
    height: 120,
    backgroundColor: "#f2f6fd",
  },
};
