import React from "react";
import "./styles.css";
import arrow from "../../../assets/svg/icon/next.svg";
import { useNavigate } from "react-router-dom";
function BreadCrumbs({ data }: { data?: any }) {
  const navigate = useNavigate();

  if (!data) return <></>;
  if (data.length == 0) return <></>;

  return (
    <div className="breadCrumbs my-2 py-2">
      {data?.map((item: any, index: number) => {
        return (
          <>
            <div
              onClick={() => {
                if (index == data.length - 1) return;
                if (item.state)
                  return navigate(item.route, { state: item.state });
                navigate(item.route);
              }}
              className={index != data.length - 1 ? "active" : "unActive"}
            >
              {item.label}
            </div>
            {index != data?.length - 1 && (
              <div className="arrowContainer">
                <img src={arrow} width={10} />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}

export default BreadCrumbs;
