import Background from "../../../assets/png/loginBanner.png";
import Theme from "../../../constants/Theme";

export const LoginStyles = {
  row1: {
    marginTop: "20px",
  },
  img1: {
    backgroundColor: "#F1F3F6",
    width: "50px",
    height: "50px",
    borderRadius: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "3% 0 0 5%",
  },
  img2: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
  col1: {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  row2: {
    display: "flex important",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  labelDiv: {
    display: "flex",
    paddingLeft: "20%",
    flexDirection: "column" as "column",
  },
  label1: {
    color: Theme.colors.WHITE,
    fontSize: "35px",
    fontWeight: 300,
  },
  label2: {
    color: Theme.colors.WHITE,
    fontSize: "75px",
    fontWeight: 300,
  },
  col2: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
  },
  label3: {
    fontSize: "55px",
    fontWeight: 300,
    padding: "0 0 0 10%",
    position: "relative" as "relative",
    zIndex: 9,
  },

  subLabel: {
    fontSize: "16px",
    fontWeight: 300,
    padding: "0 0 0 10%",
  },
  form: {
    padding: "3% 10%",
  },
  checkBox: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
  },
  button3: {
    backgroundColor: "#3C65F5",
    padding: "6px 10px",
    borderRadius: "40px",
    fontSize: 18,
  },
  button: {
    backgroundColor: Theme.backgroundColor.DARKBUTTON,
    padding: "12px 0px",
    borderRadius: "8px",
    width: "100%",
  },
  button2: {
    backgroundColor: "white",
    padding: "6px 0px",
    border: "none",
    color: "#000",
    fontWeight: "600",
    fontSize: 18,
  },
  labelDiv2: {
    display: "flex",
    flexDirection: "column" as "column",
    paddingTop: "15px",
  },
  formControl: {
    height: "60px",
    borderRadius: "5px",
    paddingLeft: "25px",
    borderColor: "#CCCCCC",
  },
  label4: {
    fontSize: "15px",
    fontWeight: 600,
    color: "#3C65F5",
  },
  label5: {
    fontSize: "15px",
    fontWeight: 500,
    marginTop: "5px",
  },
};
