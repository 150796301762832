export default {
  colors: {
    PRIMARY: "",
    SECONDARY: "#0138FE",
    BLACK: "#000000",
    WHITE: "#FFFFFF",
  },
  fontsize: {
    SMALL: "",
    MEDIUM: "",
    LARGE: "",
  },
  shadow: {
    NAVSHADOW: "0px 0px 1px #0138FE",
  },
  backgroundColor: {
    DARKBUTTON: "#8658F0",
    HOVER: "#EEB04E",
    CONTAINERBACKGROUND: "#F1F3F6",
  },
};
