import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Offcanvas,
} from "react-bootstrap";
import gandalfWhite from "../../assets/svg/Color logo - no background.svg";
import backArrow from "../../assets/svg/icon/backArrow.svg";
import Theme from "../../constants/Theme";
import { HeaderStyles } from "./HeaderStyle";
import CustomButton2 from "../reusableComponents/CustomAuthRoutingButtons";
import { LangEN } from "../../constants/Lang/LangEN";
import { NavLink, Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { SignUpEnabledContext } from "../../App";

function Header() {
  const isSignUpEnabled = useContext(SignUpEnabledContext);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    // const user: any = localStorage.getItem("user");
    // if (user) setRole(JSON.parse(user).role);

    function handleResize() {
      if (window.innerWidth >= 1200) {
        setExpanded(false);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Navbar
      variant="dark"
      style={{ backgroundColor: Theme.colors.SECONDARY }}
      expand="xl"
      expanded={expanded}
      onToggle={(e) => {
        setExpanded(e);
      }}
    >
      <Container
        style={{
          paddingInline: "1vw",
          margin: 0,
          minWidth: "100%",
        }}
      >
        <Navbar.Brand style={HeaderStyles.navBrand} href="/">
          <img
            src={gandalfWhite}
            height="100"
            className="d-inline-block align-top"
            alt="logo"
            style={{ marginRight: 0 }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="d-none d-xl-block" id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={HeaderStyles.navLink} href="/">
              {LangEN.Home}
            </Nav.Link>
            <Nav.Link style={HeaderStyles.navLink} href="/about">
              {LangEN.AboutUs}
            </Nav.Link>
          </Nav>
          <Nav className="align-self-end">
            <CustomButton2
              variant="dark"
              hrefSignin="/register"
              hrefLogin="/login"
              style={HeaderStyles.darkButton}
              label={LangEN.ContinueProfessional}
            />

            <CustomButton2
              hrefSignin="/businessregister"
              hrefLogin="/businesslogin"
              variant="light"
              style={HeaderStyles.whiteButton}
              label={LangEN.ContinueBusiness}
            />

            {/* <CustomButton
                            href="/login"
                            variant="='light"
                            style={HeaderStyles.lightButton}
                            label="Log In"
                        /> */}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Offcanvas
          className="d-xl-none"
          id={`offcanvasNavbar-expand-${"xl"}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
          placement="end"
          style={{
            maxWidth: "min(100vw , 350px)",
            backgroundColor: Theme.colors.SECONDARY,
          }}
        >
          <Offcanvas.Body
            className="py-4 px-4"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Nav>
              <div className="mb-5 mt-3" style={{ display: "flex", gap: 40 }}>
                <Button
                  onClick={() => {
                    setExpanded(false);
                  }}
                  className="mb-3f"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    width: 20,
                  }}
                >
                  <img
                    src={backArrow}
                    width={20}
                    style={{ filter: "invert()" }}
                  />
                </Button>
                <Navbar.Brand style={HeaderStyles.navBrand} href="/">
                  <img
                    src={gandalfWhite}
                    className="d-inline-block align-top"
                    alt="logo"
                    style={{ marginRight: 20, width: "100%" }}
                  />
                </Navbar.Brand>
              </div>
              <NavLink
                onClick={() => setExpanded(false)}
                style={({ isActive }) =>
                  isActive ? HeaderStyles.navItemActive : HeaderStyles.navItem
                }
                to="/"
              >
                {LangEN.Home}
              </NavLink>
              <NavLink
                onClick={() => setExpanded(false)}
                style={({ isActive }) =>
                  isActive ? HeaderStyles.navItemActive : HeaderStyles.navItem
                }
                to="about"
              >
                {LangEN.AboutUs}
              </NavLink>
              <div
                className="p-3 my-3"
                style={{
                  border: "1px solid #aaa",
                  borderRadius: 12,
                }}
              >
                <Row>
                  <Col
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 20,
                      marginBottom: 20,
                    }}
                  >
                    {LangEN.ContinueBusiness}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      textAlign: "center",
                      fontSize: 18,
                    }}
                  >
                    <Link
                      to="/businesslogin"
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      {LangEN.Login}
                    </Link>
                  </Col>
                  {isSignUpEnabled && (
                    <>
                      <Col className="col-auto">
                        <div
                          style={{
                            width: 1,
                            height: "100%",
                            backgroundColor: "white",
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: 18,
                        }}
                      >
                        <Link
                          to="/businessregister"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          {LangEN.SignUp}
                        </Link>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
              <div
                className="p-3 my-3"
                style={{
                  border: "1px solid #aaa",
                  borderRadius: 12,
                }}
              >
                <Row>
                  <Col
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 20,
                      marginBottom: 20,
                    }}
                  >
                    {LangEN.ContinueProfessional}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      textAlign: "center",
                      fontSize: 18,
                    }}
                  >
                    <Link
                      to="/login"
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      {LangEN.Login}
                    </Link>
                  </Col>
                  {isSignUpEnabled && (
                    <>
                      <Col className="col-auto">
                        <div
                          style={{
                            width: 1,
                            height: "100%",
                            backgroundColor: "white",
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: 18,
                        }}
                      >
                        <Link
                          to="/register"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          {LangEN.SignUp}
                        </Link>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
