import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import dropdown from "../../assets/svg/dropdownIcon.svg";
import { useState, useEffect, useRef } from "react";
import { rolesEnum } from "../../enums/filterEnums";
import "./styles.css";
import CustomTagInput from "../../components/reusableComponents/CustomTagInput";
import {
  workExperience,
  paymentDuration,
  currencyType,
} from "../../enums/CreateJobEnums";
import { professionalAvailability } from "../../enums/ProfessionalAvailability";
import Slider from "rc-slider";
import { debounce } from "lodash";
import { handleRender } from "../../Utils/TooltipSlider";
import {
  currencyTypeArray,
  paymentDurationArray,
} from "../../constants/enumLabel";
import { mapWorkExperience } from "../../Utils/Mappers";

function Filter({
  roles,
  setRoles,
  experience,
  setHourlyRate,
  setAvailability,
  availability,
  hourlyRate,
  setExperience,
  skills,
  setSkills,
  isSearchJobs,
  paymentDurationValue,
  setPaymentDurationValue,
  currencyTypeValue,
  setCurrencyTypeValue,
  roleDisabled,
}: {
  currencyTypeValue: number;
  setCurrencyTypeValue: Function;
  paymentDurationValue: number;
  setPaymentDurationValue: Function;
  roles: number[];
  setRoles: Function;
  skills: number[];
  setSkills: Function;
  experience: number | undefined;
  setExperience: Function;
  availability: number | undefined;
  setAvailability: Function;
  hourlyRate: number[];
  setHourlyRate: Function;
  isSearchJobs?: boolean;
  roleDisabled?: boolean;
}) {
  const [sliderKey, setSliderKey] = useState(Date.now());

  useEffect(() => {
    paymentDurationValue == paymentDuration.Hourly
      ? setHourlyRate([1, 120])
      : setHourlyRate([1, 1000]);
    setSliderKey(Date.now());
  }, [paymentDurationValue]);

  const resetFilter = () => {
    setRoles([]);
    setSkills([]);
    setExperience();
    setAvailability();
    setHourlyRate([1, 120]);
    setSliderKey(Date.now());
  };
  return (
    <>
      <Row className="my-3 mx-2">
        <Col className={"job_title"}>{LangEN.Filter}</Col>
        <Col className="col-auto d-flex">
          <div className="job_reset" onClick={resetFilter}>
            {LangEN.Reset}
          </div>
        </Col>
      </Row>
      <Card className="cardShadow p-3 mx-2">
        <Form>
          {!isSearchJobs && (
            <>
              <Card.Title className="jobs_filterTitle">
                {LangEN.Roles}
              </Card.Title>
              <Form.Group className="mt-3">
                <Form.Check
                  disabled={roleDisabled}
                  label={LangEN.All}
                  name="filter-1"
                  className="my-2"
                  type="checkbox"
                  id={`filter-all`}
                  checked={roles.length == 0}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoles([]);
                    }
                  }}
                />
                <Form.Check
                  disabled={roleDisabled}
                  label={LangEN.ProjectManager}
                  name="filter-2"
                  className="my-2"
                  type="checkbox"
                  id={`filter-pm`}
                  checked={roles.includes(rolesEnum.ProjectManager)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoles((roles: number[]) => [
                        ...roles,
                        rolesEnum.ProjectManager,
                      ]);
                    } else {
                      setRoles((roles: number[]) =>
                        roles.filter((i) => i != rolesEnum.ProjectManager)
                      );
                    }
                  }}
                />
                <Form.Check
                  disabled={roleDisabled}
                  label={LangEN.Architect}
                  name="filter-3"
                  checked={roles.includes(rolesEnum.Architect)}
                  className="my-2"
                  type="checkbox"
                  id={`filter-architect`}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoles((roles: number[]) => [
                        ...roles,
                        rolesEnum.Architect,
                      ]);
                    } else {
                      setRoles((roles: number[]) =>
                        roles.filter((i) => i != rolesEnum.Architect)
                      );
                    }
                  }}
                />
                <Form.Check
                  disabled={roleDisabled}
                  label={LangEN.Experts}
                  name="filter-4"
                  className="my-2"
                  type="checkbox"
                  checked={roles.includes(rolesEnum.Experts)}
                  id={`filter-Experts`}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoles((roles: number[]) => [
                        ...roles,
                        rolesEnum.Experts,
                      ]);
                    } else {
                      setRoles((roles: number[]) =>
                        roles.filter((i) => i != rolesEnum.Experts)
                      );
                    }
                  }}
                />
              </Form.Group>
              <div className="horizontal-divider my-4" />
            </>
          )}
          <Card.Title className="jobs_filterTitle">{LangEN.Skills}</Card.Title>
          <CustomTagInput
            setSelectedValues={setSkills}
            selectedValues={skills}
            isSkills
            hideLabel
          />
          <div className="horizontal-divider my-4" />
          <Card.Title className="jobs_filterTitle">
            {LangEN.Experience}
          </Card.Title>
          <Form.Group className="mt-3">
            <Form.Check
              checked={workExperience.ZeroToTwoYears === experience}
              className="my-2"
              label={mapWorkExperience(0)}
              name="group2"
              type="radio"
              id={`workExperience${workExperience.ZeroToTwoYears}`}
              onChange={(e) => {
                if (e.target.checked)
                  setExperience(workExperience.ZeroToTwoYears);
                else setExperience(undefined);
              }}
            />
            <Form.Check
              checked={workExperience.TwoToFiveYears == experience}
              className="my-2"
              label={mapWorkExperience(1)}
              name="group2"
              type="radio"
              id={`workExperience${workExperience.TwoToFiveYears}`}
              onChange={(e) => {
                setExperience(
                  e.target.checked ? workExperience.TwoToFiveYears : undefined
                );
              }}
            />
            <Form.Check
              checked={workExperience.FiveToTenYears == experience}
              className="my-2"
              label={mapWorkExperience(2)}
              name="group2"
              type="radio"
              id={`workExperience${workExperience.FiveToTenYears}`}
              onChange={(e) => {
                setExperience(
                  e.target.checked ? workExperience.FiveToTenYears : undefined
                );
              }}
            />
            <Form.Check
              checked={workExperience.MoreThenTenYears == experience}
              label={mapWorkExperience(3)}
              className="my-2"
              name="group2"
              type="radio"
              id={`workExperience${workExperience.MoreThenTenYears}`}
              onChange={(e) => {
                setExperience(
                  e.target.checked ? workExperience.MoreThenTenYears : undefined
                );
              }}
            />
          </Form.Group>

          <div className="horizontal-divider my-4" />
          <Card.Title className="jobs_filterTitle">
            {LangEN.Availability}
          </Card.Title>
          <Form.Group className="mt-3">
            <Form.Check
              checked={professionalAvailability.FullTime == availability}
              label="Full Time"
              className="my-2"
              name="group3"
              type="radio"
              id={`availability${professionalAvailability.FullTime}`}
              onChange={(e) => {
                if (e.target.checked)
                  setAvailability(professionalAvailability.FullTime);
              }}
            />
            <Form.Check
              checked={professionalAvailability.PartTime == availability}
              label="Part Time"
              name="group3"
              className="my-2"
              type="radio"
              id={`availability${professionalAvailability.PartTime}`}
              onChange={(e) => {
                if (e.target.checked)
                  setAvailability(professionalAvailability.PartTime);
              }}
            />
            <Form.Check
              checked={professionalAvailability.Hourly == availability}
              label="Hourly"
              name="group3"
              className="my-2"
              type="radio"
              id={`availability${professionalAvailability.Hourly}`}
              onChange={(e) => {
                if (e.target.checked)
                  setAvailability(professionalAvailability.Hourly);
              }}
            />
          </Form.Group>
          <div className="horizontal-divider my-4" />
          <Card.Title className="jobs_filterTitle">
            {LangEN.HourlyRate}
          </Card.Title>
          <Row className="mb-2 gy-3 mt-2">
            <Col xl={7} lg={12}>
              <Form.Select
                onChange={(e) => setCurrencyTypeValue(parseInt(e.target.value))}
                className="shadow-none createJob_select"
                value={currencyTypeValue}
              >
                <option disabled>{LangEN.SelectCurrencyType}</option>
                <option selected value={currencyType.Default}>
                  {LangEN.Default}
                </option>
                <option value={currencyType.Dollar}>{LangEN.Dollar}</option>
                <option value={currencyType.Rupee}>{LangEN.Rupee}</option>
                <option value={currencyType.Dinar}>{LangEN.Dinar}</option>
                <option value={currencyType.Euro}>{LangEN.Euro}</option>
              </Form.Select>
            </Col>
            <Col xl={5} lg={12}>
              <Form.Select
                onChange={(e) =>
                  setPaymentDurationValue(parseInt(e.target.value))
                }
                value={paymentDurationValue}
                className="shadow-none createJob_select"
              >
                <option disabled>{LangEN.PaymentDuration}</option>
                <option selected value={paymentDuration.Default}>
                  {LangEN.Default}
                </option>
                <option selected value={paymentDuration.Hourly}>
                  {LangEN.Hourly}
                </option>
                <option value={paymentDuration.Daily}>{LangEN.Daily}</option>
              </Form.Select>
            </Col>
          </Row>
          <div className="d-flex " style={{ justifyContent: "space-between" }}>
            <div className="my-3 job_filterHour">
              {currencyTypeArray[currencyTypeValue]}
              {hourlyRate[0]}/
              {paymentDurationArray[paymentDurationValue] ? null : "-"}
              {paymentDurationArray[paymentDurationValue]}
            </div>
            <div className="my-3 job_filterHour">
              {currencyTypeArray[currencyTypeValue]}
              {hourlyRate[1]}/
              {paymentDurationArray[paymentDurationValue] ? null : "-"}
              {paymentDurationArray[paymentDurationValue]}
            </div>
          </div>
          <div className="mt-3 pe-2">
            <Slider
              key={sliderKey}
              range
              min={1}
              max={paymentDurationValue == paymentDuration.Hourly ? 120 : 1000}
              onChange={debounce((e: any) => {
                setHourlyRate(e);
              }, 500)}
              defaultValue={
                paymentDurationValue == paymentDuration.Hourly
                  ? [1, 120]
                  : [1, 1000]
              }
              railStyle={styles.railStyle}
              trackStyle={styles.trackStyle}
              handleStyle={styles.handleStyle}
              handleRender={handleRender}
            />
          </div>
          <div className="mt-4" />
        </Form>
      </Card>
    </>
  );
}

const styles = {
  railStyle: {
    backgroundColor: "#ddd",
    height: 8,
  },
  trackStyle: {
    backgroundColor: "#3c64f3",
    height: 8,
  },
  handleStyle: {
    borderColor: "#3c64f3",
    borderWidth: 7,
    height: 24,
    width: 24,
    marginLeft: 5,
    marginTop: -8,
    backgroundColor: "#fff",
    opacity: 1,
  },
};

export default Filter;
