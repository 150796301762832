import { LangEN } from "../constants/Lang/LangEN";
export const imageConverter = (e: any, setImage: any, setImageError: any) => {
  var file = e.files[0];
  var imageType = /image.*/;

  if (file.type.match(imageType)) {
    var reader = new FileReader();

    reader.onload = function (e) {
      var img: any = new Image();
      img.src = reader.result;
      setImage(img);
    };

    reader.readAsDataURL(file);
  } else {
    setImageError(LangEN.FileUnsupported);
  }
};

export const getAvatarInitials = (name: string) => {
  if (!name) return "";
  const nameLabelArr = name.split(" ");
  const checkLength = nameLabelArr.length > 1;
  const secondLetter = checkLength ? nameLabelArr[1][0] : "";
  return (nameLabelArr[0][0] + secondLetter).toUpperCase();
};

export const getName = () => {
  const user: any = localStorage.getItem("user");
  if (user)
    if (JSON.parse(user).lastName)
      return JSON.parse(user).firstName + " " + JSON.parse(user).lastName;
    else return JSON.parse(user).firstName;
};

export const getEmail = () => {
  const user: any = localStorage.getItem("user");
  if (user) return JSON.parse(user).email;
};

export const dateParser = (date: any) => {
  // let splitDate = date.split("-");
  // let year = parseInt(splitDate[0]);
  // let month = parseInt(splitDate[1]);
  // let day = parseInt(splitDate[2]);
  // let newDate = new Date(year, month - 1, day);
  let newDate2 = new Date(date);
  // var now_utc = Date.UTC(
  //   newDate.getUTCFullYear(),
  //   newDate.getUTCMonth(),
  //   newDate.getUTCDate(),
  //   newDate.getUTCHours(),
  //   newDate.getUTCMinutes(),
  //   newDate.getUTCSeconds()
  // );

  // console.log("data=>", date);
  // console.log("2-data=>", newDate2);
  // console.log("new data=>", newDate2.toUTCString());
  // console.log("utc data=>", now_utc);

  return newDate2.toUTCString();
};
