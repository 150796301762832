import Theme from "../../constants/Theme";
export const EmailVerificationStyles = {
    root: {
        backgroundColor: Theme.backgroundColor.CONTAINERBACKGROUND,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "100px",
        paddingTop: "100px",
    },
    col: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row" as "row",
        alignItems: "center",
        backgroundColor: Theme.colors.WHITE,
        boxShadow: Theme.shadow.NAVSHADOW,
    },
    section1: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as "column",
        alignItems: "center",
        width: "40%",
        height: "70vh",
    },
    col2: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row" as "row",
        alignItems: "center",
        backgroundColor: "#ffffff",
        marginLeft: "30px",
        boxShadow: Theme.shadow.NAVSHADOW,
    },
    section2: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as "column",
        alignItems: "center",
        width: "40%",
        height: "70vh",
    },
    container: {
        backgroundColor: Theme.backgroundColor.CONTAINERBACKGROUND,
        padding: "80px 5vw",
        margin: 0,
    },
    row1: {
        display: "flex",
        justifyContent: "space-evenly",
        gap: 20,
    },
    card: {
        display: "flex",
        alignItems: "center",
        paddingTop: 50,
        paddingBottom: 10,
        maxWidth: 700,
        flexShrink: 0,
        border: "none",
        boxShadow: "1px 3px 1px #ddd",
        minHeight: "100%",
    },
    cardBody: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
    },
    cardTitle: {
        fontWeight: 600,
        fontSize: 25,
        margin: 20,
        wordSpacing: 1.5,
    },
    cardSubTitle: {
        fontWeight: 400,
        fontSize: 18,
        wordSpacing: 2,
        paddingInline: 48,
        textAlign: "center" as "center",
    },
    cardSubTitle2: {
        fontWeight: 500,
        fontSize: 22,
        padding: 20,
    },
    otpInput: {
        width: "40px",
        height: "50px",
        borderRadius: "6px",
        borderColor: "#ccc",
        borderStyle: "solid",
        borderWidth: 1,
    },
    resendButton: {
        backgroundColor: "#fff",
        color: "#0d6efd",
        fontWeight: 600,
        fontSize: 14,
        border: "none",
    },
    cardText: {
        fontWeight: 400,
        fontSize: 18,
        wordSpacing: 2,
        paddingRight: 30,
        paddingLeft: 30,
        textAlign: "center" as "center",
    },
    completeProfileButton: {
        margin: 40,
        padding: "10px 20px",
        fontSize: 20,
    },
    skipNowContainer: {
        justifyContent: "center",
        display: "flex",
        margin: 50,
    },
};
