import Http from "./HttpService";
import { API } from "../constants/ApiDetails";

class NotificationService {
  public static async GetNotifications() {
    const url = `${API.NOTIFICATION}`;
    const response = await Http.get(url);
    return response;
  }
  public static async SaveFCMToken(apiReq: any) {
    const url = `${API.SAVE_TOKEN}`;
    const response = await Http.post(url, apiReq);
    return response;
  }
  public static async MarkAllNotificationsRead() {
    const url = `${API.READ_ALL_NOTIFICATIONS}`;
    const response = await Http.get(url);
    return response;
  }

  public static async MarkSingleNotificationsRead(id: number) {
    const url = `${API.READ_SINGLE_NOTIFICATIONS}/${id}`;
    const response = await Http.get(url);
    return response;
  }

  public static async DeleteFCMToken(deviceId: any) {
    const url = `${API.SAVE_TOKEN}/${deviceId}`;
    const response = await Http.delete(url);
    return response;
  }
}

export default NotificationService;
