function StepIndicator({ step }: { step: number }) {
    return (
        <div style={styles.container}>
            <div style={styles.progress}>
                <div
                    style={{
                        backgroundColor: "#3C65F5",
                        height: 5,
                        borderRadius: 10,
                        width: `${(step * 100) / 6}%`,
                    }}
                />
            </div>
            <div style={step >= 1 ? styles.steps : styles.UnActiveSteps}>1</div>
            <div style={step >= 2 ? styles.steps : styles.UnActiveSteps}>2</div>
            <div style={step >= 3 ? styles.steps : styles.UnActiveSteps}>3</div>
            <div style={step >= 4 ? styles.steps : styles.UnActiveSteps}>4</div>
            <div style={step >= 5 ? styles.steps : styles.UnActiveSteps}>5</div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-evenly",
        height: 50,
        position: "relative" as "relative",
        maxWidth: 870,
        minWidth: "min(870px , 80vw",
        flexShrink: 0,
    },
    progress: {
        position: "absolute" as "absolute",
        width: "100%",
        backgroundColor: "#D9D9D9",
        height: 5,
        top: 22.5,
        zIndex: 0,
        borderRadius: 20,
    },
    steps: {
        border: "4px solid white",
        borderRadius: 50,
        height: 50,
        width: 50,
        backgroundColor: "#3C65F5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: 25,
        zIndex: 2,
    },
    UnActiveSteps: {
        border: "4px solid white",
        borderRadius: 50,
        height: 50,
        width: 50,
        backgroundColor: "#D0DAFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 25,
        zIndex: 2,
    },
};

export default StepIndicator;
