import { useState, useEffect, useContext, useRef } from "react";
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { EditProfileStyle } from "./EditProfileStyle";
import CustomTagInput from "../../components/reusableComponents/CustomTagInput";
import { workExperience } from "../../enums/WorkExperience";
import { professionalAvailability } from "../../enums/ProfessionalAvailability";
import { professionalApplyAs } from "../../enums/ProfessionalApplyAs";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { validateCurrentRole } from "../../Utils/Validators";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import CustomImageInput from "../../components/reusableComponents/CustomImageInput";
import { LangEN } from "../../constants/Lang/LangEN";
import { User } from "../../App";
import CustomButton from "../../components/reusableComponents/CustomButton";
import { findAllByTestId } from "@testing-library/react";
import Loader from "../../components/reusableComponents/Loader";
import { UserRoles } from "../../enums/UserRoles";
import { currencyType, paymentDuration } from "../../enums/CreateJobEnums";
import {
  currencyTypeArray,
  paymentDurationArray,
} from "../../constants/enumLabel";
import CustomSkillService from "../../services/CustomSkillService";

export default function EditProfile() {
  const user = useContext(User);
  const navigate = useNavigate();
  const [id, setId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [pictureLoading, setPictureLoading] = useState(true);
  const [cvLoading, setCvLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [image, setImage] = useState<any>();
  const [imageFile, setImageFile] = useState([]);
  const [currentRole, setCurrentRole] = useState("");
  const [currentRoleError, setCurrentRoleError] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [CVError, setCVError] = useState("");
  const [linkdinUrl, setLinkdinUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [skills, setSkills] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [qualifications, setQualifications] = useState<any>([]);
  const [changeCV, setChangeCV] = useState(true);
  const [hourlyRate, setHourlyRate] = useState(20);
  const [isCVUploaded, setIsCVUploaded] = useState("");
  const [customSkills, setCustomSkills] = useState<any>([]);
  const [customSkillsPrevious, setCustomSkillsPrevious] = useState<any>([]);
  const [profileUrl, setProfileUrl] = useState("");
  const cvRef = useRef<any>();
  const [CV, setCV] = useState<any>([]);
  const [currencyTypeValue, setCurrencyTypeValue] = useState(
    currencyType.Dollar
  );
  const [paymentDurationValue, setPaymentDurationValue] = useState(
    paymentDuration.Daily
  );
  const [workExperienceValue, setWorkExperienceValue] = useState(
    workExperience.ZeroToTwoYears
  );
  const [applyAsValue, setApplyAsValue] = useState(
    professionalApplyAs.ProjectManager
  );
  const [availabilityValue, setAvailabilityValue] = useState(
    professionalAvailability.FullTime
  );

  const updateProfilePicture = (id: any) => {
    var data = new FormData();
    // console.log(imageFile[0]);
    data.append("profilePicture", imageFile[0]);
    data.append("userId", id ? id.toString() : "");
    data.append("role", UserRoles.Professional.toString());
    // console.log("jsfslfjslkfjsd=====>>>>", data);
    UserService.UploadProfilePicture(data)
      .then((res) => {
        setPictureLoading(false);
        console.log(res);
        navigatorAfterSuccess();
      })
      .catch((err) => console.log(err));
  };

  const updateCVUsingId = () => {
    UserService.ViewProfile()
      .then((res) => {
        const id = res.data.id;
        updateCV(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCV = (id: any) => {
    var data = new FormData();
    data.append("cv", CV[0]);
    data.append("userId", id ? id.toString() : "");
    UserService.UploadCV(data)
      .then((res) => {
        console.log(res);
        setCvLoading(false);
        navigatorAfterSuccess();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setHourlyRate(45);
  }, [paymentDurationValue]);

  const updateProfilePictureUsingId = () => {
    UserService.ViewProfile()
      .then((res) => {
        const id = res.data.id;
        updateProfilePicture(id);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPreviousData = () => {
    UserService.ViewProfile()
      .then((res) => {
        const user = res.data;
        setId(user.id);
        console.log(user);
        user.aboutMe && setAboutMe(user.aboutMe.slice(0, 500));
        user.applyAs && setApplyAsValue(user.applyAs);
        user.profilePicture && setProfileUrl(user.profilePicture);
        user.availability && setAvailabilityValue(user.availability);
        user.currentRole && setCurrentRole(user.currentRole);
        user.hourlyRate && setHourlyRate(user.hourlyRate);
        user.linkedinURL && setLinkdinUrl(user.linkedinURL);
        user.currencyType && setCurrencyTypeValue(user.currencyType);
        user.paymentDuration && setPaymentDurationValue(user.paymentDuration);
        if (user.customSkill) {
          setCustomSkillsPrevious(user.customSkill);
          let s = user.customSkill;
          setCustomSkills([]);
          s.map((item: any) =>
            setCustomSkills((pre: any) => [...pre, item.skillName])
          );
        }
        if (user.qualification) {
          let q = user.qualification;
          setQualifications([]);
          q.map((item: any) =>
            setQualifications((pre: any) => [...pre, item.qualification.id])
          );
        }
        if (user.skill) {
          let s = user.skill;
          setSkills([]);
          s.map((item: any) =>
            setSkills((pre: any) => [...pre, item.skill.id])
          );
        }
        // console.log(user.skill);

        user.workExperience && setWorkExperienceValue(user.workExperience);
        if (user.cv) {
          setIsCVUploaded(user.cv);
          setChangeCV(false);
        }

        setPageLoading(false);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPreviousData();
    UserService.GetAccountVerification()
      .then((res) => {
        let data = res.data.isProfileComplete;
        setIsEdit(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigatorAfterSuccess = () => {
    setPictureLoading((pre: any) => {
      if (!pre) {
        setCvLoading((pre: any) => {
          if (!pre) {
            setIsLoading((pre: any) => {
              if (!pre) {
                navigate("/profile");
              }
              return pre;
            });
          }
          return pre;
        });
      }
      return pre;
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    if (!currentRole || !!currentRoleError) return;
    setIsLoading(true);
    const isImageChanged = imageFile.length > 0;
    const isCVChanged = CV.length > 0;
    if (!isImageChanged) setPictureLoading(false);
    if (!isCVChanged) setCvLoading(false);
    if (isEdit) {
      const deletedCustomSkills = customSkillsPrevious.filter((item: any) => {
        return !customSkills.includes(item.skillName);
      });

      deletedCustomSkills.map((item: any) => {
        deleteCustomSkills(item.id);
      });

      const customSkillsAdded = customSkills.filter((item: any) => {
        let match = 0;
        customSkillsPrevious.map((innerItem: any) => {
          if (innerItem.skillName == item) {
            match += 1;
          }
        });
        return match == 0;
      });

      console.log("deleted =>", deletedCustomSkills);
      console.log("added =>", customSkillsAdded);

      let apiReq = {
        applyAs: applyAsValue,
        currentRole: currentRole.trim(),
        // profilePicture: null,
        skillId: skills,
        workExperience: workExperienceValue,
        qualificationId: qualifications,
        customSkill: customSkillsAdded,
        linkedinURL: linkdinUrl,
        hourlyRate: hourlyRate,
        availability: availabilityValue,
        aboutMe: aboutMe.slice(0, 500).trim(),
        id: id,
        currencyType: currencyTypeValue,
        paymentDuration: paymentDurationValue,
      };
      updateCustomSkills(id, customSkillsAdded);
      if (isImageChanged) updateProfilePicture(id);
      if (isCVChanged) updateCV(id);
      console.log("=======>>>>>>", apiReq);
      UserService.EditProfessionalProfile(apiReq)
        .then((res) => {
          console.log("success", res);
          setIsLoading(false);
          navigatorAfterSuccess();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      let apiReq = {
        applyAs: applyAsValue,
        currentRole: currentRole.trim(),
        // profilePicture: null,
        skillId: skills,
        workExperience: workExperienceValue,
        qualificationId: qualifications,
        linkedinURL: linkdinUrl,
        hourlyRate: hourlyRate,
        availability: availabilityValue,
        aboutMe: aboutMe.slice(0, 500).trim(),
        customSkill: customSkills,
        currencyType: currencyTypeValue,
        paymentDuration: paymentDurationValue,
      };
      console.log(apiReq);
      UserService.CreateProfessionalProfile(apiReq)
        .then((res) => {
          console.log("success", res);
          user.setUser((pre: any) => {
            pre.applyAs = apiReq.applyAs;
            localStorage.setItem("userData", JSON.stringify(pre));
            return pre;
          });
          user.setAccStatus((pre: any) => {
            pre.isProfileComplete = true;
            localStorage.setItem("accStatus", JSON.stringify(pre));
            return pre;
          });
          if (isCVChanged) updateCVUsingId();
          if (isImageChanged) updateProfilePictureUsingId();
          setIsLoading(false);
          navigatorAfterSuccess();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const updateCustomSkills = (profileId: any, customSkills: any) => {
    let apiReq = {
      profileId: profileId,
      customSkills: customSkills,
    };

    CustomSkillService.AddCustomProfileSkills(apiReq)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCustomSkills = (id: any) => {
    CustomSkillService.DeleteCustomProfileSkills(id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container
        style={EditProfileStyle.container}
        className="d-flex justify-content-center"
      >
        {pageLoading ? (
          <Loader lg />
        ) : (
          <Card
            className="cardShadow px-sm-5 px-4 pt-5 pb-5"
            style={EditProfileStyle.card}
          >
            <Form onSubmit={onSubmit} noValidate>
              <Row style={{ justifyContent: "space-evenly" }}>
                <Col className="col-12 col-md-5">
                  <label style={EditProfileStyle.label1}>
                    {isEdit ? LangEN.EditProfile : LangEN.CreateYourProfile}
                  </label>
                  <CustomImageInput
                    setImageFile={setImageFile}
                    image={image}
                    setImage={setImage}
                    setImageError={setImageError}
                    imageError={imageError}
                  />
                  {!isEdit && (
                    <>
                      <Form.Group className="mt-4" id={"check-api-radio"}>
                        <Form.Label
                          className="my-2 "
                          style={EditProfileStyle.label2}
                        >
                          {LangEN.ApplyAs} *
                        </Form.Label>
                        <Form.Check
                          style={EditProfileStyle.checkButton}
                          label="Project Manager"
                          name="group1"
                          checked={
                            applyAsValue == professionalApplyAs.ProjectManager
                          }
                          className="my-2"
                          type="radio"
                          id={`applyAs${professionalApplyAs.ProjectManager}`}
                          onChange={(e) => {
                            if (e.target.checked)
                              setApplyAsValue(
                                professionalApplyAs.ProjectManager
                              );
                          }}
                        />
                        <Form.Check
                          label="Architect"
                          checked={
                            applyAsValue == professionalApplyAs.Architect
                          }
                          style={EditProfileStyle.checkButton}
                          className="my-2"
                          name="group1"
                          type="radio"
                          id={`applyAs${professionalApplyAs.Architect}`}
                          onChange={(e) => {
                            if (e.target.checked)
                              setApplyAsValue(professionalApplyAs.Architect);
                          }}
                        />
                        <Form.Check
                          label="Experts"
                          name="group1"
                          checked={applyAsValue == professionalApplyAs.Expert}
                          style={EditProfileStyle.checkButton}
                          className="my-2"
                          type="radio"
                          id={`applyAs${professionalApplyAs.Expert}`}
                          onChange={(e) => {
                            if (e.target.checked)
                              setApplyAsValue(professionalApplyAs.Expert);
                          }}
                        />
                      </Form.Group>
                      <div className="my-3" style={EditProfileStyle.divider} />
                    </>
                  )}
                  <Form.Group controlId="formFile" className="mb-4 mt-4">
                    <Form.Label style={EditProfileStyle.label2}>
                      {LangEN.CurrentRole} *
                    </Form.Label>
                    <Form.Control
                      className="dim-placeholder shadow-none"
                      style={EditProfileStyle.inputField}
                      type="text"
                      placeholder={LangEN.EnterCurrentRole}
                      value={currentRole}
                      onChange={(e) => {
                        let value = e.target.value.trimStart();
                        currentRoleError &&
                          validateCurrentRole(value, setCurrentRoleError);
                        setCurrentRole(value);
                      }}
                      onBlur={() => {
                        validateCurrentRole(currentRole, setCurrentRoleError);
                      }}
                      isInvalid={!!currentRoleError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {currentRoleError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="my-3" style={EditProfileStyle.divider} />
                  <Form.Group controlId="formFile" className="mb-3 mt-4">
                    <Form.Label style={EditProfileStyle.label2}>
                      {LangEN.Skills}
                    </Form.Label>
                    <CustomTagInput
                      setSelectedValues={setSkills}
                      selectedValues={skills}
                      isSkills
                      customSkill={customSkills}
                      setCustomSkill={setCustomSkills}
                    />
                  </Form.Group>
                  <div className="my-3 mt-5" style={EditProfileStyle.divider} />
                  <Form.Group className="mt-4">
                    <Form.Label
                      className="my-2 "
                      style={EditProfileStyle.label2}
                    >
                      {LangEN.WorkExperience} *
                    </Form.Label>
                    <Form.Check
                      checked={
                        workExperience.ZeroToTwoYears == workExperienceValue
                      }
                      className="my-2"
                      style={EditProfileStyle.checkButton}
                      label="0 - 2 Years"
                      name="group2"
                      type="radio"
                      id={`workExperience${workExperience.ZeroToTwoYears}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setWorkExperienceValue(workExperience.ZeroToTwoYears);
                      }}
                    />
                    <Form.Check
                      checked={
                        workExperience.TwoToFiveYears == workExperienceValue
                      }
                      className="my-2"
                      label="2 - 5 Years"
                      style={EditProfileStyle.checkButton}
                      name="group2"
                      type="radio"
                      id={`workExperience${workExperience.TwoToFiveYears}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setWorkExperienceValue(workExperience.TwoToFiveYears);
                      }}
                    />
                    <Form.Check
                      checked={
                        workExperience.FiveToTenYears == workExperienceValue
                      }
                      className="my-2"
                      label="5 - 10 Years"
                      name="group2"
                      style={EditProfileStyle.checkButton}
                      type="radio"
                      id={`workExperience${workExperience.FiveToTenYears}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setWorkExperienceValue(workExperience.FiveToTenYears);
                      }}
                    />
                    <Form.Check
                      checked={
                        workExperience.MoreThenTenYears == workExperienceValue
                      }
                      label="More than 10 years"
                      className="my-2"
                      name="group2"
                      style={EditProfileStyle.checkButton}
                      type="radio"
                      id={`workExperience${workExperience.MoreThenTenYears}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setWorkExperienceValue(
                            workExperience.MoreThenTenYears
                          );
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-1 d-none d-md-flex justify-content-center">
                  <div style={EditProfileStyle.verticalDivider} />
                </Col>
                <Col className="col-12 col-md-5">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label
                      className="mb-3"
                      style={EditProfileStyle.label2}
                    >
                      {LangEN.EnterQualification}
                    </Form.Label>
                    <CustomTagInput
                      selectedValues={qualifications}
                      setSelectedValues={setQualifications}
                    />
                  </Form.Group>
                  <div className="my-3" style={EditProfileStyle.divider} />
                  <Form.Group className="mt-4">
                    <Form.Label
                      className="my-2 "
                      style={EditProfileStyle.label2}
                    >
                      Curriculum Vitae
                    </Form.Label>
                    {isCVUploaded ? (
                      <div className="mb-2" style={{ color: "green" }}>
                        CV uploaded
                      </div>
                    ) : (
                      ""
                    )}
                    {changeCV ? (
                      <>
                        <Form.Control
                          ref={cvRef}
                          onChange={(e: any) => {
                            console.log(e);
                            if (e.target.files[0]?.size > 5000000) {
                              setCVError("File size must be less then 5mb");
                              cvRef.current.value = "";
                            } //5 mb
                            else {
                              setCVError("");
                              setCV(e.target.files);
                            }
                          }}
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                        {CVError && (
                          <div className="mt-2 invalid-feedback d-block">
                            {CVError}
                          </div>
                        )}
                      </>
                    ) : (
                      <CustomButton
                        onClick={() => setChangeCV(true)}
                        label="Update CV"
                      />
                    )}
                  </Form.Group>
                  <div className="my-3" style={EditProfileStyle.divider} />

                  <Form.Group controlId="formFile" className="mb-3 mt-4">
                    <Form.Label style={EditProfileStyle.label2}>
                      {LangEN.EnterLinkdin}
                    </Form.Label>
                    <Form.Control
                      className="dim-placeholder shadow-none"
                      style={EditProfileStyle.inputField}
                      type="text"
                      placeholder="www.linkedin.com/in"
                      value={linkdinUrl}
                      onChange={(e) => setLinkdinUrl(e.target.value)}
                    />
                  </Form.Group>
                  <div className="my-3" style={EditProfileStyle.divider} />

                  <Form.Group controlId="formFile" className="mb-3 mt-4">
                    <Form.Label
                      className="mb-3"
                      style={EditProfileStyle.label2}
                    >
                      {LangEN.Rate} *
                    </Form.Label>
                    <Row className="mb-4 gy-3">
                      <Col md={12} lg={7}>
                        <Form.Select
                          onChange={(e) =>
                            setCurrencyTypeValue(parseInt(e.target.value))
                          }
                          className="shadow-none createJob_select"
                          value={currencyTypeValue}
                        >
                          <option disabled>{LangEN.SelectCurrencyType}</option>
                          <option selected value={currencyType.Dollar}>
                            {LangEN.Dollar}
                          </option>
                          <option value={currencyType.Rupee}>
                            {LangEN.Rupee}
                          </option>
                          <option value={currencyType.Dinar}>
                            {LangEN.Dinar}
                          </option>
                          <option value={currencyType.Euro}>
                            {LangEN.Euro}
                          </option>
                        </Form.Select>
                      </Col>
                      <Col md={12} lg={5}>
                        <Form.Select
                          onChange={(e) =>
                            setPaymentDurationValue(parseInt(e.target.value))
                          }
                          value={paymentDurationValue}
                          className="shadow-none createJob_select"
                        >
                          <option disabled>{LangEN.PaymentDuration}</option>
                          <option selected value={paymentDuration.Hourly}>
                            {LangEN.Hourly}
                          </option>
                          <option value={paymentDuration.Daily}>
                            {LangEN.Daily}
                          </option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <h3 style={EditProfileStyle.labelBlue}>
                      {currencyTypeArray[currencyTypeValue]}
                      {hourlyRate}/{paymentDurationArray[paymentDurationValue]}
                    </h3>
                    <Slider
                      className="mt-4"
                      min={1}
                      max={
                        paymentDurationValue == paymentDuration.Daily
                          ? 1000
                          : 120
                      }
                      value={hourlyRate}
                      onChange={(e: any) => setHourlyRate(e)}
                      defaultValue={20}
                      railStyle={EditProfileStyle.railStyle}
                      trackStyle={EditProfileStyle.trackStyle}
                      handleStyle={EditProfileStyle.handleStyle}
                      //handleRender={handleRender}
                    />
                  </Form.Group>
                  <div className="my-3 mt-4" style={EditProfileStyle.divider} />
                  <Form.Group className="mt-4">
                    <Form.Label
                      className="my-2 "
                      style={EditProfileStyle.label2}
                    >
                      {LangEN.Availability} *
                    </Form.Label>
                    <Form.Check
                      checked={
                        professionalAvailability.FullTime == availabilityValue
                      }
                      label="Full Time"
                      style={EditProfileStyle.checkButton}
                      className="my-2"
                      name="group3"
                      type="radio"
                      id={`availability${professionalAvailability.FullTime}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setAvailabilityValue(
                            professionalAvailability.FullTime
                          );
                      }}
                    />
                    <Form.Check
                      checked={
                        professionalAvailability.PartTime == availabilityValue
                      }
                      style={EditProfileStyle.checkButton}
                      label="Part Time"
                      name="group3"
                      className="my-2"
                      type="radio"
                      id={`availability${professionalAvailability.PartTime}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setAvailabilityValue(
                            professionalAvailability.PartTime
                          );
                      }}
                    />
                    <Form.Check
                      checked={
                        professionalAvailability.Hourly == availabilityValue
                      }
                      label="Hourly"
                      name="group3"
                      style={EditProfileStyle.checkButton}
                      className="my-2"
                      type="radio"
                      id={`availability${professionalAvailability.Hourly}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setAvailabilityValue(professionalAvailability.Hourly);
                      }}
                    />
                  </Form.Group>
                  <div className="mb-3" style={EditProfileStyle.divider} />
                  <Form.Group controlId="formFile" className="mb-3 mt-4">
                    <Form.Label style={EditProfileStyle.label2}>
                      {LangEN.AboutMe} *
                    </Form.Label>
                    <Form.Control
                      style={EditProfileStyle.textArea}
                      onChange={(e) => {
                        setAboutMe(e.target.value.slice(0, 500).trimStart());
                      }}
                      value={aboutMe}
                      maxLength={500}
                      placeholder="Add Description"
                      as="textarea"
                    />
                  </Form.Group>
                  <Row className="mt-5">
                    <Col className="col-auto">
                      <Button
                        style={EditProfileStyle.cancelButton}
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        {LangEN.Cancel}
                      </Button>
                    </Col>
                    <Col className="col-auto">
                      <CustomButton
                        isLoading={isLoading}
                        style={EditProfileStyle.submitButton}
                        disabled={
                          !currentRole || !!currentRoleError || !aboutMe
                        }
                        label={LangEN.Submit}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Container>
    </>
  );
}
