import { Container, Row, Col, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MyProjectStyles } from "./MyProjectStyles";
import CustomButton from "../../components/reusableComponents/CustomButton";
import folder from "../../assets/svg/icon/folder.svg";
import { LangEN } from "../../constants/Lang/LangEN";
import { useState, useEffect } from "react";
import ProjectCard from "../../components/reusableComponents/ProjectCard";
import moment from "moment";
import ProjectService from "../../services/ProjectServices";
import JobSApplicationService from "../../services/JobApplicationService";
import Loader from "../../components/reusableComponents/Loader";
import { getTimeBetweenDates } from "../../Utils/Mappers";

function MyProjectProfessional() {
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // ProjectService.ProjectList()
    //   .then((res) => {
    //     console.log(res.data);
    //     setProjectList(res.data);
    //   })
    //   .catch((err) => console.log(err));

    JobSApplicationService.GetHiredJob("")
      .then((res) => {
        setProjectList(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const getWeeks = (startDate: string, endDate: string) => {
    let projectStartDate: any = moment(new Date(startDate));
    let projectEndDate: any = moment(new Date(endDate));
    let diff = moment.duration(projectEndDate.diff(projectStartDate));
    return `${Math.floor(diff.asWeeks())} Weeks`;
  };

  const getProjectMAnagerCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 1 ? item.resourceCount : null;
    });
  };

  const getExpertsCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 3 ? item.resourceCount : null;
    });
  };

  const getArchitectCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 2 ? item.resourceCount : null;
    });
  };

  if (loading) return <Loader lg />;

  return (
    <Container style={MyProjectStyles.container}>
      <Row className="py-5 px-0">
        <Col>
          {!projectList.length ? (
            <>
              <h3 className="mb-4" style={MyProjectStyles.heading}>
                {LangEN.MyProjects}
              </h3>
              <Card className="p-4" style={MyProjectStyles.card}>
                <img width={100} height={100} src={folder} />
                <Card.Title style={MyProjectStyles.cardTitle} className="m-4">
                  {LangEN.NoProjectJoined}
                </Card.Title>
                <Card.Subtitle style={MyProjectStyles.cardSubTitle}>
                  {LangEN.ApplyForAJob}
                </Card.Subtitle>
              </Card>
            </>
          ) : (
            <>
              <Row
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Col className="col-auto">
                  <h3 className="mb-0" style={MyProjectStyles.heading}>
                    {LangEN.MyProjects}
                  </h3>
                </Col>
              </Row>
              {projectList.map((application: any, index: number) => {
                return (
                  <ProjectCard
                    isProfessional
                    projectId={application.job.project.id}
                    title={application.job.project.name}
                    subtitle={application.job.project.projectDescription}
                    requiredArchitect={getArchitectCount(
                      application.job.project
                    )}
                    requiredExperts={getExpertsCount(application.job.project)}
                    requiredPM={getProjectMAnagerCount(application.job.project)}
                    projectDuration={
                      application.job.project.projectStartDate ==
                      application.job.project.projectEndDate
                        ? "a day"
                        : getTimeBetweenDates(
                            application.job.project.projectStartDate,
                            application.job.project.projectEndDate
                          )
                    }
                  />
                );
              })}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default MyProjectProfessional;
