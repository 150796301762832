import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { LangEN } from "../../constants/Lang/LangEN";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomButton from "../../components/reusableComponents/CustomButton";
import JobCard from "./JobCard";
import ProjectService from "../../services/ProjectServices";
import moment from "moment";
import pencil from "../../assets/svg/icon/pencil-blue.svg";
import EditProjectModal from "../../components/Modals/EditProjectModal/EditProjectModal";
import UserCard from "../../components/reusableComponents/UserCard";
import Loader from "../../components/reusableComponents/Loader";
import { useContext } from "react";
import { BreadCrumbsContext, User, showToast } from "../../App";
import { resourceType } from "../../enums/CreateJobEnums";
import { getTimeBetweenDates, getWeeks } from "../../Utils/Mappers";

function ProjectDetails({
  notProjectRoute,
  isProfessional,
  isHired,
  isJobs,
}: {
  notProjectRoute?: boolean;
  isProfessional?: boolean;
  isHired?: boolean;
  isJobs?: boolean;
}) {
  const bread = useContext(BreadCrumbsContext);
  const { state } = useLocation();
  const [projectInfo, setProjectInfo] = useState<any>(null);
  const [projectTeamMembers, setProjectTeamMembers] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const { id } = useParams();

  const breadData = [
    {
      label: LangEN.MyProjects,
      route: "/projects",
    },
    {
      label: LangEN.ProjectDetails,
      route: "/",
    },
  ];
  const breadDataJobsRoute = [
    {
      label: LangEN.Jobs,
      route: "/jobs/search",
    },
    {
      label: LangEN.JobDetails,
      route: "/jobs/details",
      state: state.job,
    },
    {
      label: LangEN.ProjectDetails,
      route: "/",
    },
  ];
  const breadDataProfessionalRoute = [
    {
      label: LangEN.Professionals,
      route: "/professional",
    },
    {
      label: LangEN.ProfessionalDetails,
      route: "/professional/details",
      state: {
        id: state.userId,
        application: { id: 1, jobSorting: true },
        showAction: false,
      },
    },
    {
      label: LangEN.Invite,
      route: "/",
    },
  ];

  const getMembers = () => {
    ProjectService.ProjectTeamMembers(state.id)
      .then((res) => {
        setProjectTeamMembers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    ProjectService.ProjectDetail(state.id)
      .then((res) => {
        setProjectInfo(res.data);
        setLoading(false);
        getMembers();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state.id]);

  useEffect(() => {
    if (notProjectRoute) {
      bread.setData(breadDataProfessionalRoute);
    } else if (isJobs) {
      bread.setData(breadDataJobsRoute);
    } else {
      bread.setData(breadData);
    }

    return () => {
      bread.setData([]);
    };
  }, []);

  const getProjectMAnagerCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 1 ? item.resourceCount : null;
    });
  };

  const getArchitectCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 2 ? item.resourceCount : null;
    });
  };

  const getExpertsCount = (project: any) => {
    if (!project) return;
    return project?.resourceCount?.map((item: any) => {
      return item.resourceType === 3 ? item.resourceCount : null;
    });
  };

  // const getWeeks = (startDate: string, endDate: string) => {
  //   let projectStartDate: any = moment(new Date(startDate));
  //   let projectEndDate: any = moment(new Date(endDate));
  //   let diff = moment.duration(projectEndDate.diff(projectStartDate));
  //   return `${Math.floor(diff.asWeeks())} Weeks`;
  // };

  return (
    <Container className="main_container">
      <EditProjectModal
        data={projectInfo}
        show={showModal}
        setShow={setShowModal}
        setData={setProjectInfo}
      />
      {loading ? (
        <Loader lg />
      ) : (
        <Card className="p-4" style={styles.card}>
          {!isProfessional && (
            <div
              style={{
                right: 20,
                top: 20,
                borderRadius: 40,
                height: 45,
                width: 45,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #3C65F5",
                cursor: "pointer",
                position: "absolute",
                backgroundColor: " #E0E6F7",
              }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={pencil} width={20} height={20} />
            </div>
          )}
          <Card.Title style={styles.title}>{projectInfo?.name}</Card.Title>
          <Card.Subtitle style={styles.subTitle}>
            {projectInfo?.projectDescription}
          </Card.Subtitle>
          <div className="horizontal-divider my-3" />
          <Row>
            <Col xs="auto">
              <Card.Title style={styles.title2}>
                {LangEN.RequiredResource}
              </Card.Title>
              <Row className="my-3" styles={styles.label}>
                <Col style={styles.label}>{LangEN.ProjectManager}:</Col>
                <Col className="col-auto" style={styles.blueText}>
                  {getProjectMAnagerCount(projectInfo)}
                </Col>
              </Row>
              <Row className="my-3">
                <Col style={styles.label}>{LangEN.Architect}:</Col>
                <Col className="col-auto" style={styles.blueText}>
                  {getArchitectCount(projectInfo)}
                </Col>
              </Row>
              <Row className="my-3">
                <Col style={styles.label}>{LangEN.Experts}:</Col>
                <Col className="col-auto" style={styles.blueText}>
                  {getExpertsCount(projectInfo)}
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              <div className="vertical-divider mx-4"></div>
            </Col>
            <Col xs="auto" style={styles.flexBox}>
              <div>
                <Card.Title style={styles.title2}>
                  {LangEN.ProjectDuration}
                </Card.Title>
                <div className="my-3" style={styles.blueText}>
                  {projectInfo?.projectStartDate == projectInfo?.projectEndDate
                    ? "a day"
                    : getTimeBetweenDates(
                        projectInfo?.projectStartDate,
                        projectInfo?.projectEndDate
                      )}
                </div>
              </div>
            </Col>
            {/* Team Members */}
            {!isJobs && (
              <>
                <Col className="col-auto">
                  <div className="vertical-divider mx-4"></div>
                </Col>
                <Col style={styles.flexBox}>
                  <div>
                    <Card.Title style={styles.title2}>
                      {LangEN.TeamMembers}
                    </Card.Title>
                    <div className="my-3" style={styles.teamMembers}>
                      {projectTeamMembers?.map((item: any, index: number) => (
                        <UserCard
                          projectId={projectInfo.id}
                          item={item}
                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <div className="horizontal-divider my-4"></div>
          <Row className="mb-3">
            <Col>
              <Card.Title style={styles.title2}>
                {LangEN.CreatedJobsList}
              </Card.Title>
            </Col>
            {!isProfessional && (
              <Col className="d-flex justify-content-end">
                <CustomButton
                  onClick={() => {
                    console.log(projectInfo);
                    const pmLeft =
                      projectInfo.resourceCount.filter(
                        (item: any) =>
                          item.resourceType == resourceType.ProjectManager
                      )[0].resourceCount - projectInfo.totalProjectManagerJobs;

                    const architectLeft =
                      projectInfo.resourceCount.filter(
                        (item: any) =>
                          item.resourceType == resourceType.Architect
                      )[0].resourceCount - projectInfo.totalArchitectJobs;

                    const expertLeft =
                      projectInfo.resourceCount.filter(
                        (item: any) => item.resourceType == resourceType.Experts
                      )[0].resourceCount - projectInfo.totalExpertJobs;

                    if (pmLeft > 0 || architectLeft > 0 || expertLeft > 0) {
                      navigate("/projects/details/createjob", {
                        state: {
                          id: projectInfo.id,
                          unHiredArchitectCount: architectLeft,
                          unHiredExpertCount: expertLeft,
                          unHiredPMCount: pmLeft,
                        },
                      });
                    } else {
                      showToast(LangEN.NoMoreJobToCreate);
                    }
                  }}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #3C65F5",
                    color: "#3C65F5",
                    shadow: "none",
                  }}
                  label={LangEN.CreateAJob}
                />
              </Col>
            )}
          </Row>
          {projectInfo?.jobs?.map((job: any, index: number) => {
            job.project = projectInfo;
            return (
              <JobCard
                notProjectRoute={notProjectRoute}
                job={job}
                index={index}
                id={projectInfo.id}
                isProfessional={isProfessional}
                isHired={isHired}
                userId={state?.userId}
                isJobs={isJobs}
                role={state.role}
              />
            );
          })}
        </Card>
      )}
    </Container>
  );
}

const styles = {
  card: {
    width: "100%",
    border: "none",
    boxShadow: "1px 3px 1px #ddd",
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  teamMembers: {
    maxHeight: "200px",
    overflow: "auto" as "auto",
    overflowX: "hidden" as "hidden",
  },
  title: {
    fontSize: 24,
    color: "#05264E",
    fontWeight: 600,
    marginBottom: 15,
  },
  subTitle: {
    fontSize: 18,
    color: "#66789C",
    lineHeight: 1.6,
  },
  title2: {
    fontWeight: 600,
  },
  label: {
    fontSize: 18,
  },
  blueText: {
    color: "#3C65F5",
    fontSize: 18,
  },
  flexBox: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between" as "space-between",
  },
};

export default ProjectDetails;
