import React, { useEffect } from "react";
import { InputGroup, Form } from "react-bootstrap";

import up from "../../assets/svg/icon/upArrow.svg";
import down from "../../assets/svg/icon/downArrow.svg";

function CustomNumberInput({
  value,
  setValue,
  disabled = false,
  minValue = 1,
}: {
  value: number;
  setValue: Function;
  disabled?: boolean;
  minValue?: number;
}) {
  useEffect(() => {
    if (value < minValue) setValue(minValue);
  }, []);
  return (
    <InputGroup>
      <Form.Control
        disabled={disabled}
        className="shadow-none"
        readOnly
        type="number"
        // onKeyDown={(event: any) => event.charCode >= 48}
        // onkeyup="if(this.value<0)this.value=1"
        onKeyDown={(e) => {
          let code = e.keyCode;
          return true;
        }}
        style={styles.input}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="noSelect" style={styles.numberInputButtonsContainer}>
        <div
          onClick={() => setValue((pre: any) => pre + 1)}
          style={styles.button}
        >
          <img src={up} width={12} />
        </div>
        <div
          onClick={() =>
            setValue((pre: any) => {
              if (pre == minValue) return pre;
              return pre - 1;
            })
          }
          style={styles.button}
        >
          <img src={down} width={12} />
        </div>
      </div>
    </InputGroup>
  );
}

const styles = {
  input: {
    borderRightWidth: 0,
    borderColor: "#cce",
    height: 50,
  },
  numberInputButtonsContainer: {
    border: "1px solid #cce",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderLeftWidth: 0,
    backgroundColor: "#ddd",
    position: "relative" as "relative",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 6.5,
    paddingInline: 10,
    cursor: "pointer",
    height: "50%",
  },
};

export default CustomNumberInput;
