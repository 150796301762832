import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import success from "../../assets/svg/icon/okay.svg";
import error from "../../assets/svg/icon/error.svg";

function ToastModal({
  label,
  buttonText,
  type,
  action,
  show,
  setShow,
}: {
  show: boolean;
  setShow: Function;
  label: string;
  type: string;
  action: Function;
  buttonText: string;
}) {
  useEffect(() => {
    // setTimeout(() => {
    //   setShow(false);
    // }, 3000);
  }, []);
  return (
    <Modal
      onHide={() => {
        setShow(false);
      }}
      show={show}
      animation
      className="modalContainer"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="px-5 py-3"
      >
        {type == "success" && <img src={success} width={30} />}
        {type == "error" && <img src={error} width={30} />}
        <div
          style={{
            fontSize: 20,
            color: "#000",
            textAlign: "center",
            fontWeight: 500,
          }}
          className="p-3"
        >
          {label}
          <span
            style={{
              fontSize: 20,
              color: "#3C65F5",
              textAlign: "center",
              fontWeight: 600,
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            className="p-2"
            onClick={() => {
              setShow(false);
              action();
            }}
          >
            {buttonText}
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default ToastModal;
