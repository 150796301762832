import React from "react";
import { Col, Row } from "react-bootstrap";
import { mapJobRole } from "../../Utils/Mappers";
import TextAvatar from "./TextAvatar";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";

function UserCard({
  item,
  index,
  projectId,
}: {
  projectId?: any;
  item: any;
  index: number;
}) {
  const navigate = useNavigate();
  console.log(item);
  return (
    <Row
      key={index}
      className="my-3"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate("professional", {
          state: { application: item, id: item.user.id, projectId: projectId },
        });
      }}
    >
      <Col xs="auto">
        <Avatar
          url={item.profilePicture}
          size={45}
          fontSize={20}
          firstName={item.user.firstName}
          lastName={item.user.lastName}
        />
      </Col>
      <Col>
        <Row>
          <Col className="ps-0">
            <div style={{ fontWeight: 600, fontSize: 18 }}>
              {item.user.firstName} {item.user.lastName}
            </div>
            <div style={{ fontSize: 14, color: "#A0ABB8;" }}>
              {mapJobRole(item.applyAs)}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default UserCard;
