import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

function Text({
  style,
  children,
  line = 1,
}: {
  style?: any;
  children: string;
  line?: number;
}) {
  return (
    <ResponsiveEllipsis
      style={style}
      text={children}
      maxLine={line}
      ellipsis="..."
      trimRight
      basedOn="letters"
    />
  );
}

export default Text;
